import { useState } from 'react';
import { useIntl } from 'react-intl';
import UserAddEdit from 'components/branch/user/UserAddEdit';
import './UserMenu.scss';

export default function UserMenu(props: any) {
  const { formatMessage } = useIntl();
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="user-page-menu-wrap">
      <div className="menu-button">
        <button
          className="common-button-brand initial-config-bt"
          type="button"
          onClick={() => setOpenModal(true)}
        >
          {formatMessage({ id: 'Button_10', defaultMessage: '등 록' })}
        </button>
      </div>
      {openModal && <UserAddEdit visible={openModal} isAddMode dataInfo={{}} toggleModal={setOpenModal} />}
    </div>
  );
}
