import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { addUser, updateUser } from 'store/user';
import * as valid from 'utils/validation';
import Loading from 'components/common/Loading';
import ModalTemplate from 'components/common/ModalTemplate';
import FormSelectField from 'components/common/FormSelectField';
import { getEduAttach } from 'store/edu';
import { addEduQuiz, updateEduQuiz } from 'store/quiz';
// import './QuizAddEdit.scss';

function QuizAddEdit({ visible, isAdmin, isAddMode, dataInfo, toggleModal }: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { errors, control, register, getValues, setValue, setError, handleSubmit, watch } = useForm(
    {
      mode: 'all',
      defaultValues: {
        quizContent: dataInfo.quizContent,
        quizSolution: dataInfo.quizSolution,
        eduAttachNo: dataInfo.eduAttachNo,
        fileNo: dataInfo.fileNo,
      },
    },
  );
  // 교육 영상 드롭다운 메뉴
  const [fileMenu, setFileMenu] = useState([]);
  const eduAttachData = useSelector((state: any) => state.edu.eduAttach.data);
  const watchFields = watch();

  useEffect(() => {
    onGetEduAttach();
  }, []);

  // 교육 컨텐츠 조회
  const onGetEduAttach = async () => {
    try {
      setLoading(true);
      const response: any = await dispatch(getEduAttach({}));
      if (response?.data?.list) {
        const eduFileMenu: any = [];
        let selectAttachNo = 0;
        response.data.list.forEach((data: any) => {
          data.fileInfo.forEach((file: any) => {
            if (file.fileNo === dataInfo.fileNo) {
              selectAttachNo = data.eduAttachNo;
              // 교육 컨텐츠 기본값 설정
              setValue('eduAttachNo', data.eduAttachNo);
            }
          });

          // 선택한 교육 영상 드롭다운 리스트
          if (selectAttachNo === data.eduAttachNo) {
            data.fileInfo.forEach((file: any) => {
              eduFileMenu.push({
                key: file.fileNo,
                value: file.fileNo,
                text: file.fileName,
              });
            });
            // 교육 영상 기본값 설정
            setValue('fileNo', dataInfo.fileNo);
          }
        });
        setFileMenu(eduFileMenu);

        setLoading(false);
      }
    } catch (error) {
      console.log('QuizAddEdit onGetEduAttach', error);
    }
  };

  // 퀴즈 정보 저장
  const onSaveQuiz = async (formData: any) => {
    try {
      const params: any = {};

      // 값 변경 여부
      let changed = isAddMode ? true : false;
      Object.keys(formData).forEach((key: any) => {
        let value = formData[key];
        if (key === 'quizSolution') {
          value = parseInt(formData[key], 10);
        }

        // 변경된 항목만 파라미터에 넣어줌
        if (value !== dataInfo[key]) {
          if (key !== 'eduAttachNo') {
            changed = true;
            params[key] = value;
          }
        }

        if (isAddMode) {
          params.quizType = 1;
        } else {
          params.quizNo = dataInfo.quizNo;
        }
      });

      if (changed) {
        setLoading(true);
        const response: any = await dispatch(
          isAddMode ? addEduQuiz(params) : updateEduQuiz(params),
        );
        if (!response?.data?.error) {
          setLoading(false);
          toggleModal(false);
        }
      } else {
        toggleModal(false);
      }
    } catch (error) {
      console.log('QuizAddEdit onSaveQuiz', error);
    }
  };

  // 교육 컨텐츠 드롭다운 리스트
  const eduAttachMenu: any = [];
  eduAttachData?.forEach((attach: any) => {
    eduAttachMenu.push({
      key: attach.eduAttachNo,
      value: attach.eduAttachNo,
      text: attach.attachName,
    });
  });

  // 교육 컨텐츠 변경 시
  const changeEduAttach = (name: string, data: any) => {
    setValue('eduAttachNo', data);
    setValue('fileNo', null);

    const selectData = eduAttachData.filter((attach: any) => attach.eduAttachNo === data);
    const eduFileMenu: any = [];
    if (selectData && selectData[0]) {
      selectData[0].fileInfo?.forEach((data: any) => {
        eduFileMenu.push({
          key: data.fileNo,
          value: data.fileNo,
          text: data.fileName,
        });
      });
      setFileMenu(eduFileMenu);
    }
  };

  return (
    <ModalTemplate
      className="user-add-edit-modal modal-464"
      visible={visible}
      title={
        <div className="modal-template-header">
          <div className="title">
            {isAddMode
              ? formatMessage({ id: 'Edu_67', defaultMessage: '퀴즈 등록' })
              : formatMessage({ id: 'Edu_68', defaultMessage: '퀴즈 수정' })}
          </div>
        </div>
      }
      onOk={handleSubmit(onSaveQuiz)}
      onCancel={() => toggleModal(false)}
      okText={
        isAddMode
          ? formatMessage({ id: 'Button_10', defaultMessage: '등 록' })
          : formatMessage({ id: 'Button_14', defaultMessage: '수 정' })
      }
      cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
      loading={loading}
    >
      <Loading loading={loading} />
      <form autoComplete="off" onSubmit={(e: any) => e.preventDefault()}>
        <div className="input-title">
          {formatMessage({ id: 'Edu_33', defaultMessage: '퀴즈 내용' })}
        </div>
        <Controller
          control={control}
          name="quizContent"
          defaultValue={dataInfo.quizContent}
          rules={{
            validate: {
              required: (value: any) => valid.required(value),
              range: (value: any) => valid.range(value, 0, 5000),
            },
          }}
          render={({ onChange, onBlur, value }: any) => (
            <textarea
              className={`textarea-input${errors.quizContent ? ' warning' : ''}`}
              cols={100}
              rows={3}
              onChange={onChange}
              defaultValue={dataInfo.quizContent}
            />
          )}
        />

        {/* 정답 */}
        <div className="text-field-title">
          {formatMessage({ id: 'Edu_35', defaultMessage: '정답' })}
        </div>
        <FormSelectField
          name="quizSolution"
          control={control}
          error={errors.quizSolution}
          menuList={[
            { key: 1, value: 1, text: 'O' },
            { key: 2, value: 2, text: 'X' },
          ]}
          defaultValue={watchFields.quizSolution}
          validation={{
            validate: {
              required: (value: any) => valid.required(value),
            },
          }}
          showArrow
        />

        {/* 교육 컨텐츠 */}
        <div className="text-field-title">
          {formatMessage({ id: 'Edu_10', defaultMessage: '교육 컨텐츠' })}
        </div>
        <FormSelectField
          name="eduAttachNo"
          control={control}
          error={errors.eduAttachNo}
          menuList={eduAttachMenu}
          value={watchFields.eduAttachNo}
          handleOnChange={changeEduAttach}
          showArrow
          validation={{
            validate: {
              required: (value: any) => valid.required(value),
            },
          }}
        />

        {/* 교육 영상 */}
        <div className="text-field-title">
          {formatMessage({ id: 'Edu_38', defaultMessage: '교육 영상' })}
        </div>
        <FormSelectField
          name="fileNo"
          control={control}
          error={errors.fileNo}
          menuList={fileMenu}
          value={watchFields.fileNo}
          //   defaultValue={isAddMode ? 1 : dataInfo.fileNo}
          showArrow
          disabled={!watchFields.eduAttachNo}
          validation={{
            validate: {
              required: (value: any) => valid.required(value),
            },
          }}
        />
      </form>
    </ModalTemplate>
  );
}

export default QuizAddEdit;
