import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { adminCheckSmtp } from 'store/admin';
import { userCheckSmtp } from 'store/config';
import { timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import { LoadingOutlined } from '@ant-design/icons';
import './SmtpTest.scss';

// import iconCheck from 'img/exam/startExam/ico_check.png';
// import iconFail from 'img/exam/startExam/ico_fail.png';

export default function Smtpconf(props: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { isAdmin } = props;
  let smtpTestInfo: any = null;
  if (isAdmin === 1) {
    smtpTestInfo = useSelector((state: any) => state.admin.smtpConfig?.parameter?.mailSmtpTested);
  } else {
    smtpTestInfo = useSelector((state: any) => state.config.smtpConfig?.parameter?.mailSmtpTested);
  }

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const paramOption = smtpTestInfo?.paramOptions;
  const paramValue = smtpTestInfo?.value;
  const { checkSuccessEpoch, checkFailEpoch, needReCheck, log } = paramOption || {};

  const checkSmtp = async () => {
    setLoading(true);
    setVisible(true);

    try {
      if (isAdmin === 1) {
        await dispatch(adminCheckSmtp());
      } else {
        await dispatch(userCheckSmtp());
      }
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  };

  return (
    <div className="test-box">
      <div className="bt-test-area">
        <button type="button" className="ok" disabled={loading} onClick={checkSmtp}>
          {formatMessage({ id: 'Config_10', defaultMessage: '연결 테스트' })}
          {loading && (
            <span className="button-loading">
              <LoadingOutlined />
            </span>
          )}
        </button>
      </div>
      {visible && !loading && paramValue !== '0' && (
        <div>
          <div className="connecting-row">
            {paramValue === '1' ? (
              <div className="connecting-text">
                <img src="/img/exam/startExam/ico_check.png" alt="icon" />
                {`${formatMessage({ id: 'Config_13', defaultMessage: '연결에 성공하였습니다.' })}
                  ${checkSuccessEpoch ? ` (${timeFormatFromUTCEpoch(checkSuccessEpoch)})` : ''}`}
              </div>
            ) : (
              <div className="connecting-text">
                <img src="/img/exam/startExam/ico_fail.png" alt="icon" />
                {`${formatMessage({ id: 'Config_14', defaultMessage: '연결에 실패하였습니다.' })}
                    ${checkSuccessEpoch ? ` (${timeFormatFromUTCEpoch(checkSuccessEpoch)})` : ''}`}
              </div>
            )}
          </div>
          {!!needReCheck && (
            <div className="smtp-test-comment inline-block">
              {formatMessage({
                id: 'Config_15',
                defaultMessage:
                  '마지막 연결 테스트 이후에 설정을 변경하였습니다. 다시 테스트 해보세요.',
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
