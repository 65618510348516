import { useEffect, useRef } from 'react';

// 첫 렌더링 시 실행되지 않는 useEffect 커스텀 함수
const UseDidMountEffect = (func: any, deps: any) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      func();
    } else {
      didMount.current = true;
    }
  }, deps);
};

export default UseDidMountEffect;
