import React from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from 'antd';
import { Option } from 'utils/commonValues';
import { fileSizeTransform, timeFormatFromUTCEpoch } from 'utils/commonFunctions';

// import pcImage from 'img/exam/list_pc_red.png';
// import pcGreyImage from 'img/exam/list_pc_grey.png';
// import declareImage from 'img/declare/declare_on.png';
// import declareOffImage from 'img/declare/declare_off.png';
// import eduImage from 'img/edu/edu_on.png';
// import eduOffImage from 'img/edu/edu_off.png';

interface Extra {
  data: any;
  className?: string;
  useList?: Array<string>;
  declareType?: string; // 'count'(대상자수) or 'date'(신고일시). 기본은 count
}

ExamListExtra.defaultProps = {
  className: '',
  useList: ['infection', 'declare', 'edu'],
  declareType: 'count',
};

function ExamListExtra({ data, className, useList, declareType }: Extra) {
  const { formatMessage } = useIntl();

  // 피싱 유도 유형
  const isPhishing = data.examType === 4;

  // 감염 PC
  let infectionPcTitle = `${formatMessage({ id: 'Infection_3', defaultMessage: '감염 PC' })}: `;
  if (data.infectionPc) {
    infectionPcTitle += `${data.infectionPc}${formatMessage({
      id: 'Infection_29',
      defaultMessage: '대',
    })} ${data.leakAllcount}(${fileSizeTransform(data.leakAllsize).common})`;
  } else {
    infectionPcTitle += formatMessage({ id: 'Infection_28', defaultMessage: '없음' });
  }

  // 신고한 대상자
  let declareTitle = `${formatMessage({ id: 'Target_3', defaultMessage: '신고한 대상자' })}: `;
  let declareValue: any = '';
  if (data.declare) {
    if (declareType === 'date') {
      declareTitle = `${formatMessage({
        id: 'Date_7',
        defaultMessage: '신고 일시',
      })}: ${timeFormatFromUTCEpoch(data.declare)}`;
      declareValue = formatMessage({ id: 'Declare_1', defaultMessage: '신고' });
    } else {
      declareTitle += `${data.declare}${formatMessage({
        id: 'StartExam_35',
        defaultMessage: '명',
      })}`;
      declareValue =
        data.declare > 999
          ? '999+'
          : `${data.declare}${formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}`;
    }
  } else if (!data.declare) {
    if (declareType === 'date') {
      declareTitle = formatMessage({ id: 'Declare_24', defaultMessage: '신고 안함' });
      declareValue = formatMessage({ id: 'Declare_1', defaultMessage: '신고' });
    } else {
      declareTitle += formatMessage({ id: 'Infection_28', defaultMessage: '없음' });
      declareValue = formatMessage({ id: 'Infection_28', defaultMessage: '없음' });
    }
  }

  // 연결된 교육
  let eduTitle = `${formatMessage({ id: 'Edu_2', defaultMessage: '연결된 교육' })}: `;
  let eduCountMsg = '';
  if (data.edu) {
    eduTitle += `${data.edu}${formatMessage({ id: 'StartExam_21', defaultMessage: '개' })}`;
    eduCountMsg =
      data.edu > 999
        ? '999+'
        : `${data.edu}${formatMessage({ id: 'StartExam_21', defaultMessage: '개' })}`;
  } else {
    eduTitle += formatMessage({ id: 'Infection_28', defaultMessage: '없음' });
    eduCountMsg = formatMessage({ id: 'Infection_28', defaultMessage: '없음' });
  }

  return (
    <div className="exam-extra-column">
      {/* 감염 PC */}
      {isPhishing ? (
        <div className="empty" />
      ) : (
        <Tooltip overlayClassName="black-tooltip full" placement="bottom" title={infectionPcTitle}>
          <div className={`exam-extra-tag ${className} ${data.infectionPc && 'exist-infection'}`}>
            <img
              src={data.infectionPc ? '/img/exam/list_pc_red.png' : '/img/exam/list_pc_grey.png'}
              alt="infection"
            />
            {data.infectionPc
              ? `${data.infectionPc}${formatMessage({
                  id: 'Infection_29',
                  defaultMessage: '대',
                })}`
              : formatMessage({ id: 'Infection_28', defaultMessage: '없음' })}
          </div>
        </Tooltip>
      )}

      {/* 신고한 대상자 */}
      {useList?.includes('declare') && Option.isDeclare === 1 && (
        <Tooltip overlayClassName="black-tooltip full" placement="bottom" title={declareTitle}>
          <div className={`exam-extra-tag ${data.declare && 'exist-declare'}`}>
            <img
              src={data.declare ? '/img/declare/declare_on.png' : '/img/declare/declare_off.png'}
              alt="declare"
            />
            {declareValue}
          </div>
        </Tooltip>
      )}

      {/* 연결된 교육 */}
      {useList?.includes('edu') && Option.isEdu === 1 && (
        <Tooltip overlayClassName="black-tooltip full" placement="bottom" title={eduTitle}>
          <div className={`exam-extra-tag ${data.edu && 'exist-edu'}`}>
            <img src={data.edu ? '/img/edu/edu_on.png' : '/img/edu/edu_off.png'} alt="edu" />
            {eduCountMsg}
          </div>
        </Tooltip>
      )}
    </div>
  );
}

export default ExamListExtra;
