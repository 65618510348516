import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Option, updateVersion } from 'utils/commonValues';
import { Redirect, Route, useRouteMatch, Switch, withRouter, useLocation } from 'react-router-dom';
import AutoRoute from 'components/common/AutoRoute';
import { useIntl } from 'react-intl';
import { Menu } from 'antd';
import {
  SettingOutlined,
  AppstoreOutlined,
  FileOutlined,
  PullRequestOutlined,
  KeyOutlined,
  SkinOutlined,
  UserOutlined,
  GroupOutlined,
  MailOutlined,
  BarChartOutlined,
  UnorderedListOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import './Mudmanage.scss';
import { setInitial } from 'store/edu';

// import logoImage from 'img/logo/mf_logo.png';
// import sxLogoImage from 'img/logo/sx_logo.png';

export const authLevel = 2;
function Mudmanage(props: any) {
  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  const adminMenu = useSelector((state: any) => state.user.adminMenu, shallowEqual);
  const subAdminData = useSelector((state: any) => state.admin.subAdminData, shallowEqual);
  const loginData = useSelector((state: any) => state.login.userData, shallowEqual);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  let activeLink = 'basicconf';
  if (pathname) {
    [activeLink] = pathname.split('/').slice(-1);
  }

  // Manager, Attach version
  const version = Option.customer === 'saxa' ? 'V1.2' : 'V1.0';

  return (
    <div className="mudmanage">
      {adminMenu && (
        <div className="mudmanage-tab">
          <Menu className="mudmanage-menu" selectedKeys={[activeLink]}>
            <Menu.Item
              key="basicconf"
              className="mudmanage-menu-item"
              onClick={() => props.history.push('/mudmanage/basicconf')}
            >
              <div style={{ margin: '0 0 0 10px' }}>
                {formatMessage({ id: 'Config_2', defaultMessage: '기본 설정' })}
                <SettingOutlined className="mudmanage-tab-icon" />
              </div>
            </Menu.Item>
            <Menu.Item
              key="smtpconf"
              className="mudmanage-menu-item"
              onClick={() => props.history.push('/mudmanage/smtpconf')}
            >
              <div style={{ margin: '0 0 0 10px' }}>
                SMTP
                <MailOutlined className="mudmanage-tab-icon" />
              </div>
            </Menu.Item>
            <Menu.Item
              key="examtemplate"
              className="mudmanage-menu-item"
              onClick={() => props.history.push('/mudmanage/examtemplate')}
            >
              <div style={{ margin: '0 0 0 10px' }}>
                {formatMessage({ id: 'Template_1', defaultMessage: '훈련 템플릿' })}
                <GroupOutlined className="mudmanage-tab-icon" />
              </div>
            </Menu.Item>
            <Menu.Item
              key="phishingpage"
              className="mudmanage-menu-item submenu"
              onClick={() => props.history.push('/mudmanage/phishingpage')}
            >
              <div>{formatMessage({ id: 'Phishing_1', defaultMessage: '피싱 페이지' })}</div>
            </Menu.Item>
            <Menu.Item
              key="attach"
              className="mudmanage-menu-item submenu"
              onClick={() => props.history.push('/mudmanage/attach')}
            >
              <div>{formatMessage({ id: 'Attach_1', defaultMessage: '첨부파일' })}</div>
            </Menu.Item>
            {Option.isEdu === 1 && (
              <>
                <Menu.Item
                  key="edutemplate"
                  className="mudmanage-menu-item cursor-default"
                  // onClick={() => props.history.push('/mudmanage/edutemplate')}
                >
                  <div style={{ margin: '0 0 0 10px' }}>
                    {formatMessage({ id: 'Template_90', defaultMessage: '교육 템플릿' })}
                    <GroupOutlined className="mudmanage-tab-icon" />
                  </div>
                </Menu.Item>
                <Menu.Item
                  key="edubasic"
                  className="mudmanage-menu-item submenu"
                  onClick={() => {
                    dispatch(setInitial());
                    props.history.push('/mudmanage/edubasic');
                  }}
                >
                  <div>{formatMessage({ id: 'Template_38', defaultMessage: '기본 템플릿' })}</div>
                </Menu.Item>
                <Menu.Item
                  key="eduuser"
                  className="mudmanage-menu-item submenu"
                  onClick={() => {
                    dispatch(setInitial());
                    props.history.push('/mudmanage/eduuser');
                  }}
                >
                  <div>{formatMessage({ id: 'Template_36', defaultMessage: '사용자 템플릿' })}</div>
                </Menu.Item>
              </>
            )}
            <Menu.Item
              key="examing"
              className="mudmanage-menu-item"
              onClick={() => props.history.push('/mudmanage/examing')}
            >
              <div style={{ margin: '0 0 0 10px' }}>
                {formatMessage({ id: 'Exam_8', defaultMessage: '진행 중인 훈련' })}
                <UnorderedListOutlined className="mudmanage-tab-icon" />
              </div>
            </Menu.Item>
            {Option.customer === 'saxa' && loginData?.userAuthority === 1 && (
              <Menu.Item
                key="subadmin"
                className="mudmanage-menu-item"
                onClick={() => props.history.push('/mudmanage/subadmin')}
              >
                <div style={{ margin: '0 0 0 10px' }}>
                  {formatMessage({ id: 'User_30', defaultMessage: '부관리자' })}
                  <UserAddOutlined className="mudmanage-tab-icon" />
                </div>
              </Menu.Item>
            )}
            <Menu.Item
              key="user"
              className="mudmanage-menu-item"
              onClick={() => props.history.push('/mudmanage/user')}
            >
              <div style={{ margin: '0 0 0 10px' }}>
                {formatMessage({ id: 'User_1', defaultMessage: '사용자' })}
                <UserOutlined className="mudmanage-tab-icon" />
              </div>
            </Menu.Item>
            <Menu.Item
              key="target"
              className="mudmanage-menu-item"
              onClick={() => props.history.push('/mudmanage/target')}
            >
              <div style={{ margin: '0 0 0 10px' }}>
                {formatMessage({ id: 'Name_4', defaultMessage: '대상자' })}
                <SkinOutlined className="mudmanage-tab-icon" />
              </div>
            </Menu.Item>
            {loginData?.userAuthority !== 2 && (
              <Menu.Item
                key="license"
                className="mudmanage-menu-item"
                onClick={() => props.history.push('/mudmanage/license')}
              >
                <div style={{ margin: '0 0 0 10px' }}>
                  {formatMessage({ id: 'License_1', defaultMessage: '라이선스' })}
                  <KeyOutlined className="mudmanage-tab-icon" />
                </div>
              </Menu.Item>
            )}
            <Menu.Item
              key="setting"
              className="mudmanage-menu-item submenu"
              onClick={() => props.history.push('/mudmanage/setting')}
            >
              <div>{formatMessage({ id: 'Config_1', defaultMessage: '설정' })}</div>
            </Menu.Item>
            {Option.isJapan !== 1 && (
              <Menu.Item
                key="syncconf"
                className="mudmanage-menu-item"
                onClick={() => props.history.push('/mudmanage/syncconf')}
              >
                <div style={{ margin: '0 0 0 10px' }}>
                  {formatMessage({ id: 'Config_3', defaultMessage: '대상자 연동' })}
                  <PullRequestOutlined className="mudmanage-tab-icon" />
                </div>
              </Menu.Item>
            )}
            <Menu.Item
              key="log"
              className="mudmanage-menu-item"
              onClick={() => props.history.push('/mudmanage/log')}
            >
              <div style={{ margin: '0 0 0 10px' }}>
                {formatMessage({ id: 'Log_1', defaultMessage: '로그' })}
                <FileOutlined className="mudmanage-tab-icon" />
              </div>
            </Menu.Item>
            {Option.isSaas === 1 && (
              <>
                <Menu.Item className="mudmanage-menu-item no-click-menu" disabled>
                  <div style={{ margin: '0 0 0 10px' }}>
                    {formatMessage({ id: 'Config_7', defaultMessage: '주요현황' })}
                    <BarChartOutlined className="mudmanage-tab-icon" />
                  </div>
                </Menu.Item>
                <Menu.Item
                  key="monit"
                  className="mudmanage-menu-item submenu"
                  onClick={() => props.history.push('/mudmanage/monit')}
                >
                  <div>Monit</div>
                </Menu.Item>
                <Menu.Item
                  key="netdata"
                  className="mudmanage-menu-item submenu"
                  onClick={() => props.history.push('/mudmanage/netdata')}
                >
                  <div>netData</div>
                </Menu.Item>
                <Menu.Item
                  key="rabbitmq"
                  className="mudmanage-menu-item submenu"
                  onClick={() => props.history.push('/mudmanage/rabbitmq')}
                >
                  <div>RabbitMQ</div>
                </Menu.Item>
              </>
            )}
            {Option.customer !== 'hyundai' && (
              <Menu.Item
                key="api"
                className="mudmanage-menu-item"
                onClick={() => props.history.push('/mudmanage/api')}
              >
                <div style={{ margin: '0 0 0 10px' }}>
                  API
                  <AppstoreOutlined className="mudmanage-tab-icon" />
                </div>
              </Menu.Item>
            )}
          </Menu>
          {/* 머드픽스 버전 정보 */}
          <div className="version-box">
            <div className="bold font-size-20 version-logo">
              <span className="inline-block mr-5">
                <img
                  className="mf-logo"
                  src="/img/logo/mf_logo.png"
                  alt="logo"
                  // {Option.isSaxa === 1 ? sxLogoImage : logoImage}
                />
              </span>
              {version}
            </div>
            <div className="mt-10 bold">
              {Option.serviceName} Manager: {version}
            </div>
            <div className="mt-3 bold">
              {Option.serviceName} Attach: {version}
            </div>
            <div className="mt-3 bold">Update: {updateVersion}</div>
          </div>
        </div>
      )}
      <div className="mudmanage-main">
        <Switch>
          <Route path={`${url}/:name`} component={AutoRoute} />
          <Redirect path="*" to="/mudmanage/basicconf" />
        </Switch>
      </div>
    </div>
  );
}

export default withRouter(Mudmanage);
