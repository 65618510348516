import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { changeSubState, getResultTemplate } from 'store/template';
import ContentList from 'components/common/ContentList';
import { Tag, Tooltip } from 'antd';
import moment from 'moment';

// 템플릿별 통계
function Bytemplate() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const templateList = useSelector((state: any) => {
    return {
      data: state.template.resultTemplate.data,
      // dataByExamNo: state.template.resultTemplate.dataByExamNo,
      param: state.template.resultTemplate.param,
      needReload: state.template.resultTemplate.needReload,
      page: state.template.resultTemplate.page,
      totalPages: state.template.resultTemplate.totalPages,
      selectedList: state.template.resultTemplate.selectedList,
    };
  }, shallowEqual);

  useEffect(() => {
    onGetTemplateList(true);

    // unmount 시 선택한 리스트 초기화
    return () => {
      dispatch(changeSubState({ subStore: 'resultTemplate', key: 'selectedList', value: [] }));
    };
  }, [templateList.param]);

  // 템플릿 리스트 조회
  const onGetTemplateList = async (refresh = false) => {
    if (loading) return;
    setLoading(true);

    try {
      const params: any = {
        filter: JSON.stringify(templateList.param.filter),
        sort: JSON.stringify(templateList.param.sort),
        offset: 0,
        limit: templateList.param.limit,
      };

      if (refresh) {
        // 처음부터 조회
        params.refresh = true;
      } else {
        // 이어서 조회
        params.offset = templateList.param.limit * templateList.page;
      }

      await dispatch(getResultTemplate(params));

      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  // 훈련 선택
  const onSelectItem = useCallback(
    (data: any) => {
      const no = data.templatePick ? data.serviceTemplateNo : data.templateNo;
      const isSelected = templateList.selectedList[0]?.no === no;

      dispatch(
        changeSubState({
          subStore: 'resultTemplate',
          key: 'selectedList',
          value: [{ no: isSelected ? null : no, info: isSelected ? {} : data }],
        }),
      );
    },
    [templateList.selectedList],
  );

  return (
    <div className="exam-content-wrap by">
      {/* 내용 리스트 */}
      <ContentList
        dataList={templateList.data}
        onLoadData={onGetTemplateList}
        loading={loading}
        page={templateList.page}
        totalPage={templateList.totalPages}
        noContent={{
          title: formatMessage({ id: 'Exam_35', defaultMessage: '훈련이 없습니다.' }),
          subTitle: formatMessage({ id: 'Exam_36', defaultMessage: '훈련을 실행해 보세요!' }),
        }}
      >
        {templateList.data?.length > 0 &&
          templateList.data?.map((item: any) => {
            const no = item.templatePick ? item.serviceTemplateNo : item.templateNo;

            const infectionRate = `${
              item.statisticsTarget > 0
                ? ((item.statisticsInfection / item.statisticsTarget) * 100).toFixed(0)
                : 0
            }%`;

            const templateType = item.templatePick
              ? formatMessage({ id: 'User_1', defaultMessage: '사용자' })
              : formatMessage({ id: 'Template_14', defaultMessage: '기 본' });

            const regEpoch = moment
              .unix(item.regEpoch)
              .format(
                `${
                  localStorage.getItem('language') === 'en' ? 'MM/DD/YYYY' : 'YYYY-MM-DD'
                } HH:mm:ss`,
              );

            let examTypeData: any = null;
            if (item.attachExamType === 1) {
              examTypeData = {
                type: 'warning',
                text: formatMessage({ id: 'Template_10', defaultMessage: '경고 안내' }),
              };
            } else if (item.attachExamType === 2) {
              examTypeData = {
                type: 'file',
                text: formatMessage({ id: 'Template_11', defaultMessage: '모의 악성파일' }),
              };
            } else if (item.attachExamType === 3) {
              examTypeData = {
                type: 'research',
                text: formatMessage({ id: 'Template_12', defaultMessage: '실태 조사' }),
              };
            } else {
              examTypeData = {
                type: 'phishing',
                text: formatMessage({ id: 'Template_13', defaultMessage: '피싱 유도' }),
              };
            }

            return (
              <div
                className={`content-list-item ${
                  templateList?.selectedList[0]?.no === no ? 'selected' : ''
                }`}
                key={no}
                onClick={() => onSelectItem(item)}
                aria-hidden="true"
              >
                <div className="list-item exam-list-item">
                  <div className="long-column ellipsis">
                    <Tooltip
                      overlayClassName="black-tooltip"
                      placement="bottomLeft"
                      title={`${formatMessage({
                        id: 'Template_24',
                        defaultMessage: '템플릿명',
                      })}: ${item.templateName}`}
                    >
                      {item.templateName}
                    </Tooltip>
                  </div>

                  <div className="center-column">
                    <Tooltip
                      overlayClassName="black-tooltip"
                      placement="bottom"
                      title={`${formatMessage({
                        id: 'Infection_45',
                        defaultMessage: '감염/유출률',
                      })}: ${infectionRate}`}
                    >
                      <div className="bold">{infectionRate}</div>
                    </Tooltip>
                  </div>

                  <div className="center-column">
                    <Tooltip
                      overlayClassName="black-tooltip"
                      placement="bottom"
                      title={`${formatMessage({
                        id: 'Template_40',
                        defaultMessage: '템플릿 유형',
                      })}: ${templateType}`}
                    >
                      <Tag
                        className={`temp-type-label color-temp-${
                          item.templatePick ? 'user' : 'system'
                        }`}
                      >
                        {templateType}
                      </Tag>
                    </Tooltip>
                  </div>

                  <div className="center-column">
                    <Tooltip
                      overlayClassName="black-tooltip"
                      placement="bottom"
                      title={`${formatMessage({
                        id: 'Template_23',
                        defaultMessage: '훈련 유형',
                      })}: ${examTypeData.text}`}
                    >
                      <Tag className={`temp-type-label color-temp-${examTypeData.type}`}>
                        {examTypeData.text}
                      </Tag>
                    </Tooltip>
                  </div>

                  <div className="epoch-column">
                    <Tooltip
                      overlayClassName="black-tooltip"
                      placement="bottom"
                      title={`${formatMessage({
                        id: 'Date_3',
                        defaultMessage: '등록일시',
                      })}: ${regEpoch}`}
                    >
                      {regEpoch}
                    </Tooltip>
                  </div>
                </div>
              </div>
            );
          })}
      </ContentList>
    </div>
  );
}

export default Bytemplate;
