import { useEffect, useMemo, useState } from 'react';
import { getSystemLogdetail } from 'store/log';
import Loading from 'components/common/Loading';
import { useDispatch } from 'react-redux';
import './LogDetail.scss';

export default function LogDetail(props: any) {
  const [loading, setLoding] = useState(false);
  const [logDetail, setLogDetail] = useState<{ [key: string]: any }>({ req: {}, res: {} });
  const dispatch = useDispatch();

  useEffect(() => {
    getLogDetail(props);
  }, []);

  const getLogDetail = async (params: any) => {
    setLoding(true);
    const respObj: any = await dispatch(getSystemLogdetail({ logNo: params.row.original.logNo }));
    if (respObj?.status === 200 && respObj.data) {
      const { logDetail } = respObj.data.list;
      setLogDetail(JSON.parse(logDetail));
    }
    setLoding(false);
  };

  const reqData = logDetail?.req ? Object.keys(logDetail.req) : [];
  const resData = logDetail?.res ? Object.keys(logDetail.res) : [];

  return (
    <>
      <div className="react-table-td-sub-area systemLog-content">
        <Loading loading={loading} />
        {reqData.length > 0 && (
          <div className="systemLog-res">
            <div className="systemLog-title">request</div>
            <pre>{JSON.stringify(logDetail?.req, null, 4)}</pre>
          </div>
        )}
        {resData.length > 0 && (
          <div className="systemLog-res">
            <div className="systemLog-title">response</div>
            <pre>{JSON.stringify(logDetail?.res, null, 4)}</pre>
          </div>
        )}
        {reqData.length === 0 &&
          resData.length === 0 &&
          (Object.keys(logDetail)?.length > 0 ? (
            <div className="systemLog-res">
              <pre>{JSON.stringify(logDetail, null, 4)}</pre>
            </div>
          ) : (
            <div className="systemLog-res">로그가 존재하지 않습니다.</div>
          ))}
      </div>
    </>
  );
}
