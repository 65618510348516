import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import TargetAddEdit from 'components/branch/target/TargetAddEdit';

function TargetSettings({ isAdmin, dataInfo }: any) {
  const { formatMessage } = useIntl();
  const [openModal, setOpenModal] = useState('');

  return (
    <div className="table-settings">
      {/* 수정 */}
      <Tooltip
        overlayClassName="black-tooltip"
        placement="bottom"
        title={formatMessage({ id: 'Target_54', defaultMessage: '대상자 수정' })}
      >
        <FormOutlined onClick={() => setOpenModal('edit')} />
      </Tooltip>

      {openModal === 'edit' && (
        <TargetAddEdit
          visible={openModal === 'edit'}
          isAdmin={isAdmin}
          isAddMode={false}
          dataInfo={dataInfo}
          toggleModal={(open: boolean) => setOpenModal(open ? 'edit' : '')}
        />
      )}
    </div>
  );
}

export default TargetSettings;
