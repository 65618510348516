import MainTitle from 'components/common/admin/MainTitle';
import ConfigTemplate from 'components/common/config/ConfigTemplate';
import SmtpTest from 'components/common/config/SmtpTest';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getServiceConfig, updateServiceConfig } from 'store/config';
import "../mudmanage/Conf.scss";

export default function Declare(props: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const isAdmin = useSelector((state: any) => state.login.userData.isAdmin, shallowEqual)
  const declareConfig = useSelector((state: any) => state.config.declareConfig)


  useEffect(() => {
    dispatch(getServiceConfig({ category: "declare" }))
  }, [])

  return (
    <div className="admin-basic-conf">
      <MainTitle className="small" title={formatMessage({ id: "MyPage_3", defaultMessage: "신고설정" })} />
      <ConfigTemplate category="declareConfig" updateConfig={updateServiceConfig} order={declareConfig.classOrder || []} lists={declareConfig.class || {}} isAdmin={isAdmin} />
    </div>
  );
}