import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { timeDiffFromUTCEpoch } from 'utils/commonFunctions';
import { resendAuthEmail } from 'store/register';
import { LoadingOutlined } from '@ant-design/icons';
import { resendUnlockEmail } from 'store/login';

function Lock(props: any) {
  const loginData = useSelector((state: any) => state.login);
  const dispatch = useDispatch();
  const [resendSuccess, setResendSuccess] = useState(0);
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();

  const { formStore, loginResult, loginMessage } = loginData;

  // 새로고침
  if (loginResult !== 128) {
    return <Redirect to="/" />;
  }

  // 인증메일 재전송
  const onResendAuthEmail = async () => {
    setLoading(true);
    try {
      const params = {
        userEmail: formStore.userEmail,
      };
      const response: { [key: string]: any } = await dispatch(resendUnlockEmail(params));
      if (response?.data?.list) {
        // 재전송 성공
        setResendSuccess(1);
      } else {
        // 재전송 실패
        setResendSuccess(2);
      }
      setLoading(false);
    } catch (error) {
      console.log('onResendEmail', error);
    }
  };

  return (
    <div className="login-wrapper">
      <div className="long-term-lock login-item">
        <img className="logo-white" src="/img/logo/mf_logo_white.png" alt="logo" />
        <div className="content-box">
          <div className="info-text">
            <div className="main-text">
              <img src="/img/login/icon_pw.png" className="check-icon" alt="check-icon" />
              {formatMessage({
                id: 'NoActive_9',
                defaultMessage: '계정 활성화 메일 발송 완료',
              })}
            </div>
            <div className="sub-text">
              {formatMessage({
                id: 'NoActive_8',
                defaultMessage: '해당 계정은 장기 미접속으로 인해 비활성화 되었습니다.',
              })}
            </div>
            <div className="sub-text">
              {formatMessage({
                id: 'NoActive_10',
                defaultMessage: '메일로 전송된 링크를 클릭하여 계정을 활성화하세요.',
              })}
            </div>
          </div>

          {/* 등록된 메일 주소 */}
          <div className="border-box">
            <span className="text">
              {formatMessage({ id: 'NoActive_3', defaultMessage: '등록된 메일 주소' })}
            </span>
            <span>{formStore ? formStore.userEmail : ''}</span>
          </div>
          {/* 버튼 */}
          <div className="button-box">
            <button
              type="button"
              className="login-btn grey"
              onClick={onResendAuthEmail}
              style={{ background: 'url(/img/login/grey_button.png) no-repeat center' }}
            >
              {formatMessage({ id: 'NoActive_5', defaultMessage: '인증메일 재전송' })}
              {loading && (
                <span className="button-loading">
                  <LoadingOutlined />
                </span>
              )}
            </button>
          </div>
          {/* 인증메일 재전송 결과 */}
          <div className={`resend-result${resendSuccess === 1 ? ' success' : ''}`}>
            {!!resendSuccess &&
              (resendSuccess === 1
                ? formatMessage({ id: 'NoActive_6', defaultMessage: '메일 재전송 성공' })
                : formatMessage({ id: 'NoActive_7', defaultMessage: '메일 재전송 실패' }))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Lock);