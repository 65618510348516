import React from 'react';
import { Button } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

interface colorProps {
  color: string;
  onSelectColor: (color: string) => void;
  className?: any;
}

TagColor.defaultProps = {
  className: '',
};

function TagColor({ color, onSelectColor, className }: colorProps) {
  const colorList = [
    'red',
    'orange',
    'yellow',
    'olive',
    'lightgreen',
    'green',
    'pink',
    'purple',
    'violet',
    'blue',
    'lightblue',
    'emerald',
    'lightpink',
    'plum',
    'darkplum',
    'brown',
    'ash',
    'apricot',
  ];

  return (
    <div className={`tag-color-palette ${className}`}>
      {colorList.map((colorValue: string) => {
        return (
          <Button
            className={`tag-${colorValue}`}
            shape="circle"
            onClick={() => onSelectColor(colorValue)}
            key={colorValue}
          >
            {` `}
            {colorValue === color && <CheckOutlined />}
          </Button>
        );
      })}
    </div>
  );
}

export default TagColor;
