import { useEffect, useState } from 'react';
import MainTitle from 'components/common/admin/MainTitle';
import { useIntl } from 'react-intl';
import './Conf.scss';
import './Setting.scss';
import axios from 'axios';
import Template from '../Template';

export default function Setting(props: any) {
  interface statusType {
    [key: string]: { file?: any; error?: string; list?: any };
  }
  const { formatMessage } = useIntl();
  const [dragStatus, setDragStatue] = useState<boolean>(false);
  const [hostName, setHostName] = useState<string>('');
  const [licenseKey, setLicenseKey] = useState<string>('');
  const [keyError, setKeyError] = useState<string>('');
  const [keyList, setKeyList] = useState(null);
  const [state, setState] = useState<statusType>({
    template: { file: null, error: '', list: null },
    attachFile: { file: null, error: '', list: null },
    license: { file: null, error: '', list: null },
  });

  useEffect(() => {
    getSystemConfig();
  }, []);

  const getSystemConfig = async () => {
    const params = { category: 'license' };
    try {
      const response = await axios.get('/exec/SystemConfig', { params });

      if (!response.data.error) {
        const result = response.data.list;
        let host = '';
        let key = '';
        result.map((item: { [key: string]: any }) => {
          if (item.parameter === 'HostName') {
            host = item.value;
          } else if (item.parameter === 'LicenseKey') {
            key = item.value;
          }
          return true;
        });

        setHostName(host);
        setLicenseKey(key);
      } else {
        setKeyError(response.data.error[0].errInfo);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleFile = (e: HTMLInputElement, target: string) => {
    if (dragStatus) setDragStatue(false);

    setState({ ...state, [target]: { file: e.files![0] } });
  };

  const dragEnterHandler = () => {
    setDragStatue(true);
  };

  const dragLeaveHandler = () => {
    setDragStatue(false);
  };

  const storeFile = async (target: string) => {
    setState({ ...state, [target]: { error: '', list: null } });

    const formData = new FormData();
    formData.append('file', state[target].file);

    let apiUrl = '/api/v1/License/BuildLicense';
    if (target === 'template') apiUrl = '/api/v1/License/Template';
    else if (target === 'attachFile') apiUrl = '/api/v1/License/Attach';

    try {
      const response = await axios.post(apiUrl, formData);

      if (!response.data.error) {
        const { list } = response.data;
        setState({ ...state, [target]: { list: list, file: null } });
      } else {
        setState({ ...state, [target]: { error: response.data.error[0].errInfo, file: null } });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const inputLicense = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLicenseKey(e.target.value);
  };

  const inputHostname = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHostName(e.target.value);
  };

  const getLicenseKey = async () => {
    setKeyError('');
    setKeyList(null);
    setLicenseKey('');

    const params = { host: hostName };
    try {
      const response = await axios.get('/api/v1/License/LicenseKey', { params });

      if (!response.data.error) {
        setLicenseKey(response.data.list);
      } else {
        setKeyError(response.data.error[0].errInfo);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addLicense = async () => {
    setKeyError('');
    setKeyList(null);

    const formData = new FormData();
    formData.append('licenseKey', licenseKey);
    formData.append('host', hostName);

    try {
      const response = await axios.post('/api/v1/License/LicenseKey', formData);

      if (!response.data.error) {
        setKeyList(response.data.list);
      } else {
        setKeyError(response.data.error[0].errInfo);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="admin-basic-conf">
      <MainTitle className="small" title="Setting" />

      <div className="admin-license-config">
        <div className="group">
          <div className="group-title">
            {formatMessage({ id: 'License_1', defaultMessage: '라이선스' })}
          </div>
          <div className="group-item">
            <div className="item-title">
              {`${formatMessage({
                id: 'License_53',
                defaultMessage: '라이선스 파일 등록',
              })} (mudfix_license.enc)`}
            </div>
            <div className="set-file">
              <form>
                <div className="file-input">
                  {state.license.file ? (
                    <label htmlFor="filebox-1">{state.license.file.name}</label>
                  ) : (
                    <label htmlFor="filebox-1">
                      {formatMessage({ id: 'Phishing_13', defaultMessage: '파일을 선택하세요.' })}
                    </label>
                  )}
                  <input
                    accept="*"
                    type="file"
                    id="filebox-1"
                    onDragEnter={dragEnterHandler}
                    onDragLeave={dragLeaveHandler}
                    onChange={(e) => handleFile(e.target, 'license')}
                    onClick={(e: any) => {
                      e.target.value = null;
                      return false;
                    }}
                  />
                </div>
              </form>
              <div className="file-btns">
                <button
                  className={`default-button upload ${!state.license.file && 'disable'}`}
                  type="button"
                  onClick={() => storeFile('license')}
                >
                  {formatMessage({ id: 'Button_20', defaultMessage: '업로드' })}
                </button>
              </div>
            </div>
            {state.license.error && <div className="message red">{state.license.error}</div>}
            {state.license.list && <div className="message green">Success</div>}
          </div>
          <div className="group-item">
            <div className="item-title">
              {formatMessage({ id: 'License_54', defaultMessage: '라이선스 키 등록' })}
            </div>
            <div className="set-text">
              <input
                className="input-text"
                type="text"
                onChange={inputHostname}
                placeholder="Hostname"
                value={hostName}
              />
              <button className="default-button" type="button" onClick={getLicenseKey}>
                {formatMessage({ id: 'License_55', defaultMessage: '가져오기' })}
              </button>
            </div>
            <div className="set-text">
              <input
                className="input-text"
                type="text"
                onChange={inputLicense}
                placeholder="License Key"
                value={licenseKey}
              />
              <button className="default-button" type="button" onClick={addLicense}>
                {formatMessage({ id: 'Button_22', defaultMessage: '등록' })}
              </button>
            </div>
            {keyError && <div className="message red">{keyError}</div>}
            {keyList && <div className="message green">Success</div>}
          </div>
        </div>

        <div className="group">
          <div className="group-title">
            {formatMessage({ id: 'Button_22', defaultMessage: '등록' })}
          </div>
          <div className="group-item">
            <div className="item-title">
              {formatMessage({ id: 'License_56', defaultMessage: '템플릿 파일 등록' })}
            </div>
            <div className="set-file">
              <form>
                <div className="file-input">
                  {state.template.file ? (
                    <label htmlFor="filebox-2">{state.template.file.name}</label>
                  ) : (
                    <label htmlFor="filebox-2">
                      {formatMessage({ id: 'Phishing_13', defaultMessage: '파일을 선택하세요.' })}
                    </label>
                  )}
                  <input
                    accept="*"
                    type="file"
                    id="filebox-2"
                    onDragEnter={dragEnterHandler}
                    onDragLeave={dragLeaveHandler}
                    onChange={(e) => handleFile(e.target, 'template')}
                    onClick={(e: any) => {
                      e.target.value = null;
                      return false;
                    }}
                  />
                </div>
              </form>
              <div className="file-btns">
                <button
                  type="button"
                  className={`default-button upload ${!state.template.file && 'disable'}`}
                  onClick={() => storeFile('template')}
                >
                  {formatMessage({ id: 'Button_20', defaultMessage: '업로드' })}
                </button>
              </div>
            </div>
            {state.template.error && <div className="message red">{state.template.error}</div>}
            {state.template.list && <div className="message green">Success</div>}
          </div>
        </div>

        <div className="group">
          <div className="group-title">
            {formatMessage({ id: 'Template_15', defaultMessage: '첨부 파일' })}
          </div>
          <div className="group-item">
            <div className="item-title">
              {formatMessage({ id: 'License_57', defaultMessage: '첨부 파일 등록' })}
            </div>
            <div className="set-file">
              <form>
                <div className="file-input">
                  {state.attachFile.file ? (
                    <label htmlFor="filebox-3">{state.attachFile.file.name}</label>
                  ) : (
                    <label htmlFor="filebox-3">
                      {formatMessage({ id: 'Phishing_13', defaultMessage: '파일을 선택하세요.' })}
                    </label>
                  )}
                  <input
                    accept="*"
                    type="file"
                    id="filebox-3"
                    onDragEnter={dragEnterHandler}
                    onDragLeave={dragLeaveHandler}
                    onChange={(e) => handleFile(e.target, 'attachFile')}
                    onClick={(e: any) => {
                      e.target.value = null;
                      return false;
                    }}
                  />
                </div>
              </form>
              <div className="file-btns">
                <button
                  type="button"
                  className={`default-button upload ${!state.attachFile.file && 'disable'}`}
                  onClick={() => storeFile('attachFile')}
                >
                  {formatMessage({ id: 'Button_20', defaultMessage: '업로드' })}
                </button>
              </div>
            </div>
            {state.attachFile.error && <div className="message red">{state.attachFile.error}</div>}
            {state.attachFile.list && <div className="message green">Success</div>}
          </div>
        </div>
      </div>
    </div>
  );
}
