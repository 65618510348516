import React from 'react';
import { useIntl } from 'react-intl';
import { Dropdown, Menu, Popover, Progress, Tooltip } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { nowEpoch, timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import { useHistory } from 'react-router-dom';

// import settingImage from 'img/exam/setting.png';
// import eduTitleImage from 'img/edu/icon_edu_title.png';

interface eduSidebarProps {
  summaryInfo: any;
  selectedItem: string | number;
  onClickItem: any;
  onClickDropdownMenu: any;
}

function EduSidebarItem({
  summaryInfo,
  selectedItem,
  onClickItem,
  onClickDropdownMenu,
}: eduSidebarProps) {
  const { formatMessage } = useIntl();
  const history = useHistory();

  // 드롭다운 설정 메뉴
  const settingMenu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          onClickDropdownMenu(summaryInfo.eduNo, 'eduEdit');
        }}
      >
        <EditFilled />
        {formatMessage({ id: 'Button_14', defaultMessage: '수 정' })}
      </Menu.Item>
      {!!summaryInfo.eduFix && (
        <Menu.Item
          onClick={() => {
            onClickDropdownMenu(summaryInfo.eduNo, 'eduDelete');
          }}
        >
          <DeleteFilled />
          {formatMessage({ id: 'Button_15', defaultMessage: '삭 제' })}
        </Menu.Item>
      )}
    </Menu>
  );

  // 교육 진행상태
  let progress = 1;
  // 예약 교육 여부
  let isReserveEdu = false;

  if (summaryInfo.startEpoch > nowEpoch()) {
    progress = 0;
    isReserveEdu = true;
  } else {
    progress = Math.round(
      ((nowEpoch() - summaryInfo.startEpoch) / (summaryInfo.endEpoch - summaryInfo.startEpoch)) *
        100,
    );

    if (progress < 1) {
      progress = 1;
    } else if (progress >= 100) {
      progress = 100;
    }
  }

  const bindExamMessages: any = {
    ing: {
      title: formatMessage({ id: 'Edu_148', defaultMessage: '교육 대상자 집계 중' }),
      text: [
        formatMessage({ id: 'Edu_149', defaultMessage: '미종료훈련을 포함하는 경우,' }),
        formatMessage({
          id: 'Edu_150',
          defaultMessage: '훈련 종료 시에 교육 대상자가 확정됩니다.',
        }),
      ],
      imgSrc: '/img/exam/startExam/btn_qmark.png',
    },
    fail: {
      title: formatMessage({ id: 'Edu_152', defaultMessage: '교육실시 실패' }),
      text: [
        formatMessage({ id: 'Edu_153', defaultMessage: '알 수 없는 이유로,' }),
        formatMessage({
          id: 'Edu_154',
          defaultMessage: '연결 훈련에 의한 교육실시에 실패하였습니다.',
        }),
      ],
      imgSrc: '/img/common/btn_warning.png',
    },
  };

  let bindExamResult = '';
  if (summaryInfo.eduFix !== 1) {
    bindExamResult = summaryInfo.startEpoch > nowEpoch() ? 'ing' : 'fail';
  }
    

  return (
    <div
      className={`summary-item-wrap summary-item edu ${
        selectedItem === summaryInfo.eduNo ? 'selected' : ''
      }`}
      onClick={() => onClickItem(summaryInfo.eduNo)}
      aria-hidden="true"
    >
      <div className="summary-item-inner">
        <div className="inner-top">
          {/* 예약 훈련 아이콘 */}
          {isReserveEdu && (
            <Tooltip
              overlayClassName="black-tooltip"
              placement="top"
              title={formatMessage({ id: 'Edu_20', defaultMessage: '예약 교육' })}
            >
              <div className="send-icon">
                <img src="/img/exam/send_reserve.png" alt="reserve" />
              </div>
            </Tooltip>
          )}
          <div className="exam-text ellipsis">{summaryInfo.eduName}</div>
          <div
            className="exam-buttons"
            onClick={(e: any) => {
              // 훈련이 선택되지 않도록 하기 위함
              e.stopPropagation();
            }}
            aria-hidden="true"
          >
            {/* 설정 */}
            <Dropdown
              getPopupContainer={(triggerNode: any) => triggerNode.parentElement} // 부모 노드와 함께 움직이도록 설정
              overlayClassName="summary-dropdown"
              overlay={settingMenu}
              trigger={['click']}
              placement="bottomRight"
            >
              {/* <img className="icon setting" src={settingImage} alt="setting" /> */}
              <img className="icon setting" src="/img/exam/setting.png" alt="setting" />
            </Dropdown>
          </div>
        </div>
        <div className="inner-bottom">
          <div className="summary-detail">
            {/* <div className="edu-row">
              <img src="/img/edu/icon_edu_title.png" alt="icon" />
              {summaryInfo.attachName}
            </div> */}
            <div className="edu-row">
              {`${timeFormatFromUTCEpoch(summaryInfo.startEpoch, 3)} ~ ${timeFormatFromUTCEpoch(
                summaryInfo.endEpoch,
                3,
              )}`}
            </div>

            <div className="bg-box">
              <div className="bg-inner top">
                <div className="inner-row">
                  <div className="text">
                    {formatMessage({ id: 'Edu_112', defaultMessage: '교 육' })}
                  </div>
                  <div className="value">{`${summaryInfo.attachInfo.length}${formatMessage({
                    id: 'Exam_26',
                    defaultMessage: '개',
                  })}`}</div>
                </div>
              </div>
              <div className="bg-inner bottom">
                <div className="inner-row">
                  <div className="text flex">
                    <span>{`· ${formatMessage({
                      id: 'Edu_110',
                      defaultMessage: '교육 대상',
                    })}`}</span>
                    {summaryInfo.eduFix !== 1 && (
                      <Popover
                        overlayClassName="edu-sidebar-popover full"
                        placement="right"
                        content={
                          <div>
                            <b>{bindExamMessages[bindExamResult].title}</b>
                            <div>{bindExamMessages[bindExamResult].text[0]}</div>
                            <div className="flex">
                              {bindExamMessages[bindExamResult].text[1]}
                              {bindExamResult === 'ing' && (
                                <>
                                  <span className="ml-5">
                                    {formatMessage({ id: 'Log_10', defaultMessage: '상세' })}
                                  </span>
                                  <Tooltip
                                    overlayClassName="black-tooltip"
                                    placement="bottom"
                                    title={formatMessage({
                                      id: 'Edu_151',
                                      defaultMessage: '훈련 결과로 이동',
                                    })}
                                  >
                                    <img
                                      src="/img/exam/startExam/btn_arrow.png"
                                      alt="move"
                                      onClick={(e: any) => {
                                        e.stopPropagation();
                                        history.push('/exam/pi');
                                      }}
                                      aria-hidden="true"
                                    />
                                  </Tooltip>
                                </>
                              )}
                            </div>
                          </div>
                        }
                      >
                        <img
                          src={bindExamMessages[bindExamResult].imgSrc}
                          alt="icon"
                          className="status-icon"
                        />
                      </Popover>
                    )}
                  </div>
                  <div className="value">{`${summaryInfo.eduCount}${formatMessage({
                    id: 'StartExam_35',
                    defaultMessage: '명',
                  })}`}</div>
                </div>
                <div className="inner-row li">
                  <div className="text">
                    {`· ${formatMessage({ id: 'Status_15', defaultMessage: '진행전' })}`}
                  </div>
                  <div className="value">{`${summaryInfo.beforeCount}${formatMessage({
                    id: 'StartExam_35',
                    defaultMessage: '명',
                  })}`}</div>
                </div>
                <div className="inner-row li">
                  <div className="text">
                    {`· ${formatMessage({ id: 'Status_8', defaultMessage: '진행중' })}`}
                  </div>
                  <div className="value">{`${summaryInfo.playCount}${formatMessage({
                    id: 'StartExam_35',
                    defaultMessage: '명',
                  })}`}</div>
                </div>
                <div className="inner-row li">
                  <div className="text">
                    {`· ${formatMessage({ id: 'Status_16', defaultMessage: '완료' })}`}
                  </div>
                  <div className="value">{`${summaryInfo.afterCount}${formatMessage({
                    id: 'StartExam_35',
                    defaultMessage: '명',
                  })}`}</div>
                </div>
              </div>
            </div>

            <Tooltip
              className="flex"
              overlayClassName="black-tooltip"
              placement="bottom"
              title={
                isReserveEdu
                  ? formatMessage({ id: 'Edu_20', defaultMessage: '예약 교육' })
                  : `${formatMessage({
                      id: 'Template_28',
                      defaultMessage: '진행률',
                    })}: ${bindExamResult === 'fail' ? 100 : progress}%`
              }
            >
              <Progress
                className={`${isReserveEdu ? 'disabled' : ''}`}
                percent={bindExamResult === 'fail' ? 100 : progress}
                status="active"
                showInfo={false}
                strokeColor={progress === 100 && bindExamResult !== 'fail' ? '#14ae96' : '#ee4f4f'}
                strokeWidth={6}
              />
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EduSidebarItem;
