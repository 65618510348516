import { apiUrl, execUrl } from 'utils/commonValues';
import { actionFormat, getFollowOrderArray } from 'utils/commonFunctions';

// Action Types
const SET_INITIAL = 'log/SET_INITIAL';
const GET_LOG = 'log/GET_LOG';
const CHANGE_PARAM = 'log/CHANGE_PARAM';

// Actions
export const getLog = (params?: { [key: string]: any }) =>
  actionFormat(GET_LOG, params, 'get', `${apiUrl}SystemLog`);
export const changeParam = (params: { [key: string]: any }) =>
  actionFormat(CHANGE_PARAM, params, null, '');
export const getSystemLogdetail = (params: { [key: string]: any }) =>
  actionFormat(null, params, 'get', `${apiUrl}SystemLog/Detail`);
export const logDownload = (params: { [key: string]: any }) =>
  actionFormat(null, params, 'post', `${apiUrl}SystemLog/Download`, {
    headers: { 'Content-Type': 'application/vnd.openxmlformats' },
    responseType: 'arraybuffer',
  });

// initial state
interface stateType {
  data: { [key: string]: any };
  param: { [key: string]: any };
  needReload: boolean;
  totalPages: number;
  totalCount: number;
}

const initialState: stateType = {
  data: {
    result: -1,
    success: [],
    fail: [],
  },
  param: {
    filter: {},
    sort: [{ field: 'logNo', order: 'DESC' }],
  },
  needReload: false,
  totalPages: 0,
  totalCount: 0,
};

// Reducers
export default function (state: { [key: string]: any } = initialState, action: any) {
  switch (action.type) {
    case SET_INITIAL:
      return initialState;
    case GET_LOG:
      if (action.payload) {
        const { data } = action.payload;
        const { offset, limit } = action.payload.config.params;

        // 전체 목록 수
        const totalCount = data.list.length > 0 ? data.list[0].rnum + offset : 0;
        // 전체 페이지 수
        const totalPages = Math.ceil(totalCount / limit);

        if (!data.error && data.list && Array.isArray(data.list)) {
          state.data = {
            result: 1,
            success: data.list,
            fail: [],
          };
        } else if (data.error && Array.isArray(data.error)) {
          state.data = {
            result: 0,
            success: [],
            fail: data.errror,
          };
        }

        state.needReload = false;
        state.totalPages = totalPages;
        state.totalCount = totalCount;
      }
      return state;
    case CHANGE_PARAM:
      if (action.payload) {
        const { name, value } = action.payload;
        state.param[name] = value;
      }
      return state;
    default:
      return state;
  }
}
