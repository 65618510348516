import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import MainTitle from 'components/common/admin/MainTitle';
import EduTemplateMenu from './EduTemplateMenu';
import EduBasicTemplateList from './EduBasicTemplateList';
import '../../standard/mudmanage/Conf.scss';

function EduBasicTemplate() {
  const { formatMessage } = useIntl();
  const isAdmin = useSelector((state: any) => state.login.userData.isAdmin);

  return (
    <div className="admin-basic-conf table">
      <MainTitle
        type="table"
        title={formatMessage({ id: 'Template_38', defaultMessage: '기본 템플릿' })}
      >
        {!!isAdmin && <EduTemplateMenu />}
      </MainTitle>
      <div className="react-table-area">
        <EduBasicTemplateList />
      </div>
    </div>
  );
}

export default EduBasicTemplate;
