import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import $ from 'jquery';
// import 'summernote/dist/summernote-lite';
// import 'summernote/dist/summernote-lite.css';

import 'summernote/dist/summernote';
import 'summernote/dist/summernote.css';

import 'summernote/lang/summernote-ko-KR.js';
import 'summernote/lang/summernote-ja-JP.js';

import 'bootstrap/js/modal';
import 'bootstrap/js/dropdown';
import 'bootstrap/js/tooltip';
import 'bootstrap/dist/css/bootstrap.css';

import './CustomEditor.scss';

interface editorProps {
  onImageUploadEvt: (fileList: any, insertImage: any) => void;
  onFocusEvt: (target: string) => void;
  onChangeEvt: (contents: any) => void;
  onChangeCodeviewEvt: (contents: any) => void;
  value: any;
  className?: string;
  disabled?: boolean;
}

CustomEditor.defaultProps = {
  className: '',
  disabled: false,
};

function CustomEditor({
  onImageUploadEvt,
  onFocusEvt,
  onChangeEvt,
  onChangeCodeviewEvt,
  value,
  className,
  disabled,
}: editorProps) {
  const { formatMessage } = useIntl();
  const [uid, setUid] = useState(Math.floor(Math.random() * 100000).toString());

  useEffect(() => {
    let editor: any = {};

    // Custom image upload
    $.extend(($ as any).summernote.plugins, {
      customPlugin: function (context: any) {
        const self: any = this; // eslint-disable-line
        const { ui } = ($ as any).summernote;
        const lang = context.options.langInfo;
        const $editor = context.layoutInfo.editor;
        const contextOptions = context.options;

        context.memo('button.customPlugin', function () {
          // Here we create a button
          const button = ui.button({
            // icon for button
            contents: ui.icon(contextOptions.icons.picture),
            // tooltip for button
            tooltip: lang.image.insert,
            // Keep button from being disabled when in CodeView
            codeviewKeepButton: false,
            click: function () {
              context.invoke('customPlugin.show');
            },
          });
          return button.render();
        });

        self.initialize = function () {
          const $container = options.dialogsInBody ? $(document.body) : $editor;

          // Build the Body HTML of the Dialog.
          const extensionMsg = formatMessage({
            id: 'Template_57',
            defaultMessage: '*업로드 가능한 파일 형식',
          });

          // let extensionMsg = '*Uploadable file format';
          // if (options.lang === 'ko-KR') {
          //   extensionMsg = '*업로드 가능한 파일 형식';
          // } else if (options.lang === 'ja-JP') {
          //   extensionMsg = '*アップロード可能なファイル形式';
          // }

          const body = `<div class="note-group-select-from-files">
            <label for="${contextOptions.id}" class="note-form-label">${lang.image.selectFromFiles}</label>
            <input id="${contextOptions.id}" class="note-image-input" type="file" accept="image/jpeg, image/png, image/jpg, image/bmp, image/gif"/>
            <div class="extension-message">${extensionMsg}: .bmp, .jpg, .jpeg, png, gif</div></div>`;
          // Build the Footer HTML of the Dialog.
          self.$dialog = ui
            .dialog({
              // Set the title for the Dialog. Note: We don't need to build the markup for the Modal
              // Header, we only need to set the Title.
              title: lang.image.insert,
              // Set the Body of the Dialog.
              body: body,
              // Set the Footer of the Dialog.
              // footer: footer
              // editor adds the Modal to the DOM.
            })
            .render()
            .appendTo($container);
        };

        self.destroy = function () {
          ui.hideDialog(self.$dialog);
          self.$dialog.remove();
        };

        self.show = function () {
          context.invoke('editor.saveRange');

          self.showcustomPluginDialog().then(function (data: any) {
            ui.hideDialog(self.$dialog);

            context.invoke('editor.restoreRange');

            if (contextOptions.callbacks) {
              contextOptions.callbacks.onImageUpload(data);
            }
          });
        };

        self.showcustomPluginDialog = function () {
          return $.Deferred(function (deferred) {
            const $imageInput = self.$dialog.find('.note-image-input');

            ui.onDialogShown(self.$dialog, function () {
              context.triggerEvent('dialog.shown');

              $imageInput.replaceWith(
                $imageInput
                  .clone()
                  .on('change', function (event: any) {
                    deferred.resolve(event.target.files || event.target.value);
                  })
                  .val(''),
              );
            });
            ui.onDialogHidden(self.$dialog, function () {
              $imageInput.off();
              if (deferred.state() === 'pending') deferred.reject();
            });
            ui.showDialog(self.$dialog);
          });
        };
      },
    });

    let language = 'en-US';
    const fonts: any = ['Arial', 'Georgia', 'Times New Roman', 'Verdana', 'Malgun Gothic'];
    if (localStorage.getItem('language') === 'ko') {
      language = 'ko-KR';
    } else if (localStorage.getItem('language') === 'ja') {
      language = 'ja-JP';
      fonts.push('MEIRYO');
    }

    const callbacks = {
      onFocus: onFocusEvt,
      onChange: onChangeEvt,
      onChangeCodeview: onChangeCodeviewEvt,
      onImageUpload: (images: any) => {
        onImageUploadEvt(images, (url: string) => {
          editor.summernote('insertImage', url);
        });
      },
    };

    const summernoteOptions: any = {
      height: 400,
      lang: language,
      dialogsInBody: true,
      disableDragAndDrop: true,
      codeviewFilter: true,
      // fontNamesIgnoreCheck: fonts,
      fontNames: fonts,
      fontSizes: ['10', '11', '12', '14', '18', '24', '36'],
      styleTags: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
      toolbar: [
        ['font', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
        ['fontsize', ['fontsize']],
        ['fontname', ['fontname']],
        ['style', ['style']],
        ['color', ['color']],
        ['para', ['paragraph']],
        ['insert', ['hr', 'customPlugin']], // 'customPlugin'
        ['view', ['codeview']],
      ],
      callbacks: callbacks,
    };

    const options: any = summernoteOptions;
    editor = $(`#${uid}`);
    editor.summernote(options);
    editor.summernote('code', value);
    if (disabled) {
      editor.summernote('disable');
    }

    // editor.trigger('summernote.change');
    // editor.on('summernote.codeview.toggled', (e: any) => {
    //   if ($(e.target).summernote('codeview.isActivated')) {
    //     $(e.target).summernote('code', value);
    //   }
    // });
  }, []);

  return (
    <div className={`custom-editor ${className || ''}`}>
      <div id={uid} />
    </div>
  );
}

export default CustomEditor;
