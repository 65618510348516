import { useEffect } from 'react'
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getSystemConfig, updateSystemConfig } from 'store/admin'
import MainTitle from 'components/common/admin/MainTitle'
import InitialConfig from 'components/common/config/InitialConfig'
import ConfigTemplate from 'components/common/config/ConfigTemplate'
import "./Conf.scss";

export default function Basicconf(props: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const isAdmin = useSelector((state: any) => state.login.userData.isAdmin, shallowEqual)
  const basicConfig = useSelector((state: any) => state.admin.basicConfig)

  useEffect(() => {
    dispatch(getSystemConfig({ category: "basic" }))
  }, [])

  return (
    <div className="admin-basic-conf">
      <MainTitle className="small" title={formatMessage({ id: 'Config_2', defaultMessage: '기본 설정' })}>
        <InitialConfig category="basic" />
      </MainTitle>
      <ConfigTemplate category="basicConfig" updateConfig={updateSystemConfig} order={basicConfig?.classOrder || []} lists={basicConfig?.class || {}} isAdmin={isAdmin} />
    </div>
  );
}