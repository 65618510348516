import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Popover, Select, Tooltip } from 'antd';
import { changeState } from 'store/declare';
import ContentList from 'components/common/ContentList';
import SearchFilter from 'components/common/SearchFilter';

// import declareImage from 'img/declare/declare_on.png';

function DeclareExam({
  declareData,
  selectedTag,
  loading,
  onGetDeclare,
  onSelectItem,
  changeExam,
}: any) {
  const { formatMessage } = useIntl();
  const { Option } = Select;

  useEffect(() => {
    onGetDeclare(true);
  }, [selectedTag, declareData.param, declareData.needReload]);

  // 훈련 탭 훈련 리스트
  const examMenuList = useMemo(() => {
    const dataList = [...declareData.examData];
    dataList.unshift({
      examName: formatMessage({ id: 'Exam_5', defaultMessage: '전체 훈련' }),
      examNo: 'all',
    });
    return dataList;
  }, [declareData.examData]);

  return (
    <div className="declare-content-wrap pi">
      {/* 검색 영역 */}
      <SearchFilter
        param={declareData.param}
        paramAction={changeState}
        extraBtn={
          <Select
            className="declare-select"
            defaultValue="all"
            placeholder={formatMessage({ id: 'Filter_12', defaultMessage: '정렬' })}
            onChange={(value: any) => {
              changeExam(value);
            }}
          >
            {examMenuList?.map((data: any) => {
              return (
                <Option value={data.examNo} key={data.examNo}>
                  {data.examName}
                </Option>
              );
            })}
          </Select>
        }
      />

      {/* 리스트 */}
      <ContentList
        dataList={declareData.data}
        onLoadData={onGetDeclare}
        loading={loading}
        page={declareData.page}
        totalPage={declareData.totalPages}
        noContent={{
          title: formatMessage({ id: 'Declare_4', defaultMessage: '신고 이력이 없습니다.' }),
          subTitle: formatMessage({
            id: 'Declare_5',
            defaultMessage: '신고한 훈련 대상이 없습니다.',
          }),
        }}
        showNoImage
      >
        {declareData?.data.map((rowData: any) => {
          return (
            <div
              className={`content-list-item ${
                declareData.selectedNo === rowData.targetNo ? 'selected' : ''
              }`}
              key={rowData.targetNo}
              onClick={() => onSelectItem(rowData.targetNo)}
              aria-hidden="true"
            >
              <div className="list-item">
                <div className="list-column">
                  <Tooltip
                    overlayClassName="black-tooltip"
                    placement="bottom"
                    title={`${formatMessage({ id: 'Declare_7', defaultMessage: '신고 이력' })}: ${
                      rowData.declareCount
                    }${formatMessage({ id: 'Exam_26', defaultMessage: '개' })}`}
                  >
                    <span className="round-grey-button declare-icon-label color-border-declare">
                      {/* <img src={declareImage} alt="declare" /> */}
                      <img src="/img/declare/declare_on.png" alt="declare" />
                      {`${rowData.declareCount}${formatMessage({
                        id: 'Exam_26',
                        defaultMessage: '개',
                      })}`}
                    </span>
                  </Tooltip>
                </div>
                <div className="name-column">
                  <Popover
                    overlayClassName="between-popover full"
                    placement="topLeft"
                    content={
                      <>
                        <div className="popover-row">
                          <div className="text">
                            {formatMessage({ id: 'Target_10', defaultMessage: '대상자 번호' })}
                          </div>
                          <div className="value">{rowData.targetNo}</div>
                        </div>
                        <div className="popover-row">
                          <div className="text">
                            {formatMessage({ id: 'Name_1', defaultMessage: '이름' })}
                          </div>
                          <div className="value">{rowData.targetName}</div>
                        </div>
                        <div className="popover-row">
                          <div className="text">
                            {formatMessage({ id: 'Email_1', defaultMessage: '이메일' })}
                          </div>
                          <div className="value">{rowData.targetEmail}</div>
                        </div>
                      </>
                    }
                  >
                    <span className="ellipsis">{`${rowData.targetName} (${rowData.targetEmail})`}</span>
                  </Popover>
                </div>
                <div className="list-column">
                  <Popover
                    overlayClassName="between-popover division-popover full"
                    placement="topLeft"
                    content={
                      <div className="popover-row">
                        <div className="text">
                          {formatMessage({ id: 'Division_1', defaultMessage: '소속' })}
                        </div>
                        <div className="value">{rowData.targetDivision || '-'}</div>
                      </div>
                    }
                  >
                    <div className="round-border ellipsis">{rowData.targetDivision || '-'}</div>
                  </Popover>

                  <Popover
                    overlayClassName="between-popover division-popover full"
                    placement="topLeft"
                    content={
                      <div className="popover-row">
                        <div className="text">
                          {formatMessage({ id: 'Position_1', defaultMessage: '직급' })}
                        </div>
                        <div className="value">{rowData.targetPosition || '-'}</div>
                      </div>
                    }
                  >
                    <div className="round-border ellipsis">{rowData.targetPosition || '-'}</div>
                  </Popover>
                </div>
              </div>
            </div>
          );
        })}
      </ContentList>
    </div>
  );
}

export default DeclareExam;
