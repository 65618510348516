import { Checkbox } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import template, { selectTemplate } from 'store/template';
import './ExamTemplateTableCheck.scss';

export default function ExamTemplateTableCheck(props: any) {
  const dispatch = useDispatch();

  const {
    examData,
    allChecked,
    examTemplateArray,
    serviceTemplateArray,
    setAllChecked,
    templateInfo,
  } = props;

  const examList = JSON.parse(JSON.stringify(examTemplateArray));
  const serviceList = JSON.parse(JSON.stringify(serviceTemplateArray));

  let checkExam = false;
  let checkService = false;
  let indeterminate = false;

  if (templateInfo) {
    // 단일 체크 박스
    if (templateInfo.templatePick === 0) {
      // 기본 템플릿
      if (examTemplateArray && examTemplateArray.indexOf(templateInfo.templateNo) > -1) {
        // 기본 템플릿 체크 시
        checkExam = true;
      }
    } else if (
      serviceTemplateArray &&
      serviceTemplateArray.indexOf(templateInfo.serviceTemplateNo) > -1
    ) {
      // 사용자 템플릿

      // 사용자 템플릿 체크 시
      checkService = true;
    }
  } else {
    // 전체 체크 박스
    if (examTemplateArray.length > 0) {
      // 선택한 기본 템플릿이 있을 시
      checkExam = true;
    }
    if (serviceTemplateArray.length > 0) {
      // 선택한 사용자 템플릿이 있을 시
      checkService = true;
    }
  }

  // 전체 선택 체크 박스 '-'로 표시
  if (examTemplateArray.length + serviceTemplateArray.length < examData.length) {
    indeterminate = true;
  }

  useEffect(() => {
    if (!templateInfo) {
      setAllChecked(false);
      dispatch(
        selectTemplate({
          name: 'examTemplateNoArray',
          value: [],
        }),
      );
      dispatch(
        selectTemplate({
          name: 'serviceTemplateNoArray',
          value: [],
        }),
      );
    }
  }, [examData]);

  const clickCheckbox = () => {
    if (templateInfo) {
      // 단일 선택
      let targetNo = -1;
      if (templateInfo.templatePick === 0) {
        // 기본 템플릿
        targetNo = templateInfo.templateNo;
        if (examList.indexOf(targetNo) > -1) {
          // 체크 해제 시
          examList.splice(examList.indexOf(targetNo), 1);
        } else {
          // 체크 시
          examList.push(targetNo);
        }
      } else if (templateInfo.templatePick === 1) {
        // 사용자 템플릿
        targetNo = templateInfo.serviceTemplateNo;
        if (serviceList.indexOf(targetNo) > -1) {
          // 체크 해제 시
          serviceList.splice(serviceList.indexOf(targetNo), 1);
        } else {
          // 체크 시
          serviceList.push(targetNo);
        }
      }
      dispatch(
        selectTemplate({
          name: 'examTemplateNoArray',
          value: examList,
        }),
      );
      dispatch(
        selectTemplate({
          name: 'serviceTemplateNoArray',
          value: serviceList,
        }),
      );
    } else {
      // 전체 선택
      if (!allChecked) {
        setAllChecked(true);
        // 전체 선택 체크 시
        examList.splice(0).push([]);
        serviceList.splice(0).push([]);
        examData.map((item: any) => {
          if (item.serviceTemplateNo) {
            serviceList.push(item.serviceTemplateNo);
          } else {
            examList.push(item.templateNo);
          }
          return true;
        });
      } else if (examList.length + serviceList.length < examData.length) {
        // 전체 선택 체크 해제 시
        // indeterminate 상태일 시 전체 선택으로 바뀜
        setAllChecked(true);
        examList.splice(0).push([]);
        serviceList.splice(0).push([]);
        examData.map((item: any) => {
          if (item.serviceTemplateNo) {
            serviceList.push(item.serviceTemplateNo);
          } else {
            examList.push(item.templateNo);
          }
          return true;
        });
      } else {
        // 전체 선택된 상태일 시 전체 해제로 바뀜
        setAllChecked(false);
        examList.splice(0).push([]);
        serviceList.splice(0).push([]);
      }

      dispatch(
        selectTemplate({
          name: 'examTemplateNoArray',
          value: examList,
        }),
      );
      dispatch(
        selectTemplate({
          name: 'serviceTemplateNoArray',
          value: serviceList,
        }),
      );
    }
  };

  // 체크박스 이미지
  let CheckBoxComponent = <Checkbox className="exam-check" checked={false} />;
  if (checkExam || checkService) {
    if (templateInfo) {
      CheckBoxComponent = (
        <img src="/img/exam/list_check_on.png" alt="check" className="exam-check" />
      );
    } else {
      CheckBoxComponent = indeterminate ? (
        <img src="/img/exam/list_check_each.png" alt="check" className="exam-check" />
      ) : (
        <img src="/img/exam/list_check_on.png" alt="check" className="exam-check" />
      );
    }
  }

  return (
    <div
      className="exam-template-checkbox"
      onClick={clickCheckbox}
      onKeyDown={clickCheckbox}
      role="button"
      tabIndex={0}
    >
      {CheckBoxComponent}

      {/*
      // 기존 background-image 체크박스 
      {templateInfo ? (
        <Checkbox
          className="exam-check"
          checked={checkExam || checkService}
          style={{ backgroundImage: 'url(/img/exam/list_check_on.png)' }}
        />
      ) : // eslint-disable-line
      checkExam || checkService ? (
        // eslint-disable-line
        indeterminate ? (
          <Checkbox className="exam-check" indeterminate />
        ) : (
          <Checkbox className="exam-check" checked />
        )
      ) : (
        <Checkbox className="exam-check" checked={false} />
      )} */}
    </div>
  );
}
