import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import FormTextField from 'components/common/FormTextField';
import * as valid from 'utils/validation';
import { withRouter } from 'react-router-dom';
import { adminInitSetting } from 'store/admin';
import { Option } from 'utils/commonValues';

// import logoImage from "img/logo/m_logo.png";
// import sLogoImage from 'img/logo/s_logo.png';

function Adminfirst(props: any) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>({
    type: '',
    content: '',
  });
  const { formatMessage } = useIntl();

  const { errors, register, getValues, handleSubmit } = useForm({
    mode: 'all',
  });
  const { userEmail, userPasswd, userPasswdCheck, userName } = getValues();

  const onAdminInitSetting = async (formData: any) => {
    if (!loading) {
      setLoading(true);
      try {
        if (formData.userPasswd === formData.userPasswdCheck) {
          const params = {
            userEmail: formData.userEmail,
            userPasswd: formData.userPasswd,
            userName: formData.userName,
          };

          const respObj: any = await dispatch(adminInitSetting(params));
          if (respObj?.status === 200) {
            if (respObj.data && Array.isArray(respObj.data.list)) {
              setErrorMessage({
                type: 'success',
                content: (
                  <div>
                    <div className="confirm-message">
                      {formatMessage({ id: 'FirstLogin_4', defaultMessage: '변경 완료!' })}
                    </div>
                  </div>
                ),
              });
            } else if (Array.isArray(respObj.data.error)) {
              setErrorMessage({
                type: 'userEmail',
                content: respObj.data.error[0].errInfo,
              });
            } else {
              setErrorMessage({
                type: 'userEmail',
                content: formatMessage({
                  id: 'Password_14',
                  defaultMessage: '비밀번호가 틀렸습니다.',
                }),
              });
            }
          }
        } else {
          setErrorMessage({
            type: 'userPasswd',
            content: formatMessage({
              id: 'Password_15',
              defaultMessage: '비밀번호가 일치하지 않습니다.',
            }),
          });
        }

        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const onChangeForm = () => {
    setErrorMessage({ type: '', content: '' });
  };

  return (
    <div className="admin-first-edit-area">
      <div className="display-flex">
        <img
          src="/img/logo/m_logo.png" // {Option.isSaxa === 1 ? sLogoImage : logoImage}
          alt="logo"
        />
        <div>
          <div className="admin-title">
            {formatMessage({ id: 'FirstLogin_1', defaultMessage: '관리자 계정 최초 등록' })}
          </div>
          <div className="bold mt-5">
            {formatMessage({
              id: 'FirstLogin_2',
              defaultMessage: '최초 로그인시 ID와 비밀번호를 변경 할 수 있습니다.',
            })}
          </div>
        </div>
      </div>
      {errorMessage.type === 'success' ? (
        <>
          <div className="modify-confirm">{errorMessage.content}</div>
          <div className="button-area">
            <button
              className="bt-change"
              onClick={() => props.history.push('/mudlogin')}
              type="button"
            >
              {formatMessage({ id: 'Login_1', defaultMessage: '로그인' })}
            </button>
          </div>
        </>
      ) : (
        <div>
          <form
            onSubmit={handleSubmit(onAdminInitSetting)}
            onChange={onChangeForm}
            autoComplete="off"
          >
            <span className="text-field-title">ID</span>
            <div className="first-wrap-input">
              <FormTextField
                name="userEmail"
                value={userEmail}
                error={errors.userEmail}
                errorCheck={errorMessage.type === 'userEmail'}
                errorText={errorMessage.type === 'userEmail' && errorMessage.content}
                register={register}
                validation={{
                  validate: {
                    required: (value: any) => valid.required(value),
                    range: (value: any) => valid.range(value, 5, 50),
                    mailId: (value: string) => valid.mailId(value),
                  },
                }}
              />
            </div>
            <span className="text-field-title">
              {formatMessage({ id: 'Password_1', defaultMessage: '비밀번호' })}
            </span>
            <div className="first-wrap-input">
              <FormTextField
                name="userPasswd"
                type="password"
                value={userPasswd}
                error={errors.userPasswd}
                errorCheck={errorMessage.type === 'userPasswd'}
                errorText={errorMessage.type === 'userPasswd' && errorMessage.content}
                register={register}
                validation={{
                  validate: {
                    required: (value: any) => valid.required(value),
                  },
                }}
              />
            </div>
            <span className="text-field-title">
              {formatMessage({ id: 'Password_3', defaultMessage: '비밀번호 확인' })}
            </span>
            <div className="first-wrap-input">
              <FormTextField
                name="userPasswdCheck"
                type="password"
                value={userPasswdCheck}
                error={errors.userPasswdCheck}
                errorCheck={errorMessage.type === 'userPasswdCheck'}
                errorText={errorMessage.type === 'userPasswdCheck' && errorMessage.content}
                register={register}
                validation={{
                  validate: {
                    required: (value: any) => valid.required(value),
                  },
                }}
              />
            </div>
            <span className="text-field-title">
              {formatMessage({ id: 'Name_1', defaultMessage: '이름' })}
            </span>
            <div className="first-wrap-input">
              <FormTextField
                name="userName"
                value={userName}
                error={errors.userName}
                errorCheck={errorMessage.type === 'userName'}
                errorText={errorMessage.type === 'userName' && errorMessage.content}
                register={register}
                validation={{}}
              />
            </div>
            <div>
              <div className="button-area">
                <button className="bt-change" type="submit">
                  {formatMessage({ id: 'Button_1', defaultMessage: '변경' })}
                  {loading && (
                    <span className="button-loading">
                      <LoadingOutlined />
                    </span>
                  )}
                </button>
                <button
                  className="bt-noChange"
                  type="button"
                  onClick={() => props.history.push('/mudlogin')}
                >
                  {formatMessage({ id: 'Button_2', defaultMessage: '취소' })}
                  {loading && (
                    <span className="button-loading">
                      <LoadingOutlined />
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default withRouter(Adminfirst);
