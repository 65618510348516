import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { putDeclare, deleteDeclare } from 'store/declare';
import * as valid from 'utils/validation';
import { timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import Loading from 'components/common/Loading';
import ModalTemplate from 'components/common/ModalTemplate';
import FormTextField from 'components/common/FormTextField';

function DeclareDetail({
  detailType,
  detailData,
  detailLoading,
  selectedNo,
  onGetDeclareDetail,
}: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [openModal, setOpenModal]: any = useState({});
  const [loading, setLoading] = useState(false);
  const { control, errors, register, getValues, handleSubmit } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    if (detailType === 'exam') {
      onGetDeclareDetail();
    }
  }, [selectedNo]);

  // 신고 정보 수정
  const onEditDeclare = async () => {
    try {
      setLoading(true);
      const { subject, content } = getValues();

      const params: any = {
        declareNo: openModal.declareData.declareNo,
      };

      let isEdit = false;
      // 신고 제목 변경 시
      if (subject !== openModal.declareData.subject) {
        params.subject = subject;
        isEdit = true;
      }

      // 신고 내용 변경 시
      if (content !== openModal.declareData.content) {
        params.content = content;
        isEdit = true;
      }

      if (isEdit) {
        await dispatch(putDeclare(params));
        setOpenModal({});
      }
      setLoading(false);
    } catch (error) {
      console.log('DeclareDetail onEditDeclare', error);
    }
  };

  // 신고 정보 삭제
  const onDeleteDeclare = async () => {
    try {
      setLoading(true);

      const params = {
        declareNo: openModal.declareData.declareNo,
        targetNo: selectedNo,
      };

      await dispatch(deleteDeclare(params));
      setLoading(false);
      setOpenModal({});
    } catch (error) {
      console.log('DeclareDetail onDeleteDeclare', error);
    }
  };

  // declareFlag - 1: 훈련신고, 2: 외부신고
  const isExternal = detailData.declareFlag === 2;

  return (
    <div className="detail-area declare-detail">
      <Loading loading={detailLoading || loading} />
      <div className="detail-item">
        <div className="detail-title">
          {formatMessage({ id: 'Declare_8', defaultMessage: '신고 정보' })}
        </div>
        <div className="detail-contents">
          {detailData?.map((data: any) => {
            return (
              <div className="declare-detail-box" key={data.declareNo}>
                {/* 신고 분류 및 편집 버튼 영역 */}
                <div className="detail-extra-area">
                  {data.isNis === 1 && (
                    <div className="declare-label nis">
                      {formatMessage({ id: 'Declare_9', defaultMessage: '국정원 신고' })}
                    </div>
                  )}
                  {isExternal && (
                    <div className="declare-label external">
                      {formatMessage({ id: 'Declare_10', defaultMessage: '외부 신고' })}
                    </div>
                  )}
                  {data.isNis !== 1 && !isExternal && (
                    <div className="declare-label exam">
                      {formatMessage({ id: 'Declare_11', defaultMessage: '훈련 신고' })}
                    </div>
                  )}

                  <div className="detail-btns">
                    {data.isNis !== 1 && !isExternal && (
                      <div
                        className="edit-icon"
                        onClick={() => setOpenModal({ type: 'declareEdit', declareData: data })}
                        aria-hidden="true"
                        title={formatMessage({
                          id: 'Target_16',
                          defaultMessage: '편집하려면 클릭하세요.',
                        })}
                      >
                        <EditFilled />
                      </div>
                    )}
                    {!isExternal && (
                      <div
                        className="edit-icon"
                        onClick={() => setOpenModal({ type: 'declareDelete', declareData: data })}
                        aria-hidden="true"
                        title={formatMessage({
                          id: 'Declare_12',
                          defaultMessage: '신고 이력 삭제하기',
                        })}
                      >
                        <DeleteFilled />
                      </div>
                    )}
                  </div>
                </div>

                {/* 신고 정보 */}
                <div className="detail-row">
                  <div className="detail-text">No</div>
                  <div className="detail-value">{data.declareNo}</div>
                </div>

                {!isExternal && (
                  <>
                    <div className="detail-row">
                      <div className="detail-text">
                        {formatMessage({ id: 'Exam_1', defaultMessage: '훈련명' })}
                      </div>
                      <div className="detail-value">{data.examName || '-'}</div>
                    </div>
                    <div className="detail-row">
                      <div className="detail-text">
                        {formatMessage({ id: 'Template_24', defaultMessage: '템플릿명' })}
                      </div>
                      <div className="detail-value">{data.templateName || '-'}</div>
                    </div>
                  </>
                )}

                {isExternal && (
                  <div className="detail-row">
                    <div className="detail-text">
                      {formatMessage({ id: 'Email_20', defaultMessage: '메일 제목' })}
                    </div>
                    <div className="detail-value">{data.mailSubject || '-'}</div>
                  </div>
                )}

                <div className="detail-row">
                  <div className="detail-text">
                    {formatMessage({ id: 'Declare_13', defaultMessage: '신고 제목' })}
                  </div>
                  <div className="detail-value">{data.subject || '-'}</div>
                </div>

                <div className="detail-row">
                  <div className="detail-text">
                    {formatMessage({ id: 'Declare_14', defaultMessage: '메일 신고 내용' })}
                  </div>
                  <div className="detail-value">{data.content || '-'}</div>
                </div>

                {isExternal && (
                  <div className="detail-row">
                    <div className="detail-text">
                      {formatMessage({ id: 'Declare_15', defaultMessage: '메일 신고 유형' })}
                    </div>
                    <div className="detail-value">
                      {data.declareType === 'spam'
                        ? formatMessage({ id: 'Declare_16', defaultMessage: '스팸' })
                        : formatMessage({ id: 'Declare_17', defaultMessage: '해킹' })}
                    </div>
                  </div>
                )}

                {data.isNis !== 1 && (
                  <>
                    <div className="detail-row">
                      <div className="detail-text">
                        {formatMessage({ id: 'Name_8', defaultMessage: '신고자 이름' })}
                      </div>
                      <div className="detail-value">{data.extraInfo?.declareName || '-'}</div>
                    </div>

                    <div className="detail-row">
                      <div className="detail-text">
                        {formatMessage({ id: 'Email_25', defaultMessage: '신고자 이메일' })}
                      </div>
                      <div className="detail-value">{data.extraInfo?.declareEmail || '-'}</div>
                    </div>

                    <div className="detail-row">
                      <div className="detail-text">
                        {formatMessage({ id: 'Division_4', defaultMessage: '신고자 소속' })}
                      </div>
                      <div className="detail-value">{data.extraInfo?.declareDivision || '-'}</div>
                    </div>

                    <div className="detail-row">
                      <div className="detail-text">
                        {formatMessage({ id: 'Date_7', defaultMessage: '신고 일시' })}
                      </div>
                      <div className="detail-value">
                        {timeFormatFromUTCEpoch(data.declareEpoch)}
                      </div>
                    </div>

                    {isExternal && (
                      <div className="detail-row">
                        <div className="detail-text">
                          {formatMessage({ id: 'Declare_18', defaultMessage: '발신지 도시' })}
                        </div>
                        <div className="detail-value">{data.countryName || '-'}</div>
                      </div>
                    )}

                    {isExternal && (
                      <div className="detail-row">
                        <div className="detail-text">
                          {formatMessage({ id: 'Declare_19', defaultMessage: '발신지 IP' })}
                        </div>
                        <div className="detail-value">{data.ip || '-'}</div>
                      </div>
                    )}
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>

      {/* 대상자 수정 모달 */}
      {openModal?.type === 'declareEdit' && (
        <ModalTemplate
          className="declare-edit-modal modal-464"
          visible={openModal.type === 'declareEdit'}
          title={`${formatMessage({ id: 'Declare_23', defaultMessage: '신고 정보 수정' })} (No.${
            openModal.declareData.declareNo
          })`}
          onOk={handleSubmit(onEditDeclare)}
          onCancel={() => setOpenModal({})}
          okText={formatMessage({ id: 'Button_14', defaultMessage: '수 정' })}
          cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
          loading={loading}
        >
          <form autoComplete="off" onSubmit={(e: any) => e.preventDefault()}>
            <div className="input-title">
              {formatMessage({ id: 'Declare_13', defaultMessage: '신고 제목' })}
            </div>
            <FormTextField
              name="subject"
              defaultValue={openModal.declareData.subject}
              error={errors.subject}
              register={register}
              arrowPosition="top"
              validation={{
                validate: {
                  required: (value: any) => valid.required(value),
                  name: (value: any) => valid.name(value),
                },
              }}
            />

            <div className="text-field-title">
              {formatMessage({ id: 'Declare_14', defaultMessage: '메일 신고 내용' })}
            </div>
            <Controller
              control={control}
              name="content"
              defaultValue={openModal.declareData.content}
              render={({ onChange }) => (
                <textarea
                  className="declare-textarea"
                  defaultValue={openModal.declareData.content}
                  rows={5}
                  onChange={onChange}
                >
                  {/* {openModal.declareData.content} */}
                </textarea>
              )}
            />
          </form>
        </ModalTemplate>
      )}

      {/* 대상자 삭제 모달 */}
      {openModal?.type === 'declareDelete' && (
        <ModalTemplate
          className="modal-464"
          visible={openModal.type === 'declareDelete'}
          title={formatMessage({ id: 'Declare_20', defaultMessage: '신고 정보 삭제' })}
          onOk={onDeleteDeclare}
          onCancel={() => setOpenModal({})}
          okText={formatMessage({ id: 'Button_15', defaultMessage: '삭 제' })}
          cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
          loading={loading}
          greyButton
        >
          <div className="modal-explain-text">
            <div>
              {formatMessage({
                id: 'Declare_21',
                defaultMessage: '삭제한 신고 정보는 복구할 수 없습니다.',
              })}
            </div>
            <div>
              {formatMessage({
                id: 'Declare_22',
                defaultMessage: '다음 정보를 삭제합니까?',
              })}
            </div>
          </div>
          <div className="modal-border-box">
            <ul className="modal-item-list">
              <li>
                <div className="item-title">No</div>
              </li>
              <div>{openModal.declareData.declareNo}</div>
            </ul>
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'Exam_1', defaultMessage: '훈련명' })}
                </div>
              </li>
              <div>{openModal.declareData.examName}</div>
            </ul>
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'Declare_13', defaultMessage: '신고 제목' })}
                </div>
              </li>
              <div>{openModal.declareData.subject}</div>
            </ul>
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'Date_7', defaultMessage: '신고 일시' })}
                </div>
              </li>
              <div>{timeFormatFromUTCEpoch(openModal.declareData.regEpoch)}</div>
            </ul>
          </div>
        </ModalTemplate>
      )}
    </div>
  );
}

export default DeclareDetail;
