import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from 'antd';
import { DeleteOutlined, FormOutlined } from '@ant-design/icons';
import { deleteEduQuiz } from 'store/quiz';
import DeleteModal from 'components/common/modal/DeleteModal';
import QuizAddEdit from 'components/branch/quiz/QuizAddEdit';

function QuizSettings({ isAdmin, dataInfo }: any) {
  const { formatMessage } = useIntl();
  const [openModal, setOpenModal] = useState('');

  const deleteData = {
    title: formatMessage({ id: 'Edu_64', defaultMessage: '퀴즈 삭제' }),
    contents: (
      <div>
        {formatMessage({
          id: 'Edu_65',
          defaultMessage: '삭제한 퀴즈는 복구할 수 없습니다.',
        })}
        <br />
        {formatMessage({
          id: 'Edu_66',
          defaultMessage: '다음 퀴즈를 삭제합니까?',
        })}
      </div>
    ),
    list: [
      {
        key: 'No',
        value: dataInfo.quizNo,
      },
      {
        key: formatMessage({ id: 'Edu_33', defaultMessage: '퀴즈 내용' }),
        value: dataInfo.quizContent,
      },
    ],
  };

  return (
    <div className="table-settings">
      {/* 수정 */}
      {!!dataInfo.quizEnabled && (
        <Tooltip
          overlayClassName="black-tooltip"
          placement="bottom"
          title={formatMessage({ id: 'Edu_68', defaultMessage: '퀴즈 수정' })}
        >
          <FormOutlined onClick={() => setOpenModal('edit')} />
        </Tooltip>
      )}
      
      {openModal === 'edit' && (
        <QuizAddEdit
          visible={openModal === 'edit'}
          isAdmin={isAdmin}
          isAddMode={false}
          dataInfo={dataInfo}
          toggleModal={(open: boolean) => setOpenModal(open ? 'edit' : '')}
        />
      )}

      {/* 삭제 */}
      {!!dataInfo.quizEnabled && (
        <Tooltip
          overlayClassName="black-tooltip"
          placement="bottom"
          title={formatMessage({ id: 'Edu_64', defaultMessage: '퀴즈 삭제' })}
        >
          <DeleteOutlined onClick={() => setOpenModal('delete')} />
        </Tooltip>
      )}

      {openModal === 'delete' && (
        <DeleteModal
          visible={openModal === 'delete'}
          params={{ quizNo: dataInfo.quizNo }}
          modalData={deleteData}
          dispatchFunc={deleteEduQuiz}
          toggleModal={(open: boolean) => setOpenModal(open ? 'delete' : '')}
        />
      )}
    </div>
  );
}

export default QuizSettings;
