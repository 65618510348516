import { useIntl } from 'react-intl';
import './TablePagenation.scss';

// import arrowLeftImage from 'img/table/paging_arrow_left.png';
// import arrowRightImage from 'img/table/paging_arrow_right.png';

export default function TablePagenation(props: any) {
  const { formatMessage } = useIntl();
  const { currentPage, totalPages, setCurrentPage } = props;

  const pageList = [];

  if( totalPages === 0 ){
    setCurrentPage(0);
  }
  
  for (let i = 1; i <= totalPages; i += 1) {
    if (
      i === 1 ||
      i === totalPages ||
      (currentPage - i < 3 && currentPage - i >= 0) ||
      (i - currentPage > 0 && i - currentPage < 3)
    ) {
      pageList.push(
        <div
          key={i}
          role="button"
          tabIndex={0}
          className={`pagenation-bt${currentPage === i ? ' underline' : ''}`}
          onClick={() => setCurrentPage(i - 1)}
          onKeyDown={() => true}
        >
          {i}
        </div>,
      );
    } else if (
      (currentPage > 4 && currentPage - i === 3) ||
      (totalPages - currentPage >= 4 && i - currentPage === 3)
    ) {
      pageList.push(
        <div
          key={i}
          role="button"
          tabIndex={0}
          className="pagenation-bt"
          onClick={() => setCurrentPage(i - 1)}
          onKeyDown={() => true}
        >
          . . .
        </div>,
      );
    }
  }

  return (
    <div className="react-pagenation-area">
      <div className="react-pagenation">
        <div className="pagenation previous">
          <div className="pagenation-bt">
            <div
              className="image-wrap"
              role="button"
              tabIndex={0}
              onClick={() => currentPage - 1 > 0 && setCurrentPage(currentPage - 2)}
              onKeyDown={() => true}
            >
              <img src="/img/table/paging_arrow_left.png" alt="" />
            </div>
          </div>
        </div>
        <div className="pagenation each-area">{pageList}</div>
        <div className="pagenation next">
          <div className="pagenation-bt">
            <div
              className="image-wrap"
              role="button"
              tabIndex={0}
              onClick={() => currentPage < totalPages && setCurrentPage(currentPage)}
              onKeyDown={() => true}
            >
              <img src="/img/table/paging_arrow_right.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
