export default function Netdata(props: any) {

    return (
      <iframe
          id="inlineFrameExample"
          title="Inline Frame Example"
          width="100%"
          height="100%"
          src="/netdata"
          style= {{border: 0}}
        />
    )
  }