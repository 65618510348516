import EduTemplateList from 'components/branch/eduTemplate/EduTemplateList';
import EduTemplateMenu from 'components/branch/eduTemplate/EduTemplateMenu';
import MainTitle from 'components/common/admin/MainTitle';
import { useIntl } from 'react-intl';
import "./Conf.scss";

export default function Edutemplate(props: any) {
  const { formatMessage } = useIntl();
  

  return (
    <div className="admin-basic-conf table">
      <MainTitle type="table" title={formatMessage({ id: 'Edu_4', defaultMessage: '교육 템플릿' })}>
        <EduTemplateMenu />
      </MainTitle>
      <div className="react-table-area">
        <EduTemplateList />
      </div>
    </div>
  );
}