import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { getSystemConfig, systemConfigInitBasic } from 'store/admin';
import { getServiceConfig, serviceConfigInitBasic } from 'store/config';

import ModalTemplate from 'components/common/ModalTemplate';

import "./InitialConfig.scss";

export default function InitialConfig(props: any) {
  const [loading, setloading] = useState<boolean>(false);
  const [isModal, setIsModal] = useState<boolean>(false);
  const isAdmin = useSelector((state: any) => state.user.isAdmin)
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { category } = props;

  const onToggleModal = (type: boolean) => {
    setIsModal(type)
  };

  const onInitialConfig = async () => {
    setloading(true);

    try {
      if (category) {
        if (isAdmin === 1) {
          await dispatch(systemConfigInitBasic({ category: category }))
          await dispatch(getSystemConfig({ category: category }))
        } else {
          await dispatch(serviceConfigInitBasic({ category: category }))
          await dispatch(getServiceConfig({ category: category }))
        }
      }
    } catch (e) {
      console.log(e)
    }
    setloading(false);
    onToggleModal(false);
  }
  return (
    <>
      <ModalTemplate
        visible={isModal}
        className="modal-464"
        onOk={() => onInitialConfig()}
        onCancel={() => onToggleModal(false)}
        loading={loading}
        title={formatMessage({ id: 'Config_4', defaultMessage: '설정을 기본값으로 복원' })}
        okText={formatMessage({ id: 'Button_8', defaultMessage: '복 원' })}
        cancelText={formatMessage({ id: 'Button_5', defaultMessage: '닫 기' })}
      >
        <div className="modal-text center">
          {formatMessage({
            id: 'Config_6',
            defaultMessage: '초기 설정의 기본값으로 설정을 복원합니다.',
          })}
        </div>
      </ModalTemplate>

      <button
        className="bt-style-gleam common-button-gray initial-config-bt"
        type="button"
        // loading={onProgress}
        // disabled={onProgress}
        onClick={() => onToggleModal(true)}
      >
        {formatMessage({ id: 'Config_5', defaultMessage: '기본값' })}
      </button>
    </>
  );
}