import React from 'react';
import { Redirect, Route, useRouteMatch, Switch } from 'react-router-dom';
import AutoRoute from 'components/common/AutoRoute';
import Footer from 'components/branch/Footer';
import { Option } from 'utils/commonValues';
import './Login.scss';

export const authLevel = 0;
export default function Login(props: any) {
  const { url } = useRouteMatch();

  return (
    <>
      <div
        className={`login-template ${Option.customer}`}
        style={{ backgroundImage: 'url(/img/login/login_back.png)' }}
      >
        <div className="login-contents">
          <Switch>
            <Route path={`${url}/:name`} component={AutoRoute} />
            <Redirect path="*" to="/login/basic" />
          </Switch>
        </div>
        {(Option.isJapan || localStorage.getItem('language') === 'ja') && <Footer />}
      </div>
    </>
  );
}
