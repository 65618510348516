import { useIntl } from 'react-intl';
import MainTitle from 'components/common/admin/MainTitle';
import SubAdminList from 'components/branch/subadmin/SubAdminList';
import SubAdminMenu from 'components/branch/subadmin/SubAdminMenu';
import './Conf.scss';

export default function Subadmin() {
  const { formatMessage } = useIntl();

  return (
    <div className="admin-basic-conf table">
      <MainTitle type="table" title={formatMessage({ id: 'User_30', defaultMessage: '부관리자' })}>
        <SubAdminMenu />
      </MainTitle>
      <div className="react-table-area">
        <SubAdminList />
      </div>
    </div>
  );
}
