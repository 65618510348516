import { Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './ModalTemplate.scss';

function ModalTemplate(props: any) {
  const {
    className,
    visible,
    title,
    onOk,
    onCancel,
    cancelText,
    okText,
    footer,
    loading,
    greyButton, // 우측 ok 버튼 회색으로 적용
    children,
    disabled, // 클릭 불가 처리
    isCloseBlack, // true일 경우 검정으로 적용
  } = props;

  // 모달 닫기 버튼 색상
  const closeIcon = isCloseBlack ? (
    <img src="/img/modal/close_black.png" alt="x" />
  ) : (
    <img src="/img/modal/close.png" alt="x" />
  );

  return (
    <Modal
      className={`common-modal ${className}`}
      closeIcon={closeIcon}
      visible={visible}
      title={title}
      onCancel={onCancel}
      footer={
        footer || (
          <>
            {cancelText && (
              <button key="cancel" type="button" onClick={onCancel}>
                {cancelText}
              </button>
            )}
            {okText && (
              <button
                key="ok"
                className={`ok ${greyButton ? 'grey' : ''} ${
                  disabled || loading ? 'disabled' : ''
                }`}
                type="button"
                onClick={onOk}
              >
                {okText}
                {loading && (
                  <span className="button-loading">
                    <LoadingOutlined />
                  </span>
                )}
              </button>
            )}
          </>
        )
      }
      centered
      maskClosable={false}
      transitionName=""
      maskTransitionName=""
    >
      <div className="modal-content">{children}</div>
    </Modal>
  );
}

export default ModalTemplate;
