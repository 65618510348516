import React from 'react';
import { useIntl } from 'react-intl';
import { LoadingOutlined } from '@ant-design/icons';
import './Loading.scss';

function Loading(props: any) {
  const { loadingText, loading, className, darkMode } = props;
  const { formatMessage } = useIntl();

  return (
    <div
      className={`common-loading ${loading ? 'active' : ''} ${className || ''} ${
        darkMode ? 'bg-dark' : ''
      }`}
    >
      <div className="loading-content">
        <LoadingOutlined />
        <div className="loading-text">
          {loadingText || formatMessage({ id: 'Loading_1', defaultMessage: '데이터 로딩중...' })}
        </div>
      </div>
    </div>
  );
}

export default Loading;
