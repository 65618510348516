import ExamTemplateList from 'components/branch/examTemplate/ExamTemplateList';
import ExamTemplateMenu from 'components/branch/examTemplate/ExamTemplateMenu';
import MainTitle from 'components/common/admin/MainTitle';
import { useIntl } from 'react-intl';
import "./Conf.scss";

export default function Examtemplate(props: any) {
  const { formatMessage } = useIntl();
  

  return (
    <div className="admin-basic-conf table">
      <MainTitle type="table" title={formatMessage({ id: 'Template_1', defaultMessage: '훈련 템플릿' })}>
        <ExamTemplateMenu />
      </MainTitle>
      <div className="react-table-area">
        <ExamTemplateList />
      </div>
    </div>
  );
}