import UserList from 'components/branch/user/UserList';
import UserMenu from 'components/branch/user/UserMenu';
import MainTitle from 'components/common/admin/MainTitle';
import { useIntl } from 'react-intl';
import "./Conf.scss";

export default function User(props: any) {
  const { formatMessage } = useIntl();
  

  return (
    <div className="admin-basic-conf table">
      <MainTitle type="table" title={formatMessage({ id: 'User_1', defaultMessage: '사용자' })}>
        <UserMenu />
      </MainTitle>
      <div className="react-table-area">
        <UserList />
      </div>
    </div>
  );
}