import LeakFileList from 'components/branch/exam/LeakFileList';
import { useIntl } from 'react-intl';
import "../mudmanage/Conf.scss";

export default function Leakfile(props: any) {
  const { formatMessage } = useIntl();
  

  return (
    <div className="admin-basic-conf table" style={{padding: 0}}>
      <div className="react-table-area" style={{padding: 0}}>
        <LeakFileList />
      </div>
    </div>
  );
}
