import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import {
  makePhishingNo,
  deletePhishingNo,
  phishingDeleteImage,
  savePhishingPage,
} from 'store/phishing';
import Loading from 'components/common/Loading';
import ModalTemplate from 'components/common/ModalTemplate';
import PhishingEditor from 'components/branch/phishingPage/PhishingEditor';
import PhishingPageHeader from 'components/branch/phishingPage/PhishingPageHeader';
import PhishingPageSidebar from 'components/branch/phishingPage/PhishingPageSidebar';
import './PhishingPageAddEdit.scss';

function PhishingPageAddEdit({ visible, isAdmin, isAddMode, phishingInfo, toggleModal }: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [phishingLevel, setPhishingLevel]: any = useState(0);
  const [currentPage, setCurrentPage]: any = useState(0);
  const [images, setImages]: any = useState(phishingInfo.imageList || []);
  const [uploadedList, setUploadedList] = useState([]);
  const [phishingNo, setPhishingNo] = useState(0);
  const [preview, setPreview] = useState(false);
  const [formValues, setFormValues]: any = useState({
    isPublic: phishingInfo.isPublic,
    phishingScreenEnable: phishingInfo.phishingScreenEnable,
  });
  const [phishingCode, setPhishingCode]: any = useState({
    phishingMailbody: '',
    phishingHtml1: '',
    phishingCss1: '',
    phishingJs1: '',
    phishingHtml2: '',
    phishingCss2: '',
    phishingJs2: '',
    phishingHtml3: '',
    phishingCss3: '',
    phishingJs3: '',
  });

  const { register, handleSubmit, errors, control } = useForm({
    mode: 'all',
    defaultValues: {
      ...phishingInfo,
    },
  });

  useEffect(() => {
    if (isAddMode) {
      // 등록
      onMakePhishingNo();

      const language = localStorage.getItem('language');
      let mailbody =
        `<div style="color: #333333; font-family: 'Malgun Gothic', sans-serif; padding: 0; text-align: left">
  메일 본문을 작성합니다.<br />
  <br />
  다음과 같이 작성하면 URL 클릭 시 감염 후 화면이 표시됩니다.<br />
  (https://~ 에 사용할 URL은 자유롭게 변경할 수 있습니다.)<br />
  <a href=$` +
        `{STAGE_FINISH}$ target="_blank">https://mudfix.co.kr/phishing/sample</a><br />
  <br />
</div>`;
      if (language === 'en') {
        mailbody =
          `<div style="color: #333333; font-family: 'Arial', sans-serif; padding: 0; text-align: left">
  Write the body of the mail. <br />
  <br />
  If you write as follows, result content is displayed when you click the URL.<br />
  (You can freely change the URL used for https://~.)<br />
  <a href=$` +
          `{STAGE_FINISH}$ target="_blank">https://mudfix.co.kr/phishing/sample</a><br />
  <br />
</div>`;
      } else if (language === 'ja') {
        mailbody =
          `<div style="color: #333333; font-family: 'MEIRYO', sans-serif; padding: 0; text-align: left">
  ここにメール本文を書きます。<br />
  <br />
  以下のように記述すると感染後画面が表示され感染となります。<br />
  (https://～のURLは自由に変更することが可能です。)<br />
  <a href=$` +
          `{STAGE_FINISH}$ target="_blank">https://mudfix.jp/phishing/sample</a><br />
  <br />
</div >`;
      }
      setPhishingCode({
        ...phishingCode,
        phishingMailbody: mailbody,
      });
    } else {
      // 수정
      const codeData: any = {};
      Object.keys(phishingCode).forEach((key: string) => {
        codeData[key] = phishingInfo[key];
      });
      setPhishingNo(phishingInfo.phishingNo);
      setPhishingLevel(phishingInfo.phishingLevel - 1);
      setPhishingCode(codeData);
    }
  }, []);

  // 피싱 페이지 번호 생성
  const onMakePhishingNo = async () => {
    try {
      setLoading(true);
      const response: any = await dispatch(makePhishingNo());
      if (!response?.data?.error) {
        setPhishingNo(response.data.list[0].phishingNo);
        setLoading(false);
      }
    } catch (error) {
      console.log('PhishingPageAddEdit deletePhishingNo', error);
    }
  };

  // 피싱 페이지 번호 삭제
  const onDeletePhishingNo = async () => {
    try {
      const params = {
        phishingNo: phishingNo,
      };
      await dispatch(deletePhishingNo(params));
    } catch (error) {
      console.log('PhishingPageAddEdit deletePhishingNo', error);
    }
  };

  // 피싱 페이지 저장
  const onSavePhishingPage = async (form: any) => {
    try {
      setLoading(true);
      const origin = isAddMode ? uploadedList : phishingInfo.imageList;
      onDeleteImages(origin, images, images, 1);

      const params: any = {
        phishingNo: phishingNo,
        phishingLevel: phishingLevel + 1,
        phishingName: form.phishingName,
        phishingScreenEnable: form.phishingScreenEnable,
        ...phishingCode,
      };

      if (isAdmin) {
        params.isPublic = form.isPublic;
      }

      await dispatch(savePhishingPage(params));
      setLoading(false);
      toggleModal(false);
    } catch (error) {
      console.log('PhishingPageAddEdit onSavePhishingPage', error);
    }
  };

  // 모달 닫기
  const closeModal = () => {
    if (isAddMode) {
      onDeleteImages([], uploadedList, [], 2);
      onDeletePhishingNo();
    } else {
      onDeleteImages(phishingInfo.imageList, images, phishingInfo.imageList, 2);
    }
    toggleModal(false);
  };

  // editor 내용 입력 이벤트
  const onChangeEditor = (type: any, code: any) => {
    if (currentPage === 0) {
      if (code !== phishingCode.phishingMailbody) {
        setPhishingCode({
          ...phishingCode,
          phishingMailbody: code || '',
        });
      }
    } else if (code !== phishingCode[type]) {
      setPhishingCode({
        ...phishingCode,
        [type]: code,
      });
    }
  };

  // 입력값 변경 시
  const changeFormValue = (type: string, value: any) => {
    setFormValues({ ...formValues, [type]: value });

    if (type === 'phishingScreenEnable') {
      setCurrentPage(0);
    }
  };

  // 피싱 단계 이벤트
  const changePhishingLevel = (type: string) => {
    if (type === 'increase' && phishingLevel < 2) {
      setPhishingLevel(phishingLevel + 1);
    } else if (type === 'decrease' && phishingLevel > 0) {
      setCurrentPage(phishingLevel - 1);
      setPhishingLevel(phishingLevel - 1);
    }
  };

  // 피싱 단계 목록 버튼 클릭 이벤트
  const changeCurrentPage = (page: number) => {
    setCurrentPage(page);
    setPreview(false);

    // 페이지 변경 시 실행결과 초기화
    const iframe: any = document.getElementById('phishing-iframe');
    iframe?.contentWindow.document.open();
    iframe?.contentWindow.document.write('');
    iframe?.contentWindow.document.close();
  };

  // 업로드한 이미지 리스트에 저장
  const setImageList = (type: string, value: any) => {
    if (type === 'upload') {
      setUploadedList(value);
      setImages(value);
    } else if (type === 'delete') {
      setImages(value);
    }
  };

  // 업로드된 이미지 삭제
  const onDeleteImages = async (
    origin: Array<any>, // 기존 이미지 리스트
    current: Array<any>, // 현재 이미지 리스트
    newImages: Array<any>, // 이미지 목록에 보여줄 리스트
    type: number, // 비교 기준 설정(1: origin, 2: current)
  ) => {
    try {
      setLoading(true);
      const originNoArray = origin?.length > 0 ? origin.map((item) => item.imageNo) : [];
      const currentNoArray = current?.length > 0 ? current.map((item) => item.imageNo) : [];
      const mainArray = type === 1 ? originNoArray : currentNoArray;
      const subArray = type === 1 ? currentNoArray : originNoArray;

      if (mainArray.length > 0) {
        const differentNoArray = mainArray.filter((element) => subArray.indexOf(element) < 0);
        if (differentNoArray.length > 0) {
          const params = {
            phishingNo: phishingNo,
            imageNoArray: JSON.stringify(differentNoArray),
          };

          await dispatch(phishingDeleteImage(params));
        }

        setLoading(false);
        // 이미지 리스트 업데이트
        setImages(newImages);
      }
    } catch (error) {
      console.log('PhishingPageAddEdit onDeleteImages', error);
    }
  };

  // 코드 실행
  const onRunCode = () => {
    setPreview(true);

    let source = '';
    const date = new Date();
    const month = date.getMonth();
    const doubleDigitMonth = month < 10 ? `0${month}` : month;

    let code: any = {};
    if (currentPage === 0) {
      code = { html: phishingCode.phishingMailbody };
    } else {
      code = {
        html: phishingCode[`phishingHtml${currentPage}`],
        js: phishingCode[`phishingJs${currentPage}`],
        css: phishingCode[`phishingCss${currentPage}`],
      };
    }

    source = `
      <html>
        <head>
          <style>${code.css}</style>
        </head>
        <body>
          ${code.html}
          <script src="${window.location.origin}/certification/jquery/jquery-1.10.2.min.js"></script>
          <script>${code.js}</script>
        </body>
      </html>
      `;

    const parsed = source
      .replace(/\$\{SERVER_ROOT\}\$/g, window.location.origin)
      .replace(/\$\{DATE_YM\}\$/g, `${date.getFullYear()}${doubleDigitMonth}`);

    const iframeElement: any = document.getElementById('phishing-iframe');
    iframeElement.contentWindow.document.open();
    iframeElement.contentWindow.document.write(parsed);
    iframeElement.contentWindow.document.close();
  };

  return (
    <div className="phishing-page-add-edit">
      <ModalTemplate
        className="phishing-page-add-edit-modal"
        visible={visible}
        title={
          <PhishingPageHeader
            handleSubmit={handleSubmit}
            onSavePhishingPage={onSavePhishingPage}
            onRunCode={onRunCode}
          />
        }
        onCancel={closeModal}
        cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
        loading={loading}
      >
        <Loading loading={loading} />
        <form onSubmit={(e: any) => e.preventDefault()} autoComplete="off">
          {/* sidebar */}
          <PhishingPageSidebar
            isAdmin={isAdmin}
            formValues={formValues}
            changeFormValue={changeFormValue}
            register={register}
            errors={errors}
            control={control}
            phishingLevel={phishingLevel}
            changePhishingLevel={changePhishingLevel}
            currentPage={currentPage}
            changeCurrentPage={changeCurrentPage}
            images={images}
            phishingNo={phishingNo}
            setImageList={setImageList}
          />
        </form>
        {/* editor */}
        <PhishingEditor
          curStep={currentPage}
          data={
            currentPage === 0
              ? { html: phishingCode.phishingMailbody }
              : {
                  html: `${phishingCode[`phishingHtml${currentPage}`] || ''}`,
                  js: `${phishingCode[`phishingJs${currentPage}`] || ''}`,
                  css: `${phishingCode[`phishingCss${currentPage}`] || ''}`,
                }
          }
          isAdmin={isAdmin}
          preview={preview}
          onChangeEditor={onChangeEditor}
        />
      </ModalTemplate>
    </div>
  );
}

export default PhishingPageAddEdit;
