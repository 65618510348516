import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Checkbox, Popover, Tooltip } from 'antd';
import ListStep from 'components/common/ListStep';
import { nowEpoch, timeFormatFromUTCEpoch } from 'utils/commonFunctions';

function EduItem({
  data,
  selectedEduNo,
  eduFileInfo,
  quizInfo,
  onSelectItem,
  isChecked,
  selectedType,
}: any) {
  const { formatMessage } = useIntl();
  const history = useHistory();

  // 전체 교육 상태 표시 데이터
  const stepDataAll = [
    {
      view: true,
      icon: {
        stepIcon: '/img/exam/list_target.png',
      },
      tooltipTitle: `${formatMessage({ id: 'Edu_110', defaultMessage: '교육 대상' })}: ${
        data.eduCount
      }`,
      count: data.eduCount,
    },
    {
      view: true,
      icon: {
        stepIcon: '/img/edu/list_send.png',
      },
      tooltipTitle: `${formatMessage({ id: 'Send_1', defaultMessage: '발송' })}: ${data.send}`,
      count: data.send,
    },
    {
      view: true,
      icon: {
        stepIcon: '/img/edu/list_read.png',
      },
      tooltipTitle: `${formatMessage({ id: 'Read_1', defaultMessage: '열람' })}: ${data.read}`,
      count: data.read,
    },
    // {
    //   view: true,
    //   icon: {
    //     stepIcon: '/img/edu/list_notyet.png',
    //   },
    //   tooltipTitle: `${formatMessage({ id: 'Status_15', defaultMessage: '진행전' })}: ${
    //     data.beforeCount
    //   }`,
    //   count: data.beforeCount,
    // },
    {
      view: true,
      icon: {
        stepIcon: '/img/edu/list_proceed.png',
      },
      tooltipTitle: `${formatMessage({ id: 'Status_8', defaultMessage: '진행중' })}: ${
        data.playCount
      }`,
      count: data.playCount,
    },
    {
      view: true,
      icon: {
        stepIcon: '/img/edu/list_end.png',
      },
      tooltipTitle: `${formatMessage({ id: 'Status_16', defaultMessage: '완료' })}: ${
        data.afterCount
      }`,
      count: data.afterCount,
    },
  ];

  let sendTooltip = formatMessage({ id: 'Send_1', defaultMessage: '발송' });
  if (data.send) {
    sendTooltip += `: ${timeFormatFromUTCEpoch(data.send)}`;
  } else if (data.eduStatus !== 1 && data.eduStatus !== 2) {
    sendTooltip = formatMessage({ id: 'Exam_62', defaultMessage: '준비' });
  }

  // 진행률
  // let stepPlayTime = 0;
  // let runningTime = 0;
  let runningFileName = '';
  if (eduFileInfo) {
    eduFileInfo.forEach((file: any, index: number) => {
      // 컨텐츠 총 시간
      // runningTime += file.runningTime;
      if (data.playStep) {
        if (data.playStep - 1 > index && data.eduStatus === 1) {
          // stepPlayTime += file.runningTime;
        } else if (data.playStep - 1 === index) {
          runningFileName = file.fileName;
        }
      }
    });

    //   if (data.playStep) {
    //     // 진행중일 때
    //     stepPlayTime += data.playTime;
    //   } else if (data.eduStatus === 2) {
    //     // 완료일 때
    //     stepPlayTime = runningTime;
    //   }
  }

  // 교육 상태 메시지
  let statusText = formatMessage({ id: 'Exam_62', defaultMessage: '준비' });
  let statusColor = '';
  if (data.eduStatus === 1) {
    statusText = formatMessage({ id: 'Status_8', defaultMessage: '진행중' });
    statusColor = 'color-green';
  } else if (data.eduStatus === 2) {
    statusText = formatMessage({ id: 'Status_16', defaultMessage: '완료' });
    statusColor = 'color-red';
  } else if (data.read) {
    statusText = formatMessage({ id: 'Read_1', defaultMessage: '열람' });
    statusColor = 'color-orange';
  } else if (data.send) {
    statusText = formatMessage({ id: 'Send_1', defaultMessage: '발송' });
  }

  // 개별 교육 상태 표시 데이터
  const stepDataEach = [
    {
      view: true,
      icon: {
        stepIcon: '/img/exam/step/send.png', // sendImage,
        active: data.send || data.eduStatus === 1 || data.eduStatus === 2,
        activeIcon: '/img/exam/step/send_a.png', // sendActiveImage,
        circleIcon: '/img/exam/step/circle_send.png', // circleSendImage,
      },
      tooltipTitle: sendTooltip,
      count: data.send,
    },
    {
      view: true,
      icon: {
        stepIcon: '/img/exam/step/read.png', // readImage,
        active: data.read,
        activeIcon: '/img/exam/step/read_a.png', // readActiveImage,
        circleIcon: '/img/exam/step/circle_read.png', // circleReadImage,
        lineIcon: '/img/exam/step/line_read.png', // lineReadImage,
      },
      tooltipTitle: `${formatMessage({
        id: 'Read_1',
        defaultMessage: '열람',
      })}: ${timeFormatFromUTCEpoch(data.read)}`,
      count: data.read,
    },
    // {
    //   view: true,
    //   icon: {
    //     stepIcon: '/img/edu/step/edu_notyet.png',
    //     active: data.read && data.eduStatus >= 0,
    //     activeIcon: '/img/edu/step/edu_notyet_a.png',
    //     circleIcon: '/img/edu/step/circle_edu_notyet.png',
    //     lineIcon: '/img/edu/step/line_edu_notyet.png',
    //   },
    //   tooltipTitle: formatMessage({ id: 'Status_15', defaultMessage: '진행전' }),
    // },
    {
      view: true,
      icon: {
        stepIcon: '/img/edu/step/edu_progress.png', // progressImage,
        active: data.eduStatus && (data.eduStatus === 1 || data.eduStatus === 2),
        activeIcon: '/img/edu/step/edu_progress_a.png', // progressActiveImage,
        circleIcon: '/img/edu/step/circle_edu_progress.png', // circleProgressImage,
        lineIcon: '/img/edu/step/line_edu_progress.png', // lineProgressImage,
      },
      tooltipTitle: data.playStep
        ? `${formatMessage({ id: 'Status_8', defaultMessage: '진행중' })} (Step ${
            data.playStep
          }. ${runningFileName})`
        : formatMessage({ id: 'Status_8', defaultMessage: '진행중' }),
      count: data.playStep,
      badgeText: data.eduStatus === 1 ? data.playStep : '',
    },
    {
      view: true,
      icon: {
        stepIcon: '/img/edu/step/edu_end.png', // endImage,
        active: data.eduStatus && data.eduStatus === 2,
        activeIcon: '/img/edu/step/edu_end_a.png', // endActiveImage,
        circleIcon: '/img/edu/step/circle_edu_end.png', // circleEndImage,
        lineIcon: '/img/edu/step/line_edu_end.png', // lineEndImage,
      },
      tooltipTitle: data.finish
        ? `${formatMessage({ id: 'Status_16', defaultMessage: '완료' })}: ${timeFormatFromUTCEpoch(
            data.finish,
          )}`
        : formatMessage({ id: 'Status_16', defaultMessage: '완료' }),
      count: data.finish,
    },
  ];

  // 개별 훈련 퀴즈 점수
  // let eduScore = 0;
  // if (selectedEduNo !== 'all') {
  //   let passCount = 0;
  //   if (data.quizSolutionData && quizInfo?.length > 0) {
  //     quizInfo.forEach((quiz: any) => {
  //       if (data.quizSolutionData[quiz.quizNo]?.result === 'pass') {
  //         passCount += 1;
  //       }
  //     });
  //   }
  //   eduScore = passCount && quizInfo?.length > 0 ? (passCount / quizInfo?.length) * 100 : 0;
  // }

  const getEduStatus = () => {
    const isNotExistTarget = data.eduFix !== 1;

    if (data.startEpoch > nowEpoch()) {
      return isNotExistTarget
        ? {
            text: formatMessage({ id: 'Status_21', defaultMessage: '예약(훈련연결)' }),
            icon: '/img/exam/startExam/btn_qmark.png',
            explain: {
              result: 'ing',
              title: formatMessage({ id: 'Edu_148', defaultMessage: '교육 대상자 집계 중' }),
              text: [
                formatMessage({ id: 'Edu_149', defaultMessage: '미종료훈련을 포함하는 경우,' }),
                formatMessage({
                  id: 'Edu_150',
                  defaultMessage: '훈련 종료 시에 교육 대상자가 확정됩니다.',
                }),
              ],
            },
          }
        : { text: formatMessage({ id: 'Dashboard_19', defaultMessage: '예약' }) };
    }

    if (data.startEpoch <= nowEpoch() && isNotExistTarget) {
      return {
        text: formatMessage({ id: 'Edu_152', defaultMessage: '교육실시 실패' }),
        icon: '/img/common/btn_warning.png',
        explain: {
          result: 'fail',
          title: formatMessage({ id: 'Edu_152', defaultMessage: '교육실시 실패' }),
          text: [
            formatMessage({ id: 'Edu_153', defaultMessage: '알 수 없는 이유로,' }),
            formatMessage({
              id: 'Edu_154',
              defaultMessage: '연결 훈련에 의한 교육실시에 실패하였습니다.',
            }),
          ],
        },
      };
    }

    if (data.endEpoch < nowEpoch()) {
      return {
        text: formatMessage({ id: 'Status_22', defaultMessage: '종료' }),
      };
    }

    return {
      text: formatMessage({ id: 'Status_8', defaultMessage: '진행중' }),
    };
  };

  const { text: eduStatusText, icon: eduStatusIcon, explain } = getEduStatus();

  return (
    <div className={`list-item ${selectedEduNo !== 'all' && data.isEnable !== 1 ? 'deleted' : ''}`}>
      <div
        className="check-div"
        onClick={(e) => {
          e.stopPropagation();
          onSelectItem(selectedEduNo === 'all' ? data.eduNo : data.targetNo);
        }}
        aria-hidden="true"
      >
        {isChecked && selectedType === 'check' ? (
          <img src="/img/exam/list_check_on.png" alt="check" className="list-check image" />
        ) : (
          <Checkbox className="list-check" checked={false} />
        )}
      </div>

      {selectedEduNo === 'all' ? (
        // 전체 교육
        <>
          <div className="email-column">
            <Tooltip
              overlayClassName="black-tooltip"
              placement="bottomLeft"
              title={`${formatMessage({ id: 'Edu_28', defaultMessage: '교육명' })}: ${
                data.eduName
              }`}
            >
              <div className="ellipsis">{data.eduName}</div>
            </Tooltip>
          </div>

          <div className="edu-text-column flex">
            <Tooltip
              overlayClassName="black-tooltip"
              placement="bottomLeft"
              title={`${formatMessage({
                id: 'Edu_29',
                defaultMessage: '교육 현황',
              })}: ${eduStatusText}`}
            >
              <span className="ellipsis">{eduStatusText}</span>
            </Tooltip>
            {explain && (
              <Popover
                overlayClassName="edu-sidebar-popover full"
                placement="right"
                content={
                  <div>
                    <b>{explain.title}</b>
                    <div>{explain.text[0]}</div>
                    <div className="flex">
                      {explain.text[1]}
                      {explain.result === 'ing' && (
                        <>
                          <span className="ml-5">
                            {formatMessage({ id: 'Log_10', defaultMessage: '상세' })}
                          </span>
                          <Tooltip
                            overlayClassName="black-tooltip"
                            placement="bottom"
                            title={formatMessage({
                              id: 'Edu_151',
                              defaultMessage: '훈련 결과로 이동',
                            })}
                          >
                            <img
                              src="/img/exam/startExam/btn_arrow.png"
                              alt="move"
                              onClick={(e: any) => {
                                e.stopPropagation();
                                history.push('/exam/pi');
                              }}
                              aria-hidden="true"
                            />
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </div>
                }
              >
                <img src={eduStatusIcon} alt="icon" className="status-icon" />
              </Popover>
            )}
          </div>

          <div className="period-column">
            <Tooltip
              overlayClassName="black-tooltip"
              placement="bottomLeft"
              title={`${formatMessage({
                id: 'Period_4',
                defaultMessage: '교육 기간',
              })}: ${timeFormatFromUTCEpoch(data.startEpoch, 3)} ~ ${timeFormatFromUTCEpoch(
                data.endEpoch,
                3,
              )}`}
            >
              <div className="ellipsis">{`${timeFormatFromUTCEpoch(
                data.startEpoch,
                3,
              )} ~ ${timeFormatFromUTCEpoch(data.endEpoch, 3)}`}</div>
            </Tooltip>
          </div>
        </>
      ) : (
        // 개별 교육 - 대상자
        <>
          <div className="email-column">
            <Popover
              overlayClassName="between-popover full"
              placement="topLeft"
              content={
                <>
                  <div className="popover-row">
                    <div className="text">
                      {formatMessage({ id: 'Target_10', defaultMessage: '대상자 번호' })}
                    </div>
                    <div className="value">{data.targetNo}</div>
                  </div>
                  <div className="popover-row">
                    <div className="text">
                      {formatMessage({ id: 'Name_1', defaultMessage: '이름' })}
                    </div>
                    <div className="value">{data.targetName}</div>
                  </div>
                  <div className="popover-row">
                    <div className="text">
                      {formatMessage({ id: 'Email_1', defaultMessage: '이메일' })}
                    </div>
                    <div className="value">{data.targetEmail}</div>
                  </div>
                  {!!data.targetPhone && (
                    <div className="popover-row">
                      <div className="text">
                        {formatMessage({ id: 'Contact_1', defaultMessage: '연락처' })}
                      </div>
                      <div className="value">{data.targetPhone}</div>
                    </div>
                  )}
                </>
              }
            >
              <div className="ellipsis">{`${data.targetName} (${data.targetEmail})`}</div>
            </Popover>
          </div>
          {/* 소속/직급 */}
          <div className="list-column">
            <Tooltip
              overlayClassName="black-tooltip"
              placement="bottom"
              title={`${formatMessage({ id: 'Division_1', defaultMessage: '소속' })}: ${
                data.targetDivision || '-'
              } / ${formatMessage({
                id: 'Position_1',
                defaultMessage: '직급',
              })}: ${data.targetPosition || '-'} `}
            >
              <div className="target-company">
                <div className="division">{data.targetDivision || '-'}</div>
                <div className="divide">/</div>
                <div className="position">{data.targetPosition || '-'}</div>
              </div>
            </Tooltip>
          </div>
        </>
      )}
      {/* 교육 상태 */}
      <div className="status-column">
        {selectedEduNo === 'all' ? (
          stepDataAll.map(
            (item: any) =>
              item.view && (
                <ListStep
                  tooltipTitle={item.tooltipTitle}
                  icon={item.icon}
                  count={item.count}
                  countColor={item.countColor}
                  key={item.tooltipTitle}
                />
              ),
          )
        ) : (
          <>
            <div className={`status-text ${statusColor}`}>{statusText}</div>
            {stepDataEach.map(
              (item: any) =>
                item.view && (
                  <ListStep
                    tooltipTitle={item.tooltipTitle}
                    icon={item.icon}
                    count={item.count}
                    countColor={item.countColor}
                    badgeText={item.badgeText}
                    key={item.tooltipTitle}
                  />
                ),
            )}
          </>
        )}
      </div>

      <div className="edu-percent-box">
        <div>
          {selectedEduNo === 'all'
            ? formatMessage({ id: 'Edu_25', defaultMessage: '진행률' })
            : formatMessage({ id: 'Template_28', defaultMessage: '진행률' })}
        </div>
        <div className="edu-percent">{`${data.progress}%`}</div>
        {selectedEduNo !== 'all' && (
          <div className="quiz-score">
            <span className="bold">{quizInfo?.length > 0 ? data.grade : '-'}</span>
            <span>{formatMessage({ id: 'Edu_26', defaultMessage: '점' })}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default EduItem;
