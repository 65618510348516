import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { copyPhishingPage } from 'store/phishing';
import { timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import * as valid from 'utils/validation';
import FormTextField from 'components/common/FormTextField';
import Loading from 'components/common/Loading';
import ModalTemplate from 'components/common/ModalTemplate';

function PhishingPageCopy({ visible, isAdmin, phishingInfo, toggleModal }: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, setError, errors } = useForm({
    mode: 'onChange',
  });

  const { phishingNo, phishingName, isBasic, regEpoch } = phishingInfo;

  // 템플릿 복제
  const onCopyTemplate = async (formValues: any) => {
    setLoading(true);
    try {
      const params: any = {};
      if (phishingNo) {
        params.phishingNo = phishingNo;
      }

      if (isAdmin && formValues.serviceNo) {
        params.serviceNo = parseInt(formValues.serviceNo, 10);
      }

      const response: any = await dispatch(copyPhishingPage(params));
      if (response?.data?.error) {
        if (response?.data?.error && response.data?.error[0].errInfo) {
          setError('serviceNo', {
            type: 'wrong',
            message: formatMessage({
              id: 'Template_75',
              defaultMessage: '잘못된 서비스 번호입니다.',
            }),
          });
        }
        setLoading(false);
      } else {
        setLoading(false);
        toggleModal(false);
      }
    } catch (error) {
      console.log('PhishingPageCopy onCopyTemplate', error);
    }
  };

  return (
    <div>
      <ModalTemplate
        className="modal-464"
        visible={visible}
        title={
          <div className="modal-template-header">
            <div className="title">
              {formatMessage({ id: 'Phishing_24', defaultMessage: '피싱 페이지 복제' })}
            </div>
          </div>
        }
        onOk={handleSubmit(onCopyTemplate)}
        onCancel={() => toggleModal(false)}
        okText={formatMessage({ id: 'Button_23', defaultMessage: '복 제' })}
        cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
        loading={loading}
        greyButton
      >
        <Loading loading={loading} />

        <div className="modal-explain-text">
          <div>
            {formatMessage({
              id: 'Phishing_30',
              defaultMessage: '피싱 페이지를 복제하여 새로 생성합니다.',
            })}
          </div>
          <div>
            {formatMessage({
              id: 'Phishing_31',
              defaultMessage: '다음 피싱 페이지를 복제합니까?',
            })}
          </div>
        </div>
        <div className="modal-border-box">
          <ul className="modal-item-list">
            <li>
              <div className="item-title">No</div>
            </li>
            <div>
              {isBasic
                ? `${phishingNo} (${formatMessage({
                    id: 'Template_71',
                    defaultMessage: '기본 제공',
                  })})`
                : phishingNo}
            </div>
          </ul>
          <ul className="modal-item-list">
            <li>
              <div className="item-title">
                {formatMessage({ id: 'Phishing_29', defaultMessage: '피싱 페이지명' })}
              </div>
            </li>
            <div>{phishingName}</div>
          </ul>
          <ul className="modal-item-list">
            <li>
              <div className="item-title">
                {formatMessage({ id: 'Date_3', defaultMessage: '등록일시' })}
              </div>
            </li>
            <div>{timeFormatFromUTCEpoch(regEpoch)}</div>
          </ul>

          {/* 대상 서비스 번호 */}
          {!!isAdmin && (
            <div className="service-no-area">
              <form autoComplete="off" onSubmit={(e: any) => e.preventDefault()}>
                <div className="text-field-title">
                  {formatMessage({
                    id: 'Phishing_32',
                    defaultMessage: '대상 서비스 번호 (미입력 시 비공개 기본 피싱 페이지로 복제)',
                  })}
                </div>
                <FormTextField
                  name="serviceNo"
                  error={errors.serviceNo}
                  register={register}
                  arrowPosition="top"
                  validation={{
                    validate: {
                      blank: (value: any) => valid.blank(value),
                      number: (value: any) => valid.number(value),
                    },
                  }}
                />
              </form>
            </div>
          )}
        </div>
      </ModalTemplate>
    </div>
  );
}

export default PhishingPageCopy;
