import './Dimmer.scss';

// import checkImage from 'img/exam/info_select.png';

function Dimmer({ dimmerText, dimmerIcon }: any) {
  return (
    <div className="common-dimmer">
      <div className="flex">
        <div className="dimmer-icon">
          <img src={dimmerIcon || '/img/exam/info_select.png'} alt="img" />
        </div>
        <div className="dimmer-text">{dimmerText}</div>
      </div>
    </div>
  );
}

export default Dimmer;
