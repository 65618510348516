import moment from 'moment';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Line,
  LineChart,
  Pie,
  PieChart,
  Sector,
  Tooltip as ChartTooltip,
  XAxis,
  YAxis,
} from 'recharts';
import './DashboardChart.scss';

interface chartProps {
  type: string;
  data: any;
  target: string;
  message: any;
  color: any;
  icon?: any;
}

DashboardChart.defaultProps = {
  icon: null,
};

function DashboardChart({ type, data, target, message, color, icon }: chartProps): any {
  // 원 차트 데이터
  let circleChartArray: any = [];
  // 막대 차트 데이터
  const barChartArray: any = [];
  // 꺾은선 차트 데이터
  const lineChartArray: any = [];

  if (type === 'circle') {
    // 데이터의 값을 퍼센트로 변환
    const percent = data.target ? parseInt(((data[target] / data.target) * 100).toFixed(0), 10) : 0;

    circleChartArray = [
      {
        name: '',
        color: '',
        value: data[target] > 0 ? 100 - percent : 100,
        percent: data[target] > 0 ? 100 - percent : 100,
      },
      {
        name: target,
        color: color.start,
        value: percent,
        label: message,
        percent: percent,
      },
    ];
  } else if (type === 'bar') {
    for (let i = 12; i >= 0; i -= 1) {
      const graphMonth = moment().subtract(i, 'months').format('YYYY-MM');
      let existExam = false;
      data.forEach((barData: any) => {
        if (barData.month === graphMonth) {
          barChartArray.push({ count: barData[target], month: barData.month.replace('-', '.') });
          existExam = true;
        }
      });

      if (!existExam) {
        barChartArray.push({ count: 0, month: graphMonth.replace('-', '.') });
      }
    }
  } else if (type === 'line') {
    for (let i = 12; i >= 0; i -= 1) {
      const graphMonth = moment().subtract(i, 'months').format('YYYY-MM');
      let existExam = false;
      data.forEach((lineData: any) => {
        if (lineData.month === graphMonth) {
          lineChartArray.push({
            send: lineData.send,
            read: lineData.read,
            download: lineData.download,
            infection: lineData.infection,
            month: lineData.month.replace('-', '.'),
          });
          existExam = true;
        }
      });

      if (!existExam) {
        lineChartArray.push({
          send: 0,
          read: 0,
          download: 0,
          infection: 0,
          month: graphMonth.replace('-', '.'),
        });
      }
    }
  }

  return (
    <div className="dashboard-chart">
      {/* 원 차트 */}
      {type === 'circle' && (
        <div className="pie-chart">
          <div className="left">
            <div className="title">{message}</div>
            <div className="percent" style={{ color: color.start }}>
              {circleChartArray[1]?.percent}
              <em>%</em>
            </div>
          </div>
          <div className="right">
            <div className="pie-chart-item">
              <img src={icon} alt="icon" />
              <PieChart width={120} height={140}>
                <Pie
                  dataKey="value"
                  data={[
                    {
                      name: '',
                      value: 100,
                    },
                  ]}
                  cx={55}
                  cy={70}
                  paddingAngle={0}
                  innerRadius={31}
                  outerRadius={60}
                  fill="#f7f7f7"
                />
                <Pie
                  dataKey="value"
                  data={circleChartArray}
                  cx={55}
                  cy={70}
                  innerRadius={37}
                  outerRadius={55}
                  startAngle={90}
                  endAngle={450}
                  paddingAngle={-1}
                  activeIndex={0}
                  activeShape={(e: any) => {
                    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle } = e;
                    return (
                      <g>
                        <defs>
                          <linearGradient
                            id={color.start.replace('#', '')}
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop offset="0%" stopColor={color.start} />
                            <stop offset="100%" stopColor={color.end} />
                          </linearGradient>
                        </defs>
                        <Sector
                          cx={cx}
                          cy={cy}
                          innerRadius={innerRadius}
                          outerRadius={outerRadius}
                          startAngle={startAngle}
                          endAngle={endAngle}
                          fill="#f7f7f7"
                        />
                        <Sector
                          cx={cx}
                          cy={cy}
                          innerRadius={outerRadius - 1}
                          outerRadius={outerRadius + 5}
                          startAngle={startAngle}
                          endAngle={endAngle}
                          fill="#f7f7f7"
                        />
                      </g>
                    );
                  }}
                >
                  {/* 퍼센트 표시 */}
                  {circleChartArray.map((entry: any) => (
                    <Cell key={entry.name} fill={`url(${entry.color})`} />
                  ))}
                </Pie>
                <ChartTooltip
                  content={({ payload }: any) => {
                    return (
                      <div>
                        {payload.length > 0 &&
                          payload[0]?.name &&
                          payload.map((data: any) => {
                            return (
                              <div className="rechart-tooltip" key={data.name}>
                                <div className="title">{data.payload.label}</div>
                                <div className="value">{data.payload.percent}%</div>
                              </div>
                            );
                          })}
                      </div>
                    );
                  }}
                />
              </PieChart>
            </div>
          </div>
        </div>
      )}

      {/* 막대 차트 */}
      {type === 'bar' && (
        <div className="bar-chart">
          <BarChart
            width={300}
            height={240}
            data={barChartArray}
            margin={{ top: 50, right: 25, bottom: 0, left: -25 }}
          >
            <CartesianGrid vertical={false} />
            <XAxis dataKey="month" />
            <YAxis />
            <ChartTooltip
              cursor={false}
              content={({ payload }: any) => {
                return (
                  <div>
                    {payload &&
                      payload.map((entry: any, index: any) => (
                        <div className="rechart-tooltip" key={entry.payload.month}>
                          <div className="title">{entry.payload.month}</div>
                          <div className="value">{`${entry.payload.count}${message}`}</div>
                          {/* <div className={'value'} style={{ color: graphColor }}> */}
                        </div>
                      ))}
                  </div>
                );
              }}
            />
            {/* <Legend /> */}
            <Bar dataKey="count" fill={color} barSize={15} />
          </BarChart>
        </div>
      )}

      {/* 꺾은선 차트 */}
      {type === 'line' && (
        <LineChart
          width={480}
          height={270}
          data={lineChartArray}
          margin={{ top: 50, right: 25, bottom: 0, left: -25 }}
        >
          <CartesianGrid vertical={false} />
          <XAxis dataKey="month" />
          <YAxis />
          <ChartTooltip
            cursor={false}
            content={({ active, payload }: any) => {
              if (active && payload && payload.length) {
                return (
                  <div>
                    <div className="rechart-tooltip">
                      <div className="title" style={{ fontSize: 14, marginBottom: '5px' }}>
                        {payload[0].payload.month}
                      </div>
                      {/* PC감염/정보유출  */}
                      <div className="value" style={{ color: payload[0].color, fontSize: 12 }}>{`${
                        message[payload[0].dataKey]
                      }: ${payload[0].payload[payload[0].dataKey]}`}</div>
                      {/* 파일다운/피싱접속  */}
                      <div className="value" style={{ color: payload[1].color, fontSize: 12 }}>{`${
                        message[payload[1].dataKey]
                      }: ${payload[1].payload[payload[1].dataKey]}`}</div>
                      {/* 열람 */}
                      <div className="value" style={{ color: payload[2].color, fontSize: 12 }}>{`${
                        message[payload[2].dataKey]
                      }: ${payload[2].payload[payload[2].dataKey]}`}</div>
                      {/* 발송 */}
                      <div className="value" style={{ color: payload[3].color, fontSize: 12 }}>
                        {`${message[payload[3].dataKey]}: ${
                          payload[3].payload[payload[3].dataKey]
                        }`}
                      </div>
                    </div>
                  </div>
                );
              }
              return null;
            }}
          />
          <Line
            type="linear"
            strokeWidth={2}
            dataKey="infection"
            stroke={color.infection}
            dot={false}
          />
          <Line
            type="linear"
            strokeWidth={2}
            dataKey="download"
            stroke={color.download}
            dot={false}
          />
          <Line type="linear" strokeWidth={2} dataKey="read" stroke={color.read} dot={false} />
          <Line type="linear" strokeWidth={2} dataKey="send" stroke={color.send} dot={false} />
        </LineChart>
      )}
    </div>
  );
}

export default DashboardChart;
