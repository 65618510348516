import React, { useEffect } from 'react';
import {
  Cell,
  Pie,
  PieChart,
  Sector,
  Tooltip as ChartTooltip,
} from 'recharts';
import './PieChartBase.scss';


interface pieChartProps {
  // type: string;
  width: number;
  height: number;
  keyName: string;
  data: Array<any>;
  // color: Array<{ start: string, end: string; }>;
  pieClassName: string;
  // message: any;
  chartOptions?: any;
  centerValue?: any;
  animationDuration?: any;
}

PieChartBase.defaultProps = {
  centerValue: null,
  chartOptions: {},
  animationDuration: 3
};


function PieChartBase({ width = 70, height = 70, keyName, data = [], pieClassName, centerValue, chartOptions, animationDuration }: pieChartProps) {
  const { part, whole } = data[0];
  const percent = whole ? parseInt(((part / whole) * 100).toFixed(0), 10) : 0;
  const circleChartArray = [
    {
      name: '',
      color: '',
      value: part > 0 ? 100 - percent : 100,
      percent: part > 0 ? 100 - percent : 100,
      label: ''
    }
  ];

  data.forEach((item: any) => {
    const { part, whole } = item;
    const percent = whole ? parseInt(((part / whole) * 100).toFixed(0), 10) : 0;

    circleChartArray.push({
      name: keyName,
      color: item.color.start,
      value: percent,
      percent: percent,
      label: item.label,
    });
  });

  const initOptions = {
    cx: 40,
    cy: 40,
    innerRadius: 22,
    outerRadius: 33,
    startAngle: 90,
    endAngle: 450,
    ...chartOptions
  };

  return (
    <div className={`pie-chart-base ${pieClassName}`}>
      <div className='center-value'>
        {centerValue ||
          <div>
            <span className='value-text'>{percent}</span>
            <span className="value-percent">%</span>
          </div>
        }
      </div>
      <PieChart width={width} height={height}>
        <Pie
          dataKey="value"
          data={[
            {
              name: '',
              value: 100,
            },
          ]}
          cx={initOptions.cx}
          cy={initOptions.cy}
          innerRadius={initOptions.innerRadius - 2}
          outerRadius={initOptions.outerRadius + 2}
          paddingAngle={-1}
          // fill="#f7f7f7"
          fill="#fff"
        />
        <Pie
          dataKey="value"
          data={circleChartArray}
          cx={initOptions.cx}
          cy={initOptions.cy}
          innerRadius={initOptions.innerRadius}
          outerRadius={initOptions.outerRadius}
          startAngle={initOptions.startAngle}
          endAngle={initOptions.endAngle}
          paddingAngle={-1}
          activeIndex={0}
          animationDuration={animationDuration} // 애니메이션 속도
          activeShape={(e: any) => {
            const { cx, cy, innerRadius, outerRadius, startAngle, endAngle } = e;
            return (
              <g>
                <defs>
                  {data.map((entry: any) => (
                    <linearGradient
                      id={`myGradient${keyName}`}
                      key={`myGradient${keyName}`}
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="0%" stopColor={entry.color.start} />
                      <stop offset="100%" stopColor={entry.color.end} />
                    </linearGradient>
                  ))}
                </defs>
                <Sector
                  cx={cx}
                  cy={cy}
                  innerRadius={innerRadius}
                  outerRadius={outerRadius}
                  startAngle={startAngle}
                  endAngle={endAngle}
                  fill="#f7f7f7"
                // fill="#fff"
                />
                <Sector
                  cx={cx}
                  cy={cy}
                  innerRadius={outerRadius}
                  outerRadius={outerRadius}
                  startAngle={startAngle}
                  endAngle={endAngle}
                  fill="#f7f7f7"
                // fill="#fff"
                />
              </g>
            );
          }}
        >
          {/* 퍼센트 표시 */}
          {circleChartArray.map((entry: any) => {
            return (
              <Cell key={entry.name} fill={`url(#myGradient${keyName})`} />
            );
          })}
        </Pie>

        <ChartTooltip
          content={({ payload }: any) => {
            return (
              <div>
                {payload.length > 0 &&
                  payload[0]?.name &&
                  payload.map((item: any) => {
                    return (
                      <div className="rechart-tooltip" key={item.name}>
                        <div className="title">{item.payload.label}</div>
                        <div className="value">{percent}%</div>
                        {/* data.payload.percent */}
                      </div>
                    );
                  })}
              </div>
            );
          }}
        />
      </PieChart>
    </div>
  );
}

export default PieChartBase;