import { combineReducers } from "redux";

interface modulesType {
  [key: string]: any;
}
// imports all file except index.js
const req = require.context(".", true, /^(?!.\/index).*.ts$/);
const modules:modulesType = {};

req.keys().forEach((key) => {
  const regex = /.\/(.*?).ts$/;
  const moduleName = regex.test(key) && key.match(regex);
  if (moduleName && moduleName[1]) {
    modules[moduleName[1]] = req(key).default;
  }
});

export default combineReducers(modules);
