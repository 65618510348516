import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { targetSync } from 'store/admin';
import { List } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ModalTemplate from '../ModalTemplate';
import './SyncTarget.scss';

export default function SyncTarget(props: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const targetSyncResult = useSelector((state: any) => state.admin.syncResult);

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const syncTarget = async () => {
    setLoading(true);

    try {
      await dispatch(targetSync());
      setLoading(false);
      setShowModal(true);
    } catch (e) {
      console.log(e);
    }
  };

  const { success, fail } = targetSyncResult;
  let syncResult = null;
  let syncSuccess = null;

  if (fail) {
    syncResult = fail;
  } else if (success?.noService) {
    syncResult = formatMessage({
      id: 'ConfigLDAP_12',
      defaultMessage: '존재하지 않는 ServiceNo 입니다.',
    });
  } else if (success?.noConnect || success?.noModule) {
    syncResult = formatMessage({
      id: 'ConfigSync_3',
      defaultMessage: '입력하신 연동 정보가 올바르지 않습니다.',
    });
  } else {
    syncResult = formatMessage({ id: 'ConfigSync_4', defaultMessage: '대상자 수동 연동 결과' });

    syncSuccess = (
      <div className="template-detail-box">
        <div className="template-detail">
          <List size="small">
            <List.Item className="template-detail-item">
              <div className="template-detail-item-title">서비스No</div>
              {success.serviceNo}
            </List.Item>
            {!!success.alreadyExist && (
              <List.Item className="template-detail-item">
                <div className="template-detail-item-title">유지 대상자</div>
                {success.alreadyExist}명
              </List.Item>
            )}
            {!!success.addCount && (
              <List.Item className="template-detail-item">
                <div className="template-detail-item-title">추가 대상자</div>
                {success.addCount}명
              </List.Item>
            )}
            {!!success.editCount && (
              <List.Item className="template-detail-item">
                <div className="template-detail-item-title">변경 대상자</div>
                {success.editCount}명
              </List.Item>
            )}
            {!!success.deleteCount && (
              <List.Item className="template-detail-item">
                <div className="template-detail-item-title">삭제 대상자</div>
                {success.deleteCount}명
              </List.Item>
            )}
            {!!success.errorCount && (
              <List.Item className="template-detail-item">
                <div className="template-detail-item-title">실패 대상자</div>
                {success.errorCount}명
              </List.Item>
            )}
          </List>
        </div>
      </div>
    );
  }

  return (
    <div className="test-box">
      <div className="bt-test-area">
        <button type="button" className="ok" disabled={loading} onClick={syncTarget}>
          {formatMessage({ id: 'ConfigSync_2', defaultMessage: '대상자 수동 연동' })}
          {loading && (
            <span className="button-loading">
              <LoadingOutlined />
            </span>
          )}
        </button>
      </div>
      {showModal && (
        <ModalTemplate
          visible
          className="modal-464"
          onCancel={() => setShowModal(false)}
          loading={loading}
          title={formatMessage({ id: 'ConfigSync_2', defaultMessage: '대상자 수동 연동' })}
          cancelText={formatMessage({ id: 'Button_4', defaultMessage: '확 인' })}
        >
          <div className="modal-text">
            <div className="modal-text-title">{syncResult}</div>
            {syncSuccess}
          </div>
        </ModalTemplate>
      )}
    </div>
  );
}
