import { apiUrl } from 'utils/commonValues';
import { actionFormat } from 'utils/commonFunctions';

// Action Types
const CHECK_PASSWORD = 'register/CHECK_PASSWORD';
const POST_REGISTER = 'register/POST_REGISTER';
const RESEND_EMAIL = 'register/RESEND_EMAIL';

// Actions
export const checkPassword = (params: any) =>
  actionFormat(
    CHECK_PASSWORD,
    params,
    'get',
    `${apiUrl}Register/CheckPassword`,
  );
export const postRegister = (params: any) =>
  actionFormat(POST_REGISTER, params, 'post', `${apiUrl}Register`);
export const resendAuthEmail = (params: any) =>
  actionFormat(
    RESEND_EMAIL,
    params,
    'post',
    `${apiUrl}Register/ReSendRegisterEmail`,
  );

// Initial state
const initialState = {
  userData: null,
};

// Reducers
export default function (state = initialState, action: any) {
  switch (action.type) {
    case CHECK_PASSWORD:
      return state;
    case POST_REGISTER:
      if (action.payload) {
        const { list } = action.payload.data;
        state = list;
      }
      return state;
    case RESEND_EMAIL:
      return state;
    default:
      return state;
  }
}
