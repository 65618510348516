import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Popover, Tag, Select, Switch } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import parse from 'html-react-parser';
import moment from 'moment';
import axios from 'axios';
import * as valid from 'utils/validation';
import FormSelectField from 'components/common/FormSelectField';
import FormDatePicker from 'components/common/FormDatePicker';
import { Option } from 'utils/commonValues';

// import qmarkImage from 'img/exam/startExam/btn_qmark.png';
// import warningImage from 'img/exam/startExam/ico_warning.png';
// import fileImage from 'img/exam/startExam/ico_file.png';
// import researchImage from 'img/exam/startExam/ico_research.png';
// import phishingImage from 'img/exam/startExam/ico_phishing.png';

function StartExamStep4(props: any) {
  const { errors, control, onSaveForm, errorMessage, setErrorMessage, loadData, examMode } = props;
  const { formatMessage } = useIntl();
  const formStoreData = useSelector((state: any) => state.form.formStore);
  const templateData = useSelector((state: any) => state.startExam.template);
  const attachData = useSelector((state: any) => state.startExam.attach);
  const systemUrlData = useSelector((state: any) => state.startExam.systemUrl);

  const [values, setValues] = useState<any>({
    examTemplate: formStoreData.examTemplate || [],
    useExamSystemUrl:
      formStoreData.useExamSystemUrl || (loadData && loadData.useExamSystemUrl) || '0',
    examSystemUrl: formStoreData.examSystemUrl || (loadData && loadData.examSystemUrl) || '',
    useAttachScreenLock: formStoreData.useAttachScreenLock || '0',
    attachScreenLockDate:
      formStoreData.attachScreenLockDate ||
      (formStoreData.sendReserveEnable === '1'
        ? moment((formStoreData.sendPeriod * 86400 + moment(formStoreData.startDate).unix()) * 1000)
        : moment((1 * 86400 + moment(formStoreData.startDate).unix()) * 1000)),
    attachScreenLockHour: formStoreData.attachScreenLockHour || 0,
    attachScreenLockMin: formStoreData.attachScreenLockMin || 0,
  });
  // 세이프 브라우징 체크 결과
  const [safeType, setSafeType] = useState(0);

  useEffect(() => {
    // 템플릿 기본값 설정
    if (loadData && !formStoreData.examTemplate) {
      if (examMode === 'expert') {
        // 전문가 모드
        setValues({
          ...values,
          examTemplate: loadData.examTemplate ? [loadData.examTemplate] : [],
        });
      } else {
        // 재훈련
        const examTemplateValue = loadData.templatePick
          ? `${loadData.templateNo}+${loadData.serviceTemplateNo}`
          : `${loadData.templateNo}+`;

        setValues({ ...values, examTemplate: [examTemplateValue] });
      }
    }
  }, [loadData]);

  useEffect(() => {
    // 값 변경 시 폼 스토어에 저장
    onSaveForm({ ...values });
  }, [values]);

  useEffect(() => {
    // google safe browsing 사이트 api 호출
    axios
      .get('https://transparencyreport.google.com/transparencyreport/api/v3/safebrowsing/status', {
        params: {
          site:
            formStoreData.useExamSystemUrl === '1' && formStoreData.examSystemUrl
              ? formStoreData.examSystemUrl
              : systemUrlData[1].url,
        },
      })
      .then((res: any) => {
        if (res.data) {
          const splitData = res.data.split(',');
          const result = splitData && parseInt(splitData[1], 10);
          setSafeType(result);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, [formStoreData?.useExamSystemUrl, formStoreData?.examSystemUrl, systemUrlData]);

  // 훈련 템플릿 선택 이벤트
  const handleSelectItem = (name: string, data: any) => {
    setValues({
      ...values,
      [name]: data,
    });

    // 에러 메시지 초기화
    setErrorMessage('');
  };

  // 선택한 훈련 템플릿 삭제(X 버튼 클릭) 이벤트
  const handleClearTag = (target: any) => {
    const selectedValue = values.examTemplate.filter(
      (templateValue: any) => templateValue !== target.toString(),
    );
    setValues({
      ...values,
      examTemplate: selectedValue,
    });
  };

  let disableLockSwitch = true;
  // 선택한 훈련 템플릿을 태그 형태로 출력
  const tagRender = (props: any) => {
    const currentTemplateData = templateData.filter(
      (template: any) =>
        `${template.templateNo}+${template.serviceTemplateNo || ''}` === props.value,
    )[0];

    let templateComponent = null;
    if (currentTemplateData) {
      const {
        templateName,
        templateNo,
        serviceTemplateNo,
        examType,
        attachInfoNo,
      } = currentTemplateData;

      // 템플릿 첨부파일 정보
      const templateAttachData = attachData.filter(
        (attach: any) => attach.attachInfoNo === attachInfoNo,
      );

      let examTypeColor = '';
      let examTypeString = '';
      if (templateAttachData) {
        if (examType === 1 && templateAttachData[0]?.attachExamType === 1) {
          examTypeColor = 'color-bg-warning';
          examTypeString = 'warning';
        } else if (examType === 1 && templateAttachData[0]?.attachExamType === 2) {
          examTypeColor = 'color-bg-file';
          examTypeString = 'file';
        } else if (examType === 1 && templateAttachData[0]?.attachExamType === 3) {
          examTypeColor = 'color-bg-research';
          examTypeString = 'research';
        } else if (examType === 4) {
          examTypeColor = 'color-bg-phishing';
          examTypeString = 'phishing';
        }
      }

      if (examTypeString === 'warning' || examTypeString === 'file') {
        disableLockSwitch = false;
      }

      templateComponent = (
        <Tag closable className={`color-tag ${examTypeColor}`}>
          {!!examTypeString && (
            <img src={`/img/exam/startExam/ico_${examTypeString}_w.png`} alt="icon" />
          )}
          {templateName}
          <CloseCircleFilled
            onClick={() => handleClearTag(`${templateNo}+${serviceTemplateNo || ''}`)}
          />
        </Tag>
      );
    }

    return templateComponent;
  };

  // 예약 실행 날짜 선택 이벤트
  const handleOnChangeDate = (type: any, current: any) => {
    setValues({ ...values, [type]: current });
  };

  // 선택 불가 날짜 지정
  const handleDisableDate = (type: any, current: any) => {
    const { sendReserveEnable, sendPeriod, startDate, endDate } = formStoreData;
    // 나눔 발송 기간
    const reserveDays = sendReserveEnable === '1' ? sendPeriod : 1;
    // 예약 실행 가능 날짜: 나눔 발송 종료일(없을 시 시작일) +1 ~ 훈련 종료일
    const minDate = moment((reserveDays * 86400 + moment(startDate).unix()) * 1000);

    return current < minDate || current > endDate;
  };

  let templateListComponent = null;
  const templateOption: any = [];

  // 템플릿이 있을 경우
  if (templateData.length > 0) {
    // 훈련 템플릿 리스트
    const templateList = templateData.filter(
      (template: any) =>
        !values.examTemplate?.includes(
          `${template.templateNo}+${template.serviceTemplateNo || ''}`,
        ),
    );

    const rowTemplateObj: any = {};
    const rowTemplateType: any = {};
    templateList.forEach((template: any, index: number) => {
      const {
        templateNo,
        templateName,
        serviceTemplateNo,
        examType,
        attachType,
        attachInfoNo,
        serviceNo,
      } = template;

      // 템플릿 첨부파일 정보
      const attachInfo = attachData.filter((attach: any) => attach.attachInfoNo === attachInfoNo);
      // 훈련 유형별 템플릿 색상
      let examTypeColor = '';
      // 훈련 유형별 템플릿
      let examTypeString = '';
      // 훈련 유형 라벨 컴포넌트
      let examTypeLabelComponent = null;
      // if (attachInfo.length > 0 && attachInfo[0] && attachInfo[0].isEnable === 1) {
      if (examType === 1 && attachInfo[0]?.attachExamType === 1) {
        examTypeColor = 'color-bg-warning';
        examTypeString = 'warning';
        examTypeLabelComponent = attachInfo[0]?.isEnable === 1 && (
          <Tag className="temp-type-label color-temp-warning">
            {formatMessage({ id: 'Template_10', defaultMessage: '경고 안내' })}
          </Tag>
        );
      } else if (examType === 1 && attachInfo[0]?.attachExamType === 2) {
        examTypeColor = 'color-bg-file';
        examTypeString = 'file';
        examTypeLabelComponent = attachInfo[0]?.isEnable === 1 && (
          <Tag className="temp-type-label color-temp-file">
            {formatMessage({ id: 'Template_11', defaultMessage: '모의 악성파일' })}
          </Tag>
        );
      } else if (examType === 1 && attachInfo[0]?.attachExamType === 3) {
        examTypeColor = 'color-bg-research';
        examTypeString = 'research';
        examTypeLabelComponent = attachInfo[0]?.isEnable === 1 && (
          <Tag className="temp-type-label color-temp-research">
            {formatMessage({ id: 'Template_12', defaultMessage: '실태 조사' })}
          </Tag>
        );
      } else if (examType === 4) {
        examTypeColor = 'color-bg-phishing';
        examTypeString = 'phishing';
        examTypeLabelComponent = attachInfo[0]?.isEnable === 1 && (
          <Tag className="temp-type-label color-temp-phishing">
            {formatMessage({ id: 'Template_13', defaultMessage: '피싱 유도' })}
          </Tag>
        );
      } else {
        examTypeLabelComponent = <div>-</div>;
      }

      rowTemplateObj[`${templateName}+${index}`] = {
        value: `${templateNo}+${serviceTemplateNo || ''}`,
        examTypeColor: examTypeColor,
        examTypeString: examTypeString,
        examTypeLabelComponent: examTypeLabelComponent,
        attachInfoData: attachInfo[0],
        ...template,
      };
      rowTemplateType[`${templateName}+${index}`] = serviceNo ? true : false;
      // }
    });

    // 정렬 처리 - 사용자 템플릿 순 + 템플릿명 순
    const sortedArray = Object.keys(rowTemplateObj).sort();
    const defaultTemplateArray: any = [];
    const userTemplateArray: any = [];
    sortedArray.forEach((key: any) => {
      // 사용자 템플릿
      if (rowTemplateType[key]) {
        userTemplateArray.push(rowTemplateObj[key]);
      }
      // 기본 템플릿
      else {
        defaultTemplateArray.push(rowTemplateObj[key]);
      }
    });
    const templateInfoArray = userTemplateArray.concat(defaultTemplateArray);

    templateInfoArray.forEach((data: any) => {
      const {
        value,
        examTypeString,
        templateReceive,
        templateNo,
        templateName,
        serviceTemplateNo,
        attachInfoNo,
        attachInfoData,
        attachType,
        examType,
        examTypeLabelComponent,
        serviceNo,
        linkAttach,
        statisticsTarget,
        statisticsInfection,
      } = data;

      // 첨부 유형
      let linkAttachText = formatMessage({ id: 'Template_18', defaultMessage: '첨부 없음' });
      if (linkAttach === 1) {
        linkAttachText = formatMessage({ id: 'Template_15', defaultMessage: '첨부 파일' });
      } else if (linkAttach === 2) {
        linkAttachText = formatMessage({ id: 'Template_16', defaultMessage: '링크 첨부' });
      } else if (linkAttach === 3) {
        linkAttachText = formatMessage({ id: 'Template_17', defaultMessage: '링크 + 첨부' });
      }

      // 훈련 결과(유출/감염률)
      let examResultText = formatMessage({ id: 'StartExam_55', defaultMessage: '훈련 결과 없음' });
      if (!serviceNo && statisticsTarget > 0) {
        const statisticsText =
          examType === 4
            ? formatMessage({ id: 'Template_20', defaultMessage: '유출률' })
            : formatMessage({ id: 'Template_19', defaultMessage: '감염률' });
        const statisticsPercent = `${Math.floor((statisticsInfection / statisticsTarget) * 100)} %`;
        examResultText = `${statisticsText}: ${statisticsPercent}`;
      }

      //  드롭다운 메뉴
      templateOption.push(
        <Select.Option key={`exam-template-${value}`} value={value} className="start-exam-template">
          <div className="template-select-option flex">
            <img
              className="exam-type-circle"
              src={`/img/exam/startExam/temp_${examTypeString || 'none'}_36${
                templateReceive === 1 ? '_brand' : ''
              }.png`}
              alt="icon"
            />
            <div className="template-info-area">
              <div className="template-info-title">
                {`(No: ${serviceTemplateNo || templateNo}) ${templateName}`}
              </div>
              <div className="template-exam-info">
                {serviceNo ? (
                  <Tag className="temp-type-label color-temp-user">
                    {formatMessage({ id: 'User_1', defaultMessage: '사용자' })}
                  </Tag>
                ) : (
                  <Tag className="temp-type-label color-temp-system">
                    {formatMessage({ id: 'Template_14', defaultMessage: '기 본' })}
                  </Tag>
                )}
                {/* 훈련 유형 */}
                {examTypeLabelComponent}
                {/* 첨부 유형 */}
                {examType !== 4 && examTypeString && `/ ${linkAttachText}`}
                {/* 유출/감염률 */}
                {!!linkAttachText && examTypeString && ` / ${examResultText}`}
              </div>
              {!!attachType && !!attachInfoData && (
                <div className="template-attach-info flex">
                  <div>{`${formatMessage({
                    id: 'Attach_2',
                    defaultMessage: '첨부파일 정보',
                  })} no.${attachInfoNo}`}</div>
                  <img
                    className="file-icon"
                    src={`/fdata/attachFile/${attachInfoData.attachInfoNo}/${attachInfoData.fileIcon}`}
                    alt="file"
                  />
                  <div>{attachInfoData.fileExt}</div>
                </div>
              )}
            </div>
          </div>
        </Select.Option>,
      );
    });

    templateListComponent = (
      <FormSelectField
        // className={values.examTemplate?.length < 1 && 'warning'}
        name="examTemplate"
        control={control}
        error={errors.examTemplate}
        option={templateOption}
        handleOnChange={handleSelectItem}
        mode="multiple"
        showArrow
        tagRender={tagRender}
        placeholder={formatMessage({
          id: 'Template_21',
          defaultMessage: '훈련 템플릿을  선택하세요.',
        })}
        // dropdownClassName={`start-exam-dropdown ${tagTargetCount < 1 ? 'disabled' : ''}`}
        // disabled={values.isAllTarget}
        validation={{
          validate: {
            required: (value: any) => {
              return valid.required(values.examTemplate?.length);
            },
          },
        }}
        value={values.examTemplate}
        filterOption={(input: any, option: any) => {
          const target = option.children.props.children[1].props.children[0].props.children;
          return target.split(') ')[1].toLowerCase().includes(input.toLowerCase());
        }}
      />
    );
  }

  // 훈련 URL 드롭다운 메뉴
  const urlMenuList: any = [];
  if (systemUrlData && systemUrlData[0].url) {
    const splitArray = systemUrlData[0].url.split('"');
    const urlList = splitArray.filter((value: any) => value.indexOf('http') > -1);
    urlList.forEach((item: any, index: number) => {
      urlMenuList.push({
        key: index,
        value: item,
        text: item,
      });
    });
  }

  // PC 잠금 실행 시간 드롭다운 메뉴
  const hourList = [];
  const minuteList = [];
  for (let i = 0; i < 60; i += 1) {
    if (i < 24) {
      hourList.push({
        key: i,
        value: i * 3600,
        text: `${i}${formatMessage({ id: 'Time_1', defaultMessage: '시' })}`,
      });
    }

    minuteList.push({
      key: i,
      value: i * 60,
      text: `${i}${formatMessage({ id: 'Time_2', defaultMessage: '분' })}`,
    });
  }

  return (
    <div className="step4-content">
      {/* 훈련 양식(템플릿) 선택 */}
      <div className="content-item">
        <div className="input-title">
          {formatMessage({ id: 'Template_3', defaultMessage: '훈련 양식(템플릿) 선택' })}
          <div className="template-type-icon-top">
            <div className="icon-area">
              <img src="/img/exam/startExam/ico_warning.png" alt="warning" />
              {formatMessage({ id: 'Template_10', defaultMessage: '경고안내' })}
            </div>
            <div className="icon-area">
              <img src="/img/exam/startExam/ico_file.png" alt="file" />
              {formatMessage({ id: 'Template_11', defaultMessage: '모의 악성파일' })}
            </div>
            <div className="icon-area">
              <img src="/img/exam/startExam/ico_research.png" alt="research" />
              {formatMessage({ id: 'Template_12', defaultMessage: '실태 조사' })}
            </div>
            <div className="icon-area">
              <img src="/img/exam/startExam/ico_phishing.png" alt="phishing" />
              {formatMessage({ id: 'Template_13', defaultMessage: '피싱 유도' })}
            </div>
          </div>
        </div>
        {templateListComponent}
        <div className="error-message mt-5">{errorMessage}</div>
      </div>
      {/* 훈련 URL 사용 */}
      <div className="content-item">
        <div className="start-exam-border-box">
          <div className="header-box">
            <div className="title bold">
              {formatMessage({ id: 'StartExam_49', defaultMessage: '훈련 URL 사용' })}
              <Popover
                placement="right"
                content={
                  Option.isJapan ? (
                    <>
                      <div>私たちサービスでは、フィッシングタイプの訓練や添付</div>
                      <div>ファイルタイプの訓練でダウンロードタイプの訓練を</div>
                      <div>実施する場合、訓練メールの本文に攻撃者が設定する</div>
                      <div>ようなURLリンクが埋め込まれます。</div>
                      <div>
                        その場合この設定を選択しなければ、”{Option.customer === 'saxa' ? 's-aemt.com' : 'mudfix.jp'}”という
                      </div>
                      <div>ドメイン名が使用されます。</div>
                      <div>この設定を選択し、次の「リンク先ドメインを選択」で</div>
                      <div>表示される中からドメインを選択すると、訓練メール</div>
                      <div>本文に埋め込まれるURLリンクのドメイン名が選択した</div>
                      <div>ものになります。</div>
                      <div>例えば、URLリンクのドメイン名を</div>
                      <div>internetbk.net</div>
                      <div>と選択し、テンプレートの設定で送信元のメール</div>
                      <div>アドレスを</div>
                      <div>admin@internetbk.net</div>
                      <div>としてすると、訓練メールを受け取った訓練対象者は</div>
                      <div>より実際のメールと間違いやすくなり、より高度な</div>
                      <div>訓練を実施することができます。</div>
                    </>
                  ) : (
                    formatMessage({
                      id: 'StartExam_51',
                      defaultMessage: '메일 링크에 들어갈 URL을 설정합니다.',
                    })
                  )
                }
              >
                <img src="/img/exam/startExam/btn_qmark.png" alt="help" />
              </Popover>
            </div>
            <Controller
              control={control}
              name="useExamSystemUrl"
              render={({ onChange }) => (
                <Switch
                  onChange={(value: any) => {
                    onChange(value ? '1' : '0');
                    setValues({ ...values, useExamSystemUrl: value ? '1' : '0' });
                  }}
                  checked={values.useExamSystemUrl === '1' ? true : false}
                  // disabled={urlMenuList.length < 1}
                />
              )}
            />
          </div>
          <div
            className={`setting-box ${
              values.useExamSystemUrl === '0' || urlMenuList.length < 1 ? 'disabled' : ''
            }`}
          >
            <div className="input-title">
              {formatMessage({ id: 'StartExam_50', defaultMessage: '훈련 URL 선택' })}
            </div>
            <div className="input-value">
              <FormSelectField
                name="examSystemUrl"
                control={control}
                value={formStoreData.examSystemUrl}
                menuList={urlMenuList}
                handleOnChange={handleSelectItem}
              />
            </div>
          </div>
        </div>
      </div>

      {/* 세이프 브라우징 문구 */}
      <div className="screen-lock-info-text">
        {`※ ${formatMessage({
          id: 'StartExam_63',
          defaultMessage: '구글 세이프 브라우징을 통해 URL 상태 확인 결과',
        })} '${
          formStoreData.useExamSystemUrl === '1' && formStoreData.examSystemUrl
            ? formStoreData.examSystemUrl
            : systemUrlData[1]?.url
        }' ${formatMessage({ id: 'StartExam_66', defaultMessage: '는' })}`}
        {localStorage.getItem('language') === 'ja' ? '' : ` `}
        {safeType === 1 ? (
          formatMessage({
            id: 'StartExam_64',
            defaultMessage: '안전합니다.',
          })
        ) : (
          <span className="color-red">
            {formatMessage({
              id: 'StartExam_65',
              defaultMessage: '안전하지 않습니다.',
            })}
          </span>
        )}
      </div>

      {/* PC 잠금 화면 실행 시간 */}
      <div className="content-item">
        <div className="start-exam-border-box">
          <div className="header-box">
            <div className="title bold">
              {formatMessage({ id: 'StartExam_52', defaultMessage: 'PC 잠금 화면 실행 시간' })}
              <Popover
                placement="top"
                content={parse(
                  formatMessage({
                    id: 'StartExam_53',
                    defaultMessage:
                      "<div><div>대상자가 <b>'경고 안내, 모의 악성파일'</b></div><div>타입의 첨부 파일 실행 시</div><div>PC에서 <b>'PC 잠금 화면'</b>의 실행시간을 </div>예약할 수 있습니다.</div></div>",
                  }),
                )}
              >
                <img src="/img/exam/startExam/btn_qmark.png" alt="help" />
              </Popover>
            </div>
            <Controller
              control={control}
              name="useAttachScreenLock"
              render={({ onChange }) => (
                <Popover
                  placement="right"
                  content={parse(
                    formatMessage({
                      id: 'StartExam_54',
                      defaultMessage:
                        "<div><div><b>'경고 안내, 모의 악성파일'</b>타입의</div><div>템플릿을 선택해야 <b>잠금 시간</b>을</div><div>지정할 수 있습니다.</div></div>",
                    }),
                  )}
                >
                  <Switch
                    onChange={(value: any) => {
                      onChange(value ? '1' : '0');
                      setValues({ ...values, useAttachScreenLock: value ? '1' : '0' });
                    }}
                    disabled={disableLockSwitch}
                    checked={values.useAttachScreenLock === '1' ? true : false}
                  />
                </Popover>
              )}
            />
          </div>
          <div className={`setting-box ${values.useAttachScreenLock === '0' ? 'disabled' : ''}`}>
            <div className="setting-row">
              <div className="screen-lock-date">
                <div className="input-title">
                  {formatMessage({ id: 'StartExam_56', defaultMessage: '예약 실행 날짜' })}
                </div>
                <FormDatePicker
                  control={control}
                  name="attachScreenLockDate"
                  value={
                    values.attachScreenLockDate ||
                    (formStoreData.sendReserveEnable === '1'
                      ? moment(
                          (formStoreData.sendPeriod * 86400 +
                            moment(formStoreData.startDate).unix()) *
                            1000,
                        )
                      : moment((1 * 86400 + moment(formStoreData.startDate).unix()) * 1000))
                  }
                  error={errors.attachScreenLockDate}
                  handleOnChange={handleOnChangeDate}
                  handleDisableDate={handleDisableDate}
                />
              </div>

              <div className="screen-lock-time">
                <div className="input-title">
                  {formatMessage({ id: 'Time_4', defaultMessage: '시간' })}
                </div>
                <FormSelectField
                  name="attachScreenLockHour"
                  control={control}
                  defaultValue={values.attachScreenLockHour}
                  menuList={hourList}
                  handleOnChange={handleSelectItem}
                />
              </div>
              <div className="screen-lock-time">
                <div className="input-title">
                  {formatMessage({ id: 'Time_2', defaultMessage: '분' })}
                </div>
                <FormSelectField
                  name="attachScreenLockMin"
                  control={control}
                  defaultValue={values.attachScreenLockMin}
                  menuList={minuteList}
                  handleOnChange={handleSelectItem}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="screen-lock-info-text">
          {formatMessage({
            id: 'StartExam_57',
            defaultMessage:
              "※ 선택한 템플릿 중 '경고안내', '모의 악성파일'은 PC잠금 화면의 실행시간을 예약 할 수 있습니다.",
          })}
        </div>
      </div>
    </div>
  );
}

export default StartExamStep4;
