import { apiUrl } from 'utils/commonValues';
import { actionFormat } from 'utils/commonFunctions';

// Action Types
// const PREPARE_EXAM = 'startExam/PREPARE_EXAM';
const SET_INIT = 'startExam/SET_INIT';
const GET_PREPARE = 'startExam/GET_PREPARE';
const GET_EXAM = 'startExam/GET_EXAM';
const GET_TAG = 'startExam/GET_TAG';
const GET_LICENSE = 'startExam/GET_LICENSE';
const GET_TEMPLATE = 'startExam/GET_TEMPLATE';
const GET_ATTACH = 'startExam/GET_ATTACH';
const GET_SYSTEM_URL = 'startExam/GET_SYSTEM_URL';
const POST_EXAM = 'startExam/POST_EXAM';

// Actions
export const setInitial = () => actionFormat(SET_INIT);
// 훈련 실시 환경 정보 조회
export const getPrepare = () => actionFormat(GET_PREPARE, null, 'get', `${apiUrl}Exam/Prepare`);
// 훈련 목록 조회 - 중복 검사 확인
export const getExam = (params?: any) => actionFormat(GET_EXAM, params, 'get', `${apiUrl}Exam`);
// 태그 정보 조회
export const getTag = () => actionFormat(GET_TAG, null, 'get', `${apiUrl}Tag`);
// 라이선스 정보 조회
export const getLicense = (params: any) =>
  actionFormat(GET_LICENSE, params, 'get', `${apiUrl}Exam/ExamLicense`);
// 템플릿 정보 조회
export const getTemplate = (params: any) =>
  actionFormat(GET_TEMPLATE, params, 'get', `${apiUrl}Template`);
// 첨부파일 정보 조회
export const getAttach = () => actionFormat(GET_ATTACH, null, 'get', `${apiUrl}TemplateAttach`);
// 훈련 URL 정보 조회
export const getSystemUrl = () => actionFormat(GET_SYSTEM_URL, null, 'get', `${apiUrl}SystemUrl`);
// 훈련 실시
export const startExam = (params: any) => actionFormat(POST_EXAM, params, 'post', `${apiUrl}Exam`);

// Initial state
const initialState = () => {
  return ({
    prepare: {},
    exam: {},
    result: { success: {}, error: {} },
    tag: {},
    // examTemplate: {},
    // phishingTemplate: {},
    license: {},
    template: {},
    attach: {},
    systemUrl: {}
  })
};

// Reducers
export default function (state = initialState(), action: any) {
  switch (action.type) {
    case SET_INIT:
      state = initialState();
      return state;
    case GET_PREPARE:
      if (action.payload) {
        const { list } = action.payload.data;
        state.prepare = list;
      }
      return state;
    case GET_EXAM:
      if (action.payload) {
        const { list } = action.payload.data;
        state.exam = list;
      }
      return state;
    case GET_TAG:
      if (action.payload) {
        const { list } = action.payload.data;
        state.tag = list;
      }
      return state;
    case GET_LICENSE:
      if (action.payload) {
        const { data } = action.payload;
        state.license = data;
      }
      return state;
    case GET_TEMPLATE:
      if (action.payload) {
        const { list } = action.payload.data;
        state.template = list;
      }
      return state;
    case GET_ATTACH:
      if (action.payload) {
        const { list } = action.payload.data;
        state.attach = list;
      }
      return state;
    case GET_SYSTEM_URL:
      if (action.payload) {
        const { list } = action.payload.data;
        state.systemUrl = list;
      }
      return state;
    case POST_EXAM:
      if (action.payload) {
        const { list, error } = action.payload.data;

        if (error) {
          const [first] = error;
          state.result.error = first;
        } else {
          state.result.success = list;
        }
      }
      return state;
    default:
      return state;
  }
}
