import AttachList from 'components/branch/attach/AttachList';
import MainTitle from 'components/common/admin/MainTitle';
import { useIntl } from 'react-intl';
import "./Conf.scss";

export default function Attach(props: any) {
  const { formatMessage } = useIntl();
  

  return (
    <div className="admin-basic-conf table">
      <MainTitle type="table" title={formatMessage({ id: 'Attach_1', defaultMessage: '첨부파일' })} />
      <div className="react-table-area">
        <AttachList />
      </div>
    </div>
  );
}