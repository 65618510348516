import { useIntl } from 'react-intl';
import MainTitle from 'components/common/admin/MainTitle';
import EduUserTemplateMenu from './EduUserTemplateMenu';
import EduUserTemplateList from './EduUserTemplateList';
import "../../standard/mudmanage/Conf.scss";

function EduUserTemplate() {
  const { formatMessage } = useIntl();

  return (
    <div className="admin-basic-conf table">
      <MainTitle type="table" title={formatMessage({ id: 'Template_36', defaultMessage: '사용자 템플릿' })}>
        <EduUserTemplateMenu />
      </MainTitle>
      <div className="react-table-area">
        <EduUserTemplateList />
      </div>
    </div>
  );
}

export default EduUserTemplate;
