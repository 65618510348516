import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from 'components/common/Loading';

// import noTargetImage from 'img/exam/list_no_target.png';

import './ContentList.scss';

function ContentList(props: any) {
  const {
    dataList,
    onLoadData,
    loading,
    page,
    totalPage,
    noContent,
    showNoImage,
    className,
    children,
  } = props;

  return (
    <div className={`common-content-list ${className || ''}`} id="scrollable-div">
      <Loading loading={loading} />
      {children.length > 0 ? (
        <InfiniteScroll
          dataLength={dataList.length}
          next={onLoadData}
          hasMore={page < totalPage && !loading}
          loader={<Loading loading={loading} />}
          scrollableTarget="scrollable-div"
        >
          {children}
        </InfiniteScroll>
      ) : (
        <div className="no-content-wrap">
          <div className="no-content">
            {showNoImage && (
              <img src="/img/exam/list_no_target.png" alt="no-target" className="no-content-img" />
            )}
            <div className="no-content-title">{noContent?.title}</div>
            <div className="no-content-sub-title">{noContent?.subTitle}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ContentList;
