import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row, Table, Tooltip } from 'antd';
import { CloseSquareOutlined } from '@ant-design/icons';
import { getDashboard } from 'store/dashboard';
import { getExamList } from 'store/exam';
import { getLicense } from 'store/license';
// import { getEduList } from 'store/edu';

import { nowEpoch, timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import { Option } from 'utils/commonValues';
import Loading from 'components/common/Loading';
import DashboardLicense from 'components/branch/dashboard/DashboardLicense';
import DashboardChart from 'components/branch/dashboard/DashboardChart';
import ModalTemplate from 'components/common/ModalTemplate';
import './Dashboard.scss';

// import sendImage from 'img/exam/list_send.png';
// import readImage from 'img/exam/list_read.png';
// import downImage from 'img/exam/list_down.png';
// import infectionImage from 'img/exam/list_infection.png';

export const authLevel = 1;
export default function DashboardOrigin() {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [examList, setExamList] = useState([]);
  const [eduList, setEduList] = useState([]);

  // 라이센스
  const license = useSelector((state: any) => {
    return {
      data: state.license.data.success,
    };
  });
  // 대시보드
  const dashboard = useSelector((state: any) => {
    return {
      data: state.dashboard.data,
      buildLicenseData: state.dashboard.buildLicenseData,
      statisticsData: state.dashboard.statisticsData,
      infectionTarget: state.dashboard.infectionTarget,
      infectionTag: state.dashboard.infectionTag,
      notice: state.dashboard.notice,
    };
  });
  // 훈련 실시 결과
  const startExamResult = useSelector((state: any) => state.startExam.result.success);

  useEffect(() => {
    onGetDashboard();
    onGetLicense();
    setTimeout(() => {
      onGetExamList();
      // onGetEduList();
    }, 1000);
  }, [startExamResult]);

  // 대시보드 정보 조회
  const onGetDashboard = async () => {
    try {
      setLoading(true);
      const response: any = await dispatch(getDashboard());
      if (response?.data?.notice?.noticeTitle) {
        setOpenModal(true);
      }
    } catch (error) {
      console.log('Dashboard onGetDashboard', error);
    }
  };

  // 훈련 리스트 조회
  const onGetExamList = async () => {
    try {
      const params = {
        examNo: 'all',
        subTab: 'pi',
        examFilter: { examIng: 1 },
        sort: [{ field: 'examNo', order: 'DESC' }],
        offset: 0,
        limit: 5,
        refresh: true,
      };
      const response: any = await dispatch(getExamList(params));
      if (!response?.data?.error) {
        setExamList(response?.data?.list);
      }

      setLoading(false);
      // if (!response?.data?.error) {
      // const examArray: any = [];
      // response.data.list?.forEach((exam: any) => {
      //   // 진행중인 훈련 최대 5개까지 출력
      //   if (
      //     new Date(exam.examStartDate) <= new Date() &&
      //     new Date() <= new Date(exam.examEndDate) &&
      //     examArray.length < 5
      //   ) {
      //     examArray.push(exam);
      //   }
      // });
      // setExamList(examArray);
      // }
    } catch (error) {
      console.log('Dashboard onGetExamList', error);
    }
  };

  // 교육 리스트 조회
  // const onGetEduList = async () => {
  //   try {
  //     const params = {
  //       eduNo: 'all',
  //       filter: { eduIng: 1 },
  //       sort: [{ "field": "eduNo", "order": "DESC" }],
  //       offset: 0,
  //       limit: 5,
  //       refresh: true
  //     };
  //     const response: any = await dispatch(getEduList(params));
  //     if (!response?.data?.error) {
  //       setEduList(response?.data?.list);
  //     }

  //     setLoading(false);
  //   } catch (error) {
  //     console.log('Dashboard onGetEduList', error);
  //   }
  // };

  // 라이센스 조회
  const onGetLicense = async () => {
    try {
      await dispatch(getLicense());
    } catch (error) {
      console.log('Dashboard onGetLicense', error);
    }
  };

  // 유지보수 라이선스
  let buildLicense = null;
  // 기간+횟수 라이선스
  const periodtimesLicense: any = [];
  // 기간(+영구) 라이선스
  const periodLicense: any = [];
  // 횟수 라이선스
  const timesLicense: any = [];
  // 교육 횟수 라이선스
  const eduTimesLicense: any = [];
  // 교육 연간 라이선스
  const eduPeriodLicense: any = [];

  if (Object.keys(dashboard.buildLicenseData).length > 0) {
    // 사용중인 유지보수 라이선스
    if (nowEpoch() < dashboard.buildLicenseData.licenseEndDate) {
      buildLicense = dashboard.buildLicenseData;
    }
  }

  license.data?.forEach((data: any) => {
    if (data && data.issueEpoch) {
      // 사용중인 기간, 횟수 라이선스
      const { licenseType, startEpoch, endEpoch, examUsedCount, examLimitCount } = data;
      if (
        (licenseType === 1 || licenseType === 2) &&
        nowEpoch() >= startEpoch &&
        nowEpoch() <= endEpoch
      ) {
        // 기간 라이선스
        periodLicense.push(data);
      } else if (licenseType === 4 && examUsedCount !== examLimitCount) {
        // 횟수 라이선스
        timesLicense.push(data);
      } else if (licenseType === 8 && examUsedCount !== examLimitCount) {
        // 기간+횟수 라이선스
        periodtimesLicense.push(data);
      } else if (licenseType === 16 && examUsedCount !== examLimitCount) {
        eduPeriodLicense.push(data);
      } else if (licenseType === 32 && examUsedCount !== examLimitCount) {
        eduTimesLicense.push(data);
      }
    }
  });

  const infectionTargetComp = [];
  const infectionTagComp = [];
  if (dashboard.infectionTarget ) {
    for (let i = 0; i < 5; i += 1) {
      infectionTargetComp.push(
        <div className="content-row" key={dashboard.infectionTarget[i]?.targetNo || i}>
          <div className="content-text ellipsis">
            <i>{i + 1}</i>
            {dashboard.infectionTarget[i]?.targetName || '-'}
          </div>
          <div className="content-value">
            {dashboard.infectionTarget[i]?.infectionCount
              ? `${formatMessage({ id: 'Infection_1', defaultMessage: '감염' })} ${
                  dashboard.infectionTarget[i].infectionCount
                }${formatMessage({ id: 'License_13', defaultMessage: '회' })}`
              : '-'}
          </div>
        </div>,
      );
    }
  }

  if (dashboard.infectionTag ) {
    for (let i = 0; i < 5; i += 1) {
    infectionTagComp.push(
      <div className="content-row" key={dashboard.infectionTag[i]?.tagNo || i}>
        <div className="content-text ellipsis">
          <i>{i + 1}</i>
          {dashboard.infectionTag[i]?.tagName || '-'}
        </div>
        <div className="content-value">
          {dashboard.infectionTag[i]?.infectionCount
            ? `${formatMessage({ id: 'Infection_1', defaultMessage: '감염' })} ${
                dashboard.infectionTag[i].infectionCount
              }${formatMessage({ id: 'License_13', defaultMessage: '회' })}`
            : '-'}
        </div>
      </div>,
    );
    }
  }

  const examTableHeader: any = {
    examName: {
      title: formatMessage({ id: 'Exam_1', defaultMessage: '훈련명' }),
      className: 'exam-name',
    },
    examPeriod: {
      title: formatMessage({ id: 'Period_3', defaultMessage: '훈련 기간' }),
      className: 'exam-period',
    },
    targetCount: { title: formatMessage({ id: 'Target_2', defaultMessage: '대상' }) },
    send: { title: formatMessage({ id: 'Send_1', defaultMessage: '발송' }) },
    read: { title: formatMessage({ id: 'Read_1', defaultMessage: '열람' }) },
    download: {
      title: `${formatMessage({ id: 'Download_1', defaultMessage: '다운' })}/${formatMessage({
        id: 'Download_11',
        defaultMessage: '접속',
      })}`,
    },
    infection: {
      title: `${formatMessage({ id: 'Infection_1', defaultMessage: '감염' })}/${formatMessage({
        id: 'Infection_44',
        defaultMessage: '유출',
      })}`,
    },
    cure: { title: formatMessage({ id: 'Cure_1', defaultMessage: '치료' }) },
    infectionPc: { title: formatMessage({ id: 'Infection_3', defaultMessage: '감염 PC' }) },
    declare: {
      title: formatMessage({ id: 'Target_3', defaultMessage: '신고한 대상자' }),
      hidden: !Option.isDeclare,
    },
  };

  const eduTableHeader: any = {
    eduName: {
      title: formatMessage({ id: 'Edu_28', defaultMessage: '교육명' }),
      className: 'exam-name',
    },
    eduPeriod: {
      title: formatMessage({ id: 'Period_4', defaultMessage: '교육 기간' }),
      className: 'exam-period',
    },
    eduCount: { title: formatMessage({ id: 'Edu_110', defaultMessage: '교육 대상' }) },
    send: { title: formatMessage({ id: 'Send_1', defaultMessage: '발송' }) },
    read: { title: formatMessage({ id: 'Read_1', defaultMessage: '열람' }) },
    playCount: { title: formatMessage({ id: 'Status_8', defaultMessage: '진행중' }) },
    afterCount: { title: formatMessage({ id: 'Status_16', defaultMessage: '완료' }) },
    progress: { title: formatMessage({ id: 'Edu_25', defaultMessage: '진행률' }) },
  };

  return (
    <div className="dashboard-page">
      <Loading loading={loading} />
      <div className="dashboard">
        {/* left */}
        <div className="left-area">
          <div className="left">
            <div className="board-row">
              <div className="board top">
                <div className="content-title">
                  {formatMessage({ id: 'Exam_77', defaultMessage: '월별 훈련 수' })}
                </div>
                <DashboardChart
                  type="bar"
                  data={dashboard.data}
                  target="examCount"
                  message={formatMessage({ id: 'StartExam_21', defaultMessage: '개' })}
                  color="#14ae96"
                />
              </div>
              <div className="board top">
                <div className="content-title">
                  {formatMessage({ id: 'Exam_78', defaultMessage: '월별 훈련 대상 수' })}
                </div>
                <DashboardChart
                  type="bar"
                  data={dashboard.data}
                  target="targetCount"
                  message={formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}
                  color="#14ae96"
                />
              </div>
              <div className="board-exam">
                <div className="statistics-row">
                  <div className="statistics-item all">
                    <div className="statistics-title">
                      {formatMessage({ id: 'Exam_5', defaultMessage: '전체 훈련' })}
                    </div>
                    <div className="number">
                      {dashboard.statisticsData?.examCount}
                      <span>{formatMessage({ id: 'StartExam_21', defaultMessage: '개' })}</span>
                    </div>
                  </div>
                  <div className="statistics-item reserve">
                    <div className="statistics-title">
                      {formatMessage({ id: 'Exam_9', defaultMessage: '예약 훈련' })}
                    </div>
                    <div className="number">
                      {dashboard.statisticsData?.reserveExamCount}
                      <span>{formatMessage({ id: 'StartExam_21', defaultMessage: '개' })}</span>
                    </div>
                  </div>
                </div>
                <div className="statistics-row">
                  <div className="statistics-item ing">
                    <div className="statistics-title">
                      {formatMessage({ id: 'Exam_8', defaultMessage: '진행 중인 훈련' })}
                    </div>
                    <div className="number">
                      {dashboard.statisticsData?.progressExamCount}
                      <span>{formatMessage({ id: 'StartExam_21', defaultMessage: '개' })}</span>
                    </div>
                  </div>
                  <div className="statistics-item end">
                    <div className="statistics-title">
                      {formatMessage({ id: 'Exam_76', defaultMessage: '종료된 훈련' })}
                    </div>
                    <div className="number">
                      {dashboard.statisticsData?.endExamCount}
                      <span>{formatMessage({ id: 'StartExam_21', defaultMessage: '개' })}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="board-row">
              <div className="board middle">
                <div className="content-title">
                  {formatMessage({ id: 'Exam_37', defaultMessage: '훈련 결과' })}
                </div>
                <DashboardChart
                  type="line"
                  data={dashboard.data}
                  target="examResult"
                  message={{
                    send: formatMessage({ id: 'Send_1', defaultMessage: '발송' }),
                    read: formatMessage({ id: 'Read_1', defaultMessage: '열람' }),
                    download: formatMessage({
                      id: 'Download_5',
                      defaultMessage: '파일 다운 / 피싱 접속',
                    }),
                    infection: formatMessage({
                      id: 'Infection_25',
                      defaultMessage: 'PC 감염 / 정보 유출',
                    }),
                  }}
                  color={{
                    send: '#14ae96',
                    read: '#f69c2e',
                    download: '#711bd1',
                    infection: '#dc3030',
                  }}
                />
              </div>
              {dashboard.statisticsData && (
                <div className="board-target">
                  <div className="target-row">
                    <div className="target-item">
                      <DashboardChart
                        type="circle"
                        target="send"
                        data={dashboard.statisticsData}
                        message={formatMessage({ id: 'Send_17', defaultMessage: '발송률' })}
                        color={{ start: '#14ae96', end: '#058f88' }}
                        icon="/img/exam/list_send.png"
                      />
                    </div>
                    <div className="target-item">
                      <DashboardChart
                        type="circle"
                        target="read"
                        data={dashboard.statisticsData}
                        message={formatMessage({ id: 'Read_4', defaultMessage: '열람률' })}
                        color={{ start: '#f69c2e', end: '#ff8715' }}
                        icon="/img/exam/list_read.png"
                      />
                    </div>
                  </div>
                  <div className="target-row">
                    <div className="target-item">
                      <DashboardChart
                        type="circle"
                        target="download"
                        data={dashboard.statisticsData}
                        message={formatMessage({
                          id: 'Download_12',
                          defaultMessage: '다운/접속률',
                        })}
                        color={{ start: '#711bd1', end: '#4506a1' }}
                        icon="/img/exam/list_down.png"
                      />
                    </div>
                    <div className="target-item">
                      <DashboardChart
                        type="circle"
                        target="infection"
                        data={dashboard.statisticsData}
                        message={formatMessage({
                          id: 'Infection_45',
                          defaultMessage: '감염/유출률',
                        })}
                        color={{ start: '#dc3030', end: '#bd0e0e' }}
                        icon="/img/exam/list_infection.png"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="board-row">
              <div className="board bottom">
                <div className="content-title">
                  {formatMessage({ id: 'Exam_8', defaultMessage: '진행 중인 훈련' })}
                </div>
                {examList.length > 0 ? (
                  <div className="exam-list-table">
                    <div className="table-header">
                      <Row>
                        {Object.keys(examTableHeader).map((key: string) => {
                          const { title, className, hidden } = examTableHeader[key];
                          return (
                            !hidden && (
                              <Col key={key} className={`gutter-row ${className}`} title={title}>
                                {title}
                              </Col>
                            )
                          );
                        })}
                      </Row>
                    </div>
                    {examList.length > 0 &&
                      examList.map((data: any) => {
                        return (
                          <Row key={data.examNo}>
                            <Col className="gutter-row exam-name">
                              <Tooltip
                                overlayClassName="black-tooltip full"
                                placement="bottomLeft"
                                title={data.examName}
                              >
                                <div className="ellipsis">{data.examName}</div>
                              </Tooltip>
                            </Col>
                            <Col className="gutter-row exam-period">
                              <Tooltip
                                overlayClassName="black-tooltip full"
                                placement="bottom"
                                title={`${data.examStartDate.split(' ')[0]} ~ ${
                                  data.examEndDate.split(' ')[0]
                                }`}
                              >
                                <div className="ellipsis">{`${data.examStartDate.split(' ')[0]} ~ ${
                                  data.examEndDate.split(' ')[0]
                                }`}</div>
                              </Tooltip>
                            </Col>
                            <Col className="gutter-row">{data.target}</Col>
                            <Col className="gutter-row">{data.send}</Col>
                            <Col className="gutter-row">{data.read}</Col>
                            <Col className="gutter-row">{data.download}</Col>
                            <Col className="gutter-row">{data.infection}</Col>
                            <Col className="gutter-row">{data.cure}</Col>
                            <Col className="gutter-row">
                              <Tooltip
                                overlayClassName="black-tooltip full"
                                placement="bottom"
                                title={
                                  data.infectionPc
                                    ? `${data.infectionPc}${formatMessage({
                                        id: 'Infection_29',
                                        defaultMessage: '대',
                                      })}`
                                    : formatMessage({ id: 'Infection_28', defaultMessage: '없음' })
                                }
                              >
                                {data.infectionPc
                                  ? `${data.infectionPc}${formatMessage({
                                      id: 'Infection_29',
                                      defaultMessage: '대',
                                    })}`
                                  : formatMessage({ id: 'Infection_28', defaultMessage: '없음' })}
                              </Tooltip>
                            </Col>
                            {!!Option.isDeclare && (
                              <Col className="gutter-row">
                                <Tooltip
                                  overlayClassName="black-tooltip full"
                                  placement="bottom"
                                  title={
                                    data.declare
                                      ? `${data.declare}${formatMessage({
                                          id: 'StartExam_35',
                                          defaultMessage: '명',
                                        })}`
                                      : formatMessage({
                                          id: 'Infection_28',
                                          defaultMessage: '없음',
                                        })
                                  }
                                >
                                  {data.declare
                                    ? `${data.declare}${formatMessage({
                                        id: 'StartExam_35',
                                        defaultMessage: '명',
                                      })}`
                                    : formatMessage({ id: 'Infection_28', defaultMessage: '없음' })}
                                </Tooltip>
                              </Col>
                            )}
                          </Row>
                        );
                      })}
                  </div>
                ) : (
                  <div className="exam-no-data">
                    {formatMessage({ id: 'Exam_79', defaultMessage: '진행 중인 훈련이 없습니다.' })}
                  </div>
                )}
              </div>
            </div>

            {!!Option.isEdu && (
              <div className="board-row">
                <div className="board bottom">
                  <div className="content-title">
                    {formatMessage({ id: 'Edu_15', defaultMessage: '진행중인 교육' })}
                  </div>
                  {eduList.length > 0 ? (
                    <div className="exam-list-table">
                      <div className="table-header">
                        <Row>
                          {Object.keys(eduTableHeader).map((key: string) => {
                            const { title, className, hidden } = eduTableHeader[key];
                            return (
                              !hidden && (
                                <Col key={key} className={`gutter-row ${className}`} title={title}>
                                  {title}
                                </Col>
                              )
                            );
                          })}
                        </Row>
                      </div>
                      {eduList.length > 0 &&
                        eduList.map((data: any) => {
                          return (
                            <Row key={data.eduNo}>
                              <Col className="gutter-row exam-name">
                                <Tooltip
                                  overlayClassName="black-tooltip full"
                                  placement="bottomLeft"
                                  title={data.eduName}
                                >
                                  <div className="ellipsis">{data.eduName}</div>
                                </Tooltip>
                              </Col>
                              <Col className="gutter-row exam-period">
                                <Tooltip
                                  overlayClassName="black-tooltip full"
                                  placement="bottom"
                                  title={`${timeFormatFromUTCEpoch(
                                    data.startEpoch,
                                    3,
                                  )} ~ ${timeFormatFromUTCEpoch(data.endEpoch, 3)}`}
                                >
                                  <div className="ellipsis">{`${timeFormatFromUTCEpoch(
                                    data.startEpoch,
                                    3,
                                  )} ~ ${timeFormatFromUTCEpoch(data.endEpoch, 3)}`}</div>
                                </Tooltip>
                              </Col>
                              <Col className="gutter-row">{data.eduCount}</Col>
                              <Col className="gutter-row">{data.send}</Col>
                              <Col className="gutter-row">{data.read}</Col>
                              <Col className="gutter-row">{data.playCount}</Col>
                              <Col className="gutter-row">{data.afterCount}</Col>
                              <Col className="gutter-row">{`${data.progress}%`}</Col>
                            </Row>
                          );
                        })}
                    </div>
                  ) : (
                    <div className="exam-no-data">
                      {formatMessage({ id: 'Edu_80', defaultMessage: '진행중인 교육이 없습니다.' })}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* right */}
        <div className="right-area">
          {/* <div className={`right ${Option.isEdu ? 'view-edu' : ''}`}> */}
          <div className="right-container license">
            <div className="content-title">{license?.data?.[0]?.serviceName}</div>
            <div className="flex">
              <span className="content-subtitle">
                {formatMessage({ id: 'License_68', defaultMessage: '사용 중인 라이선스' })}
              </span>
              <Link to="/mypage/license" className="brand-btn">
                {formatMessage({ id: 'Dashboard_20', defaultMessage: '관리' })}
                <img src="/img/exam/startExam/btn_next.png" alt="next" />
              </Link>
            </div>

            {/* 기간+횟수 라이선스(8) */}
            <div className="board">
              <DashboardLicense
                licenseData={periodtimesLicense}
                licenseType={periodtimesLicense[0]?.licenseType || 8}
              />
            </div>
            {/* 기간 라이선스(영구: 1, 기간: 2) */}
            <div className="board">
              <DashboardLicense
                licenseData={periodLicense}
                licenseType={periodLicense[0]?.licenseType || 2}
              />
            </div>

            {Option.isEdu === 1 && (
              <>
                {/* [교육] 횟수 라이선스 */}
                <div className="board">
                  <DashboardLicense
                    licenseData={eduTimesLicense}
                    licenseType={eduTimesLicense[0]?.licenseType || 32}
                  />
                </div>

                {/* [교육] 연간 라이선스 */}
                <div className="board">
                  <DashboardLicense
                    licenseData={eduPeriodLicense}
                    licenseType={eduPeriodLicense[0]?.licenseType || 16}
                  />
                </div>
              </>
            )}

            {/* 횟수(4), 유지보수(3) 라이선스 */}
            {/* {Option.isJapan !== 1 && (
              <div className="board">
                <DashboardLicense
                  licenseData={
                    Option.isSaas === 0 && buildLicense && Object.keys(buildLicense).length > 0
                      ? buildLicense
                      : timesLicense[0]
                  }
                  licenseType={
                    Option.isSaas === 0 && buildLicense && Object.keys(buildLicense).length > 0
                      ? 3
                      : 4
                  }
                />
              </div>
            )} */}
          </div>

          <div className={`right-container top5 ${Option.isEdu ? 'view-edu' : ''}`}>
            {/* 위험 대상자 TOP5 */}
            <div className="top5-item">
              <div className="content-title">
                {formatMessage({ id: 'Target_53', defaultMessage: '위험 대상자 TOP5' })}
              </div>
              <div className="content">{infectionTargetComp}</div>
            </div>

            {/* 위험 태그 TOP5 */}
            <div className="top5-item">
              <div className="content-title">
                {formatMessage({ id: 'Tag_54', defaultMessage: '위험 태그 TOP5' })}
              </div>
              <div className="content">{infectionTagComp}</div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>

      {/* 공지 모달 */}
      <ModalTemplate
        className="notice-modal modal-665"
        isCloseBlack
        visible={openModal && localStorage.getItem('notice') === 'true'}
        onCancel={() => setOpenModal(false)}
        centered
        footer={
          <div
            onClick={() => {
              localStorage.setItem('notice', 'false');
              setOpenModal(false);
            }}
            aria-hidden="true"
          >
            <CloseSquareOutlined />
            {formatMessage({ id: 'Header_12', defaultMessage: '다음 로그인 시까지 보지 않음' })}
          </div>
        }
      >
        <div className="notice-title">{dashboard.notice.noticeTitle}</div>
        <pre className="notice-content">{`${dashboard.notice.noticeContent}`}</pre>
      </ModalTemplate>
    </div>
  );
}
