import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  CompressOutlined,
  ExpandOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { eduVideoUpdate } from 'store/edumain';
import { useIntl } from 'react-intl';
import './EduPdfViewer.scss';

const EduPdfViewer = ({ updatePlayData, filePath, type = 'play' }: any) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [scale, setScale] = useState(1);
  const [fullScreen, setFullScreen] = useState(false);
  const pdfContainerRef: any = useRef(null);
  const canvasRef: any = useRef(null);
  const [canvasHeight, setCanvasHeight] = useState(300);

  const {
    userData,
    selectedEduNo,
    eduDataByEduNo
  } = useSelector((state: any) => {
    return {
      serviceNo: state.edumain.serviceNo,
      userData: state.edumain.userData,
      eduTitle: state.edumain.eduTitle,
      eduList: state.edumain.eduList,
      eduDataByEduNo: state.edumain.eduDataByEduNo,
      selectedEduNo: state.edumain.selectedEduNo,
      japanMode: state.edumain.japanMode,
    };
  }, shallowEqual);

  useEffect(() => {
    // 브라우저 크기 변경 시 사이즈 변경되도록 이벤트 부착
    window.addEventListener('resize', handleCanvasLoaded);

    return () => {
      window.removeEventListener('resize', handleCanvasLoaded);
    };
  }, []);

  useEffect(() => {
    handleCanvasLoaded();
  }, [pdfContainerRef, canvasRef]);

  const handleCanvasLoaded = () => {
    if (pdfContainerRef && canvasRef) {
      // 부모 컨테이너에 맞게 canvas 높이 설정
      setCanvasHeight(pdfContainerRef.current.clientHeight);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }: pdfjs.PDFDocumentProxy) => {
    setTotalPages(numPages);
    setCurrentPage(type === 'play' ? (eduDataByEduNo[selectedEduNo].playTime || 1) : 1);
    setScale(1);
    setFullScreen(false);

    if (updatePlayData) {
      updatePlayData({
        playTime: numPages === 1 ? 1 : eduDataByEduNo[selectedEduNo].playTime,
        runningTime: numPages,
      });
    }
  };

  const goToPrev = () => {
    if (currentPage === 1) return;

    const targetPage = currentPage > 1 ? currentPage - 1 : 1;
    setCurrentPage(targetPage);
    if (updatePlayData) {
      updatePlayData({ playTime: targetPage });
    }
  };

  const goToNext = () => {
    if (currentPage === totalPages) return;

    const targetPage = currentPage < totalPages ? currentPage + 1 : totalPages;
    setCurrentPage(targetPage);
    if (updatePlayData) {
      updatePlayData({
        playTime: targetPage,
      });
    }

    // 현재 페이지 기록
    if (type === 'play') {
      const index = eduDataByEduNo[selectedEduNo].playStep <= 1 ? 0 : eduDataByEduNo[selectedEduNo].playStep - 1;
      const currentAttachInfo = eduDataByEduNo[selectedEduNo].attachInfo[index];

      dispatch(eduVideoUpdate({
        eduNo: selectedEduNo,
        eduTargetNo: userData.eduTargetNo,
        eduAttachNo: currentAttachInfo.eduAttachNo,
        playStep: index + 1,
        playTime: targetPage,
        isEndEdu: 'false',
        isEndEach: targetPage === totalPages ? 1 : 0
      }));
    }
  };

  return (
    <div className={`edu-pdfViewer ${type} ${fullScreen ? 'full' : ''}`}>
      <div className="pdf-control-box">
        <button
          className="pdf-control-button"
          type="button"
          onClick={goToPrev}
          title={formatMessage({ id: 'Button_6', defaultMessage: '이 전' })}
        >
          <CaretLeftOutlined />
        </button>
        <span className="pdf-control-text">{`${currentPage} / ${totalPages}`}</span>
        <button
          className="pdf-control-button"
          type="button"
          onClick={goToNext}
          title={formatMessage({ id: 'Button_7', defaultMessage: '다 음' })}
        >
          <CaretRightOutlined />
        </button>
        <span className="separator">|</span>
        <button
          className="pdf-control-button"
          type="button"
          onClick={() => setScale(scale <= 0.5 ? 0.5 : scale - 0.5)}
          title={formatMessage({ id: 'Edu_115', defaultMessage: '축 소' })}
        >
          <ZoomOutOutlined />
        </button>
        <span className="pdf-control-text scale">{scale}</span>
        <button
          className="pdf-control-button"
          type="button"
          onClick={() => setScale(scale >= 3 ? 3 : scale + 0.5)}
          title={formatMessage({ id: 'Edu_116', defaultMessage: '확 대' })}
        >
          <ZoomInOutlined />
        </button>

        {type === 'play' && (
          <>
            <span className="separator">|</span>

            <button
              className="pdf-control-button"
              type="button"
              onClick={() => {
                setFullScreen(!fullScreen);
              }}
              title={
                fullScreen
                  ? formatMessage({ id: 'Edu_117', defaultMessage: '기본 화면' })
                  : formatMessage({ id: 'Edu_118', defaultMessage: '전체 화면' })
              }
            >
              <span className="font-size-15">{fullScreen ? <CompressOutlined /> : <ExpandOutlined />}</span>
            </button>
          </>
        )}
      </div>

      <div className="pdf-contents" ref={pdfContainerRef}>
        <Document file={`/fdata/${filePath}`} onLoadSuccess={onDocumentLoadSuccess}>
          <Page
            canvasRef={canvasRef}
            key={`page_${currentPage}`}
            pageNumber={currentPage}
            scale={scale}
            height={canvasHeight}
          />
        </Document>
      </div>
    </div>
  );
};

export default EduPdfViewer;
