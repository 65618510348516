import './PhishingPageHeader.scss';
import { useIntl } from 'react-intl';
import { CaretRightOutlined, SaveFilled } from '@ant-design/icons';
import { Popover } from 'antd';

// import logoImage from 'img/logo/mf_logo_white_small_v.png';

function PhishingPageHeader({ handleSubmit, onSavePhishingPage, onRunCode }: any) {
  const { formatMessage } = useIntl();

  const infoText = formatMessage({ id: 'Phishing_17', defaultMessage: '정보 제공' });
  const linkText = formatMessage({ id: 'Phishing_18', defaultMessage: '링크 설정' });

  // 예약어 목록
  const reserveList = [
    {
      title: formatMessage({ id: 'Name_1', defaultMessage: '이름' }),
      reservation: '{TARGET_NAME}',
      type: infoText,
    },
    {
      title: formatMessage({ id: 'Email_1', defaultMessage: '이메일' }),
      reservation: '{TARGET_EMAIL}',
      type: infoText,
    },
    {
      title: formatMessage({ id: 'Phishing_14', defaultMessage: '아이디' }),
      reservation: '{TARGET_ID}',
      type: infoText,
    },
    {
      title: formatMessage({ id: 'Contact_1', defaultMessage: '연락처' }),
      reservation: '{TARGET_PHONE}',
      type: infoText,
    },
    {
      title: formatMessage({ id: 'Division_1', defaultMessage: '소속' }),
      reservation: '{TARGET_DIVISION}',
      type: infoText,
    },
    {
      title: formatMessage({ id: 'Position_1', defaultMessage: '직급' }),
      reservation: '{TARGET_POSITION}',
      type: infoText,
    },
    {
      title: formatMessage({ id: 'Phishing_15', defaultMessage: '피싱 단계 진행' }),
      reservation: '{STAGE_NEXT}',
      type: linkText,
    },
    {
      title: formatMessage({ id: 'Phishing_16', defaultMessage: '피싱 결과 화면 실행' }),
      reservation: '{STAGE_FINISH}',
      type: linkText,
    },
  ];

  const reserveComponent = reserveList.map((item) => {
    return (
      <div key={item.title} className="reserve-data">
        <div>{item.title}</div>
        <div>{`$${item.reservation}$`}</div>
        <div>{item.type}</div>
      </div>
    );
  });

  return (
    <div className="phishing-page-header">
      <div className="left">
        <img src="/img/logo/mf_logo_white_small_v.png" alt="logo" />
      </div>
      <div className="center">
        <div
          className="header-btn"
          onClick={onRunCode}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') onRunCode();
          }}
          aria-hidden="true"
          title="Ctrl + Enter"
        >
          <CaretRightOutlined />
          {formatMessage({ id: 'Button_9', defaultMessage: '실 행' })}
        </div>
        <div className="header-btn" onClick={handleSubmit(onSavePhishingPage)} aria-hidden="true">
          <SaveFilled />
          {formatMessage({ id: 'Button_21', defaultMessage: '저 장' })}
        </div>
        {/* 예약어 */}
        <Popover
          overlayClassName="reserve-popover full"
          placement="bottomLeft"
          title={
            <div className="reserve-header">
              <div>{formatMessage({ id: 'Target_2', defaultMessage: '대상' })}</div>
              <div>{formatMessage({ id: 'Phishing_19', defaultMessage: '예약어' })}</div>
              <div>{formatMessage({ id: 'Phishing_20', defaultMessage: '유형' })}</div>
            </div>
          }
          content={
            <div>
              {reserveComponent}
              <div className="reserve-example">
                <span>ex)</span>
                <div>
                  {`${infoText}: ${formatMessage({
                    id: 'Phishing_22',
                    defaultMessage: '${TARGET_NAME}$님께 드리는 마지막 이벤트!', // eslint-disable-line
                  })}`}
                </div>
                <div>
                  {`${linkText}: <a href=$${'{STAGE_NEXT}$ target="_blank">'}${formatMessage({
                    id: 'Phishing_23',
                    defaultMessage: '휴대폰 인증',
                  })}</a>`}
                </div>
              </div>
            </div>
          }
        >
          <div className="reserve-area">
            <div className="reserve-label">
              {formatMessage({ id: 'Phishing_19', defaultMessage: '예약어' })}
            </div>
            {formatMessage({
              id: 'Phishing_21',
              defaultMessage: '템플릿 제작 시 필요한 정보를 확인하세요.',
            })}
          </div>
        </Popover>
      </div>
    </div>
  );
}

export default PhishingPageHeader;
