import { SettingOutlined } from '@ant-design/icons';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual } from 'react-intl/src/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getAdmin, changeParam } from 'store/admin';
import TableList from 'components/common/TableList';
import TablePagination from 'components/common/TablePagenation';
import TableSearch from 'components/common/TableSearch';
import TableDatePicker from 'components/common/TableDatePicker';
import SubAdminSettings from 'components/branch/subadmin/SubAdminSettings';

export default function SubAdminList(props: any) {
  let tableRowCount = Math.trunc((window.innerHeight - 315) / 48);
  tableRowCount = tableRowCount < 5 ? 5 : tableRowCount;

  const { formatMessage } = useIntl();
  const [loading, setLoding] = useState(false);
  const [pageSize, setPageSize] = useState(tableRowCount);
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();
  const { sort, filter } = useSelector(
    (state: any) => ({
      sort: state.admin.param.sort,
      filter: state.admin.param.filter,
    }),
    shallowEqual,
  );
  const needReload = useSelector((state: any) => state.admin.needReload);
  const subAdminData = useSelector((state: any) => state.admin.subAdminData);
  const totalCount = useSelector((state: any) => state.admin.totalCount);
  const totalPages = useSelector((state: any) => state.admin.totalPages);

  useEffect(() => {
    getAdminData();
  }, [sort, filter, pageSize, currentPage, needReload]);

  // API 호출
  const getAdminData = async () => {
    if (!loading) {
      setLoding(true);

      try {
        const params = {
          filter: JSON.stringify(filter),
          sort: JSON.stringify(sort),
          offset: pageSize * (currentPage < 0 ? 0 : currentPage), // page 는 0 번 부터 시작
          limit: pageSize < 1 ? 20 : pageSize,
        };

        await dispatch(getAdmin(params));
      } catch (e) {
        console.log(e);
      }
    }

    setLoding(false);
  };

  // 검색
  const searchOption = {
    searchAll: formatMessage({ id: 'Faq_1', defaultMessage: '전체' }),
    userNo: 'No',
    userName: formatMessage({ id: 'Name_1', defaultMessage: '이름' }),
    userEmail: formatMessage({ id: 'Email_1', defaultMessage: '이메일' }),
    userPhone: formatMessage({ id: 'Contact_1', defaultMessage: '연락처' }),
  };

  // 필터
  const filterOption: { [key: string]: any } = {
    loginFailArray: {
      name: formatMessage({ id: 'User_2', defaultMessage: '로그인 실패' }),
      child: [
        {
          label: formatMessage({ id: 'User_3', defaultMessage: '잠김' }),
          value: '1',
        },
        {
          label: formatMessage({ id: 'User_4', defaultMessage: '정상' }),
          value: '0',
        },
      ],
    },
    userEnabledArray: {
      name: formatMessage({ id: 'Template_34', defaultMessage: '삭제 여부' }),
      child: [
        {
          label: 'O',
          value: '0',
        },
        {
          label: '-',
          value: '1',
        },
      ],
    },
  };
  // 테이블
  const baseColumns: Array<{ [key: string]: any }> = [
    {
      Header: <SettingOutlined />,
      accessor: 'setting',
      resizable: false,
      sortable: false,
      width: 88,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center settings',
      Cell: function cellFunc(props: { [key: string]: any }) {
        return <SubAdminSettings dataInfo={props.row.original} />;
      },
    },
    {
      Header: 'No',
      accessor: 'userNo',
      resizable: false,
      sortable: true,
      width: 72,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center number-text',
    },
    {
      Header: formatMessage({ id: 'Name_1', defaultMessage: '이름' }),
      accessor: 'userName',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
    },
    {
      Header: formatMessage({ id: 'Email_1', defaultMessage: '이메일' }),
      accessor: 'userEmail',
      resizable: false,
      sortable: true,
      width: 177,
      headerClassName: 'react-table-th',
      className: 'react-table-td',
    },
    {
      Header: formatMessage({ id: 'Contact_1', defaultMessage: '연락처' }),
      accessor: 'userPhone',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th',
      className: 'react-table-td sub-text',
    },
    {
      Header: formatMessage({ id: 'User_2', defaultMessage: '로그인 실패' }),
      accessor: 'loginFail',
      resizable: false,
      sortable: true,
      width: 120,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center sub-text',
      Cell: (props: { [key: string]: any }) => {
        let printValue = '-';
        if (props.value === 1) {
          printValue = `${formatMessage({
            id: 'User_3',
            defaultMessage: '잠김',
          })}
          (${props.row.original.loginFailCount}${formatMessage({
            id: 'User_8',
            defaultMessage: '회',
          })})`;
        } else {
          printValue = `${formatMessage({
            id: 'User_4',
            defaultMessage: '정상',
          })}
          (${props.row.original.loginFailCount}${formatMessage({
            id: 'User_8',
            defaultMessage: '회',
          })})`;
        }
        return printValue;
      },
    },
    {
      Header: formatMessage({ id: 'Template_34', defaultMessage: '삭제 여부' }),
      accessor: 'userEnabled',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        let printValue = '-';
        if (props.value === 0) {
          printValue = 'O';
        }
        return printValue;
      },
    },
    {
      Header: (
        <TableDatePicker
          dateType="RegEpoch"
          title={formatMessage({ id: 'Date_3', defaultMessage: '등록일시' })}
          filter={filter}
          actionParam={changeParam}
        />
      ),
      accessor: 'regDate',
      resizable: false,
      sortable: true,
      width: 177,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center sub-text',
    },
  ];

  const columns: Array<any> = useMemo(() => baseColumns, [subAdminData]);

  return (
    <>
      <TableSearch
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalCount={totalCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        changeParam={changeParam}
        searchOption={searchOption}
        filter={filter}
        filterOption={filterOption}
      />
      <TableList
        columns={columns}
        data={subAdminData.success}
        changeParam={changeParam}
        loading={loading}
        disableKey="userEnabled"
      />
      <TablePagination
        totalPages={totalPages}
        currentPage={currentPage + 1}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}
