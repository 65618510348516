import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from 'antd';
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FormOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import { deleteEduTemplate } from 'store/edu';
import { timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import DeleteModal from 'components/common/modal/DeleteModal';
import EduTemplateVideo from './EduTemplateVideo';
import EduTemplateAddEdit from './EduTemplateAddEdit';

function EduTemplateSettings({ isAdmin, dataInfo, templateType }: any) {
  const { formatMessage } = useIntl();
  const [openModal, setOpenModal] = useState('');

  const deleteData = {
    title: formatMessage({ id: 'Edu_59', defaultMessage: '교육 템플릿 삭제' }),
    contents: (
      <div>
        {formatMessage({
          id: 'Edu_60',
          defaultMessage: '삭제한 교육 템플릿은 복구할 수 없습니다.',
        })}
        <br />
        {formatMessage({
          id: 'Edu_61',
          defaultMessage: '다음 교육 템플릿을 삭제합니까?',
        })}
      </div>
    ),
    list: [
      {
        key: 'No',
        value: dataInfo.eduAttachNo,
      },
      {
        key: formatMessage({ id: 'Edu_5', defaultMessage: '컨텐츠명' }),
        value: dataInfo.attachName,
      },
      {
        key: formatMessage({ id: 'Date_3', defaultMessage: '등록일시' }),
        value: timeFormatFromUTCEpoch(dataInfo.regEpoch),
      },
    ],
  };

  return (
    <div className="table-settings">
      {/* 영상 보기 */}
      <Tooltip
        overlayClassName="black-tooltip"
        placement="bottom"
        title={formatMessage({ id: 'Edu_57', defaultMessage: '컨텐츠 보기' })}
      >
        <PlayCircleOutlined onClick={() => setOpenModal('video')} />
      </Tooltip>

      {openModal === 'video' && (
        <EduTemplateVideo
          visible={openModal === 'video'}
          dataInfo={dataInfo}
          toggleModal={(open: boolean) => setOpenModal(open ? 'video' : '')}
        />
      )}

      {/* 교육 템플릿 수정 */}
      {(isAdmin || !!dataInfo.serviceNo) && dataInfo.contentType !== 1 && (
        <Tooltip
          overlayClassName="black-tooltip"
          placement="bottom"
          title={formatMessage({ id: 'Template_66', defaultMessage: '템플릿 수정' })}
        >
          <FormOutlined onClick={() => setOpenModal('edit')} />
        </Tooltip>
      )}

      {/* 교육 템플릿 삭제 */}
      {(isAdmin || !!dataInfo.serviceNo) && (
        <Tooltip
          overlayClassName="black-tooltip"
          placement="bottom"
          title={formatMessage({ id: 'Template_67', defaultMessage: '템플릿 삭제' })}
        >
          <DeleteOutlined onClick={() => setOpenModal('delete')} />
        </Tooltip>
      )}

      {openModal === 'edit' && (
        <EduTemplateAddEdit
          visible={openModal === 'edit'}
          isAdmin={isAdmin}
          isAddMode={false}
          dataInfo={dataInfo}
          toggleModal={(open: boolean) => setOpenModal(open ? 'edit' : '')}
          templateType={templateType}
        />
      )}

      {openModal === 'delete' && (
        <DeleteModal
          visible={openModal === 'delete'}
          params={{ eduAttachNo: dataInfo.eduAttachNo }}
          modalData={deleteData}
          dispatchFunc={deleteEduTemplate}
          toggleModal={(open: boolean) => setOpenModal(open ? 'delete' : '')}
        />
      )}
    </div>
  );
}

export default EduTemplateSettings;
