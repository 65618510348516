import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { postLogin, saveForm } from 'store/login';
import { setUser } from 'store/user';
import { Checkbox } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { nowEpoch } from 'utils/commonFunctions';
import { changeOptions, Option } from 'utils/commonValues';

// import lockImage from 'img/login/pass_off.png';
// import unlockImage from 'img/login/pass_on.png';

function Basic(props: any) {
  const { register, handleSubmit, clearErrors, errors } = useForm();
  const loginData = useSelector((state: any) => state.login);
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const saveCheck: any = localStorage.getItem('saveCheck');
  const [emailCheck, setEmailCheck] = useState(JSON.parse(saveCheck) || '');
  const { formatMessage } = useIntl();
  const [lock, setLock] = useState(true);
  const [password, setPassword] = useState('');

  if (loginData?.userData?.isAdmin === 0) {
    // 이미 로그인한 상태일 때 화면
    return <Redirect to="/login/already" />;
  }

  // 로그인 이벤트
  const onLogin = async (formData: any) => {
    setLoading(true);

    if (emailCheck) {
      localStorage.setItem('saveCheck', 'true');
      localStorage.setItem('saveEmail', formData.userEmail);
    } else {
      localStorage.setItem('saveCheck', 'false');
      localStorage.setItem('saveEmail', '');
    }

    // 공지사항 팝업 띄우기 가능하도록 처리
    localStorage.setItem('notice', 'true');

    const params = {
      userEmail: Buffer.from(formData.userEmail.toLowerCase()).toString('base64'),
      userPasswd: Buffer.from(password).toString('base64'),
    };

    // 로그인 횟수 저장
    const loginCnt: any = localStorage.getItem('loginCnt');
    if (Number.isNaN(loginCnt)) {
      localStorage.setItem('loginCnt', '1');
    } else {
      localStorage.setItem('loginCnt', String(Number(loginCnt) + 1));
    }

    try {
      await dispatch(saveForm(formData));
      const response: { [key: string]: any; } = await dispatch(postLogin(params));
      if (response && response.data && response.data.list) {
        const {
          loginResult,
          loginMessage,
          serviceNo,
          sessionTimeout,
          userEmail,
          isSaas,
          isGs,
          isJapan,
          isDeclare,
          isTagTree,
          isReport,
          customer,
          serviceName,
          userData,
        } = response.data.list;

        if (loginResult === 1) {
          // config 옵션 설정
          changeOptions({
            isSaas: isSaas,
            isGs: isGs,
            isJapan: isJapan,
            isEdu: userData?.isEdu ? 1 : 0,
            isDeclare: isDeclare,
            isTagTree: isTagTree,
            isReport: isReport,
            customer: customer,
            serviceName: serviceName,
          });

          // 로그인 성공
          userData.serviceNo = serviceNo;
          await dispatch(setUser({ user: userData, sessionTimeout: sessionTimeout }));

          localStorage.setItem('loginUser', userData.userEmail);
          sessionStorage.setItem('accessTime', nowEpoch());

          // 로그인 횟수 초기화
          localStorage.setItem('loginCnt', '0');

          // 언어 설정
          if (localStorage.getItem('language') !== userData.lang) {
            window.location.reload();
          }
          props.history.push('/');
        } else if (loginResult === 2) {
          // 서버 에러
          setErrorMessage(loginMessage);
        } else if (loginResult === 4) {
          // ID / PASSWD 불일치
          setErrorMessage(
            `${formatMessage({
              id: 'Login_16',
              defaultMessage: '이메일/비밀번호가 일치하지 않습니다.',
            })}`,
          );
        } else if (loginResult === 8) {
          // 비밀번호 5회 이상 실패
          const failCount: number = loginMessage.count;
          const remainTime: number = 300 - (nowEpoch().toFixed() - loginMessage.remainTime);
          const minutes = parseInt(String(remainTime / 60), 10);
          const seconds = parseInt(String(remainTime % 60), 10);
          const errorMessage = `${formatMessage({
            id: 'Login_17',
            defaultMessage: '비밀번호 {count}회 이상 실패',
          }, { count: failCount },)
            } (${formatMessage({
            id: 'Time_5',
            defaultMessage: '남은 시간',
          })}: ${minutes}${formatMessage({
            id: 'Time_2',
            defaultMessage: '분',
          })} ${seconds}${formatMessage({ id: 'Time_3', defaultMessage: '초' })})`;
          setErrorMessage(errorMessage);
        } else if (loginResult === 16) {
          // 비밀번호 찾기
          props.history.push('/login/pwreset');
        } else if (loginResult === 32) {
          // config 옵션 설정
          changeOptions({
            isSaas: isSaas,
            isGs: isGs,
            isJapan: isJapan,
            isEdu: userData?.isEdu ? 1 : 0,
            isDeclare: isDeclare,
            isTagTree: isTagTree,
            isReport: isReport,
            customer: customer,
            serviceName: serviceName,
          });

          userData.serviceNo = serviceNo;

          // 비활성화
          localStorage.removeItem('loginAdmin');
          localStorage.setItem('loginUser', userData.userEmail);
          sessionStorage.setItem('accessTime', nowEpoch());
          await dispatch(setUser({ user: userData, sessionTimeout: sessionTimeout }));

          props.history.push('/login/active');
        } else if (loginResult === 64) {
          // 비활성화 인증 기간 만료
          props.history.push('/login/active');
        } else if (loginResult === 128) {
          // 장기 미접속 잠금 계정
          props.history.push('/login/lock');
        } else if (loginResult === 512) {
          // "허용되지 않은 사용자 IP 접근"
          setErrorMessage(
            `${formatMessage({
              id: 'Login_18',
              defaultMessage: '허용되지 않은 사용자 IP 접근',
            })}`,
          );
        } else if (loginResult === 256) {
          changeOptions({
            isSaas: isSaas,
            isGs: isGs,
            isJapan: isJapan,
            isEdu: userData?.isEdu ? 1 : 0,
            isDeclare: isDeclare,
            isTagTree: isTagTree,
            isReport: isReport,
            customer: customer,
            serviceName: serviceName,
          });
          await dispatch(setUser({ user: userData, sessionTimeout: sessionTimeout }));
          // 최초 로그인 시 비밀번호 변경 화면
          props.history.push('/login/first');
        } else if (loginResult === 1024) {
          // OTP 인증 화면
          localStorage.setItem('otpUser', userEmail);
          // OTP 인증 가능한 시간 저장
          if (typeof loginMessage === 'number' && loginMessage > 0) {
            localStorage.setItem('otpTime', loginMessage.toString());
          }
          props.history.push('/login/otp');
        } else {
          setErrorMessage(loginMessage);
        }
      } else {
        setErrorMessage(loginData?.loginMessage);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('onLogin', error);
    }
  };

  // 폼 입력 이벤트
  const onChangeForm = () => {
    setErrorMessage('');
    clearErrors();
  };
  // 잠김해제 + JJS 일 경우 * 값으로 보여준다
  const displayPassword = () => {
    if (!lock && Option.isJapan === 1) {
      return password.length > 0 ? 
        '●'.repeat(password.length - 1) + password[password.length - 1] : '';
    }
    return password;
  }
  return (
    <div className="login-wrapper">
      <div className="basic-login">
        <div className="login-info login-item">
          <div className="login-logo-box">
            <img className="login-info-logo" src="/img/logo/login_logo.png" alt="logo" />
          </div>
          <div className="login-info-text">
            {Option.customer !== 'saxa' && (
              <div className={`login-info-main-text ${localStorage.getItem('language')}`}>
                {`"${
                  Option.customer === 'hyundai'
                    ? formatMessage({
                        id: 'Hyundai_1',
                        defaultMessage: '현대차 그룹 피싱 모의훈련 플랫폼',
                      })
                    : formatMessage({ id: 'Login_5', defaultMessage: '보안의 시작과 끝은 사람' })
                }"`}
              </div>
            )}
            <div className="login-info-sub-text saxa">
              <div>
                {formatMessage({ id: 'Login_6', defaultMessage: '악성메일 모의 테스트로' })}
              </div>
              <div>
                {formatMessage({ id: 'Login_7', defaultMessage: '보안 인식 수준을 점검하세요.' })}
              </div>
            </div>
          </div>
        </div>

        <div className="login-item input-box">
          {/* 입력 폼 */}
          <form
            className="login-form"
            onSubmit={handleSubmit(onLogin)}
            onChange={onChangeForm}
            autoComplete="off"
          >
            {/* 이메일 */}
            <div>
              <input
                name="userEmail"
                className="underline-input"
                placeholder={formatMessage({ id: 'Email_1', defaultMessage: '이메일' })}
                defaultValue={localStorage.getItem('saveEmail') || ''}
                ref={register({
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
                    message: formatMessage({
                      id: 'Vali_9',
                      defaultMessage: '올바르지 않은 이메일 양식입니다.',
                    }),
                  },
                })}
              />
              {/* 비밀번호 */}
              <div className="password-area">
                <input
                  name="userPasswd"
                  className="underline-input"
                  type={lock ? 'password' : 'text'}
                  value={displayPassword()}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder={formatMessage({ id: 'Password_1', defaultMessage: '비밀번호' })}
                  ref={register({ required: true })}
                />
                <div className="lock-icon" onClick={() => setLock(!lock)} aria-hidden="true">
                  {lock ? (
                    <img src="/img/login/pass_off.png" alt="" />
                  ) : (
                    <img src="/img/login/pass_on.png" alt="" />
                  )}
                </div>
              </div>
              {/* 이메일 저장 */}
              <div className="email-checkbox">
                <Checkbox onChange={(e) => setEmailCheck(e.target.checked)} checked={emailCheck}>
                  {formatMessage({ id: 'Email_3', defaultMessage: '이메일 저장' })}
                </Checkbox>
              </div>
              {/* 에러 메시지 */}
              <div className="error-message">{errorMessage || errors?.userEmail?.message}</div>
            </div>

            <button
              className="login-btn"
              type="submit"
              style={{ background: 'url(/img/login/login_button.png) no-repeat center' }}
            >
              {formatMessage({ id: 'Login_1', defaultMessage: '로그인' })}
              {loading && (
                <span className="button-loading">
                  <LoadingOutlined />
                </span>
              )}
            </button>

            <div className="service-box">
              {!!Option.isSaas && Option.customer !== 'hyundai' && (
                <div className="service-item">
                  <Link className="service-link" to="/login/register">
                    {formatMessage({ id: 'Login_2', defaultMessage: '서비스 가입' })}
                  </Link>
                </div>
              )}
              <div className="service-item">
                <Link className="service-link" to="/login/pwreset">
                  {formatMessage({ id: 'Password_5', defaultMessage: '비밀번호 찾기' })}
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Basic);
