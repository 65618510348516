import { Popover, Tooltip } from 'antd';
import { WarningFilled } from '@ant-design/icons'
import TableList from 'components/common/TableList';
import TablePagination from 'components/common/TablePagenation';
import TableSearch from 'components/common/TableSearch';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual } from 'react-intl/src/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getLeakFile, leakFileChangeParam } from 'store/exam';
import { humanFileSize } from 'utils/commonFunctions';


export default function LeakFileList(props: any) {
  let tableRowCount = Math.trunc((window.innerHeight - 315) / 48);
  tableRowCount = tableRowCount < 5 ? 5 : tableRowCount;

  const { formatMessage } = useIntl();
  const [loading, setLoding] = useState(false)
  const [pageSize, setPageSize] = useState(tableRowCount)
  const [currentPage, setCurrentPage] = useState(0)
  const dispatch = useDispatch();
  const { sort, filter } = useSelector((state: any) => ({
    sort: state.exam.leakFile.param.sort,
    filter: state.exam.leakFile.param.filter
  }), shallowEqual)
  const needReload = useSelector((state: any) => state.exam.leakFile.needReload)
  const respData = useSelector((state: any) => state.exam.leakFile.data)
  const totalCount = useSelector((state: any) => state.exam.leakFile.totalCount)
  const totalPages = useSelector((state: any) => state.exam.leakFile.totalPages)
  const isAdmin = useSelector((state: any) => state.login.userData.isAdmin)

  useEffect(() => {
    getLeakFileData();
  }, [sort, filter, pageSize, currentPage, needReload])

  // API 호출
  const getLeakFileData = async () => {
    if (!loading) {
      setLoding(true);

      try {
        const params = {
          filter: JSON.stringify(filter),
          sort: JSON.stringify(sort),
          offset: pageSize * (currentPage < 0 ? 0 : currentPage), // page 는 0 번 부터 시작
          limit: pageSize < 1 ? 20 : pageSize
        }

        await dispatch(getLeakFile(params))
      } catch (e) {
        console.log(e);
      }
    }

    setLoding(false);
  }

  // 검색 
  const searchOption = {
    searchAll: formatMessage({ id: 'Faq_1', defaultMessage: '전체' }),
    leakFileNo: "No",
    fileName: formatMessage({ id: 'Attach_27', defaultMessage: '파일명' }),
    targetName: formatMessage({ id: 'Name_4', defaultMessage: '대상자' }),
    targetEmail: formatMessage({ id: 'Email_10', defaultMessage: '대상자 이메일' }),
    hostname: "PC",
    examName: formatMessage({ id: 'Exam_1', defaultMessage: '훈련명' }),
  }

  // 필터
  const filterOption: { [key: string]: any } = {
    leakTypeArray: {
      name: formatMessage({id: 'Template_22', defaultMessage: '구분'}),
      child: [
        {
          label: formatMessage({id: 'Infection_30', defaultMessage: 'PC파일'}),
          value: "1"
        },
        {
          label: formatMessage({id: 'Infection_31', defaultMessage: '인증서'}),
          value: "2"
        },
        {
          label: formatMessage({id: 'Email_1', defaultMessage: '이메일'}),
          value: "3"
        }
      ]
    }
  }
  // 테이블 
  const baseColumns = [
    {
      Header: "No",
      accessor: 'leakFileNo',
      resizable: false,
      sortable: true,
      width: 72,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center number-text'
    },
    {
      Header: formatMessage({id: 'Exam_66', defaultMessage: '파일 구분'}),
      accessor: 'leakType',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center',
      Cell: (props: {[key: string]: any}) => {
        let printValue = '-';
        if (props.value === 1) {
          printValue = formatMessage({ id: 'Infection_30', defaultMessage: 'PC파일' });
        } else if (props.value === 2) {
          printValue = formatMessage({ id: 'Infection_31', defaultMessage: '인증서' });
        } else if (props.value === 3) {
          printValue = formatMessage({ id: 'Email_1', defaultMessage: '이메일' });
        } else {
          // 나머지
        }
        return printValue;
      }
    },
    {
      Header: formatMessage({ id: 'Attach_27', defaultMessage: '파일명' }),
      accessor: 'fileName',
      resizable: false,
      sortable: true,
      width: 261,
      headerClassName: 'react-table-th',
      className: 'react-table-td',
      Cell: function fileName (props: {[key: string]: any}) {
      //   let certObj: JSX.Element = <div/>;
      //   // 인증서 상세 정보
      //   if (props.row.original.leakType === 2 && typeof props.row.original.extraInfo.cert !== 'undefined') {
      //     const certData = props.row.original.extraInfo.cert;

      //     certObj = (
      //       <Popover
      //         trigger="click"
      //         content={
      //           <div className=
      //           'certDetail-popup'>
      //             <div className=
      //             'certDetail-title'>
      //               {formatMessage({ id: 'ExamLeakFileList_20', defaultMessage: '인증서 정보' })}
      //             </div>
      //             <div className=
      //             'certDetail-content'>{certData.subject.split(', ')[0].split('=')[1]}</div>
      //             <div className=
      //             'certDetail-content'>{certData.subject.split(', ')[1].split('=')[1]}</div>
      //             <div className=
      //             'certDetail-content'>{certData.subject.split(', ')[3].split('=')[1]}</div>
      //             <div className=
      //             'certDetail-content'>
      //               {`${timeFormatFromUTCEpoch(certData.startEpoch, 3)} ~ ${timeFormatFromUTCEpoch(certData.endEpoch, 3)}`}
      //             </div>
      //           </div>
      //         }
      //       >
      //         <Tooltip
      //             title={formatMessage({ id: 'ExamLeakFileList_19', defaultMessage: '인증서 상세 정보' })
      //             }
      //             placement="right">
      //             <WarningFilled />
      //         </Tooltip>
      //       </Popover>
      //     );
      //   }

        return (
          <Tooltip title={props.row.original.fullPath} placement="bottomLeft">
            <div className=
            'cell-fileName-area'>
              <div className={`file-ext-icon ext-${props.row.original.fileExt}`} />
              <div className=
              'fileName-text'>
                {/* {certObj} */}
                {props.row.original.fileName}
              </div>
            </div>
          </Tooltip>
        );
      }
    },
    {
      Header: formatMessage({ id: 'Infection_42', defaultMessage: '크기' }),
      accessor: 'fileSize',
      resizable: false,
      sortable: true,
      width: 82,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-right',
      Cell: (props: {[key: string]: any}) => {
        return humanFileSize(props.value);
      }
    },
    {
      Header: formatMessage({ id: 'Name_4', defaultMessage: '대상자' }),
      accessor: 'targetName',
      resizable: false,
      sortable: true,
      width: 106,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
    },
    {
      Header: formatMessage({ id: 'Email_10', defaultMessage: '대상자 이메일' }),
      accessor: 'targetEmail',
      resizable: false,
      sortable: true,
      width: 199,
      headerClassName: 'react-table-th',
      className: 'react-table-td'
    },
    {
      Header: "PC",
      accessor: 'hostname',
      sortable: true,
      width: 143,
      headerClassName: 'react-table-th',
      className: 'react-table-td'
    },
    {
      Header: formatMessage({ id: 'Exam_1', defaultMessage: '훈련명' }),
      accessor: 'examName',
      resizable: false,
      sortable: true,
      width: 193,
      headerClassName: 'react-table-th',
      className: 'react-table-td sub-text'
    },
    {
      Header: formatMessage({ id: 'Date_4', defaultMessage: '감염 일시' }),
      accessor: 'regEpoch',
      resizable: false,
      sortable: true,
      width: 177,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center',
      Cell: function regEpoch(props: { [key: string]: any }) {
        const { value } = props;
        const dateFormat = localStorage.getItem('language') === 'en' ? 'MM/DD/YYYY' : 'YYYY-MM-DD';
        return (
          <div>{moment.unix(value).format(`${dateFormat} HH:mm:ss`)}</div>
        );
      }
    },
  ]

  const columns: Array<any> = useMemo(() => baseColumns, [respData])

  return (
    <>
      <TableSearch
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalCount={totalCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        changeParam={leakFileChangeParam}
        searchOption={searchOption}
        filter={filter}
        filterOption={filterOption}
      />
      <TableList
        columns={columns}
        data={respData.success}
        changeParam={leakFileChangeParam}
        loading={loading}
      />
      <TablePagination
        totalPages={totalPages}
        currentPage={currentPage + 1}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}
