import { LoadingOutlined } from '@ant-design/icons';
import { List } from 'antd';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { postSyncTest } from 'store/admin';
import ModalTemplate from '../ModalTemplate';
import './SyncTest.scss';

export default function SyncTest(props: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const targetSyncResult = useSelector((state: any) => state.admin.syncResult);

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const syncQueryTest = async () => {
    setLoading(true);

    const { parameter } = props;
    const params = { queryType: parameter === 'userQuery' ? 1 : 2 };

    try {
      await dispatch(postSyncTest(params));
      setLoading(false);
      setShowModal(true);
    } catch (e: any) {
      console.log(e.message);
    }
  };

  return (
    <>
      {showModal && (
        <ModalTemplate
          visible
          className="modal-665"
          onCancel={() => setShowModal(false)}
          loading={loading}
          title={formatMessage({ id: 'Config_10', defaultMessage: '연결 테스트' })}
          cancelText={formatMessage({ id: 'Button_4', defaultMessage: '확 인' })}
        >
          <div className="modal-text">
            {targetSyncResult.success.length > 0 ? (
              <div className="template-detail-box">
                <div className="template-detail">
                  <List
                    size="small"
                    dataSource={targetSyncResult.success}
                    renderItem={(item) => <List.Item>{JSON.stringify(item)}</List.Item>}
                  />
                </div>
              </div>
            ) : (
              <div>{targetSyncResult.fail}</div>
            )}
          </div>
        </ModalTemplate>
      )}
      <button className="sync-test-btn" disabled={loading} onClick={syncQueryTest} type="button">
        {formatMessage({
          id: 'Config_10',
          defaultMessage: '연결 테스트',
        })}
        {loading && (
          <span className="button-loading">
            <LoadingOutlined />
          </span>
        )}
      </button>
    </>
  );
}
