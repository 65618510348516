import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip, Radio } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import { fitTwoDigit } from 'utils/commonFunctions';
import FormDatePicker from 'components/common/FormDatePicker';
import FormSelectField from 'components/common/FormSelectField';
import './TableDatePicker.scss';

// import calImage from 'img/table/ico_cal.png';
// import calActiveImage from 'img/table/ico_cal_active.png';

interface TableDateProps {
  dateType: string;
  title: string;
  filter: any;
  actionParam: any;
}

function TableDatePicker({ dateType, title, filter, actionParam }: TableDateProps) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { control, errors, setValue, watch } = useForm({
    mode: 'all',
    defaultValues: {
      recentDate: null,
      startDate: null,
      endDate: null,
      startHour: 0,
      startMinute: 0,
      endHour: 23,
      endMinute: 59,
    },
  });
  const watchValues = watch();
  const [openDatePicker, setOpenDatePicker] = useState('');
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const isFiltered =
    filter && (filter[`start${dateType}`] || filter[`end${dateType}`]) ? true : false;

  // 기간 필터(라디오 버튼) 선택 시
  const changeSelectFilter = (name: string, value: any) => {
    setValue('recentDate', value);
    setValue('startDate', null);
    setValue('endDate', null);
  };

  // 시작일자, 종료일자 선택 시
  const handleOnChangeDate = (type: string, current: any, time?: any) => {
    if (!current) return;
    setValue('recentDate', null);

    const timeValue = time ? { ...watchValues, ...time } : watchValues;

    if (type === 'startDate') {
      const start = moment(current)
        .hour(timeValue.startHour || 0)
        .minute(timeValue.startMinute || 0)
        .second(0);

      setValue('startDate', start);

      // 시작일이 종료일과 같거나 클 경우
      if (moment(current).endOf('day') >= moment(timeValue.endDate)) {
        setValue('endDate', null);
      }
    } else if (type === 'endDate') {
      const end = moment(current)
        .hour(timeValue.endHour || 23)
        .minute(timeValue.endMinute || 59)
        .second(59);

      setValue('endDate', end);
    }
  };

  // 시작일자, 종료일자 선택 불가 날짜 지정
  const handleDisableDate = (type: any, current: any) => {
    let isDisable = false;
    if (type === 'endDate') {
      isDisable =
        // 시작일자보다 작을 때
        current < moment(watchValues.startDate) || current > moment('2037-12-31').add(1, 'd');
    }
    return isDisable;
  };

  // 시간 선택 메뉴
  const hourList: Array<any> = [];
  for (let i = 0; i < 24; i += 1) {
    hourList.push({
      key: i,
      value: i,
      text: fitTwoDigit(i),
    });
  }

  // 분 선택 메뉴
  const minuteList: Array<any> = [];
  for (let i = 0; i < 60; i += 1) {
    minuteList.push({
      key: i,
      value: i,
      text: fitTwoDigit(i),
    });
  }

  // 시작 시간, 종료 시간 선택 시
  const changeTime = (type: string, name: any, data: any) => {
    setValue(name, data);
    // 날짜 재설정
    handleOnChangeDate(type, watchValues.startDate, { [name]: data });
  };

  // 달력 팝업 열기/닫기 제어
  const onOpenChange = (type: string, open: boolean) => {
    if (open) {
      setOpenDatePicker(type);
    } else {
      setOpenDatePicker('');
    }
  };

  // 필터 검색 수행
  const onSearchFilter = async () => {
    try {
      const { recentDate, startDate, endDate }: any = watchValues;
      const params: any = {};
      if (recentDate) {
        // 기간 필터 선택 시
        if (recentDate === 'recentWeek') {
          params[`start${dateType}`] = moment().subtract(1, 'weeks').unix().toString();
        } else if (recentDate === 'recentMonth') {
          params[`start${dateType}`] = moment().subtract(1, 'months').unix().toString();
        } else if (recentDate === 'recentYear') {
          params[`start${dateType}`] = moment().subtract(1, 'years').unix().toString();
        }
      } else {
        // 기간 지정 시
        if (startDate) {
          params[`start${dateType}`] = startDate.unix().toString();
        }
        if (endDate) {
          params[`end${dateType}`] = endDate.unix().toString();
        }
      }

      await dispatch(actionParam({ name: 'filter', value: { ...filter, ...params } }));
      setTooltipVisible(false);
    } catch (error) {
      console.log('TableDatePicker onSearchFilter', error);
    }
  };

  return (
    <div className="table-date-picker">
      <div>{title}</div>
      <Tooltip
        overlayClassName="table-date-picker-tooltip full"
        placement="bottom"
        trigger="click"
        visible={tooltipVisible}
        onVisibleChange={(visible: boolean) => setTooltipVisible(visible)}
        title={
          <div onClick={(e: any) => e.stopPropagation()} aria-hidden="true">
            <div className="table-date-picker-item">
              <div className="filter-title">
                {formatMessage({ id: 'Filter_2', defaultMessage: '기간 필터' })}
              </div>
              <Controller
                control={control}
                name="recentDate"
                render={({ onChange }) => (
                  <Radio.Group
                    value={watchValues.recentDate}
                    buttonStyle="outline"
                    onChange={(e: any) => {
                      changeSelectFilter('recentDate', e.target.value);
                    }}
                  >
                    <Radio value="recentWeek">
                      {formatMessage({ id: 'Filter_17', defaultMessage: '최근 일주일' })}
                    </Radio>
                    <Radio value="recentMonth">
                      {formatMessage({ id: 'Filter_18', defaultMessage: '최근 한달' })}
                    </Radio>
                    <Radio value="recentYear">
                      {formatMessage({ id: 'Filter_19', defaultMessage: '최근 일년' })}
                    </Radio>
                  </Radio.Group>
                )}
              />
            </div>
            <div className="table-date-picker-item">
              <div className="filter-title">
                {formatMessage({ id: 'Filter_20', defaultMessage: '기간 지정' })}
              </div>
              <div onClick={(e: any) => setOpenDatePicker('startDate')} aria-hidden="true">
                <FormDatePicker
                  control={control}
                  name="startDate"
                  value={watchValues.startDate}
                  error={errors.startDate}
                  handleOnChange={handleOnChangeDate}
                  handleDisableDate={handleDisableDate}
                  dropdownClassName="table-dropdown"
                  open={openDatePicker === 'startDate'}
                  onOpenChange={(open: boolean) => onOpenChange('startDate', open)}
                  format="YYYY-MM-DD HH:mm:ss"
                  renderExtraFooter={() => (
                    <div className="date-picker-footer" aria-hidden="true">
                      <div>{formatMessage({ id: 'Time_6', defaultMessage: '시작 시간' })}: </div>
                      <FormSelectField
                        name="startHour"
                        control={control}
                        error={errors.startHour}
                        menuList={hourList}
                        handleOnChange={(name: string, data: any) =>
                          changeTime('startDate', name, data)
                        }
                        defaultValue={0}
                        showArrow
                      />
                      <FormSelectField
                        name="startMinute"
                        control={control}
                        error={errors.startMinute}
                        menuList={minuteList}
                        handleOnChange={(name: string, data: any) =>
                          changeTime('startDate', name, data)
                        }
                        defaultValue={0}
                        showArrow
                      />
                    </div>
                  )}
                />
              </div>
              <div onClick={(e: any) => setOpenDatePicker('endDate')} aria-hidden="true">
                <FormDatePicker
                  control={control}
                  name="endDate"
                  value={watchValues.endDate}
                  error={errors.endDate}
                  handleOnChange={handleOnChangeDate}
                  handleDisableDate={handleDisableDate}
                  dropdownClassName="table-dropdown"
                  open={openDatePicker === 'endDate'}
                  onOpenChange={(open: boolean) => onOpenChange('endDate', open)}
                  format="YYYY-MM-DD HH:mm:ss"
                  renderExtraFooter={() => (
                    <div className="date-picker-footer" aria-hidden="true">
                      <div>{formatMessage({ id: 'Time_7', defaultMessage: '종료 시간' })}: </div>
                      <FormSelectField
                        name="endHour"
                        control={control}
                        error={errors.endHour}
                        menuList={hourList}
                        handleOnChange={(name: string, data: any) =>
                          changeTime('endDate', name, data)
                        }
                        defaultValue={23}
                        showArrow
                      />
                      <FormSelectField
                        name="endMinute"
                        control={control}
                        error={errors.endMinute}
                        menuList={minuteList}
                        handleOnChange={(name: string, data: any) =>
                          changeTime('endDate', name, data)
                        }
                        defaultValue={59}
                        showArrow
                      />
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="table-date-picker-item">
              <button type="button" className="table-search-btn" onClick={onSearchFilter}>
                <SearchOutlined />
                {formatMessage({ id: 'Filter_8', defaultMessage: '검색' })}
              </button>
            </div>
          </div>
        }
      >
        <img
          src={isFiltered ? '/img/table/ico_cal_active.png' : '/img/table/ico_cal.png'}
          alt="icon"
          onClick={(e: any) => e.stopPropagation()}
          aria-hidden="true"
        />
      </Tooltip>
    </div>
  );
}

export default TableDatePicker;
