import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import parse from 'html-react-parser';
import ModalTemplate from 'components/common/ModalTemplate';
import { cancelShareUser, cancelShareService } from 'store/share';
import { useDispatch } from 'react-redux';

export default function ShareCancel(props: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { shareInfo, mode } = props;
  const {
    userServiceNo,
    userNo,
    userName,
    userEmail,
    serviceNo,
    serviceName,
    serviceUserName,
    serviceUserEmail,
    regEpoch,
  } = shareInfo;
  const [isModal, setIsModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const cancel = async () => {
    if (!loading) {
      setLoading(true);
      const params: { [key: string]: any } = {};
      let isOk = true;

      if (userServiceNo) {
        params.userServiceNo = userServiceNo;
      } else {
        // 오류
        isOk = false;
      }

      if (isOk) {
        try {
          if (mode === 'user') {
            await dispatch(cancelShareUser(params));
          } else {
            await dispatch(cancelShareService(params));
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        console.log('cancelShareUser isOk fail!');
      }

      setLoading(false);
      setIsModal(false);
    }
  };
  return (
    <>
      <button type="button" onClick={() => setIsModal(true)}>
        {formatMessage({ id: 'Button_25', defaultMessage: '제 거' })}
      </button>
      {isModal && (
        <ModalTemplate
          className="modal-464"
          visible={isModal}
          greyButton
          onOk={cancel}
          onCancel={() => setIsModal(false)}
          centered
          loading={loading}
          title={
            mode === 'user'
              ? formatMessage({ id: 'ServiceShare_20', defaultMessage: '공유 사용자 취소' })
              : formatMessage({ id: 'ServiceShare_17', defaultMessage: '공유 받은 서비스 제거' })
          }
          okText={
            mode === 'user'
              ? formatMessage({ id: 'ServiceShare_28', defaultMessage: '공유 취소' })
              : formatMessage({ id: 'ServiceShare_30', defaultMessage: '서비스 제거' })
          }
          cancelText={formatMessage({ id: 'Button_5', defaultMessage: '닫 기' })}
        >
          <div className="modal-explain-text">
            {mode === 'user'
              ? parse(
                  formatMessage({
                    id: 'ServiceShare_21',
                    defaultMessage:
                      '공유한 사용자를 취소합니다.<br/>해당 사용자는 서비스에 접근할 수 없게 됩니다.<br/>정말로 다음 공유 사용자를 취소합니까?',
                  }),
                )
              : parse(
                  formatMessage({
                    id: 'ServiceShare_18',
                    defaultMessage:
                      '공유 받은 서비스를 제거합니다.<br/>해당 서비스 가입자가 다시 공유하기 전까지는<br/>접근할 수 없게 됩니다.<br/>정말로 공유 받은 다음 서비스를 제거합니까?',
                  }),
                )}
          </div>
          {mode === 'user' ? (
            <div className="modal-border-box">
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({ id: 'User_1', defaultMessage: '사용자' })}No
                  </div>
                </li>
                <div>{userNo}</div>
              </ul>
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({ id: 'Name_11', defaultMessage: '사용자명' })}
                  </div>
                </li>
                <div>{userName}</div>
              </ul>
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({ id: 'Email_1', defaultMessage: '이메일' })}
                  </div>
                </li>
                <div>{userEmail}</div>
              </ul>
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({ id: 'ServiceShare_29', defaultMessage: '공유추가' })}
                  </div>
                </li>
                <div>{timeFormatFromUTCEpoch(regEpoch)}</div>
              </ul>
            </div>
          ) : (
            <div className="modal-border-box">
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({ id: 'Login_9', defaultMessage: '서비스' })}No
                  </div>
                </li>
                <div>{serviceNo}</div>
              </ul>
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({ id: 'Service_1', defaultMessage: '서비스명' })}
                  </div>
                </li>
                <div>{serviceName}</div>
              </ul>
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({ id: 'ServiceShare_24', defaultMessage: '가입자' })}
                  </div>
                </li>
                <div>{serviceUserName}</div>
              </ul>
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({ id: 'Email_1', defaultMessage: '이메일' })}
                  </div>
                </li>
                <div>{serviceUserEmail}</div>
              </ul>
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({ id: 'ServiceShare_19', defaultMessage: '공유시작' })}
                  </div>
                </li>
                <div>{timeFormatFromUTCEpoch(regEpoch)}</div>
              </ul>
            </div>
          )}
        </ModalTemplate>
      )}
    </>
  );
}
