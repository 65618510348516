import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { getDashboardTotal } from 'store/dashboard';
import PieChartBase from 'components/common/PieChartBase';

function DashboardTotal() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [totalData, setTotalData]: any = useState({
    progress: 0,
    leakage: 0,
    target: 0,
    send: 0,
    read: 0,
    download: 0,
    connect: 0,
    infection: 0,
    phishingTarget: 0,
    fileTarget: 0,
  });

  useEffect(() => {
    onGetDashboardTotal();
  }, []);

  const onGetDashboardTotal = async () => {
    try {
      const response: any = await dispatch(getDashboardTotal());
      if (response.data?.list[0]) {
        setTotalData(response.data?.list[0]);
      }
    } catch (error) {
      console.log('Dashboard onGetDashboardTotal', error);
    }
  };

  // 통합점수별 데이터
  const getStatusData = () => {
    const statusText = [
      {
        text: formatMessage({ id: 'Exam_86', defaultMessage: '안전' }),
        color: '#04CA53',
        rangeStart: 0,
        level: 1
      },
      {
        text: formatMessage({ id: 'Exam_87', defaultMessage: '주의' }),
        color: '#FFCF23',
        rangeStart: 30,
        level: 2
      },
      {
        text: formatMessage({ id: 'Exam_88', defaultMessage: '경계' }),
        color: '#FF7223',
        rangeStart: 60,
        level: 3
      },
      {
        text: formatMessage({ id: 'Exam_89', defaultMessage: '심각' }),
        color: '#CE0808',
        rangeStart: 80,
        level: 4
      }
    ];

    for (let i = statusText.length - 1; i >= 0; i -= 1) {
      if (totalData.progress >= statusText[i].rangeStart) {
        return [{
          name: statusText[i].text,
          color: statusText[i].color,
          level: statusText[i].level
        }];
      }
    }

    return [{
      name: statusText[0].text,
      color: statusText[0].color,
      level: statusText[0].level
    }];
  };

  // 통합점수
  const TotalScoreGraph = useMemo(() => {
    const statusData: Array<any> = getStatusData();
    if (statusData.length < 1) return <></>;

    return (
      <>
        <div className="group-point-text" style={{ color: statusData[0].color }}>
          {statusData[0].name}
        </div>
        <div className='total-score'>
          <img className='total-score-img' src="/img/dashboard/total_score.png" alt="score" />
          <div className='total-level'>
            <div className='level-point' style={{ color: statusData[0].color }}>
              {statusData[0].level}
            </div>
            <div>Level</div>
          </div>
        </div>
      </>
    );
  }, [totalData]);

  // 발송률, 열람률, 접속률 등
  const examResultData = [
    {
      key: 'send',
      value: 'send',
      title: formatMessage({ id: 'Send_17', defaultMessage: '발송률' }),
      subText: [totalData.send, `${formatMessage({ id: 'Target_2', defaultMessage: '대상' })} ${totalData.target}`],
      color: { start: '#04CA53', end: '#04CA53' }
    },
    {
      key: 'read',
      value: 'read',
      title: formatMessage({ id: 'Read_4', defaultMessage: '열람률' }),
      subText: [totalData.read, `${formatMessage({ id: 'Target_2', defaultMessage: '대상' })} ${totalData.target}`],
      color: { start: '#FFCF23', end: '#FFCF23' }
    },
    {
      key: 'access',
      value: 'connect',
      title: formatMessage({ id: 'Download_13', defaultMessage: '접속률' }),
      subText: [totalData.connect, `${formatMessage({ id: 'Download_8', defaultMessage: '피싱 접속' })} ${totalData.phishingTarget}`],
      color: { start: '#FF7223', end: '#FF7223' }
    },
    {
      key: 'download',
      value: 'download',
      title: formatMessage({ id: 'Download_14', defaultMessage: '다운로드률' }),
      subText: [totalData.download, `${formatMessage({ id: 'Exam_81', defaultMessage: '파일 다운' })} ${totalData.fileTarget}`],
      color: { start: '#CE0808', end: '#CE0808' }
    },
    {
      key: 'leakage',
      value: 'leakage',
      title: formatMessage({ id: 'Template_20', defaultMessage: '유출률' }),
      subText: [totalData.leakage, `${formatMessage({ id: 'Download_8', defaultMessage: '피싱 접속' })} ${totalData.phishingTarget}`],
      color: { start: '#CE0808', end: '#CE0808' }
    },
    {
      key: 'infection',
      value: 'infection',
      title: formatMessage({ id: 'Template_19', defaultMessage: '감염률' }),
      subText: [totalData.infection, `${formatMessage({ id: 'Exam_81', defaultMessage: '파일 다운' })} ${totalData.fileTarget}`],
      color: { start: '#CE0808', end: '#CE0808' }
    }
  ];

  const unit = formatMessage({ id: 'StartExam_35', defaultMessage: '명' });

  return (
    <div className='dash-flex'>
      {/* 통합점수 */}
      <div className='result-group'>
        <div className='result-group-title'>
          {formatMessage({ id: 'Exam_85', defaultMessage: '통합점수' })}
        </div>
        {TotalScoreGraph}
        <div className='group-sub-text'>
          <span className='color-red'>{`${totalData.progress}`}</span>
          <span>{`${formatMessage({ id: 'Edu_26', defaultMessage: '점' })} / 100${formatMessage({ id: 'Edu_26', defaultMessage: '점' })}`}</span>
        </div>
      </div>

      {/* 발송, 열람, 접속 등 */}
      {examResultData.map((item: any) => {
        return (
          <div className='result-group' key={item.key}>
            <div className='result-group-title'>
              {item.title}
            </div>
            <div className='group-point-text'>
              {`${totalData[item.value] ? parseInt(((totalData[item.value] / totalData.target) * 100).toFixed(0), 10) : 0}%`}
            </div>
            <div>
              <PieChartBase
                pieClassName="dashboard-pie"
                width={80}
                height={80}
                keyName={item.value}
                data={[{ part: totalData[item.value], whole: totalData.target, label: item.title, color: { start: item.color.start, end: item.color.end } }]}
                animationDuration={4000}
                chartOptions={{
                  cx: 35,
                  cy: 35,
                  innerRadius: 20,
                  outerRadius: 30,
                  startAngle: 90,
                  endAngle: 450
                }}
              />
            </div>
            <div className='group-sub-text'>
              <span className='color-red'>{`${item.subText[0]}${unit}`}</span>
              <span>{` / ${item.subText[1]}${unit}`}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default DashboardTotal;