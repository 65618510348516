import { Popover, Tag } from 'antd';
import { useIntl } from 'react-intl';
import './AttachInfoPopover.scss';

interface attachProps {
  data: any; // 첨부파일 데이터
  examType: number; // 훈련 유형
  target: any; // 팝오버 대상
}

function AttachInfoPopover({ data, examType, target }: attachProps) {
  const {
    attachExamType,
    attachInfoNo,
    attachCategory,
    fileExt,
    fileIcon,
    attachFunction,
    attachRestrict,
  } = data;
  const { formatMessage } = useIntl();

  // 훈련 유형 태그
  let examTypeComponent = null;
  if (examType === 1) {
    if (attachExamType === 1) {
      examTypeComponent = (
        <Tag className="temp-type-label color-temp-warning">
          {formatMessage({ id: 'Template_10', defaultMessage: '경고 안내' })}
        </Tag>
      );
    } else if (attachExamType === 2) {
      examTypeComponent = (
        <Tag className="temp-type-label color-temp-file">
          {formatMessage({ id: 'Template_11', defaultMessage: '모의 악성파일' })}
        </Tag>
      );
    } else if (attachExamType === 3) {
      examTypeComponent = (
        <Tag className="temp-type-label color-temp-research">
          {formatMessage({ id: 'Template_12', defaultMessage: '실태 조사' })}
        </Tag>
      );
    }
  } else if (examType === 4) {
    examTypeComponent = (
      <Tag className="temp-type-label color-temp-phishing">
        {formatMessage({ id: 'Template_13', defaultMessage: '피싱 유도' })}
      </Tag>
    );
  }

  return (
    <Popover
      overlayClassName="attach-info-popover full"
      placement="right"
      content={
        <div className="attach-popover">
          {/* No */}
          <div className="attach-popover-item">
            <div className="attach-popover-title">No</div>
            <div className="attach-popover-value">{attachInfoNo}</div>
          </div>
          {/* 구분 */}
          <div className="attach-popover-item">
            <div className="attach-popover-title">
              {formatMessage({ id: 'Template_22', defaultMessage: '구분' })}
            </div>
            <div className="attach-popover-value">
              {attachCategory === 1 && (
                <Tag className="temp-type-label color-attach-exe">
                  {formatMessage({ id: 'Attach_7', defaultMessage: '실행파일' })}
                </Tag>
              )}
              {attachCategory === 2 && (
                <Tag className="temp-type-label color-attach-lnk">
                  {formatMessage({ id: 'Attach_8', defaultMessage: '바로가기' })}
                </Tag>
              )}
              {attachCategory === 3 && (
                <Tag className="temp-type-label color-attach-doc">
                  {formatMessage({ id: 'Attach_9', defaultMessage: '문서파일' })}
                </Tag>
              )}
              {attachCategory === 4 && (
                <Tag className="temp-type-label color-attach-html">
                  {formatMessage({ id: 'Attach_10', defaultMessage: '웹문서' })}
                </Tag>
              )}
            </div>
          </div>
          {/* 훈련 유형 */}
          <div className="attach-popover-item">
            <div className="attach-popover-title">
              {formatMessage({ id: 'Template_23', defaultMessage: '훈련 유형' })}
            </div>
            <div className="attach-popover-value">{examTypeComponent}</div>
          </div>
          {/* 아이콘 */}
          <div className="attach-popover-item">
            <div className="attach-popover-title">
              {formatMessage({ id: 'Attach_11', defaultMessage: '아이콘' })}
            </div>
            <div className="attach-popover-value">
              <img
                className="attach-file-icon"
                src={`/fdata/attachFile/${attachInfoNo}/${fileIcon}`}
                alt="icon"
              />
            </div>
          </div>
          {/* 확장자 */}
          <div className="attach-popover-item">
            <div className="attach-popover-title">
              {formatMessage({ id: 'Attach_12', defaultMessage: '확장자' })}
            </div>
            <div className="attach-popover-value">{fileExt}</div>
          </div>
          {/* 부가 기능 */}
          <div className="attach-popover-item">
            <div className="attach-popover-title">
              {formatMessage({ id: 'Attach_13', defaultMessage: '부가 기능' })}
            </div>
            <div className="attach-popover-value">
              {attachFunction && Object.keys(JSON.parse(attachFunction)).length > 0
                ? Object.keys(JSON.parse(attachFunction)).map((attachKey: string) => {
                    return (
                      <Tag className="attach-label color-bg-attachFunction" key={attachKey}>
                        {attachKey}
                      </Tag>
                    );
                  })
                : '-'}
            </div>
          </div>
          {/* 제약 사항 */}
          <div className="attach-popover-item">
            <div className="attach-popover-title">
              {formatMessage({ id: 'Attach_14', defaultMessage: '제약 사항' })}
            </div>
            <div className="attach-popover-value">
              {attachRestrict && Object.keys(JSON.parse(attachRestrict)).length > 0
                ? Object.keys(JSON.parse(attachRestrict)).map((attachKey: string) => {
                    return (
                      <Tag className="attach-label color-bg-attachRestrict" key={attachKey}>
                        {attachKey}
                      </Tag>
                    );
                  })
                : '-'}
            </div>
          </div>
        </div>
      }
    >
      {target}
    </Popover>
  );
}

export default AttachInfoPopover;
