import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Cell, Pie, PieChart, Sector, Tooltip as ChartTooltip } from 'recharts';
import './ExamRandomChart.scss';

// import targetImage from 'img/exam/list_target.png';
// import sendImage from 'img/exam/list_send.png';
// import readImage from 'img/exam/list_read.png';
// import downImage from 'img/exam/list_down.png';
// import infectionImage from 'img/exam/list_infection.png';

function ExamRandomChart() {
  const { formatMessage } = useIntl();

  const selectedRandomGroupNo = useSelector(
    (state: any) => state.exam.examSummary.selectedRandomGroupNo,
  );
  const data = useSelector((state: any) => state.exam.examSummary.randomData);

  let totalTargetCnt = 0;
  let totalSendCnt = 0;
  let totalReadCnt = 0;
  let totalDownloadCnt = 0;
  let totalInfectionCnt = 0;

  Object.keys(data).forEach((key: string) => {
    if (selectedRandomGroupNo.toString() === key) {
      const examGroup = data[key];
      Object.keys(examGroup).forEach((key: string) => {
        if (examGroup[key].target) {
          totalTargetCnt += examGroup[key].target;
        }
        if (examGroup[key].send) {
          totalSendCnt += examGroup[key].send;
        }
        if (examGroup[key].read) {
          totalReadCnt += examGroup[key].read;
        }
        if (examGroup[key].download) {
          totalDownloadCnt += examGroup[key].download;
        }
        if (examGroup[key].infection) {
          totalInfectionCnt += examGroup[key].infection;
        }
      });
    }
  });

  const graphInfo = [
    {
      name: 'target',
      value: totalTargetCnt ? Number(((totalTargetCnt / totalTargetCnt) * 100).toFixed(0)) : 0,
      color: { start: '#bdbdbd', end: '#9e9e9e' },
      // icon: targetImage,
      text: formatMessage({ id: 'Target_1', defaultMessage: '훈련 대상' }),
      percentText: formatMessage({ id: 'Target_1', defaultMessage: '훈련 대상' }),
    },
    {
      name: 'send',
      value: totalSendCnt ? Number(((totalSendCnt / totalTargetCnt) * 100).toFixed(0)) : 0,
      color: { start: '#14ae96', end: '#058f88' },
      // icon: sendImage,
      text: formatMessage({ id: 'Send_2', defaultMessage: '메일 발송' }),
      count: totalSendCnt,
      percentText: formatMessage({ id: 'Send_17', defaultMessage: '발송률' }),
    },
    {
      name: 'read',
      value: totalReadCnt ? Number(((totalReadCnt / totalTargetCnt) * 100).toFixed(0)) : 0,
      color: { start: '#f69c2e', end: '#ff8715' },
      // icon: readImage,
      text: formatMessage({ id: 'Read_2', defaultMessage: '메일 열람' }),
      count: totalReadCnt,
      percentText: formatMessage({ id: 'Read_4', defaultMessage: '열람률' }),
    },
    {
      name: 'down',
      value: totalDownloadCnt ? Number(((totalDownloadCnt / totalTargetCnt) * 100).toFixed(0)) : 0,
      color: { start: '#711bd1', end: '#4506a1' },
      // icon: downImage,
      text: `${formatMessage({ id: 'Download_1', defaultMessage: '다운' })}/${formatMessage({
        id: 'Download_11',
        defaultMessage: '접속',
      })}`,
      count: totalDownloadCnt,
      percentText: formatMessage({ id: 'Download_12', defaultMessage: '다운/접속률' }),
    },
    {
      name: 'infection',
      value: totalInfectionCnt
        ? Number(((totalInfectionCnt / totalTargetCnt) * 100).toFixed(0))
        : 0,
      color: { start: '#dc3030', end: '#bd0e0e' },
      // icon: infectionImage,
      text: `${formatMessage({ id: 'Infection_1', defaultMessage: '감염' })}/${formatMessage({
        id: 'Infection_44',
        defaultMessage: '유출',
      })}`,
      count: totalInfectionCnt,
      percentText: formatMessage({ id: 'Infection_45', defaultMessage: '감염/유출률' }),
    },
  ];

  return (
    <div className="exam-random-chart">
      {graphInfo.map((item: any) => {
        const circleChartArray = [
          {
            name: '',
            color: '',
            value: 100 - item.value,
            // percent: 100 - item.value,
          },
          item,
        ];

        return (
          <div className="pie-chart-item" key={item.name}>
            {/* 그래프 안 아이콘 */}
            <div className="chart-icon">
              <img src={`/img/exam/list_${item.name}.png`} alt="icon" />
            </div>
            {/* 통계 그래프 */}
            <PieChart width={150} height={150}>
              <Pie
                dataKey="value"
                data={[
                  {
                    name: '',
                    value: 100,
                  },
                ]}
                cx={70}
                cy={60}
                paddingAngle={0}
                innerRadius={31}
                outerRadius={65}
                startAngle={90}
                endAngle={450}
                fill="#f7f7f7"
              />
              <Pie
                dataKey="value"
                data={circleChartArray}
                cx={70}
                cy={60}
                innerRadius={37}
                outerRadius={60}
                startAngle={90}
                endAngle={450}
                paddingAngle={-1}
                activeIndex={0}
                activeShape={(e: any) => {
                  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle } = e;
                  return (
                    <g>
                      <defs>
                        <defs>
                          <linearGradient id="797979" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor="#797979" />
                            <stop offset="100%" stopColor="#5d5d5d" />
                          </linearGradient>
                        </defs>
                        <defs>
                          <linearGradient
                            id={item.color.start.replace('#', '')}
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop offset="0%" stopColor={item.color.start} />
                            <stop offset="100%" stopColor={item.color.end} />
                          </linearGradient>
                        </defs>
                      </defs>
                      <Sector
                        cx={cx}
                        cy={cy}
                        innerRadius={innerRadius}
                        outerRadius={outerRadius}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        fill="#f7f7f7"
                      />
                      <Sector
                        cx={cx}
                        cy={cy}
                        innerRadius={outerRadius}
                        outerRadius={outerRadius}
                        startAngle={startAngle}
                        endAngle={endAngle}
                        fill="#f7f7f7"
                      />
                    </g>
                  );
                }}
              >
                {/* 퍼센트 표시 */}
                {circleChartArray.map((entry: any) => (
                  <Cell key={entry.name} fill={`url(${entry.color.start})`} />
                ))}
              </Pie>
              <ChartTooltip
                content={({ payload }: any) => {
                  return (
                    <div>
                      {payload.length > 0 &&
                        payload[0]?.name &&
                        payload.map((data: any) => {
                          return (
                            <div className="rechart-tooltip" key={data.name}>
                              <div className="title">{data.payload.label}</div>
                              <div className="value">
                                {`${data.payload.percentText}: ${data.payload.value}%`}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  );
                }}
              />
            </PieChart>
            {/* 통계 텍스트 */}
            <div className="random-chart-result">
              <div className="random-chart-text">{item.text}</div>
              <div className="random-chart-count">
                <span className="random-value-count">
                  {item.name === 'target' ? totalTargetCnt : item.count}
                </span>
                <span className="random-value-unit">
                  {item.name !== 'target' && `/${totalTargetCnt}`}
                  {formatMessage({
                    id: 'StartExam_35',
                    defaultMessage: '명',
                  })}
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ExamRandomChart;
