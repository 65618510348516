import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from 'antd';
import { CopyOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons';
import { deleteAttach } from 'store/attach';
import { timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import AttachCopy from 'components/branch/attach/AttachCopy';
import AttachEdit from 'components/branch/attach/AttachEdit';
import DeleteModal from 'components/common/modal/DeleteModal';

function AttachSettings({ isAdmin, dataInfo }: any) {
  const { formatMessage } = useIntl();
  const [openModal, setOpenModal] = useState('');

  const deleteData = {
    title: formatMessage({ id: 'Attach_40', defaultMessage: '첨부파일 삭제' }),
    contents: (
      <div>
        {formatMessage({
          id: 'Attach_41',
          defaultMessage: '삭제한 첨부파일은 복구할 수 없습니다.',
        })}
        <br />
        {formatMessage({
          id: 'Attach_42',
          defaultMessage: '다음 첨부파일을 삭제합니까?',
        })}
      </div>
    ),
    list: [
      {
        key: 'No',
        value: dataInfo.attachInfoNo,
      },
      {
        key: formatMessage({ id: 'Email_23', defaultMessage: '제목' }),
        value: dataInfo.attachSubject,
      },
      {
        key: formatMessage({ id: 'Email_23', defaultMessage: '등록일' }),
        value: timeFormatFromUTCEpoch(dataInfo.regEpoch),
      },
    ],
  };

  return (
    <div className="table-settings">
      {/* 첨부파일 복제 */}
      {(!!isAdmin || dataInfo.attachInfoNo) && dataInfo.isEnable === 1 && (
        <Tooltip
          overlayClassName="black-tooltip"
          placement="bottom"
          title={formatMessage({ id: 'Attach_38', defaultMessage: '첨부파일 복제' })}
        >
          <CopyOutlined onClick={() => setOpenModal('copy')} />
        </Tooltip>
      )}

      {openModal === 'copy' && (
        <AttachCopy
          visible={openModal === 'copy'}
          isAdmin={isAdmin}
          dataInfo={dataInfo}
          toggleModal={(open: boolean) => setOpenModal(open ? 'copy' : '')}
        />
      )}

      {/* 첨부파일 수정 */}
      {(!!isAdmin || dataInfo.attachInfoNo) && dataInfo.isEnable === 1 && (
        <Tooltip
          overlayClassName="black-tooltip"
          placement="bottom"
          title={formatMessage({ id: 'Attach_39', defaultMessage: '첨부파일 수정' })}
        >
          <FormOutlined onClick={() => setOpenModal('edit')} />
        </Tooltip>
      )}

      {openModal === 'edit' && (
        <AttachEdit
          visible={openModal === 'edit'}
          isAdmin={isAdmin}
          isAddMode={false}
          dataInfo={dataInfo}
          toggleModal={(open: boolean) => setOpenModal(open ? 'edit' : '')}
        />
      )}

      {/* 첨부파일 삭제 */}
      {(!!isAdmin || dataInfo.attachInfoNo) && dataInfo.isEnable === 1 && (
        <Tooltip
          overlayClassName="black-tooltip"
          placement="bottom"
          title={formatMessage({ id: 'Attach_40', defaultMessage: '첨부파일 삭제' })}
        >
          <DeleteOutlined onClick={() => setOpenModal('delete')} />
        </Tooltip>
      )}

      {openModal === 'delete' && (
        <DeleteModal
          visible={openModal === 'delete'}
          params={{ attachInfoNo: dataInfo.attachInfoNo }}
          modalData={deleteData}
          dispatchFunc={deleteAttach}
          toggleModal={(open: boolean) => setOpenModal(open ? 'delete' : '')}
        />
      )}
    </div>
  );
}

export default AttachSettings;
