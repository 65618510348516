import LogList from 'components/branch/log/LogList';
import LogMenu from 'components/branch/log/LogMenu';
import MainTitle from 'components/common/admin/MainTitle';
import { useIntl } from 'react-intl';
import "./Conf.scss";

export default function Examtemplate(props: any) {
  const { formatMessage } = useIntl();


  return (
    <div className="admin-basic-conf table">
      <MainTitle type="table" title={formatMessage({ id: 'Log_2', defaultMessage: '시스템 로그 조회' })}>
        <LogMenu />
      </MainTitle>
      <div className="react-table-area">
        <LogList />
      </div>
    </div>
  );
}