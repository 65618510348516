import React from 'react';
import { useRouteMatch, Redirect, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { getLogout, setInitial } from 'store/login';
import { eduLogout, setInitial as setEduInitial } from 'store/edumain';

function AutoRoute(props: any) {
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();

  // 서버에서 401 에러 발생시 로그인 화면으로 이동
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        sessionStorage.removeItem('curUser');

        if (url.includes('/edumain')) {
          eduLogout();
          setInitial();
          history.push(`/edumain/edulogin${window.location.search}`);
        } else {
          // 로그아웃 처리
          dispatch(getLogout());
          // 로그인 정보 초기화
          dispatch(setInitial());
          // 로그인 화면으로 이동
          history.push('/login');
        }
      }
    },
  );

  const urlArray = url.split('/');
  const lastItem = urlArray.splice(-1)[0].replace(/\b[a-z]/, (letter) => letter.toUpperCase());
  urlArray.push(lastItem);
  try {
    const component = require(`components/standard${urlArray.join('/')}`); // eslint-disable-line global-require
    return <component.default />;
  } catch {
    return <Redirect to="/" />;
  }
}

export default AutoRoute;
