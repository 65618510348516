import { Popover, Tag } from "antd";
import { useIntl } from "react-intl";


export default function AttachLabel(props: any) {
  const { formatMessage } = useIntl();
  const { type, data } = props

  const attachData = JSON.parse(data);
  const returnLabel: Array<JSX.Element> = [];

  const active: Array<string> = [];
  Object.keys(attachData).forEach((key: string) => {
    if (attachData[key]) {
      active.push(key);

      returnLabel.push(
        <Tag key={key} className={`color-bg-${type}`}>
          {key}
        </Tag>
      );
    }
  });

  return (
    <>
      <Popover
        placement="left"
        overlayClassName="full"
        content={
          <div className='attach-popup'>
            {type === 'attachFunction' ? (
              <div className='func'>
                <div className={active.indexOf('PG') > -1 ? "brand-color" : ""} >
                  {formatMessage({ id: 'Attach_16', defaultMessage: 'PG(PC Gather) - PC 정보 수집 기능' })}
                </div>
                <div className={active.indexOf('LG') > -1 ? "brand-color" : ""} >
                  {formatMessage({ id: 'Attach_17', defaultMessage: 'LG(LeakFile Gather) - PC의 파일 정보 수집 기능' })}
                </div>
                <div className={active.indexOf('PL') > -1 ? "brand-color" : ""} >
                  {formatMessage({ id: 'Attach_18', defaultMessage: 'PL(PC Lock) - PC 잠금(모의악성) 기능' })}
                </div>
                <div className={active.indexOf('VS') > -1 ? "brand-color" : ""} >
                  {formatMessage({ id: 'Attach_19', defaultMessage: 'VS(Vaccine Step) - 백신 단계 기능' })}
                </div>
              </div>
            ) : (
                <div className='restrict'>
                  <div className={active.indexOf('UAC') > -1 ? "brand-color" : ""} >
                    {formatMessage({ id: 'Attach_20', defaultMessage: 'UAC(User Account Control) - Windows 사용자 계정 컨트롤 탐지 가능' })}
                  </div>
                  <div className={active.indexOf('SS') > -1 ? "brand-color" : ""} >
                    {formatMessage({ id: 'Attach_21', defaultMessage: 'SS(Smart Screen) - Windows 스마트 스크린에 탐지 가능' })}
                  </div>
                  <div className={active.indexOf('SF') > -1 ? "brand-color" : ""} >
                    {formatMessage({ id: 'Attach_22', defaultMessage: 'SF(Spam Filter) - 메일 스팸 필터에 탐지 가능' })}
                  </div>
                  <div className={active.indexOf('VD') > -1 ? "brand-color" : ""} >
                    {formatMessage({ id: 'Attach_23', defaultMessage: 'VD(Vaccine Detect) - 백신 탐지 가능함(V3, 알약···)' })}
                  </div>
                  <div className={active.indexOf('LC') > -1 ? "brand-color" : ""} >
                    {formatMessage({ id: 'Attach_24', defaultMessage: 'LC(Link Click) - 문서 안에 링크를 클릭해야 감염 처리' })}
                  </div>
                  <div className={active.indexOf('UC') > -1 ? "brand-color" : ""} >
                    {formatMessage({ id: 'Attach_25', defaultMessage: 'UC(User Control) - 사용자의 동의가 필요함' })}
                  </div>
                  <div className={active.indexOf('DW') > -1 ? "brand-color" : ""} >
                    {formatMessage({ id: 'Attach_26', defaultMessage: 'DW(Download Warning) - 메일에서 파일 다운로드시 경고 발생' })}
                  </div>
                </div>
              )}
          </div>
        }>
      {returnLabel}
      </Popover>
    </>
  );
}