import { useState } from 'react';
import Loading from 'components/common/Loading';
import ModalTemplate from 'components/common/ModalTemplate';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import { deleteTemplate } from 'store/template';

interface ExamTemplateProps {
  visible: boolean; // 모달 띄우기
  templateInfo: any; // 템플릿 정보
  toggleModal: (open: boolean) => void;
}

function ExamTemplateDelete({
  visible,
  templateInfo,
  toggleModal,
}: ExamTemplateProps) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { templateNo, serviceTemplateNo, templateName, regEpoch } = templateInfo;

  // 템플릿 삭제
  const onDeleteTemplate = async () => {
    setLoading(true);
    try {
      const params: any = {};
      if (serviceTemplateNo) {
        params.serviceTemplateNo = serviceTemplateNo;
      } else if (templateNo) {
        params.examTemplateNo = templateNo;
      }

      await dispatch(deleteTemplate(params));
      setLoading(false);
      toggleModal(false);
    } catch (error) {
      console.log('ExamTemplateDelete onDeleteTemplate', error);
    }
  };

  return (
    <div>
      <ModalTemplate
        className="modal-464"
        visible={visible}
        title={
          <div className="modal-template-header">
            <div className="title">
              {formatMessage({ id: 'Template_7', defaultMessage: '훈련 양식(템플릿) 삭제' })}
            </div>
          </div>
        }
        onOk={onDeleteTemplate}
        onCancel={() => toggleModal(false)}
        okText={formatMessage({ id: 'Button_15', defaultMessage: '삭 제' })}
        cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
        loading={loading}
        greyButton
      >
        <Loading loading={loading} />

        <div className="modal-explain-text">
          <div>
            {formatMessage({
              id: 'Template_69',
              defaultMessage: '삭제한 템플릿은 복구할 수 없습니다.',
            })}
          </div>
          <div>
            {formatMessage({
              id: 'Template_70',
              defaultMessage: '다음 템플릿을 삭제합니까?',
            })}
          </div>
        </div>
        <div className="modal-border-box">
          <ul className="modal-item-list">
            <li>
              <div className="item-title">No</div>
            </li>
            <div>
              {serviceTemplateNo ||
                `${templateNo} (${formatMessage({
                  id: 'Template_71',
                  defaultMessage: '기본 제공',
                })})`}
            </div>
          </ul>
          <ul className="modal-item-list">
            <li>
              <div className="item-title">
                {formatMessage({ id: 'Template_24', defaultMessage: '템플릿명' })}
              </div>
            </li>
            <div>{templateName}</div>
          </ul>
          <ul className="modal-item-list">
            <li>
              <div className="item-title">
                {formatMessage({ id: 'Date_3', defaultMessage: '등록일시' })}
              </div>
            </li>
            <div>{timeFormatFromUTCEpoch(regEpoch)}</div>
          </ul>
        </div>
      </ModalTemplate>
    </div>
  );
}

export default ExamTemplateDelete;
