import { apiUrl, execUrl } from 'utils/commonValues';
import { actionFormat } from 'utils/commonFunctions';

// Action Types
const SET_INITIAL = 'edumain/SET_INITIAL';
const CHANGE_STATE = 'edumain/CHANGE_STATE';
const EDU_CHECK_LOGIN = 'edumain/EDU_CHECK_LOGIN';
const GET_EDU_TITLE = 'edumain/GET_EDU_TITLE';
const EDU_LOGIN = 'edumain/EDU_LOGIN';
const FIND_PASSWORD = 'edumain/FIND_PASSWORD';
const SET_PASSWORD = 'edumain/SET_PASSWORD';
const EDU_LOGOUT = 'edumain/EDU_LOGOUT';
const GET_EDU = 'edumain/GET_EDU';
const READ_EMAIL = 'edumain/READ_EMAIL';
const QUIZ_SOLUTION = 'edumain/QUIZ_SOLUTION';
const EDU_VIDEO_UPDATE = 'edumain/EDU_VIDEO_UPDATE';

// Actions
export const setInitial = () => actionFormat(SET_INITIAL);
export const changeState = (params: { [key: string]: any }) =>
  actionFormat(CHANGE_STATE, params, null, '');
// 교육 페이지 제목 조회
export const getEduTitle = (params: { [key: string]: any }) =>
  actionFormat(GET_EDU_TITLE, params, 'get', `${apiUrl}Edu/TrainingLogin`);
// 교육 로그인
export const eduLogin = (params: { [key: string]: any }) =>
  actionFormat(EDU_LOGIN, params, 'post', `${apiUrl}Edu/TrainingLogin`);
// 교육 로그인 여부 확인
export const eduCheckLogin = () =>
  actionFormat(EDU_CHECK_LOGIN, null, 'get', `${apiUrl}Edu/TrainingCheckLogin`);
// 비밀번호 찾기
export const findPassword = (params: { [key: string]: any }) =>
  actionFormat(FIND_PASSWORD, params, 'get', `${apiUrl}Edu/TrainingChangePw`);
// 비밀번호 설정하기(최초 로그인 시)
export const setPassword = (params: { [key: string]: any }) =>
  actionFormat(SET_PASSWORD, params, 'post', `${apiUrl}Edu/TrainingInitSetting`);
// 로그아웃
export const eduLogout = () => actionFormat(EDU_LOGOUT, null, 'get', `${apiUrl}Edu/TrainingLogout`);
// 교육 목록 조회
export const getEdu = (params: { [key: string]: any }) =>
  actionFormat(GET_EDU, params, 'get', `${apiUrl}Edu/Training`);
// 교육 메일 읽음 처리
export const readEmail = (params: { [key: string]: any }) =>
  actionFormat(READ_EMAIL, params, 'get', `${apiUrl}Edu/ReadEmail`);
// 교육 퀴즈 답 제출
export const eduQuizSolution = (params: { [key: string]: any }) =>
  actionFormat(QUIZ_SOLUTION, params, 'post', `${apiUrl}Quiz/Solution`);
// 교육 영상 정보 업데이트
export const eduVideoUpdate = (params: { [key: string]: any }) =>
  actionFormat(EDU_VIDEO_UPDATE, params, 'put', `${apiUrl}Edu/TrainingVideo`);

const initialState: any = {
  serviceNo: 0,
  eduTitle: '',
  loginResult: 0,
  loginMessage: '',
  userData: null,
  eduList: [],
  eduDataByEduNo: {},
  selectedEduNo: 0,
  japanMode: localStorage.getItem('language')?.includes('ja'),
};

function calculateProgress(item: any, attachInfo: any, quizData: any) {
  const attachCount = attachInfo.length;
  const currentQuiz = quizData.filter(
    (data: any) =>
      data.eduAttachNo === attachInfo[item.playStep > 0 ? item.playStep - 1 : 0]?.eduAttachNo,
  );
  const { eduStatus, playStep, playTime, isEndEach, eduName } = item;

  let progressStep: any = 0;
  if (eduStatus === 2) {
    progressStep = 100;
  } else if (eduStatus === 0) {
    progressStep = 0;
  } else if (playStep !== 1 && playTime === 0) {
    progressStep = (playStep - 1) / attachCount;
    progressStep = (progressStep * 100).toFixed();
  } else if (currentQuiz?.length) {
    progressStep = playStep / attachCount - (isEndEach ? 1 : 2) / (attachCount * 3);
    progressStep = (progressStep * 100).toFixed();
  } else {
    progressStep = playStep / attachCount - 1 / (attachCount * 2);
    progressStep = (progressStep * 100).toFixed();
  }
  return progressStep;
}

// Reducers
export default function (state = initialState, action: any) {
  switch (action.type) {
    case SET_INITIAL:
      return initialState;
    case CHANGE_STATE:
      if (action.payload) {
        const { key, value } = action.payload;
        state[key] = value;
      }
      return state;
    case GET_EDU_TITLE:
      if (action.payload) {
        const { list } = action.payload.data;
        if (list?.title) {
          state = {
            ...state,
            eduTitle: list.title,
          };
        }
      }
      return state;
    case EDU_LOGIN:
      if (action.payload) {
        const { list } = action.payload.data;
        if (list) {
          state = {
            ...state,
            loginResult: list.loginResult,
            loginMessage: list.loginMessage,
            userData: list.userData,
          };
        }
      }
      return state;
    case EDU_CHECK_LOGIN:
      if (action.payload) {
        const { list } = action.payload.data;
        if (list) {
          state = {
            ...state,
            loginResult: list.loginResult,
            loginMessage: list.loginMessage,
            userData: list.userData,
          };
        }
      }
      return state;
    case FIND_PASSWORD:
      return state;
    case SET_PASSWORD:
      return state;
    case EDU_LOGOUT:
      if (action.payload) {
        const { list } = action.payload.data;
        if (list) {
          state = {
            ...initialState,
            serviceNo: state.serviceNo,
            japanMode: state.japanMode,
          };
        }
      }
      return state;
    case GET_EDU:
      if (action.payload) {
        const { list } = action.payload.data;
        if (Array.isArray(list) && list?.length > 0) {
          const eduDataByEduNo: any = {};
          list.forEach((item: any) => {
            const { attachInfo, quizData } = item;
            const progress = calculateProgress(item, attachInfo, quizData);

            eduDataByEduNo[item.eduNo] = {
              ...item,
              eduProgress: progress,
            };
          });
          state = {
            ...state,
            eduList: list,
            selectedEduNo: list[0].eduNo,
            eduDataByEduNo: eduDataByEduNo,
          };
        }
      }
      return state;
    case READ_EMAIL:
      return state;
    case QUIZ_SOLUTION:
      return state;
    case EDU_VIDEO_UPDATE:
      if (action.payload?.data) {
        const { list } = action.payload.data;
        if (Array.isArray(list) && list?.length > 0) {
          const tempEduDataByEduNo: any = {};
          list.forEach((item: any) => {
            const { attachInfo, quizData } = state.eduDataByEduNo[item.eduNo];
            const progress = calculateProgress(item, attachInfo, quizData);

            tempEduDataByEduNo[item.eduNo] = {
              ...state.eduDataByEduNo[item.eduNo],
              ...item,
              playStep: item.playStep,
              eduProgress: progress,
            };
          });

          state = {
            ...state,
            eduDataByEduNo: { ...state.eduDataByEduNo, ...tempEduDataByEduNo },
          };
        }
      }
      return state;
    default:
      return state;
  }
}
