import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { changeParam, getDashboard, getDashboardTemplate } from 'store/dashboard';
import TableList from 'components/common/TableList';
import { Progress } from 'antd';

function DashboardExamTemplates() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const sortData = useSelector((state: any) => (state.dashboard.param.sort));
  const [templateData, setTemplateData]: any = useState([]);
  const [loading, setLoading]: any = useState(false);

  useEffect(() => {
    onGetDashboardTemplate();
  }, [sortData]);

  const onGetDashboardTemplate = async () => {
    if (loading) return;

    try {
      setLoading(true);
      const params = {
        sort: JSON.stringify(sortData),
      };
      const response: any = await dispatch(getDashboardTemplate(params));
      if (response.data?.list) {
        setTemplateData(response.data?.list);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('Dashboard getDashboardTarget', error);
    }
  };

  const baseColumns = [
    {
      Header: formatMessage({ id: 'Template_24', defaultMessage: '템플릿명' }),
      accessor: 'templateName',
      fixSortIcon: true,
      sortable: true,
      width: 300,
      headerClassName: 'react-table-th',
      className: 'react-table-td'
    },
    {
      Header: formatMessage({ id: 'Read_4', defaultMessage: '열람률' }),
      accessor: 'read',
      fixSortIcon: true,
      sortable: true,
      width: 150,
      headerClassName: 'react-table-th',
      className: 'react-table-td',
      Cell: function content({ value }: any) {
        return <div>{`${value}%`}</div>;
      },
    },
    {
      Header: formatMessage({ id: 'Download_12', defaultMessage: '다운/접속률' }),
      accessor: 'download',
      fixSortIcon: true,
      sortable: true,
      width: 150,
      headerClassName: 'react-table-th',
      className: 'react-table-td',
      Cell: function content({ value }: any) {
        return <div>{`${value}%`}</div>;
      },
    },
    {
      Header: formatMessage({ id: 'Infection_45', defaultMessage: '감염/유출률' }),
      accessor: 'infection',
      fixSortIcon: true,
      sortable: true,
      width: 150,
      headerClassName: 'react-table-th',
      className: 'react-table-td',
      Cell: function content({ value }: any) {
        return <div>{`${value}%`}</div>;
      },
    },
    {
      Header: formatMessage({ id: 'Dashboard_7', defaultMessage: '통합위험률' }),
      accessor: 'progress',
      fixSortIcon: true,
      sortable: true,
      width: 350,
      headerClassName: 'react-table-th',
      className: 'react-table-td',
      Cell: function content({ value }: any) {
        return (
          <div className='dash-total-progress'>
            <div> <Progress
              percent={value}
              status="active"
              showInfo={false}
              strokeColor='#CE0808'
              strokeWidth={14}
              width={300}
            /></div>
            <div>{`${value}%`}</div>
          </div>
        );
      },
    }
  ];

  const columns: Array<any> = useMemo(() => baseColumns, []);

  return (
    <div className='dash-exam-templates'>
      <div className='result-group right'>
        <div className='group-title'>
          {formatMessage({ id: 'Exam_91', defaultMessage: '훈련 템플릿별 통계' })}
        </div>

        <div className='dash-table'>
          <TableList
            columns={columns}
            data={templateData}
            changeParam={changeParam}
            loading={loading}
            disableKey="isEnable"
            defaultSort={sortData}
          />
        </div>
      </div>
    </div>
  );
}

export default DashboardExamTemplates;