import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { editAttach } from 'store/attach';
import { timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import Loading from 'components/common/Loading';
import ModalTemplate from 'components/common/ModalTemplate';

function AttachEdit({ visible, dataInfo, toggleModal }: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { attachInfoNo, attachSubject, isPublic } = dataInfo;

  // 첨부파일 수정
  const onEditAttach = async () => {
    setLoading(true);
    try {
      const params: any = {
        attachInfoNo: attachInfoNo,
      };

      await dispatch(editAttach(params));
      setLoading(false);
      toggleModal(false);
    } catch (error) {
      console.log('AttachEdit onEditAttach', error);
    }
  };

  return (
    <div>
      <ModalTemplate
        className="modal-464"
        visible={visible}
        title={
          <div className="modal-template-header">
            <div className="title">
              {formatMessage({ id: 'Attach_39', defaultMessage: '첨부파일 수정' })}
            </div>
          </div>
        }
        onOk={onEditAttach}
        onCancel={() => toggleModal(false)}
        okText={formatMessage({ id: 'Button_14', defaultMessage: '수 정' })}
        cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
        loading={loading}
        greyButton
      >
        <Loading loading={loading} />

        <div className="modal-explain-text">
          {isPublic
            ? formatMessage({
                id: 'Attach_47',
                defaultMessage: '다음 첨부파일을 비공개로 변경합니까?',
              })
            : formatMessage({
                id: 'Attach_48',
                defaultMessage: '다음 첨부파일을 공개로 변경합니까?',
              })}
        </div>
        <div className="modal-border-box">
          <ul className="modal-item-list">
            <li>
              <div className="item-title">No</div>
            </li>
            <div>{attachInfoNo}</div>
          </ul>
          <ul className="modal-item-list">
            <li>
              <div className="item-title">
                {formatMessage({ id: 'Email_23', defaultMessage: '제목' })}
              </div>
            </li>
            <div>{attachSubject}</div>
          </ul>
          <ul className="modal-item-list">
            <li>
              <div className="item-title">
                {formatMessage({ id: 'Attach_46', defaultMessage: '공개 여부' })}
              </div>
            </li>
            <div>
              {isPublic
                ? formatMessage({ id: 'Template_26', defaultMessage: '공개' })
                : formatMessage({ id: 'Template_39', defaultMessage: '비공개' })}
            </div>
          </ul>
        </div>
      </ModalTemplate>
    </div>
  );
}

export default AttachEdit;
