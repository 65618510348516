import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { postLogin, saveForm } from 'store/login';
import { setUser } from 'store/user';
import { LoadingOutlined } from '@ant-design/icons';
import { nowEpoch } from 'utils/commonFunctions';
import '../Mudlogin.scss';

function Admin(props: any) {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const loginData = useSelector((state: any) => state.login);
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const saveCheck: any = localStorage.getItem('saveCheck');
  const [emailCheck, setEmailCheck] = useState(JSON.parse(saveCheck) || '');
  const { formatMessage } = useIntl();

  if (loginData?.userData?.isAdmin === 1) {
    // 이미 로그인한 상태일 때 화면
    return <Redirect to="/login/already" />;
  }

  // 로그인 이벤트
  const onLogin = async (formData: any) => {
    setLoading(true);

    const params = {
      userEmail: btoa(formData.userEmail.toLowerCase()),
      userPasswd: btoa(formData.userPasswd),
      isAdmin: 1,
    };

    try {
      await dispatch(saveForm(formData));
      const response: { [key: string]: any } = await dispatch(postLogin(params));
      if (response?.data?.list) {
        const { loginResult, loginMessage, userData, sessionTimeout } = response.data.list;
        if (loginResult === 1) {
          // 로그인 성공
          localStorage.setItem('loginAdmin', userData.userEmail);
          localStorage.removeItem('loginUser');
          sessionStorage.setItem('accessTime', nowEpoch());

          await dispatch(setUser({ user: userData, sessionTimeout: sessionTimeout }));

          props.history.push('/mudmanage');
        } else if (loginResult === 2) {
          // 서버 에러
        } else if (loginResult === 4) {
          // ID / PASSWD 불일치
          setErrorMessage(loginMessage);
        } else if (loginResult === 8) {
          // 비밀번호 5회 이상 실패
          const remainTime: number = 300 - (nowEpoch().toFixed() - loginMessage.split(',')[1]);
          const minutes = parseInt(String(remainTime / 60), 10);
          const seconds = parseInt(String(remainTime % 60), 10);
          const errorMessage = `${loginMessage.split(',')[0]} (${formatMessage({
            id: 'Time_5',
            defaultMessage: '남은 시간',
          })}: ${minutes}${formatMessage({
            id: 'Time_2',
            defaultMessage: '분',
          })} ${seconds}${formatMessage({ id: 'Time_3', defaultMessage: '초' })})`;
          setErrorMessage(errorMessage);
        } else if (loginResult === 16) {
          // 비밀번호
          props.history.push('/mudlogin/pwchange');
        } else if (loginResult === 32) {
          // 비활성화
        } else if (loginResult === 64) {
          // 비활성화 인증 기간 만료
        } else if (loginResult === 128) {
          // 잠금 계정
        } else if (loginResult === 256) {
          // 최초 로그인 시 비밀번호 변경 화면
          props.history.push('/mudlogin/adminfirst');
        } else {
          setErrorMessage(loginMessage);
        }
      }
      setLoading(false);
    } catch (error) {
      console.log('onLogin', error);
    }
  };

  // 폼 입력 이벤트
  const onChangeForm = () => {
    setErrorMessage('');
  };

  return (
    <div className="admin-login-area">
      <div className="admin-login-title">
        {formatMessage({ id: 'Service_16', defaultMessage: '관리자 서비스' })}
      </div>
      <div className="login-input">
        <form onSubmit={handleSubmit(onLogin)} onChange={onChangeForm} autoComplete="off">
          <div className="wrap-input">
            <input
              name="userEmail"
              className="wrap-input-item"
              placeholder="ID"
              ref={register({ required: true })}
            />
            {/* 비밀번호 */}
            <input
              name="userPasswd"
              className="wrap-input-item"
              type="password"
              placeholder={formatMessage({ id: 'Password_1', defaultMessage: '비밀번호' })}
              ref={register({ required: true })}
            />
          </div>
          <div className="error-message">{errorMessage}</div>
          <button className="admin-login-btn" type="submit">
            {formatMessage({ id: 'Login_1', defaultMessage: '로그인' })}
            {loading && (
              <span className="button-loading">
                <LoadingOutlined />
              </span>
            )}
          </button>
        </form>
      </div>
    </div>
  );
}

export default withRouter(Admin);
