import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { CloseSquareOutlined } from '@ant-design/icons';
import { getDashboard, getDashboardNotice } from 'store/dashboard';
import { Option } from 'utils/commonValues';
import ModalTemplate from 'components/common/ModalTemplate';
import DashboardTotal from './DashboardTotal';
import DashboardTop5 from './DashboardTop5';
import DashboardYears from './DashboardYears';
import DashboardExamStatus from './DashboardExamStatus';
import DashboardExamTemplates from './DashboardExamTemplates';
import './DashboardRenewal.scss';

export const authLevel = 1;
export default function DashboardRenewal() {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [openModal, setOpenModal] = useState(false);
  const [noticeData, setNoticeData]: any = useState({});

  useEffect(() => {
    onGetDashboard();
  }, []);

  // 대시보드 정보 
  const onGetDashboard = async () => {
    try {
      const response: any = await dispatch(getDashboardNotice());
      if (response?.data?.notice?.noticeTitle) {
        setNoticeData(response?.data?.notice);
        setOpenModal(true);
      }
    } catch (error) {
      console.log('Dashboard onGetDashboard', error);
    }
  };

  return (
    <div className={`dashboard-bg-layout ${Option.isJapan ? '' : 'grey-theme'}`}>
      <div className='dash-inner-layout'>
        <div className='main-title'>
          {formatMessage({ id: 'Exam_95', defaultMessage: '훈련 종합 결과' })}
        </div>
        <DashboardTotal />

        <div className='dash-flex'>
          <DashboardTop5 />
          <DashboardYears />
        </div>

        <div className='dash-flex'>
          <DashboardExamStatus />
          <DashboardExamTemplates />
        </div>
      </div>

      {/* 공지 모달 */}
      <ModalTemplate
        className="notice-modal modal-665"
        isCloseBlack
        visible={openModal && localStorage.getItem('notice') === 'true'}
        onCancel={() => setOpenModal(false)}
        centered
        footer={
          <div
            onClick={() => {
              localStorage.setItem('notice', 'false');
              setOpenModal(false);
            }}
            aria-hidden="true"
          >
            <CloseSquareOutlined />
            {formatMessage({ id: 'Header_12', defaultMessage: '다음 로그인 시까지 보지 않음' })}
          </div>
        }
      >
        <div className="notice-title">{noticeData.noticeTitle}</div>
        <pre className="notice-content">{`${noticeData.noticeContent}`}</pre>
      </ModalTemplate>
    </div>
  );
}
