import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from 'antd';
import { DeleteOutlined, FormOutlined } from '@ant-design/icons';
import { deleteAdmin } from 'store/admin';
import DeleteModal from 'components/common/modal/DeleteModal';
import SubAdminAddEdit from 'components/branch/subadmin/SubAdminAddEdit';

export default function SubAdminSettings({ dataInfo }: any) {
  const { formatMessage } = useIntl();
  const [openModal, setOpenModal] = useState('');

  const deleteData = {
    title: formatMessage({ id: 'User_33', defaultMessage: '부관리자 삭제' }),
    contents: (
      <div>
        {formatMessage({
          id: 'User_34',
          defaultMessage: '등록된 부관리자 삭제입니다.',
        })}
        <br />
        {formatMessage({
          id: 'User_35',
          defaultMessage: '다음 부관리자를 삭제합니까?',
        })}
      </div>
    ),
    list: [
      {
        key: 'No',
        value: dataInfo.userNo,
      },
      {
        key: formatMessage({ id: 'Name_1', defaultMessage: '이름' }),
        value: dataInfo.userName,
      },
      {
        key: formatMessage({ id: 'Date_3', defaultMessage: '등록일시' }),
        value: dataInfo.regDate,
      },
    ],
  };

  return (
    <div className="table-settings">
      {/* 수정 */}
      <Tooltip
        overlayClassName="black-tooltip"
        placement="bottom"
        title={formatMessage({ id: 'User_32', defaultMessage: '부관리자 수정' })}
      >
        <FormOutlined onClick={() => setOpenModal('edit')} />
      </Tooltip>

      {openModal === 'edit' && (
        <SubAdminAddEdit
          visible={openModal === 'edit'}
          isAddMode={false}
          dataInfo={dataInfo}
          toggleModal={(open: boolean) => setOpenModal(open ? 'edit' : '')}
        />
      )}

      {/* 삭제 */}
      {dataInfo.userEnabled === 1 && (
        <Tooltip
          overlayClassName="black-tooltip"
          placement="bottom"
          title={formatMessage({ id: 'User_33', defaultMessage: '부관리자 삭제' })}
        >
          <DeleteOutlined onClick={() => setOpenModal('delete')} />
        </Tooltip>
      )}

      {openModal === 'delete' && (
        <DeleteModal
          visible={openModal === 'delete'}
          params={{ userNo: dataInfo.userNo }}
          modalData={deleteData}
          dispatchFunc={deleteAdmin}
          toggleModal={(open: boolean) => setOpenModal(open ? 'delete' : '')}
        />
      )}
    </div>
  );
}
