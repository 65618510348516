import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import 'videojs-youtube';
import { timeDiffFromSeconds } from 'utils/commonFunctions';
import ModalTemplate from 'components/common/ModalTemplate';
import VideoPlayer from 'components/branch/eduTemplate/VideoPlayer';
import EduPdfViewer from 'components/branch/edumain/EduPdfViewer';
import EduPreviewQuiz from 'components/branch/eduTemplate/EduPreviewQuiz';

function EduTemplateVideo({ visible, dataInfo, toggleModal }: any) {
  const { formatMessage } = useIntl();
  const [page, setPage]: any = useState(1);
  const [selectedVideo, setSelectedVideo] = useState(dataInfo?.fileInfo?.[0].fileNo);
  const { fileInfo } = dataInfo;

  // 영상 목록 드롭다운
  const videoMenuList: Array<any> = [];
  // 영상 url 리스트
  const videoSourceList: Array<any> = [];
  // 총 영상 시간
  let totalTime = 0;

  fileInfo?.forEach((videoData: any) => {
    videoMenuList.push({
      key: videoData.fileNo,
      value: videoData.fileNo,
      text: videoData.fileName,
    });

    videoSourceList.push({
      src: videoData.filePath,
      type: dataInfo.contentType === 2 ? 'video/youtube' : 'video/mp4',
    });

    totalTime = videoData.runningTime;
  });

  // 동영상 플레이어 옵션
  const videoPlayerOption = {
    height: 330,
    autoPlay: false,
    playbackRates: [1, 1.25, 1.5, 2],
    controls: true,
    sources: videoSourceList,
    controlBar: {
      pictureInPictureToggle: false,
      skipButtons: {
        backward: 10,
      }
    },
    nativeTextTracks: true,
  };

  const currentVideoData = fileInfo.filter((data: any) => data.fileNo === selectedVideo)[0];

  const page1 = (
    <>
      <div className="input-title">
        {formatMessage({ id: 'Edu_58', defaultMessage: '컨텐츠 미리보기' })}
      </div>
      <div className="video-player-box">
        {(dataInfo.contentType === 1 || dataInfo.contentType === 4) && (
          <div className="video-header">
            <div className="video-header-left">
              <div className="video-title">{dataInfo.attachName}</div>
              <div className="video-sub-title">{currentVideoData?.fileName}</div>
            </div>
            <div className="video-header-right">
              <div>
                {`${formatMessage({
                  id: 'Time_12',
                  defaultMessage: '총 영상 시간',
                })}: ${timeDiffFromSeconds(totalTime)}`}
              </div>
              <div>
                {`${formatMessage({
                  id: 'Time_11',
                  defaultMessage: '영상 시간',
                })}: ${timeDiffFromSeconds(currentVideoData?.runningTime)}`}
              </div>
            </div>
          </div>
        )}

        <div
          className={`video-player ${dataInfo.contentType === 2 || dataInfo.contentType === 3 ? 'youtube' : ''
            }`}
        >
          {dataInfo.contentType === 3 ? (
            <EduPdfViewer filePath={dataInfo.fileInfo?.[0].filePath} type="preview" />
          ) : (
            <VideoPlayer
              videoPlayerOption={videoPlayerOption}
              currentIndex={fileInfo.findIndex((data: any) => data.fileNo === selectedVideo)}
              contentType={dataInfo.contentType}
            />
          )}
        </div>
      </div>
    </>
  );

  const page2 = (
    <EduPreviewQuiz dataInfo={dataInfo} />
  );

  return (
    <ModalTemplate
      className={`edu-template-video-modal edu-template-add-modal modal-${page === 1 ? '665' : '464'}`}
      visible={visible}
      title={
        <div className="modal-template-header">
          <div className="title">
            {formatMessage({ id: 'Edu_57', defaultMessage: '컨텐츠 보기' })}
          </div>
        </div>
      }
      onCancel={() => toggleModal(false)}
      footer={
        <>
          {page === 2 ? (
            <button type="button" className="footer-btn prev" onClick={() => setPage(1)}>
              <LeftOutlined />
              {formatMessage({ id: 'Button_31', defaultMessage: '이 전' })}
            </button>
          ) : (
            <button type="button" onClick={() => toggleModal(false)}>
              {formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
            </button>
          )}
          {page === 1 && !!dataInfo.quizCount && (
            <button type="button" className="footer-btn next" onClick={() => setPage(2)}>
              {formatMessage({ id: 'Edu_130', defaultMessage: '퀴즈 확인' })}
              <RightOutlined />
            </button>
          )}
        </>}
    >
      <form autoComplete="off" onSubmit={(e: any) => e.preventDefault()}>
        {page === 1 ? page1 : page2}
      </form>
    </ModalTemplate>
  );
}

export default EduTemplateVideo;
