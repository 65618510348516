import React from "react";
import { useIntl } from 'react-intl';
import "./MainTitle.scss"

export default function MainTitle(props: any) {
  const { formatMessage } = useIntl();
  const { className, type, title, children } = props
  return (
    <div className={`admin-main-title ${className && className} ${type === 'table' && 'table-title'}`}>
      <div className="main-title">{title}</div>
      {children && <div className="page-func">{children}</div>}
    </div>
  );
}