import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { eduLogin } from 'store/edumain';
import FormTextField from 'components/common/FormTextField';
import Loading from 'components/common/Loading';
import { Option } from 'utils/commonValues';

function Edulogin() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { errors, register, handleSubmit } = useForm({
    mode: 'all',
  });
  const edumainStore = useSelector((state: any) => {
    return {
      serviceNo: state.edumain.serviceNo,
      eduTitle: state.edumain.eduTitle,
      userData: state.edumain.userData,
      japanMode: state.edumain.japanMode,
    };
  }, shallowEqual);

  useEffect(() => {
    // form validation 표시
    if (Object.keys(errors)?.length > 0) {
      setErrorMessage(Object.values(errors)[0]?.message);
    }
  }, [Object.keys(errors)]);

  // 로그인
  const onEduLogin = async (form: any) => {
    const storeServiceNo = edumainStore.serviceNo;
    setLoading(true);

    try {
      const params = {
        serviceNo: storeServiceNo,
        eduTargetId: form.eduId,
        eduTargetPw: form.eduPassword,
      };
      const response: any = await dispatch(eduLogin(params));
      if (response?.data?.list) {
        const { loginResult, loginMessage } = response.data.list;

        if (loginResult === 1) {
          // 로그인 성공 -> 메일 페이지로 이동
          history.push(`/edumain/eduplay${window.location.search}`);
        } else if (loginResult === 2) {
          // 최초 로그인 시 비밀번호 변경 페이지로 이동
          history.push(`/edumain/setpassword${window.location.search}`);
        } else if (loginResult === 4) {
          //  아이디/비밀번호 불일치
          setErrorMessage(loginMessage);
        } else if (loginResult === 8) {
          // 비밀번호 n회 이상 실패
          // ex. 비밀번호 5회 이상 실패,1652854145.11265
          const splitLoginMessage = loginMessage.split(',');
          const currentTime = (new Date().getTime() / 1000).toFixed(0);
          const remainTime = 300 - (parseInt(currentTime, 10) - splitLoginMessage[1]);
          const minutes = `${
            Math.floor(remainTime / 60) > 0
              ? Math.floor(remainTime / 60) +
                formatMessage({
                  id: 'Time_2',
                  defaultMessage: '분',
                })
              : ''
          }`;
          const seconds = `${Math.floor(remainTime % 60)}${formatMessage({
            id: 'Time_3',
            defaultMessage: '초',
          })}`;

          const failText = `${splitLoginMessage[0]}(${formatMessage({
            id: 'Time_5',
            defaultMessage: '남은 시간',
          })}: ${minutes} ${seconds})`;

          setErrorMessage(failText);
        }
      } else if (response?.data?.error && response.data.error[0]?.errInfo) {
        setErrorMessage(response.data.error[0].errInfo);
      }
      setLoading(false);
    } catch (error) {
      console.log('Edulogin onEduLogin', error);
    }
  };

  return (
    <div className="edu-layout">
      <Loading loading={loading} />
      <div className="layout-left">
        <div className="layout-left-box">
          {edumainStore.japanMode ? (
            <div className="edu-login-left">
              <div className="bg-effect">
                <div className="effect-1" />
                <div className="effect-2" />
                <div className="effect-3" />
              </div>
            </div>
          ) : (
            <img className="login-bg" src="/img/edu/title_bg.png" alt="bg" />
          )}
          <span className="login-text">Login</span>
          <img className="arrow" src="/img/edu/login_arr.png" alt="arrow" />
        </div>
      </div>
      <div className="layout-right">
        <div className="r-logo">
          <img src="/img/logo/mf_logo.png" alt="logo" />
        </div>

        <div className="layout-right-box">
          <div className="edu-title">
            {edumainStore?.eduTitle ||
              formatMessage(
                { id: 'Edu_108', defaultMessage: '{serivce} 악성 메일 대응 교육' },
                { service: Option.serviceName },
              )}
          </div>

          <div className="edu-form-box">
            <form
              autoComplete="off"
              onChange={() => setErrorMessage('')}
              onSubmit={handleSubmit(onEduLogin)}
            >
              <FormTextField
                name="eduId"
                register={register}
                placeholder={formatMessage({ id: 'Email_1', defaultMessage: '이메일' })}
                validation={{
                  validate: {
                    required: (value: any) =>
                      !value
                        ? formatMessage({ id: 'Email_6', defaultMessage: '이메일을 입력해 주세요' })
                        : undefined,
                  },
                }}
              />

              <FormTextField
                name="eduPassword"
                register={register}
                type="password"
                placeholder={formatMessage({ id: 'Password_1', defaultMessage: '비밀번호' })}
                validation={{
                  validate: {
                    required: (value: any) =>
                      !value
                        ? formatMessage({
                            id: 'Password_19',
                            defaultMessage: '비밀번호를 입력해 주세요.',
                          })
                        : undefined,
                  },
                }}
              />
              {!!errorMessage && (
                <div className="edu-error-message">
                  <img src="/img/edu/fail_login_i.jpg" alt="icon" />
                  {errorMessage}
                </div>
              )}

              <div className="edu-button-box">
                <button className="btn-point" type="submit">
                  {formatMessage({ id: 'Login_1', defaultMessage: '로그인' })}
                </button>
                <button
                  className="btn-white"
                  onClick={() => {
                    history.push(`/edumain/findpassword${window.location.search}`);
                  }}
                  type="button"
                >
                  {formatMessage({ id: 'Password_5', defaultMessage: '비밀번호 찾기' })}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Edulogin;
