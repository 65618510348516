import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from 'antd';
import { DeleteOutlined, FormOutlined } from '@ant-design/icons';
import { deleteAttach } from 'store/attach';
import { timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import DeleteModal from 'components/common/modal/DeleteModal';
import UserAddEdit from 'components/branch/user/UserAddEdit';
import { deleteUser } from 'store/user';
import './UserSettings.scss';

function UserSettings({ isAdmin, dataInfo }: any) {
  const { formatMessage } = useIntl();
  const [openModal, setOpenModal] = useState('');

  const deleteData = {
    title: formatMessage({ id: 'User_12', defaultMessage: '사용자 삭제' }),
    contents: (
      <div>
        {formatMessage({
          id: 'User_13',
          defaultMessage: '등록된 가입자 삭제입니다.',
        })}
        <br />
        {formatMessage({
          id: 'User_14',
          defaultMessage: '다음 사용자를 삭제합니까?',
        })}
        <div className="user-delete-notice">
          {formatMessage({
            id: 'User_36',
            defaultMessage: '※ 사용자 삭제 시 사용자가 생성한 공유 사용자의 훈련결과도 삭제 될 수 있습니다.',
          })}
         </div>
      </div>
    ),
    list: [
      {
        key: 'No',
        value: dataInfo.userNo,
      },
      {
        key: formatMessage({ id: 'Name_11', defaultMessage: '사용자명' }),
        value: dataInfo.userName,
      },
      {
        key: formatMessage({ id: 'Date_3', defaultMessage: '등록일시' }),
        value: timeFormatFromUTCEpoch(dataInfo.regEpoch),
      },
    ],
  };

  return (
    <div className="table-settings">
      {/* 수정 */}
      <Tooltip
        overlayClassName="black-tooltip"
        placement="bottom"
        title={formatMessage({ id: 'User_11', defaultMessage: '사용자 수정' })}
      >
        <FormOutlined onClick={() => setOpenModal('edit')} />
      </Tooltip>

      {openModal === 'edit' && (
        <UserAddEdit
          visible={openModal === 'edit'}
          isAdmin={isAdmin}
          isAddMode={false}
          dataInfo={dataInfo}
          toggleModal={(open: boolean) => setOpenModal(open ? 'edit' : '')}
        />
      )}

      {/* 삭제 */}
      {dataInfo.userEnabled === 1 && (
        <Tooltip
          overlayClassName="black-tooltip"
          placement="bottom"
          title={formatMessage({ id: 'User_12', defaultMessage: '사용자 삭제' })}
        >
          <DeleteOutlined onClick={() => setOpenModal('delete')} />
        </Tooltip>
      )}

      {openModal === 'delete' && (
        <DeleteModal
          visible={openModal === 'delete'}
          params={{ userNo: dataInfo.userNo }}
          modalData={deleteData}
          dispatchFunc={deleteUser}
          toggleModal={(open: boolean) => setOpenModal(open ? 'delete' : '')}
        />
      )}
    </div>
  );
}

export default UserSettings;
