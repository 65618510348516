import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from 'antd';
import { CloseCircleOutlined, PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { putExamSummary, putExamSuspend } from 'store/exam';
import { useDispatch } from 'react-redux';
import ModalTemplate from 'components/common/ModalTemplate';

function EduTemplateSettings({ isAdmin, dataInfo }: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState('');
  const [loading, setLoading] = useState(false);
  const { examNo, serviceNo, examName, isSuspend } = dataInfo;

  // 메일 발송 일시정지/재시작
  const onExamSuspend = async (suspend: number) => {
    try {
      await dispatch(putExamSuspend({ examNo: examNo, serviceNo: serviceNo, isSuspend: suspend }));
    } catch (error) {
      console.log('ExamIngSettings onExamSuspend', error);
    }
  };

  // 훈련 즉시 종료
  const onExamEnd = async () => {
    try {
      setLoading(true);
      await dispatch(putExamSummary({ examNo: examNo, serviceNo: serviceNo, isEndNow: 1 }));
      setLoading(false);
    } catch (error) {
      console.log('ExamIngSettings onExamEnd', error);
    }
  };

  return (
    <div className="table-settings">
      {isSuspend === 0 && (
        <Tooltip
          overlayClassName="black-tooltip"
          placement="bottom"
          title={formatMessage({ id: 'Send_10', defaultMessage: '메일 발송 일시정지' })}
        >
          <PauseCircleOutlined onClick={() => setOpenModal('sendStop')} />
        </Tooltip>
      )}
      {openModal === 'sendStop' && (
        <ModalTemplate
          className="exam-summary-modal modal-464"
          visible={openModal === 'sendStop'}
          title={formatMessage({ id: 'Send_10', defaultMessage: '메일 발송 일시정지' })}
          onOk={() => onExamSuspend(1)}
          onCancel={() => setOpenModal('')}
          okText={formatMessage({ id: 'Send_18', defaultMessage: '일시정지' })}
          cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
          loading={loading}
        >
          <div className="modal-explain-text">
            {formatMessage({
              id: 'Send_20',
              defaultMessage: '훈련 메일 발송을 일시정지하시겠습니까?',
            })}
          </div>
          <div className="modal-border-box">
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'Exam_22', defaultMessage: '훈련 번호' })}
                </div>
              </li>
              <div>{examNo}</div>
            </ul>
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'Exam_1', defaultMessage: '훈련명' })}
                </div>
              </li>
              <div>{examName}</div>
            </ul>
          </div>
        </ModalTemplate>
      )}

      {/* 메일 발송 재시작 */}
      {isSuspend === 1 && (
        <Tooltip
          overlayClassName="black-tooltip"
          placement="bottom"
          title={formatMessage({ id: 'Send_11', defaultMessage: '메일 발송 재시작' })}
        >
          <PlayCircleOutlined onClick={() => setOpenModal('sendRestart')} />
        </Tooltip>
      )}
      {openModal === 'sendRestart' && (
        <ModalTemplate
          className="exam-summary-modal modal-464"
          visible={openModal === 'sendRestart'}
          title={formatMessage({ id: 'Send_11', defaultMessage: '메일 발송 재시작' })}
          onOk={() => onExamSuspend(0)}
          onCancel={() => setOpenModal('')}
          okText={formatMessage({ id: 'Send_19', defaultMessage: '재시작' })}
          cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
          loading={loading}
        >
          <div className="modal-explain-text">
            {formatMessage({
              id: 'Send_21',
              defaultMessage: '훈련 메일 발송을 재시작하시겠습니까?',
            })}
          </div>
          <div className="modal-border-box">
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'Exam_22', defaultMessage: '훈련 번호' })}
                </div>
              </li>
              <div>{examNo}</div>
            </ul>
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'Exam_1', defaultMessage: '훈련명' })}
                </div>
              </li>
              <div>{examName}</div>
            </ul>
          </div>
        </ModalTemplate>
      )}

      {/* 훈련 즉시 종료 */}
      <Tooltip
        overlayClassName="black-tooltip"
        placement="bottom"
        title={formatMessage({ id: 'Exam_11', defaultMessage: '훈련 즉시 종료' })}
      >
        <CloseCircleOutlined onClick={() => setOpenModal('examEnd')} />
      </Tooltip>

      {openModal === 'examEnd' && (
        <ModalTemplate
          className="exam-summary-modal modal-464"
          visible={openModal === 'examEnd'}
          title={formatMessage({ id: 'Exam_11', defaultMessage: '훈련 즉시 종료' })}
          onOk={onExamEnd}
          onCancel={() => setOpenModal('')}
          okText={formatMessage({ id: 'Button_13', defaultMessage: '종 료' })}
          cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
          loading={loading}
        >
          <div className="modal-explain-text">
            {formatMessage({
              id: 'Exam_24',
              defaultMessage: '훈련을 즉시 종료 하시겠습니까?',
            })}
          </div>
          <div className="modal-border-box">
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'Exam_22', defaultMessage: '훈련 번호' })}
                </div>
              </li>
              <div>{examNo}</div>
            </ul>
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'Exam_1', defaultMessage: '훈련명' })}
                </div>
              </li>
              <div>{examName}</div>
            </ul>
          </div>
        </ModalTemplate>
      )}
    </div>
  );
}

export default EduTemplateSettings;
