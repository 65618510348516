import { useIntl } from 'react-intl';
import './ExecuteResult.scss';

function ExecuteResult({ resultData, goToResult, goToText }: any) {
  const { formatMessage } = useIntl();

  return (
    <div className="execute-result-content">
      <div className="content-item">
        <div className="result-scroll">
          {resultData?.map((data: any) => {
            return (
              <div className="result-row" key={data.text}>
                <img
                  className="yellow-check-icon"
                  src="/img/exam/startExam/ico_check_y.png"
                  alt="check"
                />
                <div className="result-title">{data.text}</div>
                <div className="result-text">{data.value}</div>
              </div>
            );
          })}
        </div>
        <div className="result-move-button" onClick={goToResult} aria-hidden="true">
          <span>
            {formatMessage(
              {
                id: 'StartExam_58',
                defaultMessage: '{result}에서 세부 내용을 확인할 수 있습니다.',
              },
              {
                result: <span className="brand-color">{goToText}</span>,
              },
            )}
          </span>
        </div>
      </div>
    </div>
  );
}

export default ExecuteResult;
