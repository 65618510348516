import { apiUrl, execUrl } from 'utils/commonValues';
import { actionFormat, getFollowOrderArray } from 'utils/commonFunctions';

// Action Types
const SET_INITIAL = 'attach/SET_INITIAL';
const GET_ATTACH = 'attach/GET_PHISHING';
const RELOAD_ATTACH = 'attach/RELOAD_ATTACH';
const CHANGE_PARAM = 'attach/CHANGE_PARAM';

// Actions
export const getAttach = (params?: { [key: string]: any }) =>
  actionFormat(GET_ATTACH, params, 'get', `${apiUrl}TemplateAttach`);
export const editAttach = (params?: { [key: string]: any }) =>
  actionFormat(RELOAD_ATTACH, params, 'put', `${apiUrl}TemplateAttach`);
export const deleteAttach = (params?: { [key: string]: any }) =>
  actionFormat(RELOAD_ATTACH, params, 'delete', `${apiUrl}TemplateAttach`);
export const copyAttach = (params?: { [key: string]: any }) =>
  actionFormat(RELOAD_ATTACH, params, 'post', `${apiUrl}TemplateAttach/Copy`);
export const changeParam = (params: { [key: string]: any }) =>
  actionFormat(CHANGE_PARAM, params, null, '');

// initial state
interface stateType {
  data: { [key: string]: any };
  param: { [key: string]: any };
  listIndexByNo: { [key: string]: any };
  needReload: boolean;
  totalPages: number;
  totalCount: number;
}

const initialState: stateType = {
  data: {
    result: -1,
    success: [],
    fail: [],
  },
  param: {
    filter: {},
    sort: [{ field: 'attachInfoNo', order: 'DESC' }],
  },
  listIndexByNo: {},
  needReload: false,
  totalPages: 0,
  totalCount: 0,
};

// Reducers
export default function (state: { [key: string]: any } = initialState, action: any) {
  switch (action.type) {
    case SET_INITIAL:
      return initialState;
    case GET_ATTACH:
      if (action.payload) {
        const { data } = action.payload;
        const { offset, limit } = action.payload.config.params;

        // 전체 목록 수
        const totalCount = data.list.length > 0 ? data.list[0].rnum + offset : 0;
        // 전체 페이지 수
        const totalPages = Math.ceil(totalCount / limit);

        if (!data.error && data.list && Array.isArray(data.list)) {
          state.data = {
            result: 1,
            success: data.list,
            fail: [],
          };
        } else if (data.error && Array.isArray(data.error)) {
          state.data = {
            result: 0,
            success: [],
            fail: data.errror,
          };
        }

        const attachData: any = {};
        data.list.forEach((item: any, i: number) => {
          attachData[item.attachInfoNo] = i;
        });

        state.needReload = false;
        state.totalPages = totalPages;
        state.totalCount = totalCount;
        state.listIndexByNo = attachData;
      }
      return state;
    case CHANGE_PARAM:
      if (action.payload) {
        const { name, value } = action.payload;
        state.param[name] = value;
      }
      return state;
    case RELOAD_ATTACH:
      state.needReload = true;
      return state;
    default:
      return state;
  }
}
