import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { addTarget, putTarget } from 'store/target';
import * as valid from 'utils/validation';
import Loading from 'components/common/Loading';
import ModalTemplate from 'components/common/ModalTemplate';
import FormTextField from 'components/common/FormTextField';

function TargetAddEdit({ visible, isAdmin, isAddMode, dataInfo, toggleModal }: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { errors, control, register, getValues, setValue, setError, handleSubmit } = useForm({
    mode: 'all',
    defaultValues: {
      targetName: dataInfo.targetName,
      serviceNo: dataInfo.serviceNo,
      targetEmail: dataInfo.targetEmail,
      targetPhone: dataInfo.targetPhone,
      targetDivision: dataInfo.targetDivision,
      targetPosition: dataInfo.targetPosition,
      targetDetail: dataInfo.targetDetail,
    },
  });

  // 대상자 정보 저장
  const onSaveTarget = async (formData: any) => {
    try {
      const params: any = {};

      // 값 변경 여부
      let changed = isAddMode ? true : false;
      Object.keys(formData).forEach((key: any) => {
        // 변경된 항목만 파라미터에 넣어줌
        if (formData[key] !== dataInfo[key]) {
          changed = true;
          params[key] = formData[key];
        }

        // 수정일 경우 대상자번호 추가
        if (!isAddMode) {
          params.targetNo = dataInfo.targetNo;
        }

        if (dataInfo.serviceNo) {
          params.serviceNo = dataInfo.serviceNo;
        }
      });

      if (changed) {
        setLoading(true);
        const response: any = await dispatch(isAddMode ? addTarget(params) : putTarget(params));
        if (response?.data?.error) {
          if (response.data.error[0]?.result === 'Duplicate Target Exist') {
            setError('targetEmail', {
              type: 'duplicate',
              message: formatMessage({
                id: 'Email_9',
                defaultMessage: '이미 등록된 이메일입니다.',
              }),
            });
          } else if (response.data.error[0]?.errInfo === 'Invalid ServiceNo') {
            setError('serviceNo', {
              type: 'wrong',
              message: formatMessage({
                id: 'Service_14',
                defaultMessage: '잘못된 서비스번호입니다.',
              }),
            });
          }
          setLoading(false);
        } else {
          setLoading(false);
          toggleModal(false);
        }
      } else {
        toggleModal(false);
      }
    } catch (error) {
      console.log('TargetAddEdit onSaveTarget', error);
    }
  };

  return (
    <ModalTemplate
      className="modal-464"
      visible={visible}
      title={
        <div className="modal-template-header">
          <div className="title">
            {isAddMode
              ? formatMessage({ id: 'Target_27', defaultMessage: '대상자 등록' })
              : `${formatMessage({ id: 'Target_54', defaultMessage: '대상자 수정' })} (No.${
                  dataInfo.targetNo
                })`}
          </div>
        </div>
      }
      onOk={handleSubmit(onSaveTarget)}
      onCancel={() => toggleModal(false)}
      okText={
        isAddMode
          ? formatMessage({ id: 'Button_10', defaultMessage: '등 록' })
          : formatMessage({ id: 'Button_14', defaultMessage: '수 정' })
      }
      cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
      loading={loading}
    >
      <Loading loading={loading} />
      <form autoComplete="off" onSubmit={(e: any) => e.preventDefault()}>
        {/* 이름 */}
        <div className="input-title">
          {formatMessage({ id: 'Name_1', defaultMessage: '이름' })}*
        </div>
        <FormTextField
          name="targetName"
          error={errors.targetName}
          register={register}
          arrowPosition="top"
          validation={{
            validate: {
              required: (value: any) => valid.required(value),
              name: (value: any) => valid.name(value),
            },
          }}
        />

        {/* 서비스No */}
        {isAdmin && (
          <>
            <div className="text-field-title">
              {formatMessage({ id: 'Service_8', defaultMessage: '서비스No' })}*
            </div>
            <FormTextField
              className={isAddMode ? '' : 'disabled'}
              name="serviceNo"
              error={errors.serviceNo}
              register={register}
              arrowPosition="top"
              validation={{
                validate: {
                  required: (value: any) => valid.required(value),
                  number: (value: any) => valid.number(value),
                },
              }}
            />
          </>
        )}

        {/* 이메일 */}
        <div className="text-field-title">
          {formatMessage({ id: 'Email_1', defaultMessage: '이메일' })}*
        </div>
        <FormTextField
          name="targetEmail"
          error={errors.targetEmail}
          register={register}
          arrowPosition="top"
          validation={{
            validate: {
              required: (value: any) => valid.required(value),
              email: (value: any) => valid.email(value),
            },
          }}
        />

        {/* 연락처 */}
        <div className="text-field-title">
          {formatMessage({ id: 'Contact_1', defaultMessage: '연락처' })}
        </div>
        <FormTextField
          name="targetPhone"
          error={errors.targetPhone}
          register={register}
          arrowPosition="top"
          validation={{
            validate: {
              userPhone: (value: any) => valid.userPhone(value),
            },
          }}
        />

        {/* 소속 */}
        <div className="text-field-title">
          {formatMessage({ id: 'Division_1', defaultMessage: '소속' })}
        </div>
        <FormTextField
          name="targetDivision"
          error={errors.targetDivision}
          register={register}
          arrowPosition="top"
          validation={{
            validate: {
              name: (value: any) => valid.name(value),
            },
          }}
        />

        {/* 직급 */}
        <div className="text-field-title">
          {formatMessage({ id: 'Position_1', defaultMessage: '직급' })}
        </div>
        <FormTextField
          name="targetPosition"
          error={errors.targetPosition}
          register={register}
          arrowPosition="top"
          validation={{
            validate: {
              name: (value: any) => valid.name(value),
            },
          }}
        />

        {/* 메모 */}
        <div className="text-field-title">
          {formatMessage({ id: 'Target_19', defaultMessage: '메모' })}
        </div>
        <div className="form-field-wrap">
          <Controller
            control={control}
            name="targetDetail"
            defaultValue={dataInfo.targetDetail}
            rules={{
              validate: {
                range: (value: any) => valid.range(value, 0, 500),
              },
            }}
            render={({ onChange }: any) => (
              <>
                <textarea className="textarea-input" cols={100} rows={3} onChange={onChange}>
                  {dataInfo.targetDetail}
                </textarea>
                {errors?.targetDetail && (
                  <div className="arrow-box top">{errors?.targetDetail?.message}</div>
                )}
              </>
            )}
          />
        </div>
      </form>
    </ModalTemplate>
  );
}

export default TargetAddEdit;
