import { NavLink, useLocation } from 'react-router-dom';
import { Tooltip } from 'antd';
import './ContentHeader.scss';

// import refreshImage from 'img/exam/menu_refresh.png';

function ContentHeader(props: any) {
  const { title, subTitle, onRefresh, buttons, navButtonList } = props;

  return (
    <div className="common-content-header">
      <div className="content-header-text">
        <div className="content-header-title">{title}</div>
        <div className="content-header-sub-title">{subTitle}</div>
      </div>
      <div className="content-header-btn-area">
        {onRefresh && (
          <div className="refresh-icon" onClick={onRefresh} aria-hidden="true">
            <img src="/img/exam/menu_refresh.png" alt="refresh" />
          </div>
        )}
        <div className="content-header-btn">{buttons}</div>
        {navButtonList && (
          <div className="content-header-nav-btn">
            {navButtonList.map((menu: any) => {
              return menu.isShow ? (
                <Tooltip
                  overlayClassName="black-tooltip full"
                  placement="bottom"
                  title={menu.tooltipText}
                  key={menu.buttonText}
                >
                  <NavLink to={menu.moveLink} className="nav-btn" onClick={menu.clickEvent} exact>
                    {menu.buttonText}
                  </NavLink>
                </Tooltip>
              ) : null;
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default ContentHeader;
