import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import { logDownload } from 'store/log';
import { nowEpoch, timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import './LogMenu.scss';
import { Option } from 'utils/commonValues';

export default function LogMenu(props: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { sort, filter } = useSelector(
    (state: any) => ({
      sort: state.log.param.sort,
      filter: state.log.param.filter,
    }),
    shallowEqual,
  );

  // 로그 다운로드
  const onLogDownload = async () => {
    try {
      const params = {
        filter: JSON.stringify(filter),
        sort: JSON.stringify(sort),
      };

      const response: any = await dispatch(logDownload(params));
      if (response?.data) {
        fileDownload(
          response.data,
          `${Option.serviceName} ${formatMessage({
            id: 'Log_11',
            defaultMessage: '로그',
          })}(${timeFormatFromUTCEpoch(nowEpoch())}).xlsx`,
        );
      }
    } catch (error) {
      console.log('LogMenu onLogDownload', error);
    }
  };

  return (
    <div className="log-page-menu-wrap">
      <div className="menu-button">
        <button
          className="common-button-brand initial-config-bt"
          type="button"
          onClick={onLogDownload}
        >
          {formatMessage({ id: 'Log_3', defaultMessage: '로그 다운로드' })}
        </button>
      </div>
    </div>
  );
}
