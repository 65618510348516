import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { UserOutlined, RightOutlined } from '@ant-design/icons';
import parse from 'html-react-parser';
import Footer from 'components/branch/Footer';
import './Faq.scss';

// import arrowImage from 'img/table/selectbox_number_arrow.png';
// import searchImage from 'img/table/btn_search.png';

export const authLevel = 0;
function Faq() {
  const { formatMessage } = useIntl();
  const [selectedMenu, setSelectedMenu]: any = useState({
    key: 'all',
    text: formatMessage({ id: 'Faq_1', defaultMessage: '전체' }),
    icon: <UserOutlined />,
  });
  const [openSubmenu, setOpenSubmenu]: Array<any> = useState([]);
  const [detailList, setDetailList]: Array<any> = useState([]);
  const [searchText, setSearchText] = useState('');
  const [changeSearch, setChangeSearch] = useState('');

  useEffect(() => {
    // 상세 메뉴 리스트
    const selectItem: any = faqMenuList?.filter((menu: any) => menu.key === selectedMenu.key);
    if (selectItem && selectItem[0]) {
      let newList: Array<any> = [];

      if (selectItem[0].key === 'all') {
        faqMenuList.forEach((menu: any) => {
          menu.contents?.forEach((detail: any) => {
            newList.push(detail);
          });
        });
      } else {
        const detailData = selectItem[0].contents;
        detailData?.forEach((detail: any) => {
          newList.push(detail);
        });
      }

      let filterList = [];
      if (searchText) {
        filterList = newList.filter((detail: any) => {
          return detail.question.includes(searchText) || detail.answer.includes(searchText);
        });
        newList = [...filterList];
      }
      setDetailList(newList);
    }
  }, [selectedMenu?.key, changeSearch]);

  // 메뉴 목록
  const faqMenuList = [
    {
      key: 'all',
      text: formatMessage({ id: 'Faq_1', defaultMessage: '전체' }),
      icon: <UserOutlined />,
    },
    {
      key: 'user',
      text: formatMessage({ id: 'Faq_2', defaultMessage: '가입' }),
      icon: <UserOutlined />,
      contents: [
        {
          question: formatMessage({ id: 'Faq_5', defaultMessage: 'MudFix란 무엇인가요?' }),
          answer: `${formatMessage({
            id: 'Faq_6',
            defaultMessage:
              'MudFix는 정보 보안 인식을 높이기 위한 <b>악성메일 대응 모의 훈련서비스</b>입니다.<br />',
          })}${formatMessage({
            id: 'Faq_7',
            defaultMessage:
              '기업의 경우 큰 돈을 들여 망분리를 했기 때문에 외부가 위협에 노출됐다 하더라도<br />',
          })}${formatMessage({
            id: 'Faq_8',
            defaultMessage: '내부망은 100% 안전하다고 믿고 있습니다.<br />',
          })}${formatMessage({
            id: 'Faq_9',
            defaultMessage: '내부망으로 악성코드 유입은 있을 수 없을거라고 착각을 합니다.<br />',
          })}${formatMessage({
            id: 'Faq_10',
            defaultMessage:
              '내부망과 외부망은 분명 분리되어 있겠지만 이를 연결시켜주는 것은 사람입니다.<br />',
          })}${formatMessage({
            id: 'Faq_11',
            defaultMessage: '많은 기업이 이러한 문제를 안고 있는데,<br />',
          })}${formatMessage({
            id: 'Faq_12',
            defaultMessage: 'MudFix는 이러한 사람들에 대한 <b>훈련을 돕기 위한 서비스</b>입니다.',
          })}`,
        },
        {
          question: formatMessage({
            id: 'Faq_13',
            defaultMessage: 'MudFix이름의 유래는 어디서 왔나요?',
          }),
          answer: `${formatMessage({
            id: 'Faq_14',
            defaultMessage:
              '기업이라는 거대한 공동체 안에서 <b>보안</b>은 모두에게 중요합니다.<br />',
          })}${formatMessage({
            id: 'Faq_15',
            defaultMessage: '하지만 단 한명이라도 기업이라는 공동체의 문을 열어주게 되면<br />',
          })}${formatMessage({
            id: 'Faq_16',
            defaultMessage: '모든 사람들의 노력이 무너지고 맙니다.<br />',
          })}${formatMessage({
            id: 'Faq_17',
            defaultMessage:
              '그러한 사람들뿐만 아니라 모두가 경각심을 갖고 자신의 정보를 지킬 수 있도록 훈련이 필요합니다.<br />',
          })}${formatMessage({
            id: 'Faq_18',
            defaultMessage: "보통 <b>'미꾸라지 한마리가 온 물을 흐린다'</b> 라고 하죠<br />",
          })}${formatMessage({
            id: 'Faq_19',
            defaultMessage: '그래서 그러한 행동을 고치고 정보를 보호하기 위한다는 의미로<br />',
          })}${formatMessage({
            id: 'Faq_20',
            defaultMessage:
              '<b>미꾸라지(Mudfish) + 고치다(fix)의 의미를 갖고 MudFix</b>라고 지어졌습니다.',
          })}`,
        },
        {
          question: formatMessage({
            id: 'Faq_21',
            defaultMessage: 'MudFix 서비스가 왜 필요한가요?',
          }),
          answer: `${formatMessage({
            id: 'Faq_14',
            defaultMessage:
              '기업이라는 거대한 공동체 안에서 <b>보안</b>은 모두에게 중요합니다.<br />',
          })}${formatMessage({
            id: 'Faq_15',
            defaultMessage: '하지만 단 한명이라도 기업이라는 공동체의 문을 열어주게 되면<br />',
          })}${formatMessage({
            id: 'Faq_16',
            defaultMessage: '모든 사람들의 노력이 무너지고 맙니다.<br />',
          })}${formatMessage({
            id: 'Faq_22',
            defaultMessage: '그렇기 때문에 MudFix를 이용하여 보안을 위한 경각심을 갖도록<br />',
          })}${formatMessage({
            id: 'Faq_23',
            defaultMessage: '지속적으로 훈련을 실시해야 합니다.',
          })}`,
        },
        {
          question: formatMessage({
            id: 'Faq_24',
            defaultMessage: '비회원도 훈련이 가능한가요?',
          }),
          answer: `${formatMessage({
            id: 'Faq_25',
            defaultMessage: '비회원은 훈련이 불가능합니다.<br />',
          })}${formatMessage({
            id: 'Faq_26',
            defaultMessage: 'MudFix는 서비스 가입시 5명까지 무료로 이용해 보실 수 있습니다.<br />',
          })}${formatMessage({
            id: 'Faq_27',
            defaultMessage: '서비스 가입 후 이용해 보세요.',
          })}`,
        },
        {
          question: formatMessage({
            id: 'Faq_28',
            defaultMessage: '서비스 가입시 필요한 정보는 무엇이 있나요?',
          }),
          answer: `${formatMessage({
            id: 'Faq_29',
            defaultMessage:
              '사용하실 이메일, 비밀번호, 이름, 서비스명으로 간단하게 가입이 가능합니다.',
          })}`,
        },
        {
          question: formatMessage({
            id: 'Faq_30',
            defaultMessage: '아이디/비밀번호를 분실했습니다.',
          }),
          answer: `${formatMessage({
            id: 'Faq_31',
            defaultMessage: '로그인 화면 하단에 비밀번호 찾기를 통하여<br />',
          })}${formatMessage({
            id: 'Faq_32',
            defaultMessage: '새로운 비밀번호로 재설정 할 수 있습니다.',
          })}`,
        },
        {
          question: formatMessage({
            id: 'Faq_33',
            defaultMessage: '자사 자체적으로 사용하기위한 도입이 가능한가요?',
          }),
          answer: `${formatMessage({
            id: 'Faq_34',
            defaultMessage: '당연히 가능합니다.<br />',
          })}${formatMessage({
            id: 'Faq_35',
            defaultMessage: '자세한 사항은 저희 회사로 문의해 주세요.',
          })}`,
        },
      ],
    },
    {
      key: 'license',
      text: formatMessage({ id: 'Faq_3', defaultMessage: '비용' }),
      icon: <UserOutlined />,
      contents: [
        {
          question: formatMessage({
            id: 'Faq_36',
            defaultMessage: '라이선스 기간은 어떻게 되나요?',
          }),
          answer: `${formatMessage({
            id: 'Faq_37',
            defaultMessage: '모든 라이선스의 기간은 1년으로 동일합니다.',
          })}`,
        },
        {
          question: formatMessage({
            id: 'Faq_38',
            defaultMessage: '라이선스와 구축형의 차이는 무엇인가요?',
          }),
          answer: `${formatMessage({
            id: 'Faq_39',
            defaultMessage: '<b>라이선스</b>',
          })}${formatMessage({
            id: 'Faq_40',
            defaultMessage:
              '<div>- 별도의 서버장비 없이 저희측에서 제공하는 서버를 이용합니다.</div>',
          })}${formatMessage({
            id: 'Faq_41',
            defaultMessage: '<b>구축형</b>',
          })}${formatMessage({
            id: 'Faq_42',
            defaultMessage:
              '<div>- 고객사에 서버 장비를 포함하여 MudFix를 고객사에 맞춰 서비스를 이용합니다.</div>',
          })}`,
        },
        {
          question: formatMessage({
            id: 'Faq_43',
            defaultMessage: '구축형 라이선스 구입방식은 어떻게 이루어 지나요?',
          }),
          answer: `${formatMessage({
            id: 'Faq_44',
            defaultMessage: '구축형 라이선스의 구입은 저희에게 직접 문의해 주세요.',
          })}`,
        },
        {
          question: formatMessage({
            id: 'Faq_45',
            defaultMessage: '환불 정책은 어떻게 되나요?',
          }),
          answer: `${formatMessage({
            id: 'Faq_46',
            defaultMessage: '사용하신 라이선스에 대해서는 환불이 불가합니다.<br />',
          })}${formatMessage({
            id: 'Faq_47',
            defaultMessage: '또한 구입하신지 2주가 지난 라이선스도 환불이 불가합니다.<br /',
          })}${formatMessage({
            id: 'Faq_48',
            defaultMessage: '저희 고객센터로 문의해주세요.',
          })}`,
        },
      ],
    },
    {
      key: 'target',
      text: formatMessage({ id: 'Target_1', defaultMessage: '훈련 대상' }),
      icon: <UserOutlined />,
      contents: [
        {
          question: formatMessage({
            id: 'Faq_49',
            defaultMessage: '일괄등록이 가능한가요?',
          }),
          answer: `${formatMessage({
            id: 'Faq_50',
            defaultMessage: "단일 대상을 <b>'대상자 추가'</b>를 통해서 등록이 가능하고 <br />",
          })}${formatMessage({
            id: 'Faq_51',
            defaultMessage:
              "다수의 대상을 추가할 때에는 <b>'엑셀 업로드'</b>에서 양식을 다운로드 하고<br />",
          })}${formatMessage({
            id: 'Faq_52',
            defaultMessage: '엑셀 양식을 작성하여 업로드를 통해서 일괄로 등록이 가능합니다.',
          })}`,
        },
        {
          question: formatMessage({
            id: 'Faq_53',
            defaultMessage: '그룹별로 대상을 관리할 수 있나요?',
          }),
          answer: `${formatMessage({
            id: 'Faq_54',
            defaultMessage: '저희는 태그라는 그룹핑을 통해서 관리할 수 있습니다.<br />',
          })}${formatMessage({
            id: 'Faq_55',
            defaultMessage:
              '해쉬태그와 같은 기능으로 어떤 형태든 사용자가 손쉽게 태그를 만들고,<br />',
          })}${formatMessage({
            id: 'Faq_56',
            defaultMessage: '대상자를 쉽게 그룹핑 하고 관리할 수 있습니다.',
          })}`,
        },
      ],
    },
    {
      key: 'format',
      text: formatMessage({ id: 'StartExam_5', defaultMessage: '훈련 양식' }),
      icon: <UserOutlined />,
      contents: [
        {
          question: formatMessage({
            id: 'Faq_57',
            defaultMessage: '그룹별로 훈련 유형을 선택할 수 있나요?',
          }),
          answer: `${formatMessage({
            id: 'Faq_58',
            defaultMessage:
              '다양한 훈련 양식을 제공하고 마찬가지로 태그기능을 제공하고 있습니다.<br />',
          })}${formatMessage({
            id: 'Faq_59',
            defaultMessage: '원하는 그룹 형태를 태그로 만들어서 훈련을 진행하시면 됩니다.',
          })}`,
        },
        {
          question: formatMessage({
            id: 'Faq_60',
            defaultMessage: '훈련 양식을 수정할 수 있나요?',
          }),
          answer: `${formatMessage({
            id: 'Faq_61',
            defaultMessage:
              '기본적으로 제공되는 훈련양식을 사용자가 원하는대로 수정할 수 있도록<br />',
          })}${formatMessage({
            id: 'Faq_62',
            defaultMessage: '기본 템플릿을 복제하여 사용할 수 있습니다.<br />',
          })}${formatMessage({
            id: 'Faq_63',
            defaultMessage:
              '원하는 템플릿을 간단하게 복제해서 다양한 형태로 수정해서 훈련을 진행할 수 있습니다.',
          })}`,
        },
      ],
    },
    {
      key: 'startExam',
      text: formatMessage({ id: 'StartExam_1', defaultMessage: '훈련 실시' }),
      icon: <UserOutlined />,
      contents: [
        {
          question: formatMessage({
            id: 'Faq_64',
            defaultMessage: '훈련 실행 전 발송이 가능한 상태인지 점검할 수 있나요?',
          }),
          answer: `${formatMessage({
            id: 'Faq_65',
            defaultMessage:
              '네 테스트 메일을 발송해서 각각의 대상자에게 메일이 정상적으로 발송되는지<br />',
          })}${formatMessage({
            id: 'Faq_66',
            defaultMessage: '미리 확인해 볼 수 있습니다.',
          })}`,
        },
        {
          question: formatMessage({
            id: 'Faq_67',
            defaultMessage: '보안상 웹 상에서 이 서비스를 진행하기 어려운데, 다른 방법이 있을까요?',
          }),
          answer: `${formatMessage({
            id: 'Faq_68',
            defaultMessage: '외부에서 제공하는 메일서버를 이용할 수도 있지만,<br />',
          })}${formatMessage({
            id: 'Faq_69',
            defaultMessage:
              '구축형으로 고객사에 직접 서버를 설치하고 고객사내에 메일서버를 이용해서 <br />',
          })}${formatMessage({
            id: 'Faq_70',
            defaultMessage: '서비스를 진행 가능합니다.',
          })}`,
        },
      ],
    },
    {
      key: 'result',
      text: formatMessage({ id: 'Exam_37', defaultMessage: '훈련 결과' }),
      icon: <UserOutlined />,
      contents: [
        {
          question: formatMessage({
            id: 'Faq_71',
            defaultMessage: '감염 PC 및 파일을 따로 관리 가능한가요?',
          }),
          answer: `${formatMessage({
            id: 'Faq_72',
            defaultMessage:
              '훈련 실시 후 훈련 결과보기에서 각 대상자, 태그, 감염 PC, 감염 파일별로<br />',
          })}${formatMessage({
            id: 'Faq_73',
            defaultMessage: '사용자가 손쉽게 결과를 확인할 수 있습니다.',
          })}`,
        },
        {
          question: formatMessage({
            id: 'Faq_74',
            defaultMessage:
              '감염자의 파일 목록을 볼 수 있는데 저의 파일을 실제로 복제하는 것인가요?',
          }),
          answer: `${formatMessage({
            id: 'Faq_75',
            defaultMessage:
              '실제 파일을 복제하는 것이 아닌 해당 감염 PC의 파일 목록만 저장합니다.<br />',
          })}`,
        },
      ],
    },
    {
      key: 'etc',
      text: formatMessage({ id: 'Faq_4', defaultMessage: '기타' }),
      icon: <UserOutlined />,
      contents: [
        {
          question: formatMessage({
            id: 'Faq_76',
            defaultMessage: 'SMTP 설정이 필요한 이유는 무엇인가요?',
          }),
          answer: `${formatMessage({
            id: 'Faq_77',
            defaultMessage:
              '저희가 제공하는 메일서버보다 고객님의 메일 서버를 설정하는 것이 <br />',
          })}${formatMessage({
            id: 'Faq_78',
            defaultMessage: '서비스를 이용하는데 있어서 더 효율적이고 원활한 사용이 가능합니다.',
          })}`,
        },
        {
          question: formatMessage({
            id: 'Faq_79',
            defaultMessage: '1:1로 문의를 드리고 싶습니다.',
          }),
          answer: `${formatMessage({
            id: 'Faq_80',
            defaultMessage: '아래의 방법으로 저희에게 연락을 주시면 1:1로 도움을 드립니다.<br />',
          })}${formatMessage({
            id: 'Faq_81',
            defaultMessage: '연락 가능한 시간: 월 ~ 금 오전 10:00 ~ 18:00',
          })}`,
        },
      ],
    },
  ];

  // 선택 메뉴 변경 시
  const changeSelectMenu = (menu: any) => {
    setSelectedMenu(menu);
    setOpenSubmenu([]);
  };

  // 상세 메뉴 열기/닫기
  const openDetail = (target: string) => {
    const tempMenu = [...openSubmenu];
    if (openSubmenu.includes(target)) {
      tempMenu.splice(openSubmenu.indexOf(target), 1);
    } else {
      tempMenu.push(target);
    }
    setOpenSubmenu(tempMenu);
  };

  // 검색
  const onSearch = (text: string) => {
    setChangeSearch(text);
  };

  return (
    <div className="faq-page">
      <div className="faq-body">
        <div className="faq-header">
          <div>{`MudFix ${formatMessage({ id: 'Faq_82', defaultMessage: '고객 센터' })}`}</div>
          <div className="search-area">
            <input
              className="search-input"
              type="text"
              placeholder={formatMessage({ id: 'Filter_8', defaultMessage: '검색' })}
              value={searchText}
              onChange={(e: any) => setSearchText(e.target.value)}
              onKeyDown={(e: any) => {
                if (e.key === 'Enter') onSearch(searchText);
              }}
            />
            <div className="search-icon" onClick={() => onSearch(searchText)} aria-hidden="true">
              <img src="/img/table/btn_search.png" alt="search" />
            </div>
          </div>
        </div>
        <div className="faq-current">
          <div className="current-box">
            <div className="current-label">
              {formatMessage({ id: 'Faq_82', defaultMessage: '고객 센터' })}
              <RightOutlined />
              <span className="detail-title">{selectedMenu?.text}</span>
            </div>
          </div>
        </div>
        <div className="faq-content">
          <div className="faq-sidebar">
            {faqMenuList.map((menu: any) => {
              return (
                <div
                  className={`menu-item${selectedMenu?.key === menu.key ? ' active' : ''}`}
                  key={menu.key}
                  onClick={() => changeSelectMenu(menu)}
                  aria-hidden="true"
                >
                  {menu.text}
                </div>
              );
            })}
          </div>
          <div className="faq-content-detail">
            <div className="detail-title">{selectedMenu?.text}</div>

            {detailList.length > 0 ? (
              detailList.map((detail: any, index: number) => {
                return (
                  <div
                    key={`${detail.question}${detail.answer}`}
                    className={`detail-menu${openSubmenu.includes(detail.question) ? ' open' : ''}`}
                  >
                    <div
                      className="detail-question"
                      onClick={() => openDetail(detail.question)}
                      aria-hidden="true"
                    >
                      <div className="index">{index + 1}</div>
                      <div className="faq-row">
                        {detail.question}
                        <img
                          src="/img/table/selectbox_number_arrow.png"
                          alt="arrow"
                          className="arrow-img"
                        />
                      </div>
                    </div>
                    <div className="detail-answer">{parse(detail.answer)}</div>
                  </div>
                );
              })
            ) : (
              <div className="detail-menu no-result">
                {formatMessage({ id: 'Filter_9', defaultMessage: '검색 결과 없음' })}
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Faq;
