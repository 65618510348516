import QuizList from 'components/branch/quiz/QuizList';
import QuizMenu from 'components/branch/quiz/QuizMenu';
import MainTitle from 'components/common/admin/MainTitle';
import { useIntl } from 'react-intl';
import "../mudmanage/Conf.scss";

export default function Examtemplate(props: any) {
  const { formatMessage } = useIntl();
  

  return (
    <div className="admin-basic-conf table">
      <MainTitle type="table" title={formatMessage({ id: 'Edu_63', defaultMessage: '퀴즈' })}>
        <QuizMenu />
      </MainTitle>
      <div className="react-table-area">
        <QuizList />
      </div>
    </div>
  );
}