import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { NavLink, Redirect, withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import ModalTemplate from 'components/common/ModalTemplate';
import { getLogout } from 'store/login';
import { getShareService, changeShareService } from 'store/share';
import { setUser, menuOnoff } from 'store/user';
import { editPassword } from 'store/admin';
import { Menu, Dropdown } from 'antd';
import {
  GlobalOutlined,
  CheckSquareFilled,
  BorderOutlined,
  HomeOutlined,
  DoubleLeftOutlined,
} from '@ant-design/icons';
import { Option, updateVersion } from 'utils/commonValues';
import { timeDiffFromUTCEpoch } from 'utils/commonFunctions';
import * as valid from 'utils/validation';
import StartExam from 'components/branch/exam/StartExam';
import FormTextField from 'components/common/FormTextField';
import StartEdu from 'components/branch/edu/StartEdu';
import StartExamSimple from 'components/branch/exam/StartExamSimple';
import './Header.scss';

// import helpImage from 'img/header/icon_help.png';
// import settingImage from 'img/header/icon_setting.png';
// import userImage from 'img/header/icon_user.png';
// import markImage from 'img/header/mail_auth.png';
// import mfLogo from 'img/logo/mf_logo_large.png';
// import sxLogo from 'img/logo/sx_logo_large.png';
// import closeImage from 'img/modal/close_black.png';
// import myServiceImage from 'img/share/my-service.png';
// import otherServiceImage from 'img/share/other-service.png';
// import arrowImage from 'img/share/arrow.png';
// import serviceImage from 'img/share/share-on.png';
// import serviceOffImage from 'img/share/share-off.png';
// import selectedImage from 'img/share/selected.png';

function Header(props: any) {
  const userData = useSelector((state: any) => state.user.user, shallowEqual);
  const adminMenu = useSelector((state: any) => state.user.adminMenu, shallowEqual);
  const shareData = useSelector((state: any) => state.share.data, shallowEqual);
  const dispatch = useDispatch();
  const [modalTarget, setModalTarget] = useState('');
  const [selectedInfo, setSelectedInfo]: any = useState({});
  const [serviceChangeError, setServiceChangeError] = useState('');
  const [errorMessage, setErrorMessage] = useState<any>({
    type: '',
    content: '',
  });
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();
  const { errors, register, handleSubmit } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    if (auth === 1) {
      // 로그인 시 서비스 공유 데이터 조회
      dispatch(getShareService());
    }
  }, [userData]);

  const localLang = localStorage.getItem('language');
  let curLanguage = 'en';
  if (localLang === 'ko') {
    curLanguage = '한국어';
  } else if (localLang === 'ja') {
    curLanguage = '日本語';
  } else if (localLang === 'en') {
    curLanguage = 'English';
  }

  // Manager, Attach version
  const version = Option.customer === 'saxa' ? 'V1.2' : 'V1.0';

  // copyright에 표시할 내용
  let copyrightText = 'Copyright©JiranSecurity. All rights reserved.';
  if (Option.customer === 'hyundai') {
    copyrightText = 'HYUNDAI AUTOEVER Corp.';
  }

  // 로그아웃
  const onLogout = async (path?: string) => {
    await dispatch(getLogout());

    if (path) {
      props.history.push(path);
    } else {
      props.history.push('/login/basic');
    }
  };

  // 서비스 변경 이벤트
  const onChangeService = async () => {
    setLoading(true);

    try {
      const params = {
        userServiceNo: selectedInfo.userServiceNo,
      };
      const response: any = await dispatch(changeShareService(params));
      const { loginResult, userData, sessionTimeout } = response?.data?.list;
      if (loginResult === 1) {
        await dispatch(setUser({ user: userData, sessionTimeout: sessionTimeout }));
        props.history.push('/mypage');
        // window.location.reload();
      } else if (loginResult === 3) {
        //  No Allow Accesss Ip
        setServiceChangeError('No Allow Accesss Ip');
      }
      setLoading(false);
      toggleModal('');
    } catch (error) {
      console.log('onChangeService', error);
    }
  };

  // 언어 변경
  const changeLanguage = (lang: any) => {
    localStorage.setItem('language', lang);
    window.location.reload();
  };

  // 모달 열기/닫기
  const toggleModal = (target: string) => {
    setModalTarget(target ? target : '');
  };

  const selectService = (serviceInfo: any) => {
    toggleModal('service');
    setSelectedInfo(serviceInfo);
  };

  // 비밀번호 변경
  const changePasswd = async (formData: any) => {
    if (!loading) {
      setLoading(true);
      try {
        if (formData.userPasswd === formData.userPasswdCheck) {
          const params = {
            beforePasswd: formData.beforePassword,
            newPasswd: formData.userPasswd,
          };

          const respObj: any = await dispatch(editPassword(params));
          if (respObj?.status === 200) {
            if (respObj.data && Array.isArray(respObj.data.list)) {
              setErrorMessage({
                type: 'success',
                content: formatMessage({
                  id: 'Password_21',
                  defaultMessage: '비밀번호가 변경 되었습니다.',
                }),
              });
              setModalTarget('');
            } else if (Array.isArray(respObj.data.error)) {
              if (respObj.data.error[0].errInfo === 'Passwd five time mismatched.') {
                respObj.data.error[0].errInfo = formatMessage({
                  id: 'Password_24',
                  defaultMessage: '비밀번호가 5회 틀렸습니다.',
                });
              }
              setErrorMessage({
                type: 'beforePassword',
                content: respObj.data.error[0].errInfo,
              });
            } else {
              setErrorMessage({
                type: 'beforePassword',
                content: formatMessage({
                  id: 'Password_14',
                  defaultMessage: '비밀번호가 틀렸습니다.',
                }),
              });
            }
          }
        } else {
          setErrorMessage({
            type: 'userPasswdCheck',
            content: formatMessage({
              id: 'Password_15',
              defaultMessage: '비밀번호가 일치하지 않습니다.',
            }),
          });
        }

        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    }
  };

  // language dropdown menu
  const langMenu = (
    <Menu>
      {!Option.isJapan && (
        <Menu.Item
          onClick={() => {
            changeLanguage('ko');
          }}
        >
          한국어
        </Menu.Item>
      )}
      <Menu.Item
        onClick={() => {
          changeLanguage('ja');
        }}
      >
        日本語
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          changeLanguage('en');
        }}
      >
        English
      </Menu.Item>
    </Menu>
  );

  // 중간 메뉴 영역
  let navComponent = null;
  // 우측 버튼 영역
  let rightButton = null;
  // 좌측 버튼 영역
  let leftButton = (
    // <div
    //   className={`header-logo ${Option.isSaxa === 1 && 'saxa-logo'}`}
    //   onClick={() => props.history.push('/dashboard')}
    //   onKeyDown={() => props.history.push('/dashboard')}
    //   aria-hidden="true"
    //   style={{ background: 'url(/img/logo/mf_logo_white_small_v.png) no-repeat center' }}
    // />
    <div
      className="header-logo"
      onClick={() => props.history.push('/dashboard')}
      onKeyDown={() => props.history.push('/dashboard')}
      aria-hidden="true"
    >
      <img src="/img/logo/mf_logo_white_small_v.png" alt="logo" />
    </div>
  );

  const { auth } = props;
  // 사용자 로그인 시
  if (auth === 1) {
    navComponent = (
      <>
        <NavLink to="/exam" className="header-menu" activeClassName="active">
          {formatMessage({ id: 'Header_1', defaultMessage: '훈련결과' })}
        </NavLink>
        {Option.isEdu === 1 && userData?.userActived === 1 && (
          <NavLink to="/eduresult" className="header-menu" activeClassName="active">
            {formatMessage({ id: 'Header_3', defaultMessage: '교육결과' })}
          </NavLink>
        )}
        {Option.isDeclare === 1 && (
          <NavLink to="/declare" className="header-menu" activeClassName="active">
            {formatMessage({ id: 'Header_2', defaultMessage: '신고결과' })}
          </NavLink>
        )}
        <NavLink to="/target" className="header-menu" activeClassName="active">
          {formatMessage({ id: 'Header_4', defaultMessage: '대상관리' })}
        </NavLink>
        <NavLink to="/template" className="header-menu" activeClassName="active">
          {formatMessage({ id: 'Header_5', defaultMessage: '템플릿관리' })}
        </NavLink>
      </>
    );

    const helpMenuString: any = {
      Manual: formatMessage({ id: 'Header_13', defaultMessage: '훈련 매뉴얼' }),
      EduManual: formatMessage({ id: 'Header_14', defaultMessage: '교육 매뉴얼' }),
    };

    // help dropdown menu
    const helpMenu = (
      <Menu>
        <Menu.Item onClick={() => toggleModal('info')}>
          {`${Option.serviceName} ${formatMessage({ id: 'Header_6', defaultMessage: '정보' })}`}
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            let url = '/fdata/serve/MudFix_V1.0_ExamManual_en.pdf';
            if (Option.isJapan) {
              url = `/fdata/serve/${
                Option.customer === 'saxa' ? 'Maitra' : 'Mudfix'
              }_V1.0_ExamManual_japan.pdf`;
            } else if (localLang === 'ko') {
              url = '/fdata/serve/MudFix_V1.0_ExamManual.pdf';
            }

            window.open(url);
          }}
        >
          {`${helpMenuString.Manual}`}
        </Menu.Item>
        {Option.isEdu === 1 && (
          <Menu.Item
            onClick={() => {
              let url = '/fdata/serve/MudFix_V1.0_EduManual_en.pdf';
              if (Option.isJapan) {
                url = `/fdata/serve/${
                  Option.customer === 'saxa' ? 'Maitra' : 'Mudfix'
                }_V1.0_EduManual_japan.pdf`;
              } else if (localLang === 'ko') {
                url = '/fdata/serve/MudFix_V1.0_EduManual.pdf';
              }

              window.open(url);
            }}
          >
            {`${helpMenuString.EduManual}`}
          </Menu.Item>
        )}
      </Menu>
    );

    // setting dropdown menu
    const settingMenu = (
      <Menu>
        <Menu.Item>
          <NavLink to="/mypage/smtp" className="header-btn">
            SMTP
          </NavLink>
        </Menu.Item>
        {userData?.serviceNo === userData?.originalServiceNo && (
          <Menu.Item>
            <NavLink to="/mypage/share" className="header-btn">
              {formatMessage({ id: 'ServiceShare_1', defaultMessage: '서비스 공유' })}
            </NavLink>
          </Menu.Item>
        )}
        {Option.isEdu && (
          <Menu.Item>
            <NavLink to="/mypage/edu" className="header-btn">
              {formatMessage({ id: 'MyPage_2', defaultMessage: '교육설정' })}
            </NavLink>
          </Menu.Item>
        )}
        {Option.isDeclare && (
          <Menu.Item>
            <NavLink to="/mypage/declare" className="header-btn">
              {formatMessage({ id: 'MyPage_3', defaultMessage: '신고설정' })}
            </NavLink>
          </Menu.Item>
        )}
        {Option.customer === 'saxa' && (
          <Menu.Item>
            <NavLink to="/mypage/notification" className="header-btn">
              {formatMessage({ id: 'MyPage_9', defaultMessage: '알림설정' })}
            </NavLink>
          </Menu.Item>
        )}
      </Menu>
    );

    // my dropdown menu
    const myMenu = (
      <Menu>
        {userData?.serviceNo === userData?.originalServiceNo && (
          <Menu.Item>
            <NavLink to="/mypage/profile" className="header-btn">
              {formatMessage({ id: 'MyPage_1', defaultMessage: '내 정보' })}
            </NavLink>
          </Menu.Item>
        )}
        <Menu.Item>
          <NavLink to="/mypage/license" className="header-btn">
            {formatMessage({ id: 'License_1', defaultMessage: '라이선스' })}
          </NavLink>
        </Menu.Item>
        <div className="divider" />
        {Option.customer !== 'saxa' && (
          <>
            {!Option.isJapan && (
              <Menu.Item
                onClick={() => {
                  changeLanguage('ko');
                }}
              >
                {localLang === 'ko' ? <CheckSquareFilled /> : <BorderOutlined />}
                한국어
              </Menu.Item>
            )}
            <Menu.Item
              onClick={() => {
                changeLanguage('ja');
              }}
            >
              {localLang === 'ja' ? <CheckSquareFilled /> : <BorderOutlined />}
              日本語
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                changeLanguage('en');
              }}
            >
              {localLang === 'en' ? <CheckSquareFilled /> : <BorderOutlined />}
              English
            </Menu.Item>
            <div className="divider" />
          </>
        )}

        <Menu.Item className="center" onClick={() => onLogout()}>
          Logout
        </Menu.Item>
      </Menu>
    );

    // 서비스 리스트
    const serviceSelectList = shareData.filter(
      (shareService: any) => shareService.userNo === userData.userNo,
    );
    // service dropdown menu
    const serviceMenu: any[] = [];
    let currentServiceName = '';
    serviceSelectList.forEach((serviceinfo: any) => {
      if (serviceinfo.userNo === userData.userNo) {
        serviceMenu.push(
          <Menu.Item key={serviceinfo.serviceNo}>
            <div
              className="change-service-box"
              onClick={() => selectService(serviceinfo)}
              aria-hidden="true"
            >
              {serviceinfo.serviceNo === userData.serviceNo ? (
                // <img src={serviceImage} alt="service-icon" />
                <img src="/img/share/share-on.png" alt="service-icon" />
              ) : (
                // <img src={serviceOffImage} alt="service-icon" />
                <img src="/img/share/share-off.png" alt="service-icon" />
              )}
              <div
                className={`service-item ${
                  serviceinfo.serviceNo === userData.serviceNo ? 'selected' : ''
                }`}
              >
                <div className="service-main-text">
                  {serviceinfo.serviceName}
                  {serviceinfo.serviceNo === userData.serviceNo && (
                    // <img src={selectedImage} alt="selected" />
                    <img src="/img/share/selected.png" alt="selected" />
                  )}
                </div>
                <div className="service-sub-text">
                  {userData.originalServiceNo === serviceinfo.serviceNo
                    ? formatMessage({ id: 'ServiceShare_3', defaultMessage: '내 서비스' })
                    : `${serviceinfo.serviceUserName}(${serviceinfo.serviceUserEmail})`}
                </div>
              </div>
            </div>
          </Menu.Item>,
        );
      }
      // 현재 서비스명
      if (serviceinfo.serviceNo === userData.serviceNo) {
        currentServiceName = serviceinfo.serviceName;
      }
    });

    const remainDate = timeDiffFromUTCEpoch(userData ? userData.regEpoch + 259200 : 0);
    rightButton = (
      <>
        {/* 도움말 */}
        <Dropdown
          overlayClassName="header-dropdown"
          overlay={helpMenu}
          trigger={['click']}
          placement="bottomRight"
          arrow
        >
          <div className="header-icon-area">
            {/* <img className="icon help" src={helpImage} alt="help" /> */}
            <img src="/img/header/icon_help.png" alt="help" className="icon help" />
          </div>
        </Dropdown>

        {/* 설정 */}
        <Dropdown
          overlayClassName="header-dropdown"
          overlay={settingMenu}
          trigger={['click']}
          placement="bottomRight"
          arrow
        >
          <div className="header-icon-area">
            {/* <img className="icon setting" src={settingImage} alt="setting" /> */}
            <img src="/img/header/icon_setting.png" alt="setting" className="icon setting" />
          </div>
        </Dropdown>

        {/* 서비스 변경 */}
        {serviceSelectList?.length > 1 && (
          <Dropdown
            overlayClassName="header-dropdown header-share"
            overlay={<Menu>{serviceMenu}</Menu>}
            trigger={['click']}
            placement="bottomRight"
            arrow
          >
            <div
              className="header-icon-area change-service"
              title={formatMessage({
                id: 'Service_9',
                defaultMessage: '서비스 변경',
              })}
            >
              {userData.serviceNo === userData.originalServiceNo ? (
                // <img className="icon service" src={myServiceImage} alt="my" />
                <img className="icon service" src="/img/share/my-service.png" alt="my" />
              ) : (
                // <img className="icon service" src={otherServiceImage} alt="other" />
                <img className="icon service" src="/img/share/other-service.png" alt="other" />
              )}
              <span className="service-name">{currentServiceName}</span>
              {/* <img className="icon" src={arrowImage} alt="arrow" /> */}
              <img className="icon" src="/img/share/arrow.png" alt="arrow" />
            </div>
          </Dropdown>
        )}

        {/* 사용자 */}
        <Dropdown
          overlayClassName="header-dropdown"
          overlay={myMenu}
          trigger={['click']}
          placement="bottomRight"
          arrow
        >
          <div
            className={`header-icon-area ${
              userData && userData.userActived === 0 ? 'no-active' : ''
            }`}
          >
            {/* <img className="icon user" src={userImage} alt="user" /> */}
            <img className="icon user" src={`/img/header/${Option.isJapan ? 'icon_user' : 'icon_user2'}.png`} alt="user" />
            {/* 비활성 사용자 안내 툴팁 */}
            {userData && userData.userActived === 0 && (
              <>
                {/* <img className="point-mark" src={markImage} alt="mark" /> */}
                <img className="point-mark" src="/img/header/mail_auth.png" alt="mark" />
                <div className="no-active-tooltip">
                  <div>
                    {remainDate
                      ? `${formatMessage({
                          id: 'Header_8',
                          defaultMessage: '사용자 인증 만료 기간이',
                        })} 
                        ${remainDate} 
                        ${formatMessage({
                          id: 'Header_9',
                          defaultMessage: '남았습니다.',
                        })}`
                      : formatMessage({
                          id: 'Header_10',
                          defaultMessage: '사용자 인증 기간이 만료되었습니다.',
                        })}
                  </div>
                  <div className="small-text">
                    {`* ${formatMessage({
                      id: 'Header_11',
                      defaultMessage: '가입 인증 메일을 통해 계정을 활성화하세요.',
                    })}`}
                  </div>
                </div>
              </>
            )}
          </div>
        </Dropdown>
      </>
    );
  }
  // 관리자 로그인시
  else if (auth === 2 && !window.location.pathname.includes('/login')) {
    leftButton = (
      <div className="header-settings left">
        <button type="button" className="header-btn" onClick={() => dispatch(menuOnoff())}>
          <DoubleLeftOutlined
            style={
              !adminMenu
                ? { transform: 'rotate(-90deg)', paddingRight: '2px' }
                : { paddingRight: '2px' }
            }
          />
          {formatMessage({ id: 'Config_1', defaultMessage: '설정' })}
        </button>
      </div>
    );

    navComponent = (
      // {`header-logo ${Option.isSaxa === 1 && 'saxa-logo'}`}
      <div className="header-logo">
        <img src="/img/logo/mf_logo_white_small_v.png" alt="logo" />
      </div>
    );

    rightButton = (
      <>
        {/* 서비스 */}
        <NavLink className="header-btn" to="/login/basic">
          <HomeOutlined />
          {formatMessage({ id: 'Login_9', defaultMessage: '서비스' })}
        </NavLink>
        {/* 언어 선택 */}
        {Option.customer !== 'saxa' && (
          <Dropdown overlay={langMenu} trigger={['click']}>
            <button type="button" className="header-btn">
              <GlobalOutlined />
              {curLanguage}
            </button>
          </Dropdown>
        )}
        {/* 비밀번호 변경 */}
        <button type="button" className="header-btn" onClick={() => setModalTarget('passwd')}>
          {formatMessage({ id: 'Password_6', defaultMessage: '비밀번호 변경' })}
        </button>
        {/* 로그아웃 */}
        <button className="header-btn" type="button" onClick={() => onLogout('/mudlogin')}>
          Logout
        </button>
      </>
    );
  }
  // 미로그인 시
  else {
    navComponent =
      Option.isJapan || localLang === 'ja' ? (
        <></>
      ) : (
        <>
          <NavLink exact to="/intro" className="header-menu" activeClassName="active">
            {Option.serviceName}
          </NavLink>
          {Option.customer !== 'hyundai' && (
            <NavLink to="/faq" className="header-menu" activeClassName="active">
              FAQ
            </NavLink>
          )}
        </>
      );

    rightButton = (
      <>
        {/* 언어 선택 */}
        {Option.customer !== 'saxa' && (
          <Dropdown overlay={langMenu} trigger={['click']}>
            <button type="button" className="header-btn">
              <GlobalOutlined />
              {curLanguage}
            </button>
          </Dropdown>
        )}
        {/* 로그인 */}
        <NavLink className="header-btn" to="/login/basic">
          {formatMessage({ id: 'Login_1', defaultMessage: '로그인' })}
        </NavLink>
        {/* 서비스 가입 */}
        {!!Option.isSaas && Option.customer !== 'hyundai' && (
          <NavLink className="header-btn" to="/login/register">
            {formatMessage({ id: 'Login_2', defaultMessage: '서비스 가입' })}
          </NavLink>
        )}
      </>
    );
  }

  const pathName = window.location.pathname;
  // 관리자 페이지, 교육 페이지에서는 header 안보이도록
  const headerShow = !pathName?.includes('/mudlogin') && !pathName?.includes('/edumain');

  return (
    <>
      {headerShow && (
        <div className="header">
          {leftButton}
          {auth === 1 && (
            <div className="header-action-buttons">
              <button
                type="button"
                className="brand-square-btn"
                onClick={() => toggleModal(Option.customer === 'saxa' ? 'examSimple' : 'exam')}
              >
                {formatMessage({ id: 'StartExam_1', defaultMessage: '훈련 실시' })}
              </button>
              {/* 훈련 실시 모달 - 간이화 */}
              {modalTarget === 'examSimple' && (
                <StartExamSimple onCloseModal={() => toggleModal('')} />
              )}
              {/* 훈련 실시 모달 - 일반 */}
              {modalTarget === 'exam' && (
                <StartExam onCloseModal={() => toggleModal('')} visible={modalTarget === 'exam'} />
              )}
              {/* 교육 실시 */}
              {Option.isEdu === 1 && userData?.userActived === 1 && <StartEdu />}
            </div>
          )}
          <div className="header-router-link">{navComponent}</div>
          <div className={`header-settings ${Option.customer}`}>{rightButton}</div>

          {/* 머드픽스 정보 모달 */}
          <ModalTemplate
            className="info-modal modal-464"
            isCloseBlack
            visible={modalTarget === 'info'}
            onCancel={() => toggleModal('')}
            centered
            footer={null}
            transitionName=""
            maskTransitionName=""
          >
            <div className="version-logo">
              {/* <img
                src={Option.isSaxa === 1 ? sxLogo : mfLogo}
                alt={Option.isSaxa === 1 ? 'sx-logo' : 'mf-logo'}
              /> */}
              <img src="/img/logo/mf_logo_large.png" alt="logo" />
              <span>{version}</span>
            </div>
            <div className="version-text">
              {Option.serviceName} Manager: {version}
            </div>
            <div className="version-text">
              {Option.serviceName} Attach: {version}
            </div>
            {!Option.isGs && (
              <div className="version-text">
                {Option.serviceName} Update Version: {updateVersion}
              </div>
            )}
            {Option.isJapan ? (
              <div className="copyright">
                <a
                  href={`${
                    Option.customer === 'saxa'
                      ? 'https://www.saxa.co.jp/'
                      : 'https://www.jsecurity.co.jp/'
                  }`}
                >
                  {`© ${new Date().getFullYear()} ${
                    Option.customer === 'saxa' ? 'サクサ株式会社' : '株式会社 JSecurity'
                  }`}
                </a>
              </div>
            ) : (
              <div className="copyright">{copyrightText}</div>
            )}
          </ModalTemplate>

          {/* 서비스 변경 모달 */}
          <ModalTemplate
            className="service-modal modal-464"
            visible={modalTarget === 'service'}
            title={formatMessage({ id: 'ServiceShare_22', defaultMessage: '이용 서비스 변경' })}
            onOk={onChangeService}
            onCancel={() => toggleModal('')}
            okText={formatMessage({ id: 'Button_4', defaultMessage: '확 인' })}
            cancelText={formatMessage({ id: 'Button_5', defaultMessage: '닫 기' })}
            loading={loading}
          >
            <div className="modal-explain-text">
              {formatMessage({
                id: 'ServiceShare_23',
                defaultMessage: '현재 이용중인 서비스를 다음 서비스 환경으로 변경합니다.',
              })}
            </div>
            <div className="modal-border-box">
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({
                      id: 'Service_8',
                      defaultMessage: '서비스No',
                    })}
                  </div>
                </li>
                <div>{selectedInfo.serviceNo}</div>
              </ul>
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({
                      id: 'Service_1',
                      defaultMessage: '서비스명',
                    })}
                  </div>
                </li>
                <div>{selectedInfo.serviceName}</div>
              </ul>
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({
                      id: 'ServiceShare_24',
                      defaultMessage: '가입자',
                    })}
                  </div>
                </li>
                <div>{selectedInfo.serviceUserName}</div>
              </ul>
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({
                      id: 'Email_1',
                      defaultMessage: '이메일',
                    })}
                  </div>
                </li>
                <div>{selectedInfo.serviceUserEmail}</div>
              </ul>
            </div>
          </ModalTemplate>

          {/* 서비스 변경 실패 모달 */}
          <ModalTemplate
            className="service-modal modal-464"
            visible={!!serviceChangeError}
            title={formatMessage({ id: 'ServiceShare_25', defaultMessage: '서비스 변경 불가' })}
            onCancel={() => setServiceChangeError('')}
            cancelText={formatMessage({ id: 'Button_5', defaultMessage: '닫 기' })}
          >
            <div className="modal-content">
              <div className="modal-explain-text">
                <div>
                  {formatMessage({
                    id: 'ServiceShare_26',
                    defaultMessage: '허용된 IP가 아닙니다.',
                  })}
                </div>
                <div>
                  {formatMessage({
                    id: 'ServiceShare_27',
                    defaultMessage: '서비스 변경이 제한됩니다.',
                  })}
                </div>
              </div>
            </div>
          </ModalTemplate>

          {/* 비밀번호 변경 모달 */}
          <ModalTemplate
            className="modal-464"
            visible={modalTarget === 'passwd'}
            onOk={handleSubmit(changePasswd)}
            onCancel={() => toggleModal('')}
            centered
            loading={loading}
            title={formatMessage({ id: 'Password_6', defaultMessage: '비밀번호 변경' })}
            okText={formatMessage({ id: 'Button_1', defaultMessage: '변경' })}
            cancelText={formatMessage({ id: 'Button_2', defaultMessage: '취소' })}
          >
            <form autoComplete="off">
              <div className="text-field-title">
                {formatMessage({ id: 'Password_8', defaultMessage: '기존 비밀번호' })}
              </div>
              <FormTextField
                name="beforePassword"
                type="password"
                error={errors.beforePassword}
                register={register}
                validation={{
                  validate: {
                    required: (value: any) => valid.required(value),
                  },
                }}
                errorCheck={errorMessage.type === 'beforePassword'}
                errorText={errorMessage.type === 'beforePassword' && errorMessage.content}
              />
              <div className="text-field-title">
                {formatMessage({ id: 'Password_9', defaultMessage: '새 비밀번호' })}
              </div>
              <FormTextField
                name="userPasswd"
                type="password"
                error={errors.userPasswd}
                register={register}
                validation={{
                  validate: {
                    required: (value: any) => valid.required(value),
                  },
                }}
                errorCheck={errorMessage.type === 'userPasswd'}
                errorText={errorMessage.type === 'userPasswd' && errorMessage.content}
              />
              <div className="text-field-title">
                {formatMessage({ id: 'Password_10', defaultMessage: '새 비밀번호 확인' })}
              </div>
              <FormTextField
                name="userPasswdCheck"
                type="password"
                error={errors.userPasswdCheck}
                register={register}
                validation={{
                  validate: {
                    required: (value: any) => valid.required(value),
                  },
                }}
                errorCheck={errorMessage.type === 'userPasswdCheck'}
                errorText={errorMessage.type === 'userPasswdCheck' && errorMessage.content}
              />
            </form>
          </ModalTemplate>
        </div>
      )}
    </>
  );
}

export default withRouter(Header);
