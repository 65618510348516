import { SettingOutlined, InfoCircleFilled, ZoomInOutlined } from '@ant-design/icons';
import { Image, Tag, Popover } from 'antd';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual } from 'react-intl/src/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getAttach, changeParam } from 'store/attach';
import { Option } from 'utils/commonValues';
import TableList from 'components/common/TableList';
import TablePagination from 'components/common/TablePagenation';
import TableSearch from 'components/common/TableSearch';
import AttachLabel from 'components/branch/attach/AttachLabel';
import TableDatePicker from 'components/common/TableDatePicker';
import AttachSettings from 'components/branch/attach/AttachSettings';
import ModalTemplate from 'components/common/ModalTemplate';
import './AttachList.scss';

export default function PhishingPageList(props: any) {
  let tableRowCount = Math.trunc((window.innerHeight - 315) / 48);
  tableRowCount = tableRowCount < 5 ? 5 : tableRowCount;

  const { formatMessage } = useIntl();
  const [loading, setLoding] = useState(false);
  const [pageSize, setPageSize] = useState(tableRowCount);
  const [currentPage, setCurrentPage] = useState(0);
  const [openModal, setOpenModal] = useState('');
  const dispatch = useDispatch();
  const { sort, filter } = useSelector(
    (state: any) => ({
      sort: state.attach.param.sort,
      filter: state.attach.param.filter,
    }),
    shallowEqual,
  );
  const needReload = useSelector((state: any) => state.attach.needReload);
  const respData = useSelector((state: any) => state.attach.data);
  const totalCount = useSelector((state: any) => state.attach.totalCount);
  const totalPages = useSelector((state: any) => state.attach.totalPages);
  const isAdmin = useSelector((state: any) => state.login.userData.isAdmin);

  useEffect(() => {
    getAttachData();
  }, [sort, filter, pageSize, currentPage, needReload]);

  // API 호출
  const getAttachData = async () => {
    if (!loading) {
      setLoding(true);

      try {
        const params = {
          filter: JSON.stringify(filter),
          sort: JSON.stringify(sort),
          offset: pageSize * (currentPage < 0 ? 0 : currentPage), // page 는 0 번 부터 시작
          limit: pageSize < 1 ? 20 : pageSize,
        };

        await dispatch(getAttach(params));
      } catch (e) {
        console.log(e);
      }
    }

    setLoding(false);
  };

  // 검색
  const searchOption = {
    searchAll: formatMessage({ id: 'Faq_1', defaultMessage: '전체' }),
    attachInfoNo: 'No',
    serviceNo: formatMessage({ id: 'Service_8', defaultMessage: '서비스No' }),
    attachSubject: formatMessage({ id: 'Email_23', defaultMessage: '제목' }),
    fileExt: formatMessage({ id: 'Attach_12', defaultMessage: '확장자' }),
  };

  // 필터
  const filterOption: { [key: string]: any } = {
    attachCategoryArray: {
      name: formatMessage({ id: 'Template_22', defaultMessage: '구분' }),
      child: [
        {
          label: formatMessage({ id: 'Attach_7', defaultMessage: '실행파일' }),
          value: '1',
        },
        {
          label: formatMessage({ id: 'Attach_8', defaultMessage: '바로가기' }),
          value: '2',
        },
        {
          label: formatMessage({ id: 'Attach_9', defaultMessage: '문서파일' }),
          value: '3',
        },
        {
          label: formatMessage({ id: 'Attach_10', defaultMessage: '웹문서' }),
          value: '4',
        },
      ],
    },
    attachExamTypeArray: {
      name: formatMessage({ id: 'Template_23', defaultMessage: '훈련 유형' }),
      child: [
        {
          label: formatMessage({ id: 'Template_10', defaultMessage: '경고 안내' }),
          value: '1',
        },
        {
          label: formatMessage({ id: 'Template_11', defaultMessage: '모의 악성파일' }),
          value: '2',
        },
        {
          label: formatMessage({ id: 'Template_12', defaultMessage: '실태 조사' }),
          value: '3',
        },
      ],
    },
    attachFunctionArray: {
      name: formatMessage({ id: 'Attach_13', defaultMessage: '부가 기능' }),
      child: [
        {
          label: 'PG',
          value: 'PG',
        },
        {
          label: 'LG',
          value: 'LG',
        },
        {
          label: 'PL',
          value: 'PL',
        },
        {
          label: 'VS',
          value: 'VS',
        },
      ],
    },
    attachRestrictArray: {
      name: formatMessage({ id: 'Attach_14', defaultMessage: '제약 사항' }),
      child: [
        {
          label: 'UAC',
          value: 'UAC',
        },
        {
          label: 'SS',
          value: 'SS',
        },
        {
          label: 'VD',
          value: 'VD',
        },
        {
          label: 'SF',
          value: 'SF',
        },
        {
          label: 'LC',
          value: 'LC',
        },
        {
          label: 'UC',
          value: 'UC',
        },
        {
          label: 'DW',
          value: 'DW',
        },
      ],
    },
    isEnableArray: {
      name: formatMessage({ id: 'Template_34', defaultMessage: '삭제 여부' }),
      child: [
        {
          label: 'O',
          value: '1',
        },
        {
          label: '-',
          value: '0',
        },
      ],
    },
  };
  // 테이블
  const baseColumns: Array<{ [key: string]: any }> = [
    {
      Header: 'No',
      accessor: 'attachInfoNo',
      resizable: false,
      sortable: true,
      width: 72,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center number-text',
    },
    {
      Header: formatMessage({ id: 'Service_8', defaultMessage: '서비스No' }),
      accessor: 'serviceNo',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center number-text',
      Cell: (props: { [key: string]: any }) => {
        let printValue = '-';
        if (props.value) {
          printValue = props.value;
        }
        return printValue;
      },
    },
    {
      Header: formatMessage({ id: 'Template_22', defaultMessage: '구분' }),
      accessor: 'isBasic',
      resizable: false,
      sortable: true,
      width: 90,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        let printValue = (
          <Tag className="temp-type-label color-temp-user no-margin">
            {formatMessage({ id: 'User_1', defaultMessage: '사용자' })}
          </Tag>
        );

        if (props.value) {
          printValue = (
            <Tag className="temp-type-label color-temp-system no-margin">
              {formatMessage({ id: 'Template_14', defaultMessage: '기 본' })}
            </Tag>
          );
        }
        return printValue;
      },
    },
    {
      Header: formatMessage({ id: 'Attach_3', defaultMessage: '첨부 유형' }),
      accessor: 'attachCategory',
      resizable: false,
      sortable: true,
      width: 115,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        let printValue: any = '-';
        if (props.value === 1) {
          printValue = (
            <Tag className="temp-type-label color-attach-exe">
              {formatMessage({ id: 'Attach_7', defaultMessage: '실행파일' })}
            </Tag>
          );
        } else if (props.value === 2) {
          printValue = (
            <Tag className="temp-type-label color-attach-lnk">
              {formatMessage({ id: 'Attach_8', defaultMessage: '바로가기' })}
            </Tag>
          );
        } else if (props.value === 3) {
          printValue = (
            <Tag className="temp-type-label color-attach-doc">
              {formatMessage({ id: 'Attach_9', defaultMessage: '문서파일' })}
            </Tag>
          );
        } else if (props.value === 4) {
          printValue = (
            <Tag className="temp-type-label color-attach-html">
              {formatMessage({ id: 'Attach_10', defaultMessage: '웹문서' })}
            </Tag>
          );
        }
        return printValue;
      },
    },
    {
      Header: formatMessage({ id: 'Template_23', defaultMessage: '훈련 유형' }),
      accessor: 'attachExamType',
      resizable: false,
      sortable: true,
      width: 133,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        let printValue: any = '-';
        if (props.value === 1) {
          printValue = (
            <Tag className="temp-type-label color-temp-warning">
              {formatMessage({ id: 'Template_10', defaultMessage: '경고 안내' })}
            </Tag>
          );
        } else if (props.value === 2) {
          printValue = (
            <Tag className="temp-type-label color-temp-file">
              {formatMessage({ id: 'Template_11', defaultMessage: '모의 악성파일' })}
            </Tag>
          );
        } else if (props.value === 3) {
          printValue = (
            <Tag className="temp-type-label color-temp-research">
              {formatMessage({ id: 'Template_12', defaultMessage: '실태 조사' })}
            </Tag>
          );
        }
        return printValue;
      },
    },
    {
      Header: formatMessage({ id: 'Attach_11', defaultMessage: '아이콘' }),
      accessor: 'fileIcon',
      sortable: false,
      resizable: false,
      width: 72,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
      Cell: function icon(props: { [key: string]: any }) {
        return props.row.original.fileIcon ? (
          <img
            alt=""
            style={{ width: 20, height: 20 }}
            src={`/fdata/attachFile/${props.row.original.attachInfoNo}/${props.row.original.fileIcon}`}
          />
        ) : (
          <div className="text-align-center">-</div>
        );
      },
    },
    {
      Header: formatMessage({ id: 'Attach_12', defaultMessage: '확장자' }),
      accessor: 'fileExt',
      resizable: true,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        let printValue = '-';
        if (props.value) {
          printValue = props.value;
        }
        return printValue;
      },
    },
    {
      Header: formatMessage({ id: 'Attach_13', defaultMessage: '부가 기능' }),
      accessor: 'attachFunction',
      resizable: false,
      sortable: false,
      width: 170,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td',
      Cell: (props: { [key: string]: any }) => {
        let printValue = <div className="text-align-center">-</div>;
        if (props.value && props.value !== '{}') {
          printValue = <AttachLabel type="attachFunction" data={props.value} />;
        }
        return printValue;
      },
    },
    {
      Header: (
        <Popover
          overlayClassName="full"
          placement="topLeft"
          content={formatMessage({
            id: 'Attach_29',
            defaultMessage: "부가 기능, 제약사항 설명 페이지 'Click!'",
          })}
        >
          <a
            href={`/attachRestrict_${localStorage.getItem('language')}.html`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {formatMessage({ id: 'Attach_14', defaultMessage: '제약 사항' })}
            <InfoCircleFilled className="ml-5" />
          </a>
        </Popover>
      ),
      accessor: 'attachRestrict',
      resizable: false,
      sortable: false,
      width: 300,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td',
      Cell: (props: { [key: string]: any }) => {
        let printValue = <div className="text-align-center">-</div>;
        if (props.value && props.value !== '{}') {
          printValue = <AttachLabel type="attachRestrict" data={props.value} />;
        }
        return printValue;
      },
    },
    {
      Header: formatMessage({ id: 'Attach_15', defaultMessage: '실행예시' }),
      accessor: 'actionImage',
      resizable: false,
      sortable: false,
      width: 72,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
      Cell: function action(props: { [key: string]: any }) {
        return props.row.original.actionImage ? (
          <ZoomInOutlined
            onClick={() =>
              setOpenModal(
                `/fdata/attachFile/${props.row.original.attachInfoNo}/${props.row.original.actionImage}`,
              )
            }
            aria-hidden="true"
            style={{ fontSize: '18px' }}
          />
        ) : (
          <div className="text-align-center">-</div>
        );
      },
    },
    {
      Header: formatMessage({ id: 'Email_23', defaultMessage: '제목' }),
      accessor: 'attachSubject',
      resizable: true,
      sortable: true,
      width: 235,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td sub-text',
      Cell: function attachSubject(props: { [key: string]: any }) {
        return props.value ? props.value : <div className="text-align-center">-</div>;
      },
    },
    {
      Header: formatMessage({ id: 'Template_35', defaultMessage: '공개 여부' }),
      accessor: 'isPublic',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        let printValue = '-';
        if (props.value === 1) {
          printValue = 'O';
        }
        return printValue;
      },
    },
    {
      Header: formatMessage({ id: 'Template_34', defaultMessage: '삭제 여부' }),
      accessor: 'isEnable',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        let printValue = '-';
        if (props.value === 0) {
          printValue = 'O';
        }
        return printValue;
      },
    },
    {
      Header: formatMessage({ id: 'Date_20', defaultMessage: '수정일' }),
      accessor: 'modifyEpoch',
      resizable: false,
      sortable: true,
      width: 177,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center sub-text',
      Cell: function regEpoch(props: { [key: string]: any }) {
        const { value } = props;
        const dateFormat = localStorage.getItem('language') === 'en' ? 'MM/DD/YYYY' : 'YYYY-MM-DD';
        return <div>{moment.unix(value).format(`${dateFormat} HH:mm:ss`)}</div>;
      },
    },
    {
      Header: (
        <TableDatePicker
          dateType="RegEpoch"
          title={formatMessage({ id: 'Date_19', defaultMessage: '등록일' })}
          filter={filter}
          actionParam={changeParam}
        />
      ),
      accessor: 'regEpoch',
      resizable: false,
      sortable: true,
      width: 177,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center sub-text',
      Cell: function regEpoch(props: { [key: string]: any }) {
        const { value } = props;
        const dateFormat = localStorage.getItem('language') === 'en' ? 'MM/DD/YYYY' : 'YYYY-MM-DD';
        return <div>{moment.unix(value).format(`${dateFormat} HH:mm:ss`)}</div>;
      },
    },
  ];

  if (Option.isSaas === 1) {
    baseColumns.unshift({
      Header: <SettingOutlined />,
      accessor: 'setting',
      resizable: false,
      sortable: false,
      width: 120,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center settings',
      Cell: function cellFunc(props: { [key: string]: any }) {
        return <AttachSettings isAdmin={isAdmin} dataInfo={props.row.original} />;
      },
    });

    baseColumns.push({
      Header: formatMessage({ id: 'Attach_27', defaultMessage: '파일명' }),
      accessor: 'fileName',
      sortable: true,
      resizable: false,
      width: 261,
      headerClassName: 'react-table-th',
      className: 'react-table-td sub-text',
      Cell: function attachFileName(props: { [key: string]: any }) {
        return props.value ? props.value : <div className="text-align-center">-</div>;
      },
    });
  }

  const columns: Array<any> = useMemo(() => baseColumns, [respData]);

  return (
    <>
      <TableSearch
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalCount={totalCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        changeParam={changeParam}
        searchOption={searchOption}
        filter={filter}
        filterOption={filterOption}
      />
      <TableList
        columns={columns}
        data={respData.success}
        changeParam={changeParam}
        loading={loading}
        disableKey="isEnable"
      />
      <TablePagination
        totalPages={totalPages}
        currentPage={currentPage + 1}
        setCurrentPage={setCurrentPage}
      />

      {/* 실행예시 모달 */}
      {!!openModal && (
        <ModalTemplate
          className="attach-result-modal modal-965"
          visible={!!openModal}
          title={
            <div className="modal-template-header">
              <div className="title">
                {formatMessage({ id: 'Attach_49', defaultMessage: '실행결과 이미지' })}
              </div>
            </div>
          }
          onCancel={() => setOpenModal('')}
          centered
          footer={null}
        >
          <img src={openModal} alt="example" />
        </ModalTemplate>
      )}
    </>
  );
}
