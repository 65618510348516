import { Radio } from 'antd';
import { Controller } from 'react-hook-form';
import './FormButtonGroup.scss';
// import selectGreenImage from 'img/exam/template/exam_selected.png';

interface buttonProps {
  control: any;
  name: any;
  validation?: any;
  error?: any; // 에러 메시지 출력
  buttonList?: Array<any>; // 버튼 정보 리스트 ex) [{text: '공개' , value: 1}, {text: '비공개' , value: 0}]
  defaultValue?: any; // 기본값
  className?: string;
  disabled?: boolean; // 비활성화 여부
  customButton?: any; // 버튼 안에 들어갈 내용이 글자가 아닐 경우 커스텀하여 보내줌
  handleOnChange: (e: any) => void;
}

FormButtonGroup.defaultProps = {
  validation: null,
  buttonList: [],
  defaultValue: null,
  className: '',
  disabled: false,
  customButton: null,
  error: null,
};

function FormButtonGroup({
  control,
  name,
  validation,
  error,
  buttonList,
  defaultValue,
  className,
  disabled,
  customButton,
  handleOnChange,
}: buttonProps) {
  return (
    <div className="form-button-group">
      <Controller
        control={control}
        name={name}
        rules={validation}
        render={({ onChange }) => (
          <Radio.Group
            className={`${className || ''}${disabled ? ' disabled' : ''}${error ? ' warning' : ''}`}
            defaultValue={defaultValue}
            buttonStyle="outline"
            onChange={(e: any) => {
              onChange(e.target.value);
              handleOnChange(e.target.value);
            }}
          >
            {customButton ||
              buttonList?.map(
                (button: any) =>
                  button.visible && (
                    <Radio.Button value={button.value} key={button.value}>
                      {button.text}
                      {/* <i className="button-check-icon" /> */}
                    </Radio.Button>
                  ),
              )}
          </Radio.Group>
        )}
      />
    </div>
  );
}

export default FormButtonGroup;
