import { apiUrl, execUrl } from 'utils/commonValues';
import { actionFormat } from 'utils/commonFunctions';

// Action Types
const SET_INITIAL = 'dashboard/SET_INITIAL';
const GET_DASHBOARD = 'dashboard/GET_DASHBOARD';
const CHANGE_PARAM = 'dashboard/CHANGE_PARAM';

// Actions
// 대시보드 조회
export const getDashboard = () => actionFormat(GET_DASHBOARD, null, 'get', `${apiUrl}Dashboard`);

/* 새로운 대시보드 */
// 공지(스토어에 저장X)
export const getDashboardNotice = () => actionFormat(null, null, 'get', `${apiUrl}Dashboard`);
// 위험 통합 점수
export const getDashboardTotal = () =>
  actionFormat(null, null, 'get', `${apiUrl}/Dashboard/DashboardTotal`);
// 누적 위반자 TOP 5
export const getDashboardTarget = () =>
  actionFormat(null, null, 'get', `${apiUrl}/Dashboard/DashboardTarget`);
// 연간 훈련 위험 통계
export const getDashboardStatistics = () =>
  actionFormat(null, null, 'get', `${apiUrl}/Dashboard/DashboardStatistics`);
// 훈련 현황
export const getDashboardTemplate = (params?: { [key: string]: any }) =>
  actionFormat(null, params, 'get', `${apiUrl}/Dashboard/DashboardTemplate`);
// 훈련 템플릿 통계
export const getDashboardExamStatus = (params?: { [key: string]: any }) =>
  actionFormat(null, params, 'get', `${apiUrl}/Dashboard/DashboardExamStatus`);
// 필터/정렬
export const changeParam = (params: { [key: string]: any }) =>
  actionFormat(CHANGE_PARAM, params, null, '');

// initial state
interface stateType {
  data: Array<string>;
  buildLicenseData: any;
  statisticsData: any;
  infectionTarget: Array<any>;
  infectionTag: Array<any>;
  notice: any;

  param: any;
}

const initialState: stateType = {
  data: [],
  buildLicenseData: {},
  statisticsData: {},
  infectionTarget: [],
  infectionTag: [],
  notice: {},

  param: {
    sort: [{ field: 'progress', order: 'DESC' }],
  },
};

// Reducers
export default function (state: { [key: string]: any } = initialState, action: any) {
  switch (action.type) {
    case SET_INITIAL:
      return initialState;
    case GET_DASHBOARD:
      if (action.payload) {
        const {
          list,
          statistic,
          licenseHostName,
          licenseEndDate,
          infectionTarget,
          infectionTag,
          notice,
        } = action.payload.data;
        state.data = list;
        state.buildLicenseData = {
          licenseHostName: licenseHostName,
          licenseEndDate: licenseEndDate,
        };
        state.statisticsData = statistic;
        state.infectionTarget = infectionTarget;
        state.infectionTag = infectionTag;
        state.notice = notice;
      }
      return state;
    case CHANGE_PARAM:
      if (action.payload) {
        const { name, value } = action.payload;
        state.param[name] = value;
      }
      return state;
    default:
      return state;
  }
}
