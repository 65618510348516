import React, { useState } from 'react';
import { Tooltip } from 'antd';
import { CopyOutlined, FormOutlined, DeleteOutlined, FileOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { Option } from 'utils/commonValues';
import PhishingPageAddEdit from './PhishingPageAddEdit';
import PhishingPageDelete from './PhishingPageDelete';
import PhishingPageCopy from './PhishingPageCopy';

function PhishingPageSettings({ isAdmin, phishingInfo }: any) {
  const { formatMessage } = useIntl();
  const [openModal, setOpenModal] = useState('');

  return (
    <div className="table-settings">
      {/* 피싱 페이지 복제 */}
      <Tooltip
        overlayClassName="black-tooltip"
        placement="bottom"
        title={formatMessage({ id: 'Phishing_24', defaultMessage: '피싱 페이지 복제' })}
      >
        <CopyOutlined onClick={() => setOpenModal('copy')} />
      </Tooltip>

      {openModal === 'copy' && (
        <PhishingPageCopy
          visible={openModal === 'copy'}
          isAdmin={isAdmin}
          phishingInfo={phishingInfo}
          toggleModal={(open: boolean) => setOpenModal(open ? 'copy' : '')}
        />
      )}

      {/* 피싱 페이지 수정 */}
      {((isAdmin && Option.isSaas === 1) || phishingInfo.isBasic === 0) && (
        <Tooltip
          overlayClassName="black-tooltip"
          placement="bottom"
          title={formatMessage({ id: 'Phishing_25', defaultMessage: '피싱 페이지 수정' })}
        >
          <FormOutlined onClick={() => setOpenModal('edit')} />
        </Tooltip>
      )}

      {openModal === 'edit' && (
        <PhishingPageAddEdit
          visible={openModal === 'edit'}
          isAdmin={isAdmin}
          isAddMode={false}
          phishingInfo={phishingInfo}
          toggleModal={(open: boolean) => setOpenModal(open ? 'edit' : '')}
        />
      )}

      {/* 피싱 페이지 삭제 */}
      {(isAdmin || phishingInfo.isBasic === 0) && (
        <Tooltip
          overlayClassName="black-tooltip"
          placement="bottom"
          title={formatMessage({ id: 'Phishing_26', defaultMessage: '피싱 페이지 삭제' })}
        >
          <DeleteOutlined onClick={() => setOpenModal('delete')} />
        </Tooltip>
      )}

      {openModal === 'delete' && (
        <PhishingPageDelete
          visible={openModal === 'delete'}
          phishingInfo={phishingInfo}
          toggleModal={(open: boolean) => setOpenModal(open ? 'delete' : '')}
        />
      )}
    </div>
  );
}

export default PhishingPageSettings;
