import InfectionPcList from 'components/branch/exam/InfectionPcList';
import { useIntl } from 'react-intl';
import "../mudmanage/Conf.scss";

export default function Infectionpc(props: any) {
  const { formatMessage } = useIntl();
  

  return (
    <div className="admin-basic-conf table" style={{padding: 0}}>
      <div className="react-table-area" style={{padding: 0}}>
        <InfectionPcList />
      </div>
    </div>
  );
}
