import { useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { Checkbox, Dropdown, Menu, Popover, Radio, Space, Tooltip } from 'antd';
import fileDownload from 'js-file-download';
import {
  changeListState,
  changeSummaryState,
  downloadReport,
  downloadSummaryReport,
  downloadVaccine,
  sendVaccine,
  setInitial,
} from 'store/exam';
import ExamSidebar from 'components/branch/exam/ExamSidebar';
import ExamDetail from 'components/branch/exam/ExamDetail';
import AutoRoute from 'components/common/AutoRoute';
import ContentHeader from 'components/common/ContentHeader';
import ModalTemplate from 'components/common/ModalTemplate';
import FormSwitchField from 'components/common/FormSwitchField';
import FormTextField from 'components/common/FormTextField';
import Dimmer from 'components/common/Dimmer';
import { nowEpoch, timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import { Option } from 'utils/commonValues';
import * as valid from 'utils/validation';
import { changeSubState } from 'store/template';
import { changeResultTargetState } from 'store/target';
import './Exam.scss';

// import downloadImage from 'img/exam/menu_down.png';
// import qmarkImage from 'img/exam/startExam/btn_qmark.png';

export const authLevel = 1;

function Exam() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  const examSummary = useSelector((state: any) => {
    return {
      data: state.exam.examSummary.data,
      dataByExamNo: state.exam.examSummary.dataByExamNo,
      param: state.exam.examSummary.param,
      selectedExamNo: state.exam.examSummary.selectedExamNo,
      selectedRandomGroupNo: state.exam.examSummary.selectedRandomGroupNo,
    };
  }, shallowEqual);

  const examList = useSelector((state: any) => {
    return {
      data: state.exam.examList.data,
      dataByExamNo: state.exam.examList.dataByExamNo,
      param: state.exam.examList.param,
      needReload: state.exam.examList.needReload,
      page: state.exam.examList.page,
      totalPage: state.exam.examList.totalPage,
      totalItem: state.exam.examList.totalItem,
      selectedList: state.exam.examList.selectedList,
      randomParam: state.exam.examList.randomParam,
    };
  }, shallowEqual);

  const randomList = useSelector((state: any) => {
    return {
      data: state.exam.randomList.data,
      dataByExamNo: state.exam.randomList.dataByExamNo,
      param: state.exam.randomList.param,
      selectedList: state.exam.randomList.selectedList,
    };
  }, shallowEqual);

  const templateList = useSelector((state: any) => {
    return {
      param: state.template.resultTemplate.param,
      data: state.template.resultTemplate.data,
      dataByTemplateNo: state.template.resultTemplate.dataByTemplateNo,
      selectedList: state.template.resultTemplate.selectedList,
    };
  }, shallowEqual);

  const targetList = useSelector((state: any) => {
    return {
      param: state.target.resultTarget.param,
    };
  }, shallowEqual);

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState('');
  const [masking, setMasking]: any = useState({});
  const [reportType, setReportType] = useState(0);
  const [summaryReportType, setSummaryReportType] = useState(0);
  const [groupReport, setGroupReport] = useState({ groupExamNo: 0, isGroup: false, errorMsg: '' });
  const [summaryError, setSummaryError] = useState('');
  const { register, watch, handleSubmit, control, errors } = useForm();

  useEffect(() => {
    // 브라우저 새로고침 시 기본 경로로 변경
    history.push('/exam/pi');

    return () => {
      // 훈련 스토어 초기화
      dispatch(setInitial());
    };
  }, []);

  // 보고서 유형 변경
  const changeReportType = (e: any) => {
    setReportType(e.target.value);
    setGroupReport({ ...groupReport, errorMsg: '' });
  };

  // 기본 보고서 유형 변경
  const changeSummaryReportType = (e: any) => {
    setSummaryReportType(e.target.value);
    setSummaryError('');
  };

  // 마스킹 사용 옵션 변경
  const changeMasking = (name: string, value: any) => {
    setMasking({ ...masking, [name]: value });
  };

  // 훈련 리스트 새로고침
  const onRefresh = async () => {
    setLoading(true);

    const params: any = {
      filter: {},
      offset: 0,
      limit: 0,
      refresh: true,
    };

    try {
      if (pathname === '/exam/bytemplate') {
        // 템플릿 탭
        params.limit = templateList.param.limit;
        await dispatch(
          changeSubState({
            subStore: 'resultTemplate',
            key: 'param',
            value: { ...templateList.param, ...params },
          }),
        );
      } else if (pathname === '/exam/bytarget') {
        // 대상자 탭
        params.limit = targetList.param.limit;
        await dispatch(
          changeResultTargetState({ key: 'param', value: { ...targetList.param, ...params } }),
        );
      } else if (examSummary.selectedExamNo !== 'all' || examSummary.selectedRandomGroupNo !== 0) {
        // 개별 훈련 선택한 상태에서 새로고침 시 훈련 리스트만 재조회
        await dispatch(
          changeListState({ key: 'param', value: { ...examList.param, refresh: true } }),
        );
      } else {
        // 전체 훈련 선택한 상태에서 새로고침 시 훈련 요약 및 훈련 리스트 재조회
        params.limit = examSummary.selectedRandomGroupNo
          ? randomList.param.limit
          : examList.param.limit;
        await dispatch(changeSummaryState({ key: 'param', value: params }));
      }

      setLoading(false);
    } catch (error) {
      console.log('onRefresh', error);
    }
  };

  // 백신 다운로드
  const onDownloadVaccine = async () => {
    setLoading(true);
    try {
      const response: any = await dispatch(downloadVaccine());
      if (response?.data) {
        fileDownload(
          response.data,
          `${Option.customer === 'saxa' ? 'Maitra' : 'Mudfix'}Vaccine.exe`,
        );
        setLoading(false);
      }
    } catch (error) {
      console.log('Exam onDownloadVaccine', error);
    }
  };

  // 백신 메일 발송
  const onSendVaccineMail = async () => {
    setLoading(true);

    try {
      const params: any = {
        examNo: examSummary.selectedExamNo,
        filter: examList.param.filter,
        subTab: 'pi',
      };

      if (modal === 'infectionSend') {
        params.infectionTarget = 1;
      }

      await dispatch(sendVaccine(params));
      setModal('');
      setLoading(false);
    } catch (error) {
      console.log('Exam onSendVaccineMail', error);
    }
  };

  // 보고서 버튼 클릭
  const clickReportBtn = (type: string) => {
    setReportType(0);
    setSummaryReportType(0);
    setModal(type === 'random' ? 'reportRandom' : 'report');
    setMasking({});

    if (examSummary.selectedExamNo === 'all') {
      // 선택한 훈련이 그룹 훈련인지 확인
      const parentsNoData: any = {};
      examList.selectedList.forEach((examNo: number) => {
        const parentsNo = examSummary.dataByExamNo[examNo].parentsExamNo;
        if (parentsNo) {
          if (typeof parentsNoData[parentsNo] === 'undefined') {
            parentsNoData[parentsNo] = 0;
          }
          parentsNoData[parentsNo] += 1;
        }
      });
      const parentsKeys = parentsNoData ? Object.keys(parentsNoData) : [];

      setGroupReport({
        groupExamNo: parseInt(parentsKeys[0], 10),
        isGroup:
          // 2개 이상의 훈련
          examList.selectedList.length > 1 &&
          // 한 종류의 그룹
          parentsKeys.length === 1 &&
          // 선택한 그룹 훈련 개수와 선택한 훈련의 개수가 같을 때
          parentsNoData[parentsKeys[0]] === examList.selectedList.length,
        errorMsg: '',
      });
    }
  };
  // 보고서 모달 다운로드 버튼 클릭
  const reportDownload = async () => {
    try {
      // 보고서 유형 미선택 시
      if (reportType === 0) {
        setGroupReport({
          ...groupReport,
          errorMsg: formatMessage({ id: 'Exam_58', defaultMessage: '보고서 유형을 선택하세요.' }),
        });
      }
      // 개별 보고서
      else if (reportType === 1) {
        if (examSummary.selectedExamNo === 'all') {
          // 사이드바에서 전체 훈련 선택 시
          for (let i = 0; i < examList.selectedList.length; i += 1) {
            onReportFileDownload(examList.selectedList[i]);
          }
        } else {
          // 사이드바에서 개별 훈련 선택 시
          onReportFileDownload(examSummary.selectedExamNo);
        }
      }
      // 통합 보고서
      else if (reportType === 2) {
        // 랜덤발송 시 해당 그룹목록 전체 항목 다운로드
        const randomKeyList: Array<number> = [];
        randomList.data.forEach((item: any) => {
          randomKeyList.push(item.examNo);
        });

        onReportFileDownload(
          examSummary.selectedRandomGroupNo ? randomKeyList : examList.selectedList,
        );
      }
      // CSV 보고서
      else if (reportType === 3) {
        if (examSummary.selectedExamNo === 'all') {
          onReportFileDownload(examList.selectedList);
        } else {
          onReportFileDownload(examSummary.selectedExamNo);
        }
      }
    } catch (error) {
      console.log('Exam reportDownload', error);
    }
  };

  // 보고서 다운로드 액션 수행
  const onReportFileDownload = async (examData: any) => {
    setLoading(true);

    try {
      const params: any = {
        examNoArray: Array.isArray(examData)
          ? JSON.stringify(examData)
          : JSON.stringify([examData]),
        // csv 보고서
        ...(reportType === 3 ? { csv: 1 } : {}),
        // 보고서 다운로드 사유
        ...(Option.customer === 'hyundai'
          ? { reportDownloadReason: watch().reportDownloadReason }
          : {}),
      };

      // 훈련명
      let examName = examSummary?.dataByExamNo[examData]?.examName || '';

      // 통합 보고서
      if (reportType === 2) {
        // 선택한 훈련 중 첫번째 훈련의 이름에서 (1), (2) 등을 제외하고 설정
        examName = examSummary?.dataByExamNo[examData[0]]?.examName.split('(')[0]?.trim();
      }

      // 보고서명
      const reportName =
        reportType === 3
          ? `${examName}${formatMessage({
              id: 'Exam_59',
              defaultMessage: '훈련_보고서',
            })}.xlsx`
          : `${examName}_${formatMessage({
              id: 'Exam_59',
              defaultMessage: '훈련_보고서',
            })}.xlsx`;

      // 마스킹 활성화 시킨 것만 파라미터에 추가
      Object.keys(masking).forEach((key: string) => {
        if (masking[key] === 1) {
          params[key] = 1;
        }
      });

      const response: any = await dispatch(downloadReport(params));
      if (response?.data) {
        fileDownload(response.data, reportName);
      }

      setLoading(false);
      setModal('');
    } catch (error) {
      console.log('Exam onReportFileDownload', error);
    }
  };

  const summaryReportDownload = () => {
    try {
      // 보고서 유형 미선택 시
      if (summaryReportType === 0) {
        setSummaryError(
          formatMessage({ id: 'Exam_58', defaultMessage: '보고서 유형을 선택하세요.' }),
        );

        return;
      }

      if (examSummary.selectedExamNo === 'all') {
        // 사이드바에서 전체 훈련 선택 시
        onSummaryReportFileDownload(examList.selectedList);
      } else if (examSummary.selectedRandomGroupNo) {
        // 사이드바에서 랜덤발송 그룹 선택 시
        const randomKeyList: Array<number> = [];
        randomList.data.forEach((item: any) => {
          randomKeyList.push(item.examNo);
        });
        onSummaryReportFileDownload(randomKeyList);
      } else {
        // 사이드바에서 개별 훈련 선택 시
        onSummaryReportFileDownload([examSummary.selectedExamNo]);
      }
    } catch (error) {
      console.log('summary report download error', error);
    }
  };

  const onSummaryReportFileDownload = async (examData: any) => {
    setLoading(true);

    try {
      const params: any = {
        examNoArray: Array.isArray(examData)
          ? JSON.stringify(examData)
          : JSON.stringify([examData]),
        // 보고서 다운로드 사유
        ...(Option.customer === 'hyundai'
          ? { reportDownloadReason: watch().reportDownloadReason }
          : {}),
        type: summaryReportType,
      };

      // 훈련명
      const examName = examSummary?.dataByExamNo[examData[0]]?.examName?.split('(')[0]?.trim();

      // 보고서명
      const reportName = `${examName}_${formatMessage({
        id: 'Exam_98',
        defaultMessage: '훈련_기본_보고서',
      })}.html`;

      // 마스킹 활성화 시킨 것만 파라미터에 추가
      Object.keys(masking).forEach((key: string) => {
        if (masking[key] === 1) {
          params[key] = 1;
        }
      });

      const response: any = await dispatch(downloadSummaryReport(params));
      if (response?.data) {
        fileDownload(response.data, reportName);
      }

      setLoading(false);
      setModal('');
    } catch (error) {
      console.log('Exam onSummaryReportFileDownload', error);
    }
  };
  // 전체 훈련 선택 여부
  const isAllExam = useMemo(() => examSummary.selectedExamNo === 'all', [
    examSummary.selectedExamNo,
  ]);

  // 선택한 훈련 정보
  const currentExam = useMemo(() => {
    if (!isAllExam) {
      return examSummary.selectedRandomGroupNo
        ? examSummary.data.filter(
            (item: any) => item.parentsExamNo === examSummary.selectedRandomGroupNo,
          )[0]
        : examSummary.dataByExamNo[examSummary.selectedExamNo];
    }
    return false;
  }, [
    isAllExam,
    examSummary.data,
    examSummary.dataByExamNo,
    examSummary.selectedExamNo,
    examSummary.selectedRandomGroupNo,
  ]);

  // 네비게이션 버튼 목록
  const navButtonList = useMemo(
    () => [
      {
        isShow: isAllExam,
        moveLink: '/exam/pi',
        buttonText: formatMessage({ id: 'Exam_34', defaultMessage: '훈 련' }),
        tooltipText: formatMessage({
          id: 'Exam_28',
          defaultMessage: "훈련 결과를 '훈련'별로 보여줍니다.",
        }),
        clickEvent: () =>
          dispatch(
            changeListState({
              key: 'param',
              value: {
                filter: {},
                sort: [{ field: 'examNo', order: 'DESC' }],
                offset: 0,
                limit: 30,
              },
            }),
          ),
      },
      {
        isShow: isAllExam && Option.customer === 'hyundai',
        moveLink: '/exam/bytemplate',
        buttonText: formatMessage({ id: 'Template_27', defaultMessage: '템플릿' }),
        tooltipText: formatMessage({
          id: 'Exam_82',
          defaultMessage: "훈련 결과를 '템플릿'별로 보여줍니다.",
        }),
      },
      {
        isShow: isAllExam && Option.customer === 'hyundai',
        moveLink: '/exam/bytarget',
        buttonText: formatMessage({ id: 'Name_4', defaultMessage: '대상자' }),
        tooltipText: formatMessage({
          id: 'Exam_29',
          defaultMessage: "훈련 결과를 '대상자'별로 보여줍니다.",
        }),
      },
      {
        isShow: !isAllExam,
        moveLink: '/exam/pi',
        buttonText: formatMessage({ id: 'Target_5', defaultMessage: '대 상' }),
        tooltipText: formatMessage({
          id: 'Exam_29',
          defaultMessage: "훈련 결과를 '대상자'별로 보여줍니다.",
        }),
        clickEvent: () =>
          dispatch(
            changeListState({
              key: 'param',
              value: {
                filter: {},
                sort: [{ field: 'examNo', order: 'DESC' }],
                offset: 0,
                limit: 30,
              },
            }),
          ),
      },
      {
        isShow: !isAllExam,
        moveLink: '/exam/tag',
        buttonText: formatMessage({ id: 'Tag_37', defaultMessage: '태 그' }),
        tooltipText: formatMessage({
          id: 'Exam_31',
          defaultMessage: "훈련 결과를 '태그'별로 보여줍니다.",
        }),
        clickEvent: () =>
          dispatch(
            changeListState({
              key: 'param',
              value: {
                filter: {},
                sort: [{ field: 'examNo', order: 'DESC' }],
                offset: 0,
                limit: 30,
              },
            }),
          ),
      },
      {
        isShow: isAllExam || (!isAllExam && currentExam?.examType !== 4),
        moveLink: '/exam/infectionpc',
        buttonText: formatMessage({ id: 'Infection_3', defaultMessage: '감염 PC' }),
        tooltipText: formatMessage({
          id: 'Exam_32',
          defaultMessage: "훈련 결과별 '감염 가능한 PC' 목록을 보여줍니다.",
        }),
      },
      {
        isShow: isAllExam || (!isAllExam && currentExam?.examType !== 4),
        moveLink: '/exam/leakfile',
        buttonText: formatMessage({ id: 'Infection_16', defaultMessage: '감염 파일' }),
        tooltipText: formatMessage({
          id: 'Exam_33',
          defaultMessage: "훈련 결과별 '감염 가능한 파일' 목록을 보여줍니다.",
        }),
      },
    ],
    [isAllExam, currentExam],
  );

  // 상단 제목
  const headerTitle = useMemo(() => {
    let title = currentExam?.examName;
    if (isAllExam) {
      title = formatMessage({ id: 'Exam_5', defaultMessage: '전체 훈련' });
    } else if (
      examSummary.selectedRandomGroupNo &&
      examSummary.selectedRandomGroupNo === currentExam.parentsExamNo
    ) {
      title = currentExam.parentExamName;
    }
    return title;
  }, [isAllExam, currentExam, examSummary.selectedRandomGroupNo]);

  // 상단 부제목
  const headerSubTitle = useMemo(
    () =>
      isAllExam
        ? `${formatMessage({ id: 'Exam_25', defaultMessage: '총' })} ${
            examSummary.data.length > 0 ? examSummary.data[0].rnum : 0
          }${formatMessage({ id: 'Exam_26', defaultMessage: '개' })} ${formatMessage({
            id: 'Exam_27',
            defaultMessage: '훈련',
          })}`
        : `(${timeFormatFromUTCEpoch(currentExam?.examStartEpoch, 3)} ~ ${timeFormatFromUTCEpoch(
            currentExam?.examEndEpoch,
            3,
          )})`,
    [isAllExam, examSummary.data, examSummary.data.length, currentExam],
  );

  // 현재 선택한 훈련의 데이터
  const currentData = examSummary.dataByExamNo[examSummary.selectedExamNo] || {};

  // 치료 단계 존재 여부 - 모의 악성파일
  const isCure = currentData?.examType === 1 && currentData?.attachData?.attachExamType === 2;

  // 백신 드롭다운 메뉴
  const vaccineMenu = useMemo(
    () => (
      <Menu>
        <Menu.Item onClick={onDownloadVaccine}>
          {formatMessage({ id: 'Exam_42', defaultMessage: '백신 다운로드' })}
        </Menu.Item>
        {isCure && (
          <Menu.Item
            onClick={() => setModal('send')}
            disabled={examSummary.selectedExamNo === 'all'}
          >
            <Tooltip
              overlayClassName="black-tooltip full"
              placement="bottom"
              title={
                examSummary.selectedExamNo === 'all'
                  ? formatMessage({
                      id: 'Exam_45',
                      defaultMessage: "'전체 훈련'이 아닌 '개별 훈련'을 선택하세요.",
                    })
                  : examList.totalItem < 1 &&
                    formatMessage({
                      id: 'Exam_46',
                      defaultMessage: '발송 대상자가 없습니다.',
                    })
              }
            >
              {formatMessage({ id: 'Exam_43', defaultMessage: '훈련 대상에게 백신 메일 발송' })}
            </Tooltip>
          </Menu.Item>
        )}
        {isCure && (
          <Menu.Item
            onClick={() => setModal('infectionSend')}
            disabled={examSummary.selectedExamNo === 'all'}
          >
            <Tooltip
              overlayClassName="black-tooltip full"
              placement="bottom"
              title={
                examSummary.selectedExamNo === 'all'
                  ? formatMessage({
                      id: 'Exam_45',
                      defaultMessage: "'전체 훈련'이 아닌 '개별 훈련'을 선택하세요.",
                    })
                  : examList.totalItem < 1 &&
                    formatMessage({
                      id: 'Exam_46',
                      defaultMessage: '발송 대상자가 없습니다.',
                    })
              }
            >
              {formatMessage({ id: 'Exam_44', defaultMessage: '감염 대상에게 백신 메일 발송' })}
            </Tooltip>
          </Menu.Item>
        )}
      </Menu>
    ),
    [isCure, examSummary.selectedExamNo, examList.totalItem],
  );

  // 예약 훈련 여부
  const isReserve = useMemo(() => {
    if (examSummary.selectedExamNo === 'all') {
      examList.selectedList?.forEach((examNo: any) => {
        const examData = examSummary.dataByExamNo[examNo];
        if (examData?.examStartEpoch > nowEpoch()) {
          return true;
        }
        return false;
      });
    } else if (currentData.examStartEpoch > nowEpoch()) {
      return true;
    }
    return false;
  }, [examSummary.dataByExamNo, examSummary.selectedExamNo, examList.selectedList]);

  // 백신, 보고서 다운로드
  const buttonComponent = (
    <div className="flex">
      {pathname !== '/exam/bytemplate' && pathname !== '/exam/bytarget' && (
        <Dropdown overlay={vaccineMenu} trigger={['click']}>
          <button type="button" className="round-grey-button">
            <div>{formatMessage({ id: 'Exam_41', defaultMessage: '백신' })}</div>
          </button>
        </Dropdown>
      )}

      {pathname !== '/exam/bytemplate' && pathname !== '/exam/bytarget' && (
        <Tooltip
          overlayClassName="black-tooltip full"
          placement="bottom"
          title={
            isReserve
              ? formatMessage({
                  id: 'Exam_49',
                  defaultMessage: '예약 훈련은 보고서를 생성할 수 없습니다.',
                })
              : examSummary.selectedExamNo === 'all' &&
                examList.selectedList.length < 1 &&
                formatMessage({
                  id: 'Exam_39',
                  defaultMessage: '훈련을 선택하세요.',
                })
          }
        >
          <button
            className={`round-grey-button ${
              (examSummary.selectedExamNo === 'all' && examList.selectedList.length < 1) ||
              (isReserve && Option.isJapan)
                ? 'disabled'
                : ''
            }`}
            type="button"
            onClick={() => (isReserve ? undefined : clickReportBtn('normal'))}
          >
            {formatMessage({ id: 'Exam_48', defaultMessage: '보고서' })}
            <div className="download-img">
              <img src="/img/exam/menu_down.png" alt="down" />
            </div>
          </button>
        </Tooltip>
      )}
    </div>
  );

  // 상세 정보에 보여줄 데이터
  let selectedDetailData = examSummary.dataByExamNo[examSummary.selectedExamNo];
  let detailType = 'exam';
  if (examSummary.selectedExamNo === 'all') {
    if (
      pathname === '/exam/infectionpc' ||
      pathname === '/exam/leakFile' ||
      pathname === '/exam/bytarget'
    ) {
      selectedDetailData = null;
    } else if (pathname === '/exam/bytemplate') {
      selectedDetailData = templateList.selectedList[0]?.no
        ? templateList.selectedList[0]?.info
        : null;
      detailType = 'bytemplate';
    } else if (examList.selectedList.length > 0) {
      selectedDetailData = examSummary.dataByExamNo[examList.selectedList[0]];
    }
  } else if (examSummary.selectedRandomGroupNo || examList.selectedList.length > 0) {
    if (pathname === '/exam/pi') {
      detailType = 'pi';
    } else if (pathname === '/exam/tag') {
      detailType = 'tag';
    }

    selectedDetailData = examSummary.selectedRandomGroupNo
      ? randomList.data?.filter((item: any) => {
          return item.targetNo === randomList.selectedList[0];
        })[0]
      : examList.dataByExamNo[examList.selectedList[0]];

    if (pathname === '/exam/pi') {
      detailType = 'pi';
    } else if (pathname === '/exam/tag') {
      detailType = 'tag';
    }
  }

  const maskingOptions = [
    { label: formatMessage({ id: 'Name_1', defaultMessage: '이름' }), value: 'maskingName' },
    { label: formatMessage({ id: 'Email_1', defaultMessage: '이메일' }), value: 'maskingEmail' },
    {
      label: formatMessage({ id: 'Contact_1', defaultMessage: '연락처' }),
      value: 'maskingPhone',
    },
  ];

  const onMaskingChange = (checkedValues: any) => {
    const maskingObject = checkedValues.reduce((acc: any, key: string) => {
      acc[key] = 1;
      return acc;
    }, {});
    setMasking(maskingObject);
  };

  return (
    <div className="common-content-layout">
      <div className={`common-left-side ${Option.isJapan ? '' : 'grey-theme'}`}>
        <ExamSidebar />
      </div>
      <div className="common-content">
        <div className="common-inner">
          {examSummary.selectedRandomGroupNo ? (
            <ContentHeader
              title={`${headerTitle} ${formatMessage({
                id: 'StartExam_70',
                defaultMessage: '그룹',
              })}`}
              subTitle={headerSubTitle}
              buttons={
                <Tooltip
                  overlayClassName="black-tooltip full"
                  placement="bottom"
                  title={
                    isReserve
                      ? formatMessage({
                          id: 'Exam_49',
                          defaultMessage: '예약 훈련은 보고서를 생성할 수 없습니다.',
                        })
                      : examSummary.selectedExamNo === 'all' &&
                        randomList.selectedList.length < 1 &&
                        formatMessage({
                          id: 'Exam_39',
                          defaultMessage: '훈련을 선택하세요.',
                        })
                  }
                >
                  <button
                    className={`round-grey-button ${
                      (examSummary.selectedExamNo === 'all' &&
                        randomList.selectedList.length < 1) ||
                      (isReserve && Option.isJapan)
                        ? 'disabled'
                        : ''
                    }`}
                    type="button"
                    onClick={() => (isReserve ? undefined : clickReportBtn('random'))}
                  >
                    {formatMessage({ id: 'Exam_48', defaultMessage: '보고서' })}
                    <div className="download-img">
                      <img src="/img/exam/menu_down.png" alt="down" />
                    </div>
                  </button>
                </Tooltip>
              }
              // navButtonList={navButtonList}
              onRefresh={onRefresh}
            />
          ) : (
            <ContentHeader
              title={headerTitle}
              subTitle={headerSubTitle}
              buttons={buttonComponent}
              navButtonList={navButtonList}
              onRefresh={onRefresh}
            />
          )}
          <Switch>
            <Route path={`${url}/:name`} component={AutoRoute} />
            <Redirect path="*" to="/exam/pi" />
          </Switch>
        </div>
        <div className="common-inner-right">
          {selectedDetailData ? (
            <ExamDetail
              detailType={detailType}
              detailData={selectedDetailData}
              attachExamType={currentData?.attachData?.attachExamType}
            />
          ) : (
            <Dimmer
              dimmerText={
                pathname === '/exam/bytemplate'
                  ? formatMessage({
                      id: 'Template_21',
                      defaultMessage: '훈련 템플릿을 선택하세요.',
                    })
                  : formatMessage({ id: 'Exam_39', defaultMessage: '훈련을 선택하세요.' })
              }
            />
          )}
        </div>
      </div>

      {/* 백신 메일 발송 모달 */}
      {(modal === 'send' || modal === 'infectionSend') && (
        <ModalTemplate
          className="exam-send-mail-modal modal-464"
          visible={modal === 'send' || modal === 'infectionSend'}
          title={
            modal === 'send'
              ? formatMessage({ id: 'Exam_43', defaultMessage: '훈련 대상에게 백신 메일 발송' })
              : formatMessage({ id: 'Exam_44', defaultMessage: '감염 대상에게 백신 메일 발송' })
          }
          onOk={onSendVaccineMail}
          onCancel={() => setModal('')}
          okText={formatMessage({ id: 'Send_2', defaultMessage: '메일 발송' })}
          cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
          loading={loading}
        >
          <div className="modal-explain-text">
            {formatMessage({
              id: 'Exam_47',
              defaultMessage: '백신 파일이 첨부된 메일을 발송합니다.',
            })}
          </div>
          <div className="modal-border-box">
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'Exam_1', defaultMessage: '훈련명' })}
                </div>
              </li>
              <div>{currentData?.examName}</div>
            </ul>
            {modal === 'send' && (
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({ id: 'Target_6', defaultMessage: '발송 대상' })}
                  </div>
                </li>
                <div>{`${examList.totalItem}${formatMessage({
                  id: 'StartExam_35',
                  defaultMessage: '명',
                })}`}</div>
              </ul>
            )}
          </div>
        </ModalTemplate>
      )}

      {/* 보고서 다운로드 모달 */}
      {!Option.isReport && (modal === 'report' || modal === 'reportRandom') && (
        <ModalTemplate
          className="exam-report-download-modal modal-464"
          visible={modal === 'report' || modal === 'reportRandom'}
          title={formatMessage({ id: 'Download_10', defaultMessage: '보고서 다운로드' })}
          onOk={handleSubmit(reportDownload)}
          onCancel={() => {
            setModal('');
            setGroupReport({ groupExamNo: 0, isGroup: false, errorMsg: '' });
          }}
          okText={formatMessage({ id: 'Download_2', defaultMessage: '다운로드' })}
          cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
          loading={loading}
          disabled={groupReport.errorMsg}
        >
          <div className="input-title">
            {formatMessage({ id: 'Exam_50', defaultMessage: '보고서 유형' })}*
          </div>
          <div className="white-border-box">
            <Radio.Group onChange={changeReportType} value={reportType}>
              <Space direction="vertical">
                {modal === 'report' && (
                  <Radio value={1}>
                    <div className="flex">
                      {formatMessage({ id: 'Exam_51', defaultMessage: '개별 보고서' })}
                      <Popover
                        placement="right"
                        content={formatMessage({
                          id: 'Exam_54',
                          defaultMessage: "훈련 결과 '보고서'를 엑셀 파일로 다운로드",
                        })}
                      >
                        <img
                          className="qmark-img"
                          src="/img/exam/startExam/btn_qmark.png"
                          alt="qmark"
                        />
                      </Popover>
                    </div>
                  </Radio>
                )}
                <Radio value={2} disabled={modal === 'reportRandom' ? false : !groupReport.isGroup}>
                  <div className="flex">
                    {formatMessage({ id: 'Exam_52', defaultMessage: '통합 보고서' })}
                    {modal === 'report' && (
                      <Popover
                        placement="right"
                        content={
                          groupReport.isGroup
                            ? formatMessage({
                                id: 'Exam_56',
                                defaultMessage:
                                  "'통합 훈련 보고서' 다운로드 (그룹 훈련만 통합이 가능합니다.)",
                              })
                            : formatMessage({
                                id: 'Exam_55',
                                defaultMessage: "2개 이상의 '그룹 훈련'을 선택하세요",
                              })
                        }
                      >
                        <img
                          className="qmark-img"
                          src="/img/exam/startExam/btn_qmark.png"
                          alt="qmark"
                        />
                      </Popover>
                    )}
                  </div>
                </Radio>
                {modal === 'report' && (
                  <Radio value={3}>
                    <div className="flex">
                      {formatMessage({ id: 'Exam_53', defaultMessage: 'CSV 보고서' })}
                      <Popover
                        placement="right"
                        content={formatMessage({
                          id: 'Exam_84',
                          defaultMessage: '보고서를 단일 파일로 다운로드',
                        })}
                      >
                        <img
                          className="qmark-img"
                          src="/img/exam/startExam/btn_qmark.png"
                          alt="qmark"
                        />
                      </Popover>
                    </div>
                  </Radio>
                )}
              </Space>
            </Radio.Group>
          </div>
          <div className="error-message">{groupReport.errorMsg}</div>

          <form onSubmit={(e: any) => e.preventDefault()}>
            <div className="input-title">
              {formatMessage({ id: 'Exam_57', defaultMessage: '마스킹 사용' })}
            </div>
            <div className="white-border-box">
              <div className="box-item">
                {formatMessage({ id: 'Name_1', defaultMessage: '이름' })}
                <FormSwitchField
                  className="input-switch"
                  control={control}
                  name="maskingName"
                  handleOnChange={changeMasking}
                />
              </div>
              <div className="box-item">
                {formatMessage({ id: 'Email_1', defaultMessage: '이메일' })}
                <FormSwitchField
                  className="input-switch"
                  control={control}
                  name="maskingEmail"
                  handleOnChange={changeMasking}
                />
              </div>
              <div className="box-item">
                {formatMessage({ id: 'Contact_1', defaultMessage: '연락처' })}
                <FormSwitchField
                  className="input-switch"
                  control={control}
                  name="maskingPhone"
                  handleOnChange={changeMasking}
                />
              </div>
            </div>

            {Option.customer === 'hyundai' && (
              <>
                <div className="text-field-title">
                  {formatMessage({ id: 'Exam_83', defaultMessage: '보고서 다운로드 사유' })}*
                </div>
                <FormTextField
                  name="reportDownloadReason"
                  error={errors.reportDownloadReason}
                  register={register}
                  arrowPosition="top"
                  validation={{
                    validate: {
                      required: (value: any) => valid.required(value),
                    },
                  }}
                />
              </>
            )}
          </form>
        </ModalTemplate>
      )}

      {/* 보고서 다운로드 모달 - 기본/상세 보고서 */}
      {!!Option.isReport && (modal === 'report' || modal === 'reportRandom') && (
        <ModalTemplate
          className="exam-report-download-modal detail-report-modal modal-600"
          visible={modal === 'report' || modal === 'reportRandom'}
          title={formatMessage({ id: 'Download_10', defaultMessage: '보고서 다운로드' })}
          onOk={handleSubmit(reportDownload)}
          onCancel={() => {
            setModal('');
            setSummaryError('');
            setGroupReport({ groupExamNo: 0, isGroup: false, errorMsg: '' });
          }}
          okText={formatMessage({ id: 'Download_2', defaultMessage: '다운로드' })}
          // cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
          loading={loading}
          disabled={groupReport.errorMsg}
        >
          <div>
            <div className="input-title">
              {formatMessage({ id: 'Exam_96', defaultMessage: '기본 보고서' })}
            </div>
            <Radio.Group
              onChange={changeSummaryReportType}
              value={summaryReportType}
              className="detail-radio-group"
            >
              <div className="summary-report-list">
                <Radio value={1}>
                  <div className="report-type-label">html</div>
                </Radio>
                <Radio value={2} disabled>
                  <div className="report-type-label">pdf</div>
                </Radio>
              </div>
            </Radio.Group>
            <div className="error-message">{summaryError}</div>

            <button
              className={`ok-btn ${summaryError || loading ? 'disabled' : ''}`}
              type="button"
              onClick={summaryReportDownload}
            >
              {formatMessage({ id: 'Download_2', defaultMessage: '다운로드' })}
            </button>
          </div>
          <div className="horizontal-line" />
          <div>
            <div className="input-title">
              {formatMessage({ id: 'Exam_97', defaultMessage: '상세 보고서' })}
            </div>
            <Radio.Group
              onChange={changeReportType}
              value={reportType}
              className="detail-radio-group"
            >
              <div className="report-type-list">
                {modal === 'report' && (
                  <Radio value={1}>
                    <div className="report-type-label">
                      {formatMessage({ id: 'Exam_51', defaultMessage: '개별 보고서' })}
                      <Popover
                        placement="bottom"
                        content={formatMessage({
                          id: 'Exam_54',
                          defaultMessage: "훈련 결과 '보고서'를 엑셀 파일로 다운로드",
                        })}
                      >
                        <img
                          className="qmark-img"
                          src="/img/exam/startExam/btn_qmark.png"
                          alt="qmark"
                        />
                      </Popover>
                    </div>
                  </Radio>
                )}
                <Radio value={2} disabled={modal === 'reportRandom' ? false : !groupReport.isGroup}>
                  <div className="report-type-label">
                    {formatMessage({ id: 'Exam_52', defaultMessage: '통합 보고서' })}
                    {modal === 'report' && (
                      <Popover
                        placement="bottom"
                        content={
                          groupReport.isGroup
                            ? formatMessage({
                                id: 'Exam_56',
                                defaultMessage:
                                  "'통합 훈련 보고서' 다운로드 (그룹 훈련만 통합이 가능합니다.)",
                              })
                            : formatMessage({
                                id: 'Exam_55',
                                defaultMessage: "2개 이상의 '그룹 훈련'을 선택하세요",
                              })
                        }
                      >
                        <img
                          className="qmark-img"
                          src="/img/exam/startExam/btn_qmark.png"
                          alt="qmark"
                        />
                      </Popover>
                    )}
                  </div>
                </Radio>
                {modal === 'report' && (
                  <Radio value={3}>
                    <div className="report-type-label">
                      {formatMessage({ id: 'Exam_53', defaultMessage: 'CSV 보고서' })}
                      <Popover
                        placement="bottom"
                        content={formatMessage({
                          id: 'Exam_84',
                          defaultMessage: '보고서를 단일 파일로 다운로드',
                        })}
                      >
                        <img
                          className="qmark-img"
                          src="/img/exam/startExam/btn_qmark.png"
                          alt="qmark"
                        />
                      </Popover>
                    </div>
                  </Radio>
                )}
              </div>
            </Radio.Group>
            <div className="error-message">{groupReport.errorMsg}</div>
            <div className="square-border-box">
              <div className="masking-text">
                {formatMessage({ id: 'Exam_57', defaultMessage: '마스킹 사용' })}
              </div>
              <Checkbox.Group options={maskingOptions} onChange={onMaskingChange} />
            </div>
            {Option.customer === 'hyundai' && (
              <>
                <div className="text-field-title">
                  {formatMessage({ id: 'Exam_83', defaultMessage: '보고서 다운로드 사유' })}*
                </div>
                <FormTextField
                  name="reportDownloadReason"
                  error={errors.reportDownloadReason}
                  register={register}
                  arrowPosition="top"
                  validation={{
                    validate: {
                      required: (value: any) => valid.required(value),
                    },
                  }}
                />
              </>
            )}
          </div>
        </ModalTemplate>
      )}
    </div>
  );
}
export default Exam;
