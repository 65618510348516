import { useState, useEffect } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror17';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';

import 'codemirror/mode/xml/xml.js';
import 'codemirror/mode/css/css';
import 'codemirror/mode/javascript/javascript.js';

import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/edit/closetag';

import { Option } from 'utils/commonValues';
import { Resizable } from 're-resizable';

import './PhishingEditor.scss';

interface codemirrorProps {
  curStep: number;
  data: any;
  isAdmin: boolean;
  preview: boolean;
  onChangeEditor: (type: any, code: any) => void;
}

function PhishingPageEditor({ curStep, data, isAdmin, preview, onChangeEditor }: codemirrorProps) {
  const [size, setSize]: any = useState({ width: 'auto', height: '100%' });

  /*
   * <결과 화면>
   * 일본: 사용자 관리자(HTML, CSS 사용), 슈퍼 관리자(HTML, CSS, JS 모두 사용)
   * 일본 외: HTML, CSS, JS 모두 사용
   */

  // JS, CSS 영역 사용 여부 판단
  let useJS = true;
  if (Option.isJapan) {
    useJS = isAdmin || curStep !== 3 ? true : false;
  }

  useEffect(() => {
    setSize({ width: 'auto', height: curStep && useJS ? 441 : '100%' });
  }, [useJS, curStep]);

  return (
    <div className="phishing-page-editor">
      <Resizable
        className="vertical-resizable"
        defaultSize={{ width: 750, height: '100%' }}
        enable={{ right: true }}
      >
        <div className="left-side">
          {/* html */}
          <Resizable
            className="left-side-resizable"
            maxHeight={curStep && useJS ? 580 : '100%'}
            minHeight={80}
            size={size}
            onResizeStop={(e, direction, ref, delta) => {
              setSize({ width: size.width + delta.width, height: size.height + delta.height });
            }}
            enable={{ bottom: curStep && useJS ? true : false }}
          >
            <div className="code-area html">
              <span className="type-text">HTML</span>
              <CodeMirror
                value={data.html || ''}
                options={{
                  mode: 'xml',
                  theme: 'material',
                  lineNumbers: true,
                  tabSize: 2,
                }}
                onBeforeChange={(editor, data, value) => {
                  onChangeEditor(`phishingHtml${curStep}`, value);
                }}
              />
            </div>
          </Resizable>
          {/* js */}
          {!!curStep && useJS && (
            <div className="code-area js">
              <span className="type-text">Javascript + No-Library(pure JS)</span>
              <CodeMirror
                value={data.js || ''}
                options={{
                  mode: 'javascript',
                  theme: 'material',
                  lineNumbers: true,
                  tabSize: 2,
                }}
                onBeforeChange={(editor, data, value) => {
                  onChangeEditor(`phishingJs${curStep}`, value);
                }}
              />
            </div>
          )}
        </div>
      </Resizable>
      <div className="right-side">
        {/* css */}
        {!!curStep && (
          <Resizable
            className="right-side-resizable"
            defaultSize={{ width: 'auto', height: 441 }}
            enable={{ bottom: true }}
          >
            {!!curStep && (
              <div className="code-area css">
                <span className="type-text">CSS</span>
                <CodeMirror
                  value={data.css || ''}
                  options={{
                    mode: 'css',
                    theme: 'material',
                    lineNumbers: true,
                    tabSize: 2,
                  }}
                  onBeforeChange={(editor, data, value) => {
                    onChangeEditor(`phishingCss${curStep}`, value);
                  }}
                />
              </div>
            )}
          </Resizable>
        )}

        {/* 실행 결과 화면 */}
        <iframe
          id="phishing-iframe"
          className={`phishing-iframe${preview ? ' on' : ''}`}
          title={`iframe-${curStep}`}
        />
      </div>
    </div>
  );
}

export default PhishingPageEditor;
