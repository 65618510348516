import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { addAdmin, updateAdmin } from 'store/admin';
import { Option } from 'utils/commonValues';
import * as valid from 'utils/validation';
import FormSelectField from 'components/common/FormSelectField';
import FormTextField from 'components/common/FormTextField';
import Loading from 'components/common/Loading';
import ModalTemplate from 'components/common/ModalTemplate';

export default function SubAdminAddEdit({ visible, isAddMode, dataInfo, toggleModal }: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { errors, control, register, watch, setValue, setError, handleSubmit } = useForm({
    defaultValues: {
      userName: dataInfo.userName,
      userEmail: dataInfo.userEmail,
      userPasswd: null,
      userPhone: dataInfo.userPhone,
      userEnabled: isAddMode ? 1 : dataInfo.userEnabled,
      loginFailCount: isAddMode ? 1 : dataInfo.loginFailCount,
    },
  });

  // 부관리자 등록/수정
  const onSaveSubAdmin = async (formData: any) => {
    try {
      const params: any = {};

      // 값 변경 여부
      let changed = isAddMode ? true : false;
      Object.keys(formData).forEach((key: any) => {
        let value = formData[key];

        if (key === 'loginFailCount') {
          value = parseInt(formData[key], 10);
        }

        // 변경된 항목만 파라미터에 넣어줌
        if (value !== '' && value !== dataInfo[key]) {
          changed = true;
          params[key] = value;
        }

        if (isAddMode) {
          params.userAuthority = 2;
        } else {
          params.userNo = dataInfo.userNo;
        }
      });

      if (changed) {
        setLoading(true);
        const response: any = await dispatch(isAddMode ? addAdmin(params) : updateAdmin(params));
        if (response?.data?.list) {
          setLoading(false);
          toggleModal(false);
        } else {
          setLoading(false);
          if (response.data.error[0]?.errInfo === 'Duplicate User Exist') {
            setError('userEmail', {
              type: 'duplicate',
              message: formatMessage({
                id: 'Email_9',
                defaultMessage: '이미 등록된 이메일입니다.',
              }),
            });
          } else {
            setError(Object.keys(response.data.error[0])[0], {
              type: 'errInfo',
              message: Object.keys(response.data.error[0])[0],
            });
          }
        }
      } else {
        toggleModal(false);
      }
    } catch (error) {
      console.log('onSaveSubAdmin', error);
    }
  };

  return (
    <ModalTemplate
      className="sub-admin-add-edit-modal modal-464"
      visible={visible}
      title={
        <div className="modal-template-header">
          <div className="title">
            {isAddMode
              ? formatMessage({ id: 'User_31', defaultMessage: '부관리자 등록' })
              : `${formatMessage({ id: 'User_32', defaultMessage: '부관리자 수정' })} (No.${
                  dataInfo.userNo
                })`}
          </div>
        </div>
      }
      onOk={handleSubmit(onSaveSubAdmin)}
      onCancel={() => toggleModal(false)}
      okText={
        isAddMode
          ? formatMessage({ id: 'Button_10', defaultMessage: '등 록' })
          : formatMessage({ id: 'Button_14', defaultMessage: '수 정' })
      }
      cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
      loading={loading}
    >
      <Loading loading={loading} />
      <form autoComplete="off" onSubmit={(e: any) => e.preventDefault()}>
        {/* 이름 */}
        <div className="input-title">
          {formatMessage({ id: 'Name_1', defaultMessage: '이름' })}*
        </div>
        <FormTextField
          name="userName"
          error={errors.userName}
          register={register}
          arrowPosition="top"
          validation={{
            validate: {
              required: (value: any) => valid.required(value),
              name: (value: any) => valid.name(value),
            },
          }}
        />

        {/* 이메일 */}
        <div className="text-field-title">
          {formatMessage({ id: 'Email_1', defaultMessage: '이메일' })}*
        </div>
        <FormTextField
          name="userEmail"
          error={errors.userEmail}
          register={register}
          arrowPosition="top"
          validation={{
            validate: {
              required: (value: any) => valid.required(value),
              email: (value: any) => valid.email(value),
            },
          }}
        />

        {/* 비밀번호 */}
        <div className="text-field-title">
          {formatMessage({ id: 'Password_1', defaultMessage: '비밀번호' })}
          {isAddMode ? '*' : ''}
        </div>
        <FormTextField
          name="userPasswd"
          error={errors.userPasswd}
          register={register}
          type="password"
          showPassword
          arrowPosition="top"
          validation={{
            validate: {
              required: (value: any) => (isAddMode ? valid.required(value) : undefined),
              min: (value: any) =>
                value && value.length < 5
                  ? `${formatMessage({ id: 'Config_37', defaultMessage: '비밀번호 최소 길이' })}: 5`
                  : undefined,
            },
          }}
        />

        {/* 연락처 */}
        <div className="text-field-title">
          {`${formatMessage({ id: 'Contact_1', defaultMessage: '연락처' })}${
            Option.customer === 'saxa' ? '*' : ''
          }`}
        </div>
        <FormTextField
          name="userPhone"
          error={errors.userPhone}
          register={register}
          arrowPosition="top"
          validation={{
            validate:
              Option.customer === 'saxa'
                ? {
                    required: (value: any) => valid.required(value),
                    phone: (value: any) => valid.userPhone(value),
                  }
                : { phone: (value: any) => valid.userPhone(value) },
          }}
        />

        {/* 계정 삭제 */}
        {!isAddMode && (
          <>
            <div className="text-field-title">
              {formatMessage({ id: 'User_18', defaultMessage: '계정 삭제' })}
            </div>
            <FormSelectField
              name="userEnabled"
              control={control}
              error={errors.userEnabled}
              menuList={[
                {
                  key: 1,
                  value: 1,
                  text: formatMessage({ id: 'User_4', defaultMessage: '정상' }),
                },
                {
                  key: 0,
                  value: 0,
                  text: formatMessage({ id: 'User_19', defaultMessage: '삭제' }),
                },
              ]}
              defaultValue={isAddMode ? 1 : dataInfo.userEnabled}
              showArrow
            />
          </>
        )}

        {/* 로그인 실패 횟수 */}
        {!isAddMode && (
          <>
            <div className="text-field-title">
              {formatMessage({ id: 'User_20', defaultMessage: '로그인 실패 횟수' })}
            </div>
            <FormTextField
              name="loginFailCount"
              error={errors.loginFailCount}
              register={register}
              arrowPosition="top"
              validation={{
                validate: {
                  required: (value: any) => valid.required(value),
                  number: (value: any) => valid.number(value),
                  range: (value: any) => valid.range(value, 0, 50),
                },
              }}
            />
          </>
        )}
      </form>
    </ModalTemplate>
  );
}
