import { useEffect } from 'react'
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import MainTitle from 'components/common/admin/MainTitle'
import { getSystemConfig, updateSystemConfig } from 'store/admin'
import ConfigTemplate from 'components/common/config/ConfigTemplate'
import SyncTarget from 'components/common/config/SyncTarget'
import "./Conf.scss";

export default function Syncconf(props: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const isAdmin = useSelector((state: any) => state.login.userData.isAdmin, shallowEqual)
  const syncConfig = useSelector((state: any) => state.admin.syncConfig)

  useEffect(() => {
    dispatch(getSystemConfig({ category: "sync" }))
  }, [])

  return (
    <div className="admin-basic-conf">
      <MainTitle className="small" title={formatMessage({ id: 'ConfigSync_1', defaultMessage: '대상자 연동' })} />
      <ConfigTemplate category="syncConfig" updateConfig={updateSystemConfig} order={syncConfig?.classOrder || []} lists={syncConfig?.class || {}} isAdmin={isAdmin} />
      <SyncTarget />
    </div>
  );
}