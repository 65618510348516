import { Tag } from 'antd';
import { useIntl } from 'react-intl';


export default function TagItem(props: any) {
  const { formatMessage } = useIntl();
  const { tagItem, size } = props

  const { tagNo, tagName, tagInitial, createUser, color, targetCount } = tagItem

  let setColor = '#db2828';
  if (color === 'red') setColor = '#ff0000';
  else if (color === 'orange') setColor = '#ff8a00';
  else if (color === 'yellow') setColor = '#ffcc00';
  else if (color === 'olive') setColor = '#c1d91f';
  else if (color === 'lightgreen') setColor = '#76ea05';
  else if (color === 'green') setColor = '#12bb52';
  else if (color === 'pink') setColor = '#d90cec';
  else if (color === 'purple') setColor = '#9c00ff';
  else if (color === 'violet') setColor = '#632fda';
  else if (color === 'blue') setColor = '#125be1';
  else if (color === 'lightblue') setColor = '#0ea7e7';
  else if (color === 'emerald') setColor = '#02dcc5';
  else if (color === 'lightpink') setColor = '#ff6ce2';
  else if (color === 'plum') setColor = '#ff1a86';
  else if (color === 'darkplum') setColor = '#c90d6a';
  else if (color === 'brown') setColor = '#a44200';
  else if (color === 'ash') setColor = '#bb9b7c';
  else if (color === 'apricot') setColor = '#ffb99a';
  else if (color === 'grey') setColor = '#767676';
  else if (color === 'black') setColor = '#1b1c1d';

  const tagContent = (
    <div className='TagItem-wrapper'>
      <div className='editMode-area'>
        <Tag style={{ borderColor: setColor, borderRadius: 15 }}>
          <div className='tag-name' style={{ color: setColor }}>
            {tagName}
          </div>
        </Tag>
      </div>
    </div>
  );

  return tagContent
}