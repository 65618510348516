import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import parse from 'html-react-parser';
import { timeDiffFromUTCEpoch } from 'utils/commonFunctions';
import { resendAuthEmail } from 'store/register';
import { LoadingOutlined } from '@ant-design/icons';
import { Option, updateVersion } from 'utils/commonValues';

// import mfLogoImage from 'img/logo/mf_logo_white.png';
// import sxLogoImage from 'img/logo/sx_logo_white.png';

function Active(props: any) {
  const loginData = useSelector((state: any) => state.login);
  const dispatch = useDispatch();
  const [resendSuccess, setResendSuccess] = useState(0);
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();

  const { formStore, loginResult, loginMessage } = loginData;

  // 새로고침
  if (![32, 64].includes(loginResult)) {
    return <Redirect to="/" />;
  }

  // 다음에 인증하기
  const onAuthNext = () => {
    if (loginResult === 32) {
      // API SET_USER 대신 location 사용
      props.history.push('/');
    }
  };

  // 인증메일 재전송
  const onResendAuthEmail = async () => {
    setLoading(true);
    try {
      const params = {
        userEmail: formStore.userEmail,
      };
      const response: { [key: string]: any } = await dispatch(resendAuthEmail(params));
      if (response?.data?.list) {
        // 재전송 성공
        setResendSuccess(1);
      } else {
        // 재전송 실패
        setResendSuccess(2);
      }
      setLoading(false);
    } catch (error) {
      console.log('onResendEmail', error);
    }
  };

  return (
    <div className="login-wrapper">
      <div className="no-active-login login-item">
        <img
          className="logo-white"
          src="/img/logo/mf_logo_white.png" // {Option.isSaxa === 1 ? sxLogoImage : mfLogoImage}
          alt="logo"
        />
        <div className="content-box">
          <div className="info-text">
            <div className="sub-text">
              {parse(
                formatMessage({
                  id: 'NoActive_1',
                  defaultMessage:
                    "해당 계정은 <span class='bold'>이메일 인증이 이루어지지 않은 계정</span>입니다.",
                }),
              )}
            </div>
            <div className="sub-text">
              {formatMessage({
                id: 'NoActive_2',
                defaultMessage: '계정을 활성화해서 서비스를 이용하세요.',
              })}
            </div>
          </div>

          {/* 등록된 메일 주소 */}
          <div className="border-box">
            <span className="text">
              {formatMessage({ id: 'NoActive_3', defaultMessage: '등록된 메일 주소' })}
            </span>
            <span>{formStore ? formStore.userEmail : ''}</span>
          </div>
          {/* 남은 기간 */}
          <div className="period-info brand-color">
            <span className="text">
              {formatMessage({ id: 'Period_2', defaultMessage: '남은 기간 ' })}
            </span>
            <span className="bold">
              {loginResult === 32
                ? timeDiffFromUTCEpoch(loginMessage)
                : formatMessage({ id: 'Status_7', defaultMessage: '만료' })}
            </span>
          </div>
          {/* 버튼 */}
          <div className="button-box">
            {loginResult === 32 && (
              <button
                type="button"
                className="login-btn"
                onClick={onAuthNext}
                style={{ background: 'url(/img/login/login_button.png) no-repeat center' }}
              >
                {formatMessage({ id: 'NoActive_4', defaultMessage: '다음에 인증하기' })}
                {loading && (
                  <span className="button-loading">
                    <LoadingOutlined />
                  </span>
                )}
              </button>
            )}
            <button
              type="button"
              className="login-btn grey"
              onClick={onResendAuthEmail}
              style={{ background: 'url(/img/login/grey_button.png) no-repeat center' }}
            >
              {formatMessage({ id: 'NoActive_5', defaultMessage: '인증메일 재전송' })}
              {loading && (
                <span className="button-loading">
                  <LoadingOutlined />
                </span>
              )}
            </button>
          </div>
          {/* 인증메일 재전송 결과 */}
          <div className={`resend-result${resendSuccess === 1 ? ' success' : ''}`}>
            {!!resendSuccess &&
              (resendSuccess === 1
                ? formatMessage({ id: 'NoActive_6', defaultMessage: '메일 재전송 성공' })
                : formatMessage({ id: 'NoActive_7', defaultMessage: '메일 재전송 실패' }))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Active);
