import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import MainTitle from 'components/common/admin/MainTitle';
import { getServiceConfig, updateServiceConfig } from 'store/config';
import ConfigTemplate from 'components/common/config/ConfigTemplate';
import { Option } from 'utils/commonValues';
import '../mudmanage/Conf.scss';

export default function Edu(props: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const isAdmin = useSelector((state: any) => state.login.userData.isAdmin, shallowEqual);
  const eduConfig = useSelector((state: any) => state.config.eduConfig);

  useEffect(() => {
    dispatch(getServiceConfig({ category: 'edu' }));
  }, []);

  return (
    <div className="admin-basic-conf">
      <MainTitle
        className="small"
        title={formatMessage({ id: 'MyPage_2', defaultMessage: '교육 설정' })}
      />
      <ConfigTemplate
        category="eduConfig"
        updateConfig={updateServiceConfig}
        order={eduConfig.classOrder || []}
        lists={eduConfig.class || {}}
        isAdmin={isAdmin}
        styleConfig={Option.isJapan ? [] : ['edu']}
      />
    </div>
  );
}
