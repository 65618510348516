import React, { useEffect, useState } from 'react';
// import ReactPlayer from 'react-player';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import './VideoPlayer.scss';

function VideoPlayer({ videoPlayerOption, currentIndex, contentType }: any) {
  // 비디오 노드
  let videoNode: any = null;

  useEffect(() => {
    // videojs 플레이어
    let player: any = null;
    // 영상 변경 시 해당 인덱스의 src 넣어줌
    player = videojs(videoNode, videoPlayerOption);
    // 유튜브 미리보기(시작전) 화면 설정
    if (contentType === 2) {
      const url = new URL(videoPlayerOption.sources[currentIndex]?.src);
      const youtubeId =
        url?.pathname?.indexOf('watch') > -1
          ? new URLSearchParams(url?.search)?.get('v')
          : url?.pathname?.split('/').pop();
      player.poster(`https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`);
    }
    player.src(videoPlayerOption.sources[currentIndex]);
  }, [currentIndex]);

  return (
    <div data-vjs-player>
      <video
        ref={function (node: any) {
          videoNode = node;
        }}
        className="video-js vjs-big-play-centered"
      >
        <track kind="captions" />
      </video>
    </div>
  );
}

export default VideoPlayer;
