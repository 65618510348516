import { Checkbox, Col, Popover, Row, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import './TableFilter.scss';

// import arrowImage from 'img/table/selectbox_number_arrow.png';

export default function TableFilter(props: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [clicked, setClicked] = useState<any>(false);
  const {
    filterOption,
    changeParam,
    initFilter,
    filterList,
    setFilterList,
    filter,
    searchOption,
    filterStyle,
    className
  } = props;
  const filterGroup: Array<any> = [];

  let totalArrayLength = 0;
  Object.keys(filter).forEach((key: string) => {
    if (!Object.keys(searchOption).includes(key)) {
      // 테이블 날짜 필터는 필터 개수에서 제외
      if (filter[key] && !key.toLowerCase().includes('epoch')) {
        totalArrayLength += Array.isArray(filter[key]) ? filter[key].length : 1;
      } else {
        totalArrayLength += 0;
      }
    }
  });

  // 필터 설정
  Object.keys(filterOption).forEach((key: string) => {
    filterGroup.push(
      <Col key={key} style={filterStyle}>
        {/* <div className="filter-group"> */}
        <div className="filter-group-header">{filterOption[key].name}</div>
        <Checkbox.Group
          className="filter-group-checkbox"
          style={{ display: 'flex', flexDirection: 'column' }}
          options={filterOption[key].child}
          value={Object.keys(filterList).includes(key) && filterList[key]}
          onChange={(props) => changeFilterList(props, key)}
        />
        {/* </div> */}
      </Col>,
    );
  });

  // 필터 변경
  const changeFilterList = (props: Array<any>, key: string) => {
    const filterTemp = { ...filterList };
    if (props.length > 0) {
      setFilterList({ ...filterTemp, [key]: props });
    } else {
      delete filterTemp[key];
      setFilterList({ ...filterTemp });
    }
  };

  // 필터 실행
  const excuteFilterList = () => {
    setClicked(false);

    dispatch(
      changeParam({
        name: 'filter',
        value: Object.keys(filterList).length > 0 ? filterList : {},
      }),
    );
  };

  // 필터 취소
  const cancelFilterList = () => {
    setFilterList(filter);
    setClicked(false);
  };

  // 팝업 열기
  const onPopover = () => {
    setFilterList(filter);
    setClicked(!clicked);
  };

  // 필터 해제 (체크 박스 해제)
  const initFilterCheckBox = () => {
    setFilterList({});
    initFilter();
  };

  return (
    <Tooltip title="test" placement="bottom" visible={false}>
      <Popover
        overlayClassName={`table-filter-popover ${className}`}
        placement="bottom"
        trigger={['click']}
        visible={clicked}
        onVisibleChange={onPopover}
        content={
          <>
            <Row>{filterGroup}</Row>
            <Row>
              <div className="filter-group-footer">
                <div
                  className="all-check-off"
                  onClick={initFilterCheckBox}
                  onKeyDown={initFilterCheckBox}
                  role="button"
                  tabIndex={0}
                >
                  {formatMessage({ id: 'Filter_7', defaultMessage: '모든 필터 해제' })}
                </div>
                <div className="inline-block">
                  <button className="button-border" onClick={cancelFilterList} type="button">
                    {formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
                  </button>
                  <button className="button-brand" onClick={excuteFilterList} type="button">
                    {formatMessage({ id: 'Button_4', defaultMessage: '확 인' })}
                  </button>
                </div>
              </div>
            </Row>
          </>
        }
      >
        <div
          className={`target-dropdown-area ${
            totalArrayLength > 0 ? 'target-dropdown-area-border' : ''
          }`}
        >
          <div className="dropdown-arrow-area table-filter-arrow">
            {totalArrayLength > 0 ? (
              <div className="number-box">{totalArrayLength}</div>
            ) : (
              <img className="dropdown-image" src="/img/table/selectbox_number_arrow.png" alt="" />
            )}
          </div>
          <div className="default-text">
            {formatMessage({ id: 'Filter_1', defaultMessage: '필터' })}
          </div>
        </div>
      </Popover>
    </Tooltip>
  );
}
