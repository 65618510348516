import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Option } from 'utils/commonValues';
import LicenseAddEdit from 'components/branch/license/LicenseAddEdit';
import './LicenseMenu.scss';

export default function LicenseMenu(props: any) {
  const { formatMessage } = useIntl();
  const [openModal, setOpenModal] = useState(false);
  const isAdmin = useSelector((state: any) => state.login.userData.isAdmin);

  return (
    <div className="license-page-menu-wrap">
      {Option.isSaas === 1 && (Option.isJapan === 0 || isAdmin === 1) && (
        <>
          <div className="menu-button">
            <button
              className="common-button-brand initial-config-bt"
              type="button"
              onClick={() => setOpenModal(true)}
            >
              {formatMessage({ id: 'Button_27', defaultMessage: '발 급' })}
            </button>
          </div>
          {openModal && (
            <LicenseAddEdit
              visible={openModal}
              isAddMode
              isAdmin={isAdmin}
              dataInfo={{}}
              toggleModal={setOpenModal}
            />
          )}
        </>
      )}
    </div>
  );
}
