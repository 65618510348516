import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Tag, Tooltip } from 'antd';
import moment from 'moment';
import { getResultTarget } from 'store/target';
import ContentList from 'components/common/ContentList';

function Bytarget() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectNo, setSelectNo] = useState(0);

  const targetList = useSelector((state: any) => {
    return {
      data: state.target.resultTarget.data,
      // dataByExamNo: state.target.resultTarget.dataByExamNo,
      param: state.target.resultTarget.param,
      needReload: state.target.resultTarget.needReload,
      page: state.target.resultTarget.page,
      totalPages: state.target.resultTarget.totalPages,
    };
  }, shallowEqual);

  useEffect(() => {
    onGetTargetList(true);
  }, [targetList.param]);

  // 대상자 리스트 조회
  const onGetTargetList = async (refresh = false) => {
    if (loading) return;
    setLoading(true);

    try {
      const params: any = {
        filter: JSON.stringify(targetList.param.filter),
        sort: JSON.stringify(targetList.param.sort),
        offset: 0,
        limit: targetList.param.limit,
        tagNoArray: 'all-tag',
        multiType: 'or',
      };

      if (refresh) {
        // 처음부터 조회
        params.refresh = true;
      } else {
        // 이어서 조회
        params.offset = targetList.param.limit * targetList.page;
      }

      await dispatch(getResultTarget(params));

      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  // 훈련 선택
  // const onSelectItem = useCallback((no: number) => {
  //   setSelectNo(no);
  // }, []);

  return (
    <div className="exam-content-wrap by">
      {/* 내용 리스트 */}
      <ContentList
        dataList={targetList.data}
        onLoadData={onGetTargetList}
        loading={loading}
        page={targetList.page}
        totalPage={targetList.totalPages}
        noContent={{
          title: formatMessage({ id: 'Exam_35', defaultMessage: '훈련이 없습니다.' }),
          subTitle: formatMessage({ id: 'Exam_36', defaultMessage: '훈련을 실행해 보세요!' }),
        }}
      >
        {targetList.data?.length > 0 &&
          targetList.data?.map((item: any) => {
            const examHistoryData = item.examHistory?.totalData[0];
            const infectionRate = examHistoryData
              ? `${((examHistoryData.totalInfection / examHistoryData.totalTarget) * 100).toFixed(
                  0,
                )}%`
              : '0%';

            return (
              <div
                className={`content-list-item ${selectNo === item.targetNo ? 'selected' : ''}`}
                key={item.targetNo}
                // onClick={() => onSelectItem(item.targetNo)}
                aria-hidden="true"
              >
                <div className="long-column ellipsis">
                  <Tooltip
                    overlayClassName="black-tooltip"
                    placement="bottomLeft"
                    title={
                      <div>
                        <div>
                          {`${formatMessage({
                            id: 'Name_13',
                            defaultMessage: '대상자명',
                          })}: ${item.targetName}`}
                        </div>
                        <div>
                          {`${formatMessage({
                            id: 'Email_10',
                            defaultMessage: '대상자 이메일',
                          })}: ${item.targetEmail}`}
                        </div>
                      </div>
                    }
                  >
                    <img src="/img/exam/step/target.png" alt="target" />
                    {`${item.targetName} (${item.targetEmail})`}
                  </Tooltip>
                </div>

                <div className="center-column">
                  <Tooltip
                    overlayClassName="black-tooltip"
                    placement="bottomLeft"
                    title={
                      <div>
                        <div>
                          {`${formatMessage({
                            id: 'Division_1',
                            defaultMessage: '소속',
                          })}: ${item.targetDivision || '-'}`}
                        </div>
                        <div>
                          {`${formatMessage({
                            id: 'Position_1',
                            defaultMessage: '직급',
                          })}: ${item.targetPosition || '-'}`}
                        </div>
                      </div>
                    }
                  >
                    <div className="target-company">
                      <div className="division">{item.targetDivision || '-'}</div>
                      <div className="divide">/</div>
                      <div className="position">{item.targetPosition || '-'}</div>
                    </div>
                  </Tooltip>
                </div>

                <div className="center-column">
                  <Tooltip
                    overlayClassName="black-tooltip"
                    placement="bottom"
                    title={`${formatMessage({
                      id: 'Infection_45',
                      defaultMessage: '감염/유출률',
                    })}: ${infectionRate}`}
                  >
                    <div className="bold">{infectionRate}</div>
                  </Tooltip>
                </div>
              </div>
            );
          })}
      </ContentList>
    </div>
  );
}

export default Bytarget;
