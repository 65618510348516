import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { getDashboardExamStatus } from 'store/dashboard';

function DashboardExamStatus() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [statisticsData, setStatisticsData]: any = useState({});

  useEffect(() => {
    onGetDashboardExam();
  }, []);

  const onGetDashboardExam = async () => {
    try {
      const response: any = await dispatch(getDashboardExamStatus());
      if (response.data?.list) {
        setStatisticsData(response.data?.list);
      }
    } catch (error) {
      console.log('Dashboard onGetDashboardExam', error);
    }
  };

  const imagePath = {
    download: '/img/dashboard/download.png',
    phishing: '/img/dashboard/phishing.png',
    exam: '/img/dashboard/exam.png',
    examEnd: '/img/dashboard/exam_end.png',
    examReserve: '/img/dashboard/exam_reserve.png',
  };

  const ExamStatus = useMemo(() => {
    if (Object.keys(statisticsData)?.length < 1) return <></>;

    const status = [
      { key: 'ing', text: formatMessage({ id: 'Exam_8', defaultMessage: '진행중인 훈련' }), image: imagePath.exam },
      { key: 'end', text: formatMessage({ id: 'Exam_76', defaultMessage: '종료된 훈련' }), image: imagePath.examEnd },
      { key: 're', text: formatMessage({ id: 'Exam_92', defaultMessage: '예약된 훈련' }), image: imagePath.examReserve },
      { key: 'total', text: formatMessage({ id: 'Exam_93', defaultMessage: '누적된 훈련' }), image: imagePath.exam }
    ];
    const unit = formatMessage({ id: 'User_8', defaultMessage: '회' });
    // 카테고리 => 0:기본, 1:금융, 2:채용, 3:거래처, 4:공공, 5:쇼핑, 6:취약점, 7:계정관리, 8:약관동의, 9:납부, 10:예약
    const categories = [
      { text: formatMessage({ id: 'Dashboard_9', defaultMessage: '기본' }), color: '#0D8E7C', backgroundColor: '#D3F4EE' },
      { text: formatMessage({ id: 'Dashboard_10', defaultMessage: '금융' }), color: '#CA8A04', backgroundColor: '#EAB3081A' },
      { text: formatMessage({ id: 'Dashboard_11', defaultMessage: '채용' }), color: '#007AFF', backgroundColor: '#E1EFFF' },
      { text: formatMessage({ id: 'Dashboard_12', defaultMessage: '거래처' }), color: '#CE0808', backgroundColor: '#FFE2E2' },
      { text: formatMessage({ id: 'Dashboard_13', defaultMessage: '공공' }), color: '#DB2777', backgroundColor: '#EC48991A' },
      { text: formatMessage({ id: 'Dashboard_14', defaultMessage: '쇼핑' }), color: '#1CA63E', backgroundColor: '#C6FFD5' },
      { text: formatMessage({ id: 'Dashboard_15', defaultMessage: '취약점' }), color: '#B17D02', backgroundColor: '#FFF5A6C7' },
      { text: formatMessage({ id: 'Dashboard_16', defaultMessage: '계정관리' }), color: '#2F61E1', backgroundColor: '#DAE0FFB8' },
      { text: formatMessage({ id: 'Dashboard_17', defaultMessage: '약관동의' }), color: '#058383', backgroundColor: '#C8FDFDBA' },
      { text: formatMessage({ id: 'Dashboard_18', defaultMessage: '납부' }), color: '#4407B3', backgroundColor: '#F2E2FF' },
      { text: formatMessage({ id: 'Dashboard_19', defaultMessage: '예약' }), color: '#E76902', backgroundColor: '#FFEAD9' },
    ];

    return status.map((item: any) => {
      return (
        <div className='exam-border-box' key={item.key}>
          <div className='space-between'>
            <div>{item.text}</div>
            <div className='status-item'>
              <img src={item.image} alt="exam" />
              {`${statisticsData[item.key].exam || 0}${unit}`}
            </div>
          </div>
          <div className='space-between'>
            <div className='category-area'>
              {statisticsData[item.key].category
                ? statisticsData[item.key].category.map((categoryNum: number, index: number) => {
                  if (index > 4) return <span />;

                  const { text, color, backgroundColor } = categories[categoryNum];
                  return (
                    <span key={categoryNum} className='category-label'
                      style={{ color: color, backgroundColor: backgroundColor }}>
                      {text}
                    </span>
                  );
                })
                : <span />}
            </div>
            <div className='flex'>
              <div className='status-item'>
                <img src={imagePath.download} alt="download" />
                <span>{`${statisticsData[item.key].file || 0}${unit}`}</span>
              </div>
              <div className='status-item'>
                <img src={imagePath.phishing} alt="phishing" />
                <span>{`${statisticsData[item.key].phishing || 0}${unit}`}</span>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }, [statisticsData]);

  return (
    <div className='dash-exam-status'>
      <div className='result-group left'>
        <div className='group-top'>
          <div className='group-title'>
            {formatMessage({ id: 'Exam_90', defaultMessage: '훈련 현황' })}
          </div>
          <div className='flex'>
            <div className='status-item'>
              <img src={imagePath.download} alt="download" />
              <span>{formatMessage({ id: 'Download_2', defaultMessage: '다운로드' })}</span>
            </div>
            <div className='status-item'>
              <img src={imagePath.phishing} alt="phishing" />
              <span>{formatMessage({ id: 'Dashboard_8', defaultMessage: '피싱' })}</span>
            </div>
          </div>
        </div>

        {ExamStatus}
      </div>
    </div>
  );
}

export default DashboardExamStatus;