import ExamTemplateReceiveTest from 'components/branch/examTemplate/ExamTemplateReceiveTest';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Option } from 'utils/commonValues';
import ExamTemplateAddEdit from './ExamTemplateAddEdit';
import './ExamTemplateMenu.scss';

export default function ExamTemplateMenu(props: any) {
  const { formatMessage } = useIntl();
  const [isModal, setIsModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const isAdmin = useSelector((state: any) => state.login.userData.isAdmin);
  const examTemplateArray = useSelector(
    (state: any) => state.template.selectedTemplate.examTemplateNoArray,
  );
  const serviceTemplateArray = useSelector(
    (state: any) => state.template.selectedTemplate.serviceTemplateNoArray,
  );
  const receiveLength = examTemplateArray.length + serviceTemplateArray.length;

  return (
    <div className="exam-template-menu-wrap">
      {Option.isGs !== 1 && Option.isSaas === 1 && isAdmin === 1 && (
        <div className="menu-button">
          <button
            className="common-button-brand initial-config-bt"
            type="button"
            onClick={() => setOpenAddModal(true)}
            // loading={onProgress}
            // disabled={onProgress}
          >
            {formatMessage({ id: 'Button_10', defaultMessage: '등 록' })}
          </button>
        </div>
      )}
      {openAddModal && (
        <ExamTemplateAddEdit
          visible={openAddModal}
          isAdmin={isAdmin}
          isAddMode
          templateInfo={{}}
          toggleModal={setOpenAddModal}
        />
      )}

      <div className="menu-button">
        <button
          className={`common-button-gray initial-config-bt ${receiveLength > 0 ? '' : 'disable'}`}
          type="button"
          onClick={() => setIsModal(true)}
          // disabled={onProgress}
        >
          {formatMessage({ id: 'Button_11', defaultMessage: '수신 테스트' })}
        </button>
      </div>
      <ExamTemplateReceiveTest
        isModal={isModal}
        setIsModal={setIsModal}
        examTemplateArray={examTemplateArray}
        serviceTemplateArray={serviceTemplateArray}
      />
    </div>
  );
}
