import React, { useEffect, useState } from 'react';
import './FormField.scss';

// import passOff from 'img/modal/pass_off.png';
// import passOn from 'img/modal/pass_on.png';

export default function FormTextField(props: any) {
  const [showPassword, setShowPassword] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const {
    className,
    type,
    name,
    validation,
    register,
    error,
    errorCheck,
    errorText,
    arrowPosition,
    defaultValue,
    onBlur,
    onFocus,
    value,
    autoFocus,
    onChange,
    disabled,
    placeholder,
    ...rest
  } = props;

  // const handleMouseDownPassword = event => {
  //   event.preventDefault();
  // };
  useEffect(() => {
    if (errorCheck) {
      setIsError(true);
      setMessage(errorText);
    } else if (error) {
      setIsError(error);
      setMessage(error.message);
    } else {
      setIsError(false);
      setMessage('');
    }
  });

  const handleClickShowPasssword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="form-field-wrap">
      <div className="form-wrap">
        {value ? (
          <input
            name={name}
            className={`${className} ${isError && ' validate-error'}`}
            type={showPassword ? 'text' : type}
            ref={validation && register ? register(validation) : null}
            value={value}
            onChange={onChange || undefined}
            onBlur={onBlur || undefined}
            onFocus={onFocus || undefined}
            disabled={disabled}
            placeholder={placeholder || undefined}
          />
        ) : (
          <input
            name={name}
            className={`${className} ${isError && ' validate-error'}`}
            type={showPassword ? 'text' : type}
            ref={validation && register ? register(validation) : null}
            defaultValue={defaultValue || ''}
            onChange={onChange || undefined}
            onBlur={onBlur || undefined}
            onFocus={onFocus || undefined}
            disabled={disabled}
            autoFocus={autoFocus || false} // eslint-disable-line
            placeholder={placeholder || undefined}
          />
        )}
        {/* 우측 영역 */}
        {type === 'password' ? (
          <div
            className="right-box"
            onClick={handleClickShowPasssword}
            onKeyDown={handleClickShowPasssword}
            aria-hidden="true"
          >
            {showPassword ? (
              <img src="/img/modal/pass_on.png" alt="" />
            ) : (
              <img src="/img/modal/pass_off.png" alt="" />
            )}
          </div>
        ) : null}
      </div>
      {isError ? <div className={`arrow-box ${arrowPosition}`}>{message}</div> : null}
    </div>
  );
}
