import React from 'react';
import { Tooltip } from 'antd';
import './ListStep.scss';

// import lineImage from 'img/exam/step/line.png';
// import circleImage from 'img/exam/step/circle.png';

interface StepProps {
  tooltipTitle: string | Node; // 툴팁 메시지
  icon: {
    stepIcon: any;
    active?: boolean;
    activeIcon?: any;
    lineIcon?: any;
    circleIcon?: any;
  }; // 아이콘
  count?: number; // 카운트
  countColor?: string; // 카운트 색상
  badgeText?: string; // 뱃지 글자
}

ListStep.defaultProps = {
  count: 0,
  countColor: '#6e6e6e',
  badgeText: '',
};

function ListStep({ tooltipTitle, icon, count, countColor, badgeText }: StepProps) {
  return (
    <div className="list-step-wrap">
      {!!icon?.lineIcon && (
        <div className="step-line-wrap">
          {icon?.active ? (
            <img className="step-line" src={icon?.lineIcon} alt="line" />
          ) : (
            <img className="step-line" src="/img/exam/step/line.png" alt="line" />
          )}
        </div>
      )}
      <Tooltip overlayClassName="black-tooltip" placement="bottom" title={tooltipTitle}>
        <div className="step-icon-wrap">
          {!!badgeText && <div className="step-badge">{badgeText}</div>}
          {icon?.circleIcon ? (
            // 원형아이콘
            <div className="circle-icon-wrap">
              {!!icon?.active && (
                <>
                  <img src={icon.circleIcon} alt="icon" />
                  <img src={icon.activeIcon} alt="icon" />
                </>
              )}
              {!icon?.active && (
                <>
                  <img src='/img/exam/step/circle.png' alt="icon" />
                  <img src={icon.stepIcon} alt="icon" />
                </>
              )}
            </div>
          ) : (
            // 아이콘+카운트
            <div className="step-wrap">
              <img src={icon.stepIcon} alt="icon" />
              <div className={`step-count-text ${countColor}`}>{count}</div>
            </div>
          )}
        </div>
      </Tooltip>
    </div>
  );
}

export default ListStep;
