import { apiUrl, execUrl } from 'utils/commonValues';
import { actionFormat, getFollowOrderArray } from 'utils/commonFunctions';
import { param } from 'jquery';

// Action Types
const SET_INITIAL = 'admin/SET_INITIAL';
const GET_SYSTEM_CONFIG = 'admin/GET_SYSTEM_CONFIG';
const UPDATE_SYSTEM_CONFIG = 'admin/UPDATE_SYSTEM_CONFIG';
const SYSTEM_CONFIG_INITIAL_BASIC = 'admin/SYSTEM_CONFIG_INITIAL_BASIC';
const CHECK_SMTP = 'admin/CHECK_SMTP';
const GET_ADMIN = 'admin/GET_ADMIN';
const ADD_ADMIN = 'admin/ADD_ADMIN';
const UPDATE_ADMIN = 'admin/UPDATE_ADMIN';
const DELETE_ADMIN = 'admin/DELETE_ADMIN';
const CHANGE_PARAM = 'admin/CHANGE_PARAM';
const TARGET_SYNC = 'admin/TARGET_SYNC';

// Actions
export const systemConfigInitBasic = (params: { [key: string]: string }) =>
  actionFormat(SYSTEM_CONFIG_INITIAL_BASIC, params, 'put', `${execUrl}SystemConfigInit`);
export const getSystemConfig = (params: any) =>
  actionFormat(GET_SYSTEM_CONFIG, params, 'get', `${execUrl}SystemConfig`);
export const adminInitSetting = (params: any) =>
  actionFormat(null, params, 'put', `${apiUrl}Admin/InitSetting`);
export const editPassword = (params: any) =>
  actionFormat(null, params, 'put', `${apiUrl}Admin/AdminEditPasswd`);
export const updateSystemConfig = (params: { [key: string]: any }) =>
  actionFormat(UPDATE_SYSTEM_CONFIG, params, 'put', `${execUrl}SystemConfig`);
export const adminCheckSmtp = () => actionFormat(CHECK_SMTP, null, 'get', `${execUrl}CheckSmtp`);
export const postSyncTest = (params: any) =>
  actionFormat(TARGET_SYNC, params, 'post', `${apiUrl}Sync/Test`);
export const targetSync = () => actionFormat(TARGET_SYNC, null, 'post', `${apiUrl}Sync`);
export const getAdmin = (params: any) => actionFormat(GET_ADMIN, params, 'get', `${apiUrl}Admin`);
export const addAdmin = (params: any) =>
  actionFormat(ADD_ADMIN, params, 'post', `${apiUrl}Admin/Deputy`);
export const updateAdmin = (params: any) =>
  actionFormat(UPDATE_ADMIN, params, 'put', `${apiUrl}Admin/Deputy`);
export const deleteAdmin = (params?: { [key: string]: any }) =>
  actionFormat(DELETE_ADMIN, params, 'delete', `${apiUrl}Admin/Deputy`);
export const changeParam = (params: { [key: string]: any }) =>
  actionFormat(CHANGE_PARAM, params, null, '');

// initial state
interface stateType {
  basicConfig: { [key: string]: any };
  smtpConfig: { [key: string]: any };
  syncConfig: { [key: string]: any };
  subAdminData: { [key: string]: any };
  param: { [key: string]: any };
  needReload: boolean;
  totalPages: number;
  totalCount: number;
  syncResult: { [key: string]: any };
}

const initialState: stateType = {
  basicConfig: {},
  smtpConfig: {},
  syncConfig: {},
  subAdminData: {
    result: -1,
    success: [],
    fail: [],
  },
  param: {
    filter: {},
    sort: [{ field: 'userNo', order: 'DESC' }],
  },
  needReload: false,
  totalPages: 0,
  totalCount: 0,
  syncResult: { success: [], fail: [] },
};

// Reducers
export default function (state: { [key: string]: any } = initialState, action: any) {
  switch (action.type) {
    case SET_INITIAL:
      return initialState;
    case SYSTEM_CONFIG_INITIAL_BASIC:
      return initialState;
    case GET_SYSTEM_CONFIG:
      if (action?.payload?.config?.params?.category) {
        const normalizedData: { [key: string]: any } = {
          parameter: {},
          class: {},
          classOrder: [],
        };
        const { category } = action.payload.config.params;
        const { list } = action.payload.data;

        const parameterOrder = getFollowOrderArray(list, 'parameter', 'followOrder');

        const seenClass: { [key: string]: any } = {};
        for (let i = 0; i < parameterOrder.length; i += 1) {
          if (typeof list[parameterOrder[i]] === 'undefined') {
            i += 1;
          }
          const config = list[parameterOrder[i]];

          normalizedData.parameter[config.parameter] = config;
          normalizedData.parameter[config.parameter].beforeValue = config.value;

          if (config.paramType !== 0) {
            if (typeof seenClass[config.class] === 'undefined') {
              normalizedData.classOrder.push(config.class);
              normalizedData.class[config.class] = [];
              seenClass[config.class] = 1;
            }
            normalizedData.class[config.class].push({
              parameter: config.parameter,
              parameterNotice: config.parameterNotice,
              followOrder: config.followOrder,
              paramType: config.paramType,
            });
          } else {
            // 메일 서버 테스트 파라메터
          }
        }
        state = {
          ...state,
          [`${category}Config`]: normalizedData,
        };
      }
      return state;
    case UPDATE_SYSTEM_CONFIG:
      if (action?.payload) {
        const { list } = action.payload.data;
        const { category, parameter, value } = list[0];
        let configCategory = '';
        if (category === 'basic') {
          configCategory = 'basicConfig';
        } else if (category === 'smtp') {
          configCategory = 'smtpConfig';
        } else {
          configCategory = 'syncConfig';
        }

        state[configCategory].parameter[parameter].beforeValue = value;
      }
      return state;
    case CHECK_SMTP:
      if (action?.payload) {
        const { list } = action.payload.data;

        state.smtpConfig.parameter.mailSmtpTested = list;
      }
      return state;
    case GET_ADMIN:
      if (action.payload) {
        const { data } = action.payload;
        const { offset, limit } = action.payload.config.params;

        // 전체 목록 수
        const totalCount = data.list.length > 0 ? data.list[0].rnum + offset : 0;
        // 전체 페이지 수
        const totalPages = Math.ceil(totalCount / limit);

        if (!data.error && data.list && Array.isArray(data.list)) {
          state.subAdminData = {
            result: 1,
            success: data.list.filter((item: any) => item.userAuthority === 2), // 부관리자만 출력
            fail: [],
          };
        } else if (data.error && Array.isArray(data.error)) {
          state.subAdminData = {
            result: 0,
            success: [],
            fail: data.errror,
          };
        }

        state.needReload = false;
        state.totalPages = totalPages;
        state.totalCount = totalCount;
      }
      return state;
    case ADD_ADMIN:
      if (action.payload?.data?.list) {
        state.needReload = true;
      }
      return state;
    case UPDATE_ADMIN:
      if (action.payload?.data?.list) {
        state.needReload = true;
      }
      return state;
    case DELETE_ADMIN:
      if (action.payload?.data?.list) {
        state.needReload = true;
      }
      return state;
    case CHANGE_PARAM:
      if (action.payload) {
        const { name, value } = action.payload;
        state.param[name] = value;
      }
      return state;
    case TARGET_SYNC:
      if (action.payload?.data) {
        const { list, error } = action.payload.data;
        state.syncResult = { success: list || [], fail: error?.[0]?.errInfo || '' };
      }
      return state;
    default:
      return state;
  }
}
