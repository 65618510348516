import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Popover, Select, Switch, Tag } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import { Option } from 'utils/commonValues';
import parse from 'html-react-parser';
import moment from 'moment';
import axios from 'axios';
import {
  getAttach,
  getExam,
  getLicense,
  getPrepare,
  getSystemUrl,
  getTemplate,
  startExam,
} from 'store/startExam';
import { epochFromDate, timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import * as valid from 'utils/validation';
import ModalTemplate from 'components/common/ModalTemplate';
import Loading from 'components/common/Loading';
import FormSelectField from 'components/common/FormSelectField';
import FormTextField from 'components/common/FormTextField';
import FormDatePicker from 'components/common/FormDatePicker';
import StartExam from 'components/branch/exam/StartExam';
import './StartExam.scss';

function StartExamSimple({ onCloseModal }: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // 세이프 브라우징 체크 결과
  const [safeType, setSafeType] = useState(0);
  // 라이선스 오류 여부
  const [licenseError, setLicenseError] = useState('');
  // 전문가 모드 활성화
  const [expertMode, setExpertMode] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const systemUrlData = useSelector((state: any) => state.startExam.systemUrl);
  const templateData = useSelector((state: any) => state.startExam.template);
  const attachData = useSelector((state: any) => state.startExam.attach);
  const licenseData = useSelector((state: any) => state.startExam.license);

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    watch,
    clearErrors,
    errors,
    control,
  } = useForm<any>({
    mode: 'all',
    defaultValues: {
      examName: '',
      examTemplate: [],
      useExamSystemUrl: '0',
      examSystemUrl: '',
      startDate1: moment(),
      endDate1: moment(),
      startDate2: moment(),
      endDate2: moment(),
      examCount: '2',
    },
  });
  const watchValues = watch();

  useEffect(() => {
    setShowModal(true);

    onPrepareExam();
    onGetTemplate();
  }, []);

  useEffect(() => {
    if (systemUrlData.length > 0) {
      // google safe browsing 사이트 api 호출
      axios
        .get(
          'https://transparencyreport.google.com/transparencyreport/api/v3/safebrowsing/status',
          {
            params: {
              site:
                watchValues.useExamSystemUrl === '1' && watchValues.examSystemUrl
                  ? watchValues.examSystemUrl
                  : systemUrlData[1]?.url,
            },
          },
        )
        .then((res: any) => {
          if (res.data) {
            const splitData = res.data.split(',');
            const result = splitData && parseInt(splitData[1], 10);
            setSafeType(result);
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }, [watchValues?.useExamSystemUrl, watchValues?.examSystemUrl, systemUrlData]);

  const onCancelModal = () => {
    onCloseModal();
    setShowModal(false);
  };

  // 환경 검사
  const onPrepareExam = async () => {
    setLoading(true);
    try {
      const response: any = await dispatch(getPrepare());
      if (response?.data?.list) {
        const data = response?.data?.list[0];
        if (data?.targetCount < 1) {
          setLicenseError('targetCount');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 템플릿, 첨부파일 정보 조회
  const onGetTemplate = async () => {
    try {
      const params = {
        type: 'startExam',
        sort: JSON.stringify([{ field: 'serviceTemplateNo', order: 'DESC' }]),
      };
      const response: any = await dispatch(getTemplate(params));
      await dispatch(getAttach());
      await dispatch(getSystemUrl());

      // 훈련 템플릿 기본 선택 처리
      const defaultTemplate: Array<string> = [];
      response?.data?.list?.forEach((template: any, index: number) => {
        // 2개만 기본으로 넣어줌
        if (index < 2) {
          defaultTemplate.push(
            template.templatePick
              ? `${template.templateNo}+${template.serviceTemplateNo}`
              : `${template.templateNo}+`,
          );
        }
      });

      // 템플릿 기본값 설정
      setValue('examTemplate', defaultTemplate);

      // 익월 첫번째 수요일
      const wednesday = moment().startOf('month').add(1, 'month').day(3);

      // 익월 둘째주 수요일
      setValue(
        'startDate1',
        moment()
          .startOf('month')
          .add(1, 'month')
          .day(wednesday.date() <= 7 ? 10 : 17),
      );
      // 1차 시작 날짜로부터 3주
      setValue(
        'endDate1',
        moment()
          .startOf('month')
          .add(1, 'month')
          .day(wednesday.date() <= 7 ? 31 : 38),
      );
      // 시작한 달로부터 6개월 후의 둘째주 수요일
      setValue(
        'startDate2',
        moment()
          .startOf('month')
          .add(7, 'month')
          .day(wednesday.date() <= 7 ? 10 : 17),
      );
      // 2차 시작 날짜로부터 3주
      setValue(
        'endDate2',
        moment()
          .startOf('month')
          .add(7, 'month')
          .day(wednesday.date() <= 7 ? 31 : 38),
      );

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // 훈련명 중복 확인
  const onCheckExamName = async (formData: any) => {
    setLoading(true);
    try {
      const response: any = await dispatch(
        getExam({ filter: { examNameExact: formData.examName } }),
      );
      if (response?.data?.list?.length > 0) {
        setError('examName', {
          type: 'duplicate',
          message: formatMessage({
            id: 'StartExam_45',
            defaultMessage: '이미 사용중인 훈련명입니다.',
          }),
        });
      } else if (licenseError !== 'targetCount') {
        onGetExamLicense(1);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // 라이선스 정보 조회 (3단계: 대상)
  const onGetExamLicense = async (sequence: number) => {
    setLoading(true);

    const { startDate1, startDate2, examTemplate } = watchValues;

    try {
      const params: any = {
        examStartEpoch:
          sequence === 1
            ? moment(startDate1).unix().toString()
            : moment(startDate2).unix().toString(),
        isAllTarget: true,
      };

      const response: any = await dispatch(getLicense(params));
      if (response?.data) {
        if (response.data.isValidLicense === 1 && response.data.targetAllCount > 0) {
          setLicenseError('');
          onGetExamLicenseTemplate(sequence);
        } else {
          setLoading(false);
          setLicenseError('target');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 라이선스 정보 조회 (4단계: 템플릿)
  const onGetExamLicenseTemplate = async (sequence: number) => {
    setLoading(true);

    const { startDate1, startDate2, examTemplate } = watchValues;

    try {
      const params: any = {
        examStartEpoch:
          sequence === 1
            ? moment(startDate1).unix().toString()
            : moment(startDate2).unix().toString(),
        isAllTarget: true,
      };

      const serviceTempArray: any = [];
      const examTempArray: any = [];
      examTemplate.forEach((value: any) => {
        const splitTempNo = value.split('+');
        if (splitTempNo[1]) {
          serviceTempArray.push(splitTempNo[1]);
        } else {
          examTempArray.push(splitTempNo[0]);
        }
      });

      params.examServiceTemplateNoArray = JSON.stringify(
        serviceTempArray.length > 0 ? [serviceTempArray[sequence - 1]] : [],
      );
      params.examTemplateNoArray = JSON.stringify(
        examTempArray.length > 0 ? [examTempArray[sequence - 1]] : [],
      );

      const response: any = await dispatch(getLicense(params));
      if (response?.data) {
        setLoading(false);

        if (response.data.isValidLicense === 1) {
          if (sequence === 2) {
            // 훈련 실시
            onStartExamSimple();
          } else {
            // 2차 라이선스 검증
            onGetExamLicense(2);
          }
        } else {
          setLicenseError('template');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 훈련 실행
  const onStartExamSimple = async () => {
    setLoading(true);

    try {
      // 날짜 형식 지정
      const dateFormat = 'YYYYMMDD';

      const params: any = {
        isAllTarget: true,
        examMode: 2,
        sendReservePeriod: 1,
        sendReserveStartHour: 9,
        sendReserveEndHour: 17,
      };

      // 훈련 템플릿
      const templates: Array<any> = [];
      if (watchValues.examTemplate.length > 0) {
        watchValues.examTemplate.forEach((value: any) => {
          const splitTempNo = value.split('+');
          if (splitTempNo[1]) {
            templates.push({ service: splitTempNo[1] });
          } else {
            templates.push({ exam: splitTempNo[0] });
          }
        });
      }

      // 훈련 url 사용
      if (watchValues.useExamSystemUrl === '1' && !!watchValues.examSystemUrl) {
        params.examUrl = watchValues.examSystemUrl;
      }

      // 1차 훈련
      await dispatch(
        startExam({
          ...params,
          examName: `${watchValues.examName} ${watchValues.startDate1.format(dateFormat)}`,
          examStartEpoch: epochFromDate(watchValues.startDate1),
          examEndEpoch: epochFromDate(watchValues.endDate1),
          examServiceTemplateNoArray: templates[0].service
            ? JSON.stringify([templates[0].service])
            : JSON.stringify([]),
          examTemplateNoArray: templates[0].exam
            ? JSON.stringify([templates[0].exam])
            : JSON.stringify([]),
        }),
      );

      // 2차 훈련
      await dispatch(
        startExam({
          ...params,
          examName: `${watchValues.examName} ${watchValues.startDate2.format(dateFormat)}`,
          examStartEpoch: epochFromDate(watchValues.startDate2),
          examEndEpoch: epochFromDate(watchValues.endDate2),
          examServiceTemplateNoArray: templates[1].service
            ? JSON.stringify([templates[1].service])
            : JSON.stringify([]),
          examTemplateNoArray: templates[1].exam
            ? JSON.stringify([templates[1].exam])
            : JSON.stringify([]),
        }),
      );

      setLoading(false);
      onCancelModal();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // 폼 입력 감지 이벤트
  const onChangeForm = (e: any) => {
    // 에러 초기화
    if (Object.keys(errors).length > 0) {
      clearErrors([...Object.keys(watchValues)]);
    }
  };

  // 시작일자, 종료일자 선택 불가 날짜 지정
  const handleDisableDate = (type: any, current: any, index: number) => {
    let isDisable = false;
    if (type.includes('startDate')) {
      isDisable =
        // 현재일(오늘)보다 작을 때
        current <
        (index === 1 ? moment().startOf('day') : moment(watchValues.endDate1).add(1, 'd'));
    } else {
      const target = `startDate${index}`;
      isDisable =
        // 시작일자보다 작을 때
        current < moment(watchValues[target]).add(1, 'd') ||
        // 현재일(오늘)보다 작을 때
        current < moment().startOf('day') ||
        // 시작일자로부터 1년이 지날 때(최대 1년 지정 가능)
        current > moment(watchValues[target]).add(1, 'y');
    }

    return isDisable;
  };

  // 시작일자, 종료일자 선택 시
  const handleOnChangeDate = (type: string, current: any, index: number) => {
    if (type.includes('startDate')) {
      // 시작일이 종료일과 같거나 클 경우
      if (moment(current).endOf('day') >= moment(watchValues[`endDate${index}`])) {
        setValue(`startDate${index}`, moment(current).startOf('day'));
        setValue(`endDate${index}`, null);
      } else {
        setValue(`startDate${index}`, moment(current).startOf('day'));
      }
    } else if (type.includes('endDate')) {
      if (index === 1 && moment(current).endOf('day') >= moment(watchValues.startDate2)) {
        setValue('startDate2', null);
      } else {
        setValue(`endDate${index}`, moment(current).hour(23).minute(59).second(59).millisecond(0));
      }
    }
  };

  // 선택한 훈련 템플릿 삭제(X 버튼 클릭) 이벤트
  const handleClearTag = (target: any) => {
    // 템플릿 선택 에러 초기화
    clearErrors('examTemplate');

    const selectedValue = watchValues.examTemplate.filter(
      (templateValue: any) => templateValue !== target.toString(),
    );

    setValue('examTemplate', selectedValue);
  };

  // 선택한 훈련 템플릿을 태그 형태로 출력
  const tagRender = (props: any) => {
    const currentTemplateData = templateData?.filter(
      (template: any) =>
        `${template.templateNo}+${template.serviceTemplateNo || ''}` === props.value,
    )[0];

    let templateComponent = null;
    if (currentTemplateData) {
      const {
        templateName,
        templateNo,
        serviceTemplateNo,
        examType,
        attachInfoNo,
      } = currentTemplateData;

      // 템플릿 첨부파일 정보
      const templateAttachData = attachData.filter(
        (attach: any) => attach.attachInfoNo === attachInfoNo,
      );

      let examTypeColor = '';
      let examTypeString = '';
      if (templateAttachData) {
        if (examType === 1 && templateAttachData[0]?.attachExamType === 1) {
          examTypeColor = 'color-bg-warning';
          examTypeString = 'warning';
        } else if (examType === 1 && templateAttachData[0]?.attachExamType === 2) {
          examTypeColor = 'color-bg-file';
          examTypeString = 'file';
        } else if (examType === 1 && templateAttachData[0]?.attachExamType === 3) {
          examTypeColor = 'color-bg-research';
          examTypeString = 'research';
        } else if (examType === 4) {
          examTypeColor = 'color-bg-phishing';
          examTypeString = 'phishing';
        }
      }

      templateComponent = (
        <Tag closable className={`color-tag ${examTypeColor}`}>
          {!!examTypeString && (
            <img src={`/img/exam/startExam/ico_${examTypeString}_w.png`} alt="icon" />
          )}
          {templateName}
          <CloseCircleFilled
            onClick={() => handleClearTag(`${templateNo}+${serviceTemplateNo || ''}`)}
          />
        </Tag>
      );
    }

    return templateComponent;
  };

  // 훈련 URL 드롭다운 메뉴
  const urlMenuList = useMemo(() => {
    const menuList: Array<any> = [];
    if (systemUrlData && systemUrlData[0]?.url) {
      const splitArray = systemUrlData[0].url.split('"');
      const urlList = splitArray.filter((value: any) => value.indexOf('http') > -1);
      urlList.forEach((item: any, index: number) => {
        menuList.push({
          key: index,
          value: item,
          text: item,
        });
      });
    }
    return menuList;
  }, [systemUrlData]);

  let templateListComponent = null;
  const templateOption: any = [];
  // 템플릿이 있을 경우
  if (templateData?.length > 0 && attachData?.length > 0) {
    // 훈련 템플릿 리스트
    const templateList = templateData.filter(
      (template: any) =>
        !watchValues.examTemplate?.includes(
          `${template.templateNo}+${template.serviceTemplateNo || ''}`,
        ),
    );

    const rowTemplateObj: any = {};
    const rowTemplateType: any = {};
    templateList.forEach((template: any, index: number) => {
      const {
        templateNo,
        templateName,
        serviceTemplateNo,
        examType,
        attachInfoNo,
        serviceNo,
      } = template;

      // 템플릿 첨부파일 정보
      const attachInfo = attachData?.filter((attach: any) => attach.attachInfoNo === attachInfoNo);
      // 훈련 유형별 템플릿 색상
      let examTypeColor = '';
      // 훈련 유형별 템플릿
      let examTypeString = '';
      // 훈련 유형 라벨 컴포넌트
      let examTypeLabelComponent = null;
      // if (attachInfo.length > 0 && attachInfo[0] && attachInfo[0].isEnable === 1) {
      if (examType === 1 && attachInfo[0]?.attachExamType === 1) {
        examTypeColor = 'color-bg-warning';
        examTypeString = 'warning';
        examTypeLabelComponent = attachInfo[0]?.isEnable === 1 && (
          <Tag className="temp-type-label color-temp-warning">
            {formatMessage({ id: 'Template_10', defaultMessage: '경고 안내' })}
          </Tag>
        );
      } else if (examType === 1 && attachInfo[0]?.attachExamType === 2) {
        examTypeColor = 'color-bg-file';
        examTypeString = 'file';
        examTypeLabelComponent = attachInfo[0]?.isEnable === 1 && (
          <Tag className="temp-type-label color-temp-file">
            {formatMessage({ id: 'Template_11', defaultMessage: '모의 악성파일' })}
          </Tag>
        );
      } else if (examType === 1 && attachInfo[0]?.attachExamType === 3) {
        examTypeColor = 'color-bg-research';
        examTypeString = 'research';
        examTypeLabelComponent = attachInfo[0]?.isEnable === 1 && (
          <Tag className="temp-type-label color-temp-research">
            {formatMessage({ id: 'Template_12', defaultMessage: '실태 조사' })}
          </Tag>
        );
      } else if (examType === 4) {
        examTypeColor = 'color-bg-phishing';
        examTypeString = 'phishing';
        examTypeLabelComponent = attachInfo[0]?.isEnable === 1 && (
          <Tag className="temp-type-label color-temp-phishing">
            {formatMessage({ id: 'Template_13', defaultMessage: '피싱 유도' })}
          </Tag>
        );
      } else {
        examTypeLabelComponent = <div>-</div>;
      }

      rowTemplateObj[`${templateName}+${index}`] = {
        value: `${templateNo}+${serviceTemplateNo || ''}`,
        examTypeColor: examTypeColor,
        examTypeString: examTypeString,
        examTypeLabelComponent: examTypeLabelComponent,
        attachInfoData: attachInfo[0],
        ...template,
      };
      rowTemplateType[`${templateName}+${index}`] = serviceNo ? true : false;
      // }
    });

    // 정렬 처리 - 사용자 템플릿 순 + 템플릿명 순
    const sortedArray = Object.keys(rowTemplateObj).sort();
    const defaultTemplateArray: any = [];
    const userTemplateArray: any = [];
    sortedArray.forEach((key: any) => {
      // 사용자 템플릿
      if (rowTemplateType[key]) {
        userTemplateArray.push(rowTemplateObj[key]);
      }
      // 기본 템플릿
      else {
        defaultTemplateArray.push(rowTemplateObj[key]);
      }
    });
    const templateInfoArray = userTemplateArray.concat(defaultTemplateArray);

    templateInfoArray.forEach((data: any) => {
      const {
        value,
        examTypeString,
        templateReceive,
        templateNo,
        templateName,
        serviceTemplateNo,
        attachInfoNo,
        attachInfoData,
        attachType,
        examType,
        examTypeLabelComponent,
        serviceNo,
        linkAttach,
        statisticsTarget,
        statisticsInfection,
      } = data;

      // 첨부 유형
      let linkAttachText = formatMessage({ id: 'Template_18', defaultMessage: '첨부 없음' });
      if (linkAttach === 1) {
        linkAttachText = formatMessage({ id: 'Template_15', defaultMessage: '첨부 파일' });
      } else if (linkAttach === 2) {
        linkAttachText = formatMessage({ id: 'Template_16', defaultMessage: '링크 첨부' });
      } else if (linkAttach === 3) {
        linkAttachText = formatMessage({ id: 'Template_17', defaultMessage: '링크 + 첨부' });
      }

      // 훈련 결과(유출/감염률)
      let examResultText = formatMessage({ id: 'StartExam_55', defaultMessage: '훈련 결과 없음' });
      if (!serviceNo && statisticsTarget > 0) {
        const statisticsText =
          examType === 4
            ? formatMessage({ id: 'Template_20', defaultMessage: '유출률' })
            : formatMessage({ id: 'Template_19', defaultMessage: '감염률' });
        const statisticsPercent = `${Math.floor((statisticsInfection / statisticsTarget) * 100)} %`;
        examResultText = `${statisticsText}: ${statisticsPercent}`;
      }

      //  드롭다운 메뉴
      templateOption.push(
        <Select.Option key={`exam-template-${value}`} value={value} className="start-exam-template">
          <div className="template-select-option flex">
            <img
              className="exam-type-circle"
              src={`/img/exam/startExam/temp_${examTypeString || 'none'}_36${
                templateReceive === 1 ? '_brand' : ''
              }.png`}
              alt="icon"
            />
            <div className="template-info-area">
              <div className="template-info-title">
                {`(No: ${serviceTemplateNo || templateNo}) ${templateName}`}
              </div>
              <div className="template-exam-info">
                {serviceNo ? (
                  <Tag className="temp-type-label color-temp-user">
                    {formatMessage({ id: 'User_1', defaultMessage: '사용자' })}
                  </Tag>
                ) : (
                  <Tag className="temp-type-label color-temp-system">
                    {formatMessage({ id: 'Template_14', defaultMessage: '기 본' })}
                  </Tag>
                )}
                {/* 훈련 유형 */}
                {examTypeLabelComponent}
                {/* 첨부 유형 */}
                {examType !== 4 && examTypeString && `/ ${linkAttachText}`}
                {/* 유출/감염률 */}
                {!!linkAttachText && examTypeString && ` / ${examResultText}`}
              </div>
              {!!attachType && !!attachInfoData && (
                <div className="template-attach-info flex">
                  <div>{`${formatMessage({
                    id: 'Attach_2',
                    defaultMessage: '첨부파일 정보',
                  })} no.${attachInfoNo}`}</div>
                  <img
                    className="file-icon"
                    src={`/fdata/attachFile/${attachInfoData.attachInfoNo}/${attachInfoData.fileIcon}`}
                    alt="file"
                  />
                  <div>{attachInfoData.fileExt}</div>
                </div>
              )}
            </div>
          </div>
        </Select.Option>,
      );
    });

    templateListComponent = (
      <FormSelectField
        name="examTemplate"
        control={control}
        error={errors.examTemplate}
        errorMessage={errors.examTemplate?.message}
        option={templateOption}
        mode="multiple"
        showArrow
        tagRender={tagRender}
        placeholder={formatMessage({
          id: 'Template_21',
          defaultMessage: '훈련 템플릿을 선택하세요.',
        })}
        validation={{
          validate: {
            required: (value: any) => {
              return valid.required(watchValues.examTemplate?.length || value?.length);
            },
            count: (value: any) => {
              return value.length !== 2
                ? formatMessage(
                    { id: 'Vali_58', defaultMessage: '{count}개만 선택 가능합니다.' },
                    { count: 2 },
                  )
                : undefined;
            },
          },
        }}
        value={watchValues.examTemplate}
        filterOption={(input: any, option: any) => {
          const target = option.children.props.children[1].props.children[0].props.children;
          return target.split(') ')[1].toLowerCase().includes(input.toLowerCase());
        }}
      />
    );
  }

  // 라이선스 오류 안내
  let licenseContent = null;
  if (licenseData && licenseError && licenseError !== 'targetCount') {
    // 대상자 라이선스 체크
    if (licenseData.error === 'noLicense') {
      licenseContent = (
        <div>
          {formatMessage({
            id: 'License_3',
            defaultMessage: '라이선스가 존재하지 않습니다.',
          })}
        </div>
      );
    } else if (licenseData.error === 'noReserve') {
      licenseContent = (
        <div>
          {formatMessage({
            id: 'License_6',
            defaultMessage: '훈련 시작일을 라이선스 유효기간 이후로 예약할 수 없습니다.',
          })}
        </div>
      );
    } else {
      let periodCount = 0;
      let timesCount = 0;
      if (licenseError === 'target') {
        const { periodCnt, timesCnt } = licenseData;
        licenseData.list.forEach((license: any) => {
          const {
            licenseType,
            licenseNo,
            remainCount,
            licenseCount,
            startEpoch,
            endEpoch,
            examLimitCount,
          } = license;

          if (licenseType === 1 || licenseType === 2) {
            periodCount += 1;
          } else if (licenseType === 4) {
            timesCount += 1;
          }

          licenseContent = (
            <div>
              <div>
                {formatMessage({
                  id: 'License_4',
                  defaultMessage: '라이선스의 수량이 부족합니다.',
                })}
              </div>
              <div className="license-status-row">
                {(licenseType === 1 || licenseType === 2) && (
                  <div>
                    {periodCount === 1 && (
                      <div className="license-name">
                        {`· ${formatMessage({
                          id: 'License_7',
                          defaultMessage: '기간 라이선스',
                        })} (${periodCnt}${formatMessage({
                          id: 'StartExam_35',
                          defaultMessage: '명',
                        })} ${formatMessage({
                          id: 'License_9',
                          defaultMessage: '부족',
                        })})`}
                      </div>
                    )}
                    <div className="license-detail">
                      <div className="license-number">No.{licenseNo}</div>
                      <div className="license-target">{` ${
                        licenseCount - remainCount
                      }/${licenseCount}${formatMessage({
                        id: 'StartExam_35',
                        defaultMessage: '명',
                      })}`}</div>
                      <div className="license-period">{` ${timeFormatFromUTCEpoch(
                        startEpoch,
                        3,
                      )} ~ ${timeFormatFromUTCEpoch(endEpoch, 3)}`}</div>
                    </div>
                  </div>
                )}

                {licenseType === 4 && (
                  <div>
                    {timesCount === 1 && (
                      <div className="license-name">
                        {`· ${formatMessage({
                          id: 'License_8',
                          defaultMessage: '횟수 라이선스',
                        })} (${timesCnt}${formatMessage({
                          id: 'StartExam_35',
                          defaultMessage: '명',
                        })} ${formatMessage({
                          id: 'License_9',
                          defaultMessage: '부족',
                        })})`}
                      </div>
                    )}
                    <div className="license-detail">
                      <div>No.{licenseNo}</div>
                      <div>
                        {` ${licenseCount}${formatMessage({
                          id: 'StartExam_35',
                          defaultMessage: '명',
                        })}`}
                      </div>
                      <div>
                        {` ${examLimitCount - remainCount}/${examLimitCount}${formatMessage({
                          id: 'StartExam_35',
                          defaultMessage: '회',
                        })}`}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        });
      } else {
        licenseData.list.forEach((license: any) => {
          const { licenseNo, remainCount, licenseCount, examLimitCount } = license;

          // 훈련 라이선스 체크
          licenseContent = (
            <div>
              {formatMessage({
                id: 'License_5',
                defaultMessage: '라이선스의 남은 횟수가 부족합니다.',
              })}
              <div className="license-status-row">
                <div className="license-item">No.{licenseNo}</div>
                <div className="license-item">
                  {`${formatMessage({
                    id: 'License_16',
                    defaultMessage: '수량',
                  })}: ${licenseCount}${formatMessage({
                    id: 'StartExam_35',
                    defaultMessage: '명',
                  })}`}
                </div>
                <div className="remain-count">
                  {`${examLimitCount - remainCount} / ${examLimitCount}${formatMessage({
                    id: 'License_13',
                    defaultMessage: '회',
                  })}`}
                  <span className="font-size-13 bold">
                    {` (${formatMessage({
                      id: 'License_10',
                      defaultMessage: '남은 횟수',
                    })}: ${remainCount}${formatMessage({
                      id: 'License_13',
                      defaultMessage: '회',
                    })})`}
                  </span>
                </div>
              </div>
            </div>
          );
        });
      }
    }
  }

  return (
    <>
      <ModalTemplate
        className="start-exam-modal simple-modal modal-665"
        isCloseBlack
        visible={showModal}
        title={
          <div className="modal-title-area">
            <>
              <div className="modal-main-title">
                {formatMessage({ id: 'StartExam_74', defaultMessage: '간편 설정' })}
              </div>
              <div className="modal-sub-title">
                {parse(
                  formatMessage({
                    id: 'StartExam_8',
                    defaultMessage:
                      '<div class="modal-sub-title"><span class="point">훈련명 및 훈련기간</span>을 입력합니다.</div>',
                  }),
                )}
              </div>
            </>
          </div>
        }
        onCancel={onCancelModal}
        footer={
          <>
            <button
              key="cancel"
              className={`footer-btn ${
                Object.keys(errors).length > 0 || loading ? 'disabled' : ''
              }`}
              type="submit"
              onClick={() => {
                setShowModal(false);
                setExpertMode(true);
              }}
            >
              {formatMessage({ id: 'Button_28', defaultMessage: '전문가 모드' })}
            </button>

            <button
              key="ok"
              className={`footer-btn ok ${
                Object.keys(errors).length > 0 || loading ? 'disabled' : ''
              }`}
              type="submit"
              onClick={handleSubmit(onCheckExamName)}
            >
              {formatMessage({ id: 'StartExam_67', defaultMessage: '실 행' })}
              <img src="/img/exam/startExam/btn_next.png" alt="next" />
            </button>
          </>
        }
      >
        <Loading loading={loading} />

        <form autoComplete="off" onChange={onChangeForm}>
          {/* 훈련 양식(템플릿) 선택 */}
          <div className="content-item">
            <div className="input-title">
              {formatMessage({ id: 'Template_3', defaultMessage: '훈련 양식(템플릿) 선택' })}
              <div className="template-type-icon-top">
                <div className="icon-area">
                  <img src="/img/exam/startExam/ico_warning.png" alt="warning" />
                  {formatMessage({ id: 'Template_10', defaultMessage: '경고안내' })}
                </div>
                <div className="icon-area">
                  <img src="/img/exam/startExam/ico_file.png" alt="file" />
                  {formatMessage({ id: 'Template_11', defaultMessage: '모의 악성파일' })}
                </div>
                <div className="icon-area">
                  <img src="/img/exam/startExam/ico_research.png" alt="research" />
                  {formatMessage({ id: 'Template_12', defaultMessage: '실태 조사' })}
                </div>
                <div className="icon-area">
                  <img src="/img/exam/startExam/ico_phishing.png" alt="phishing" />
                  {formatMessage({ id: 'Template_13', defaultMessage: '피싱 유도' })}
                </div>
              </div>
            </div>
            {templateListComponent}
            {/* <div className="error-message mt-5">{errors}</div> */}
          </div>

          {/* 훈련 URL 사용 */}
          <div className="content-item">
            <div className="start-exam-border-box">
              <div className="header-box">
                <div className="title bold">
                  {formatMessage({ id: 'StartExam_49', defaultMessage: '훈련 URL 사용' })}
                  <Popover
                    placement="right"
                    content={parse(
                      formatMessage({
                        id: 'StartExam_51',
                        defaultMessage: '메일 링크에 들어갈 URL을 설정합니다.',
                      }),
                    )}
                  >
                    <img src="/img/exam/startExam/btn_qmark.png" alt="help" />
                  </Popover>
                </div>
                <Controller
                  control={control}
                  name="useExamSystemUrl"
                  render={({ onChange }) => (
                    <Switch
                      onChange={(value: any) => {
                        onChange(value ? '1' : '0');
                        setValue('useExamSystemUrl', value ? '1' : '0');
                        // setValue({ ...values, useExamSystemUrl: value ? '1' : '0' });
                      }}
                      checked={watchValues.useExamSystemUrl === '1' ? true : false}
                      // disabled={urlMenuList.length < 1}
                    />
                  )}
                />
              </div>
              <div
                className={`setting-box ${
                  watchValues.useExamSystemUrl === '0' || urlMenuList.length < 1 ? 'disabled' : ''
                }`}
              >
                <div className="input-title">
                  {formatMessage({ id: 'StartExam_50', defaultMessage: '훈련 URL 선택' })}
                </div>
                <div className="input-value">
                  <FormSelectField
                    name="examSystemUrl"
                    control={control}
                    defaultValue={watchValues.examSystemUrl}
                    menuList={urlMenuList}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* 세이프 브라우징 문구 */}
          <div className="screen-lock-info-text">
            {`※ ${formatMessage({
              id: 'StartExam_63',
              defaultMessage: '구글 세이프 브라우징을 통해 URL 상태 확인 결과',
            })} '${
              watchValues.useExamSystemUrl === '1' && watchValues.examSystemUrl
                ? watchValues.examSystemUrl
                : systemUrlData[1]?.url
            }' ${formatMessage({ id: 'StartExam_66', defaultMessage: '는' })}`}
            {localStorage.getItem('language') === 'ja' ? '' : ` `}
            {safeType === 1 ? (
              formatMessage({
                id: 'StartExam_64',
                defaultMessage: '안전합니다.',
              })
            ) : (
              <span className="color-red">
                {formatMessage({
                  id: 'StartExam_65',
                  defaultMessage: '안전하지 않습니다.',
                })}
              </span>
            )}
          </div>

          {/* 훈련명 */}
          <div className="content-item">
            <div className="input-title">
              {formatMessage({ id: 'Exam_1', defaultMessage: '훈련명' })}
            </div>
            <FormTextField
              name="examName"
              defaultValue={watchValues.examName}
              error={errors.examName}
              arrowPosition="top"
              register={register}
              validation={{
                validate: {
                  required: (value: any) => valid.required(value),
                  name: (value: any) => valid.name(value),
                  examName: (value: any) => (Option.customer === 'saxa' ? valid.saxaExamName(value) : valid.examName(value)),
                },
              }}
              onChange={(e: any) => setValue('examName', e.target.value)}
            />
          </div>

          {/* 시작일자/종료일자 */}
          <div className="content-item">
            <div className="date-picker-area">
              <div className="date-picker-item">
                <div className="input-title">
                  {`${formatMessage({
                    id: 'StartExam_72',
                    defaultMessage: '1차',
                  })} ${formatMessage({ id: 'Date_11', defaultMessage: '시작일자' })}`}
                </div>
                <FormDatePicker
                  control={control}
                  name="startDate1"
                  value={watchValues.startDate1}
                  error={errors.startDate1}
                  validation={{
                    validate: {
                      required: (value: any) => valid.required(value || watchValues.startDate1),
                    },
                  }}
                  handleOnChange={(name: string, date: Date) => handleOnChangeDate(name, date, 1)}
                  handleDisableDate={(name: string, current: Date) =>
                    handleDisableDate(name, current, 1)
                  }
                  helperText={
                    moment() < moment(watchValues.startDate1) &&
                    formatMessage({ id: 'StartExam_36', defaultMessage: '훈련을 예약함' })
                  }
                  arrowPosition="top"
                />
              </div>
              <div className="date-picker-item">
                <div className="input-title">
                  {`${formatMessage({
                    id: 'StartExam_72',
                    defaultMessage: '1차',
                  })} ${formatMessage({ id: 'Date_12', defaultMessage: '종료일자' })}`}
                </div>
                <FormDatePicker
                  control={control}
                  name="endDate1"
                  value={watchValues.endDate1}
                  error={errors.endDate1}
                  validation={{
                    validate: {
                      required: (value: any) => valid.required(value || watchValues.endDate1),
                    },
                  }}
                  handleOnChange={(name: string, date: Date) => handleOnChangeDate(name, date, 1)}
                  handleDisableDate={(name: string, current: Date) =>
                    handleDisableDate(name, current, 1)
                  }
                  helperText={
                    watchValues.endDate1 &&
                    `${formatMessage({
                      id: 'Period_3',
                      defaultMessage: '훈련 기간',
                    })}: ${moment
                      .duration(moment(watchValues.endDate1).diff(watchValues.startDate1))
                      .humanize()}`
                  }
                  arrowPosition="top"
                  //   disabled={watchValues.endDate < moment().startOf('day')}
                />
              </div>
            </div>
          </div>

          <div className="content-item">
            <div className="date-picker-area">
              <div className="date-picker-item">
                <div className="input-title">
                  {`${formatMessage({
                    id: 'StartExam_73',
                    defaultMessage: '2차',
                  })} ${formatMessage({ id: 'Date_11', defaultMessage: '시작일자' })}`}
                </div>
                <FormDatePicker
                  control={control}
                  name="startDate2"
                  value={watchValues.startDate2}
                  error={errors.startDate2}
                  validation={{
                    validate: {
                      required: (value: any) => valid.required(value || watchValues.startDate2),
                    },
                  }}
                  handleOnChange={(name: string, date: Date) => handleOnChangeDate(name, date, 2)}
                  handleDisableDate={(name: string, current: Date) =>
                    handleDisableDate(name, current, 2)
                  }
                  helperText={
                    moment() < moment(watchValues.startDate2) &&
                    formatMessage({ id: 'StartExam_36', defaultMessage: '훈련을 예약함' })
                  }
                  arrowPosition="top"
                />
              </div>
              <div className="date-picker-item">
                <div className="input-title">
                  {`${formatMessage({
                    id: 'StartExam_73',
                    defaultMessage: '2차',
                  })} ${formatMessage({ id: 'Date_12', defaultMessage: '종료일자' })}`}
                </div>
                <FormDatePicker
                  control={control}
                  name="endDate2"
                  value={watchValues.endDate2}
                  error={errors.endDate2}
                  validation={{
                    validate: {
                      required: (value: any) => valid.required(value || watchValues.endDate2),
                    },
                  }}
                  handleOnChange={(name: string, date: Date) => handleOnChangeDate(name, date, 2)}
                  handleDisableDate={(name: string, current: Date) =>
                    handleDisableDate(name, current, 2)
                  }
                  helperText={
                    watchValues.endDate2 &&
                    `${formatMessage({
                      id: 'Period_3',
                      defaultMessage: '훈련 기간',
                    })}: ${moment
                      .duration(moment(watchValues.endDate2).diff(watchValues.startDate2))
                      .humanize()}`
                  }
                  arrowPosition="top"
                  //   disabled={watchValues.endDate < moment().startOf('day')}
                />
              </div>
            </div>
          </div>

          {/* 훈련 횟수 */}
          <div className="content-item">
            <div className="input-title">
              {formatMessage({ id: 'License_11', defaultMessage: '훈련 횟수' })}
            </div>
            <FormTextField
              name="examCount"
              defaultValue={watchValues.examCount}
              error={errors.examCount}
              arrowPosition="top"
              register={register}
              validation={{
                validate: {
                  required: (value: any) => valid.required(value),
                  name: (value: any) => valid.name(value),
                },
              }}
              onChange={(e: any) => setValue('examCount', e.target.value)}
              disabled
            />
          </div>

          {/* 라이선스 오류 표시 */}
          {licenseError && (
            <div className="license-error">
              <div className="license-error-content">
                <div className="license-error-popup">
                  <div className="popup-header">
                    {formatMessage({
                      id: 'License_2',
                      defaultMessage: '라이선스 현황',
                    })}
                  </div>
                  <div className="popup-content">
                    {licenseError === 'targetCount'
                      ? formatMessage({
                          id: 'License_42',
                          defaultMessage: '훈련 대상자가 없습니다. 먼저 훈련 대상자를 등록하세요.',
                        })
                      : licenseContent}
                  </div>
                </div>
              </div>
            </div>
          )}
        </form>
      </ModalTemplate>

      {/* 전문가 모드 훈련 실시 */}
      {expertMode && (
        <StartExam
          examMode="expert"
          onCloseModal={onCloseModal}
          loadData={{
            examTemplate: watchValues.examTemplate[0],
            examName: watchValues.examName,
            examStartEpoch: epochFromDate(watchValues.startDate1),
            examEndEpoch: epochFromDate(watchValues.endDate1),
            isAllTarget: true,
            useExamSystemUrl: watchValues.useExamSystemUrl,
            examSystemUrl: watchValues.examSystemUrl,
          }}
        />
      )}
    </>
  );
}

export default StartExamSimple;
