import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getLogout } from 'store/login';
import { withRouter } from 'react-router-dom';
import { Option } from 'utils/commonValues';
import '../Login.scss';

// import mfLogoImage from "img/logo/mf_logo_white.png";
// import sxLogoImage from "img/logo/sx_logo_white.png";

function Already(props: any) {
  const userData = useSelector((state: any) => state.login.userData);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  // 로그아웃
  const onLogout = async () => {
    try {
      localStorage.removeItem('loginAdmin');
      localStorage.removeItem('loginUser');
      sessionStorage.removeItem('curUser');

      // 로그아웃 액션 호출
      if (userData?.isAdmin === 1) {
        await dispatch(getLogout());
        onChangePath('/mudlogin');
      } else {
        await dispatch(getLogout());
        onChangePath('/');
      }
    } catch (error) {
      console.log('AlreadyLogin onLogout');
    }
  };

  // path 변경
  const onChangePath = (path: string) => {
    // window.location.replace(path);
    props.history.push(path);
  };

  return (
    <div className="login-wrapper">
      <div className="already-login login-item">
        {/* 안내 */}
        <img
          className="logo-white"
          src="/img/logo/mf_logo_white.png" // {Option.isSaxa === 1 ? sxLogoImage : mfLogoImage}
          alt="logo"
        />
        <div className="content-box">
          <div className="info-text">
            <div className="already-login-main-text">
              {formatMessage({
                id: 'AlreadyLogin_1',
                defaultMessage: '이미 로그인이 되어 있습니다.',
              })}
            </div>
            <div className="already-login-sub-text">
              {formatMessage({
                id: 'AlreadyLogin_2',
                defaultMessage: '해당 작업은 로그아웃 후 이용 가능합니다.',
              })}
            </div>
          </div>

          {/* 버튼 */}
          <div className="button-box">
            <button
              type="button"
              className="already-login-btn brand"
              onClick={() => onChangePath('/')}
            >
              {formatMessage({ id: 'AlreadyLogin_3', defaultMessage: '돌아가기' })}
            </button>
            <button type="button" className="already-login-btn" onClick={onLogout}>
              {formatMessage({ id: 'AlreadyLogin_4', defaultMessage: '로그아웃' })}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(Already);
