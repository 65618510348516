import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Cell, Pie, PieChart, Sector } from 'recharts';
import { Option } from 'utils/commonValues';

// import openImage from 'img/tag/ico_open.png';
// import closeImage from 'img/tag/ico_close.png';
// import dangerImage from 'img/exam/chart/all_danger.png';

interface chartProps {
  chartData: any;
  chartType: string;
  useOpen: boolean;
}

function TargetExamChart({ chartData, chartType, useOpen }: chartProps) {
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState(false);

  // 훈련 이력 차트 데이터
  const textData: any = {
    total: {
      title: formatMessage({ id: 'Exam_5', defaultMessage: '전체 훈련' }),
      down: formatMessage({ id: 'Download_6', defaultMessage: '다운 / 피싱 접속' }),
      infection: `${formatMessage({
        id: 'Infection_1',
        defaultMessage: '감염',
      })} / ${formatMessage({ id: 'Infection_26', defaultMessage: '정보 유출' })}`,
      chart: `${formatMessage({
        id: 'Infection_1',
        defaultMessage: '감염',
      })}/${formatMessage({ id: 'Template_20', defaultMessage: '유출률' })}`,
    },
    attach: {
      title: formatMessage({ id: 'Exam_73', defaultMessage: '첨부 훈련' }),
      down: formatMessage({ id: 'Download_1', defaultMessage: '다운' }),
      infection: formatMessage({ id: 'Infection_1', defaultMessage: '감염' }),
      chart: formatMessage({ id: 'Infection_2', defaultMessage: '감염률' }),
    },
    phishing: {
      title: formatMessage({ id: 'Exam_74', defaultMessage: '피싱 훈련' }),
      down: formatMessage({ id: 'Download_8', defaultMessage: '피싱 접속' }),
      infection: formatMessage({ id: 'Infection_26', defaultMessage: '정보 유출' }),
      chart: formatMessage({ id: 'Template_20', defaultMessage: '유출률' }),
    },
  };

  // 차트 텍스트 데이터
  const chartTextData = textData[chartType];

  // 감염 퍼센트
  const infectionPercent =
    chartData && chartData[`${chartType}Infection`]
      ? Number(
          ((chartData[`${chartType}Infection`] / chartData[`${chartType}Target`]) * 100).toFixed(0),
        )
      : 0;

  // 감염 가능 파일
  const circleChartArray = [
    {
      name: '',
      color: '',
      value: 100 - infectionPercent,
    },
    {
      name: 'infection',
      color: '#db2828',
      value: infectionPercent,
    },
  ];

  return (
    <div className="target-exam-chart">
      <div className="exam-chart-item">
        {/* 훈련 분류 */}
        <div className="chart-title">
          {chartTextData.title}
          {useOpen && (
            <div className="exam-arrow-btn" onClick={() => setIsOpen(!isOpen)} aria-hidden="true">
              {isOpen ? (
                <img src="/img/tag/ico_close.png" alt="arrow" />
              ) : (
                <img src="/img/tag/ico_open.png" alt="arrow" />
              )}
            </div>
          )}
        </div>
        {/* 훈련 이력 상세 */}
        {(chartType === 'total' || isOpen) && (
          <div className="chart-contents">
            <div className="chart-text-area">
              <div className="chart-row">
                <div className="title">
                  <div className="color-dot brand-bg" />
                  <div className="text">
                    {formatMessage({ id: 'Exam_75', defaultMessage: '총 훈련' })}
                  </div>
                </div>
                <div className="value">
                  {`${chartData[`${chartType}Target`]} ${formatMessage({
                    id: 'User_8',
                    defaultMessage: '회',
                  })}`}
                </div>
              </div>
              <div className="chart-row">
                <div className="title">
                  <div className="color-dot bg-color-orange" />
                  <div className="text">
                    {formatMessage({ id: 'Read_1', defaultMessage: '열람' })}
                  </div>
                </div>
                <div className="value">
                  {`${chartData[`${chartType}Read`]} ${formatMessage({
                    id: 'User_8',
                    defaultMessage: '회',
                  })}`}
                </div>
              </div>
              <div className="chart-row">
                <div className="title">
                  <div className="color-dot bg-color-down" />
                  <div className="text">{chartTextData.down}</div>
                </div>
                <div className="value">
                  {`${chartData[`${chartType}Download`]} ${formatMessage({
                    id: 'User_8',
                    defaultMessage: '회',
                  })}`}
                </div>
              </div>
              <div className="chart-row">
                <div className="title">
                  <div className="color-dot bg-color-red" />
                  <div className="text">{chartTextData.infection}</div>
                </div>
                <div className="value">
                  {`${chartData[`${chartType}Infection`]} ${formatMessage({
                    id: 'User_8',
                    defaultMessage: '회',
                  })}`}
                </div>
              </div>
              {chartType !== 'phishing' && (
                <div className="chart-row sub">
                  <div className="title">
                    {formatMessage({ id: 'Infection_3', defaultMessage: '감염 PC' })}
                  </div>
                  <div className="value">
                    {`${chartData[`${chartType}InfectionPc`]} ${formatMessage({
                      id: 'Infection_29',
                      defaultMessage: '대',
                    })}`}
                  </div>
                </div>
              )}
              {chartType !== 'phishing' && (
                <div className="chart-row">
                  <div className="title">
                    <div className="color-dot bg-color-cure" />
                    <span className="text">
                      {formatMessage({ id: 'Cure_1', defaultMessage: '치료' })}
                    </span>
                  </div>
                  <div className="value">
                    {`${chartData[`${chartType}Cure`]} ${formatMessage({
                      id: 'User_8',
                      defaultMessage: '회',
                    })}`}
                  </div>
                </div>
              )}
              {Option.isDeclare === 1 && (
                <div className="chart-row">
                  <div className="title">
                    <div className="color-dot bg-color-declare" />
                    {formatMessage({ id: 'Declare_1', defaultMessage: '신고' })}
                  </div>
                  <div className="value">
                    {`${chartData[`${chartType}Declare`]} ${formatMessage({
                      id: 'User_8',
                      defaultMessage: '회',
                    })}`}
                  </div>
                </div>
              )}
            </div>
            {/* 그래프 */}
            <div className="chart-graph-area">
              <div className="infection-icon">
                <img src="/img/exam/chart/all_danger.png" alt="infection" />
              </div>
              <PieChart width={120} height={120}>
                <Pie
                  dataKey="value"
                  data={[
                    {
                      name: '',
                      value: 100,
                    },
                  ]}
                  cx={55}
                  cy={55}
                  paddingAngle={0}
                  innerRadius={47}
                  outerRadius={57}
                  fill="#e6e6e6"
                />
                <Pie
                  dataKey="value"
                  data={circleChartArray}
                  cx={55}
                  cy={55}
                  innerRadius={47}
                  outerRadius={57}
                  startAngle={90}
                  endAngle={450}
                  paddingAngle={0}
                  activeIndex={0}
                  activeShape={(e: any) => {
                    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle } = e;
                    return (
                      <g>
                        <defs>
                          <linearGradient id="db2828" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor="#ff6c00" />
                            <stop offset="100%" stopColor="#ff0b72" />
                          </linearGradient>
                        </defs>
                        <Sector
                          cx={cx}
                          cy={cy}
                          innerRadius={innerRadius}
                          outerRadius={outerRadius}
                          startAngle={startAngle}
                          endAngle={endAngle}
                          fill="#e6e6e6"
                        />
                        <Sector
                          cx={cx}
                          cy={cy}
                          innerRadius={outerRadius}
                          outerRadius={outerRadius}
                          startAngle={startAngle}
                          endAngle={endAngle}
                          fill="#e6e6e6"
                        />
                      </g>
                    );
                  }}
                >
                  {/* 퍼센트 표시 */}
                  {circleChartArray.map((entry: any) => (
                    <Cell key={entry.name} fill={`url(${entry.color})`} />
                  ))}
                </Pie>
              </PieChart>
              <div className="infection-percent-text">
                <div className="text">{textData[chartType].chart}</div>
                <div className="value">
                  <span className="percent-number">{infectionPercent}</span>
                  <span className="percent">%</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TargetExamChart;
