import { useIntl } from 'react-intl';
import ExamIngList from 'components/branch/examTemplate/ExamIngList';
import MainTitle from 'components/common/admin/MainTitle';
import './Conf.scss';

export default function Examtemplate(props: any) {
  const { formatMessage } = useIntl();

  return (
    <div className="admin-basic-conf table">
      <MainTitle
        type="table"
        title={formatMessage({ id: 'Exam_8', defaultMessage: '진행 중인 훈련' })}
      >
        {/* <EduTemplateMenu /> */}
      </MainTitle>
      <div className="react-table-area">
        <ExamIngList />
      </div>
    </div>
  );
}
