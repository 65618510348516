import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardTarget } from 'store/dashboard';

function DashboardTop5() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [targetData, setTargetData]: any = useState([]);

  useEffect(() => {
    onGetDashboardTarget();
  }, []);

  const onGetDashboardTarget = async () => {
    try {
      // setLoading(true);
      const response: any = await dispatch(getDashboardTarget());
      if (response.data?.list) {
        setTargetData(response.data?.list);
      }
    } catch (error) {
      console.log('Dashboard getDashboardTarget', error);
    }
  };

  const infectionTop5 = useMemo(() => {
    return targetData?.map((item: any) => {
      return (
        <tr className='table-row' key={item.targetNo}>
          <td>
            <div className='target-name ellipsis' title={item.targetName}>
              {item.targetName}
            </div>
            <div className='target-division ellipsis' title={item.targetDivision || formatMessage({ id: 'Division_2', defaultMessage: '소속 없음' })}>
              {item.targetDivision || formatMessage({ id: 'Division_2', defaultMessage: '소속 없음' })}
            </div>
          </td>
          <td>
            {`${item.infection}${formatMessage({ id: 'User_8', defaultMessage: '회' })}`}
          </td>
          <td >
            <span className='dash-badge'>{`${item.target || 0}${formatMessage({
              id: 'User_8', defaultMessage: '회'
            })}`}</span>
          </td>
        </tr >
      );
    });
  }, [targetData]);

  return (
    <div className='dash-top5'>
      <div className='result-group left'>
        <div className='group-title'>
          {formatMessage({ id: 'Dashboard_1', defaultMessage: '누적 위반자 TOP 5' })}
        </div>
        <table className='infection-target-table'>
          <tr>
            <th>{formatMessage({ id: 'Name_13', defaultMessage: '대상자명' })}</th>
            <th className='center'>{formatMessage({ id: 'Infection_46', defaultMessage: '감염 횟수' })}</th>
            <th className='right'>{formatMessage({ id: 'Infection_47', defaultMessage: '누적 횟수' })}</th>
          </tr>
          {infectionTop5}
        </table>
      </div>
    </div>
  );
}

export default DashboardTop5;