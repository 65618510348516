import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import TargetAddEdit from 'components/branch/target/TargetAddEdit';
import './TargetMenu.scss';

export default function TargetMenu(props: any) {
  const { formatMessage } = useIntl();
  const [openModal, setOpenModal] = useState(false);
  const isAdmin = useSelector((state: any) => state.login.userData.isAdmin);

  return (
    <div className="phishing-page-menu-wrap">
      <div className="menu-button">
        <button
          className="common-button-brand initial-config-bt"
          type="button"
          onClick={() => setOpenModal(true)}
        >
          {formatMessage({ id: 'Button_10', defaultMessage: '등 록' })}
        </button>
      </div>
      {openModal && (
        <TargetAddEdit
          visible={openModal}
          isAddMode
          isAdmin={isAdmin}
          dataInfo={{}}
          toggleModal={setOpenModal}
        />
      )}
    </div>
  );
}
