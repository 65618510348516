import { useIntl } from 'react-intl';
import { commaNumber, timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import { Option } from 'utils/commonValues';

interface dashboardProps {
  licenseData: any;
  licenseType: number;
}

function DashboardLicense({ licenseData, licenseType }: dashboardProps) {
  const { formatMessage } = useIntl();

  // 라이센스 없을 때
  const noLicenseComponent = (
    <div className="content-row no-license">
      {formatMessage({ id: 'License_19', defaultMessage: '사용 중인 라이선스가 없습니다.' })}
    </div>
  );

  const LicenseString: any = {
    Duration: formatMessage({ id: 'License_7', defaultMessage: '기간 라이선스' }),
    Times: formatMessage({ id: 'License_8', defaultMessage: '횟수 라이선스' }),
    DurationTimes: formatMessage({ id: 'License_58', defaultMessage: '기간+횟수 라이선스' }),
    EduDuration:
      formatMessage({ id: 'License_60', defaultMessage: '[교육]' }) +
      formatMessage({ id: 'License_7', defaultMessage: '기간 라이선스' }),
    EduDurationTimes:
      formatMessage({ id: 'License_60', defaultMessage: '[교육]' }) +
      formatMessage({ id: 'License_58', defaultMessage: '횟수 라이선스' }),
  };

  if (Option.isJapan === 1) {
    LicenseString.Duration =
      formatMessage({ id: 'License_61', defaultMessage: '[훈련]' }) +
      formatMessage({ id: 'License_7', defaultMessage: '기간 라이선스' });
    LicenseString.Times =
      formatMessage({ id: 'License_61', defaultMessage: '[훈련]' }) +
      formatMessage({ id: 'License_8', defaultMessage: '횟수 라이선스(무제한)' });
    LicenseString.DurationTimes =
      formatMessage({ id: 'License_61', defaultMessage: '[훈련]' }) +
      formatMessage({ id: 'License_58', defaultMessage: '횟수 라이선스' });
    LicenseString.EduDuration =
      formatMessage({ id: 'License_60', defaultMessage: '[교육]' }) +
      formatMessage({ id: 'License_7', defaultMessage: '기간 라이선스' });
    LicenseString.EduDurationTimes =
      formatMessage({ id: 'License_60', defaultMessage: '[교육]' }) +
      formatMessage({ id: 'License_58', defaultMessage: '횟수 라이선스' });
  }

  const makeLicenseItem = (data: any, index: number) => {
    if (!data) return null;

    return (
      <div key={data.licenseNo} className="content-row">
        <div className="content-row-title">{`${formatMessage({
          id: 'License_1',
          defaultMessage: '라이선스',
        })} No`}</div>
        <div className="content-value">{data.licenseNo}</div>
      </div>
    );
  };

  const isMultiple = Array.isArray(licenseData);
  let licenseComponent = <></>;
  if (licenseData?.length > 0) {
    licenseComponent = (
      <>
        {isMultiple
          ? licenseData?.map((data: any, index: number) => {
              return index > 2 ? <></> : makeLicenseItem(data, index);
            })
          : makeLicenseItem(licenseData, 0)}
        {licenseData.length > 3 && <div className="vertical-dot" />}
      </>
    );
  }

  // if (licenseData?.length > 0) {
  //   const displayedData = licenseData.length > 3 ? licenseData.slice(0, 3) : licenseData;
  //   licenseComponent = (
  //     <>
  //       {displayedData.map((data: any, index: number) => makeLicenseItem(data, index))}
  //       {licenseData.length > 3 && <div className="vertical-dot" />}
  //     </>
  //   );
  // }

  return (
    <div className="dashboard-license">
      <div className="license-title">
        {licenseType === 8 && LicenseString.DurationTimes}
        {(licenseType === 1 || licenseType === 2) && LicenseString.Duration}
        {licenseType === 3 &&
          formatMessage({ id: 'License_20', defaultMessage: '유지보수 라이선스' })}
        {licenseType === 4 && LicenseString.Times}
        {licenseType === 16 && LicenseString.EduDuration}
        {licenseType === 32 && LicenseString.EduDurationTimes}
      </div>

      <div className="content">
        {licenseData && Object.keys(licenseData).length > 0 ? licenseComponent : noLicenseComponent}
      </div>
    </div>
  );
}
export default DashboardLicense;
