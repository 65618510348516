import { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useLocation, useHistory } from 'react-router-dom';
import { Tag, Tooltip } from 'antd';
import fileDownload from 'js-file-download';
import {
  getEduTarget,
  getEduList,
  changeState,
  deleteEdu,
  setInitial,
  downloadReport,
  eduResend,
  addEduTag,
  changeTargetState,
} from 'store/edu';
import { Option } from 'utils/commonValues';
import { getTag } from 'store/tag';
import {
  getInitialTag,
  nowEpoch,
  openNotification,
  timeFormatFromUTCEpoch,
} from 'utils/commonFunctions';
import * as valid from 'utils/validation';
import ContentHeader from 'components/common/ContentHeader';
import Dimmer from 'components/common/Dimmer';
import ModalTemplate from 'components/common/ModalTemplate';
import ContentList from 'components/common/ContentList';
import SearchFilter from 'components/common/SearchFilter';
import EduSidebar from 'components/branch/edu/EduSidebar';
import EduItem from 'components/branch/edu/EduItem';
import EduDetail from 'components/branch/edu/EduDetail';
import FormSwitchField from 'components/common/FormSwitchField';
import FormTextField from 'components/common/FormTextField';
import ListStep from 'components/common/ListStep';
import './Eduresult.scss';

export const authLevel = 1;
function Eduresult() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const eduSummary = useSelector((state: any) => {
    return {
      data: state.edu.eduSummary.data,
      dataByEduNo: state.edu.eduSummary.dataByEduNo,
      param: state.edu.eduSummary.param,
      needReload: state.edu.eduSummary.needReload,
      selectedEduNo: state.edu.eduSummary.selectedEduNo,
    };
  }, shallowEqual);

  const eduList = useSelector((state: any) => {
    return {
      data: state.edu.eduList.data,
      dataByEduNo: state.edu.eduList.dataByEduNo,
      param: state.edu.eduList.param,
      needReload: state.edu.eduList.needReload,
      page: state.edu.eduList.page,
      totalPage: state.edu.eduList.totalPage,
      totalItem: state.edu.eduList.totalItem,
      selectType: state.edu.eduList.selectType,
      selectedList: state.edu.eduList.selectedList,
    };
  }, shallowEqual);

  const eduTarget = useSelector((state: any) => {
    return {
      data: state.edu.eduTarget.data,
      dataByDataNo: state.edu.eduTarget.dataByDataNo,
      param: state.edu.eduTarget.param,
      needReload: state.edu.eduTarget.needReload,
      page: state.edu.eduTarget.page,
      totalPage: state.edu.eduTarget.totalPage,
      totalItem: state.edu.eduTarget.totalItem,
      selectType: state.edu.eduTarget.selectType,
      selectedList: state.edu.eduTarget.selectedList,
    };
  }, shallowEqual);

  const userData = useSelector((state: any) => state.user.user);

  const { register, watch, handleSubmit, setError, control, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState('');
  const [masking, setMasking]: any = useState({});

  useEffect(() => {
    // 다른 탭 이동 시 교육결과 스토어 초기화
    return () => {
      dispatch(setInitial());
    };
  }, []);

  useEffect(() => {
    if (eduSummary.selectedEduNo === 'all') {
      onGetEduList(true);
    } else {
      onGetEduTarget(true);
    }
  }, [eduSummary.selectedEduNo, eduSummary.needReload, eduTarget.param, eduList.param]);

  // 교육 리스트 새로고침
  const onEduRefresh = async () => {
    setLoading(true);

    const isEduList = eduSummary.selectedEduNo === 'all';
    const selectedEdu = isEduList ? eduList : eduTarget;
    const { limit, sort, filter } = selectedEdu.param;

    try {
      const params = {
        filter: filter,
        sort: sort,
        offset: 0,
        limit: limit,
        refresh: true,
      };

      // 교육 대상자 param 변경 시
      await dispatch(
        changeState({
          key: isEduList ? 'eduList' : 'eduTarget',
          value: { param: params },
        }),
      );
    } catch (error) {
      console.log('Edu onEduRefresh', error);
    } finally {
      setLoading(false);
    }
  };

  // 교육 리스트 조회
  const onGetEduList = async (refresh = false) => {
    setLoading(true);

    try {
      const params: any = {
        eduNo: eduSummary.selectedEduNo,
        filter: JSON.stringify(eduList.param.filter),
        sort: JSON.stringify(eduList.param.sort),
        offset: 0,
        limit: eduList.param.limit,
      };

      if (Object.keys(eduSummary?.param?.filter).length > 0) {
        params.eduFilter = JSON.stringify(eduSummary.param.filter);
      }

      if (refresh) {
        // 처음부터 조회
        params.refresh = true;
      } else {
        // 이어서 조회
        params.offset = eduList.param.limit * eduList.page;
      }

      await dispatch(getEduList(params));
    } catch (error) {
      console.log('Edu onGetEduList', error);
    } finally {
      setLoading(false);
    }
  };

  // 교육 대상자 조회
  const onGetEduTarget = async (refresh = false) => {
    setLoading(true);

    try {
      const params: any = {
        eduNo: eduSummary.selectedEduNo,
        filter: JSON.stringify(eduTarget.param.filter),
        sort: JSON.stringify(eduTarget.param.sort),
        offset: 0,
        limit: eduTarget.param.limit,
        subTab: pathname === '/eduresult/tag' ? 'tag' : 'target',
      };

      if (Object.keys(eduSummary?.param?.filter).length > 0) {
        params.examFilter = JSON.stringify(eduSummary.param.filter);
      }

      if (refresh) {
        // 처음부터 조회
        params.refresh = true;
      } else {
        // 이어서 조회
        params.offset = eduTarget.param.limit * eduTarget.page;
      }

      await dispatch(getEduTarget(params));
    } catch (error) {
      console.log('Edu onGetEduTarget', error);
    } finally {
      setLoading(false);
    }
  };

  // 교육 선택
  const onSelectItem = useCallback(
    (type: string, no: number) => {
      const store = eduSummary.selectedEduNo === 'all' ? eduList : eduTarget;
      const storeText = eduSummary.selectedEduNo === 'all' ? 'eduList' : 'eduTarget';
      // 선택 타입 변경 시 선택 리스트 초기화
      const selectList = type !== store.selectType ? [] : [...store.selectedList];

      if (type === 'check') {
        if (selectList.includes(no)) {
          selectList.splice(selectList.indexOf(no), 1);
        } else {
          selectList.push(no);
        }
        dispatch(
          changeState({
            key: storeText,
            value: { selectedList: selectList, selectType: 'check' },
          }),
        );
      } else {
        dispatch(
          changeState({
            key: storeText,
            value: { selectedList: selectList.includes(no) ? [] : [no], selectType: 'select' },
          }),
        );
      }
    },
    [
      eduSummary.selectedEduNo,
      JSON.stringify(eduTarget.selectedList),
      JSON.stringify(eduList.selectedList),
    ],
  );

  // 교육 삭제
  const onDeleteEdu = async () => {
    setLoading(true);
    try {
      const param: any = {
        serviceNo: userData.serviceNo,
        eduNoArray: JSON.stringify(eduList.selectedList),
      };
      const response: any = await dispatch(deleteEdu(param));
      setLoading(false);
      if (!response.data.error) {
        setModal('');
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 엑셀 파일 다운로드
  const downloadExcel = async (params: any, reportName: string) => {
    // 마스킹 활성화 시킨 것만 파라미터에 추가
    Object.keys(masking).forEach((key: string) => {
      if (masking[key] === 1) {
        params[key] = 1;
      }
    });

    try {
      const response: any = await dispatch(downloadReport(params));
      if (response?.data) {
        fileDownload(response.data, reportName);
        setModal('');
      }
    } catch (error) {
      console.log('Edu downloadExcel', error);
    }
  };

  // 교육 메일 재전송
  const onResend = async () => {
    setLoading(true);
    const eduTargetNoList = eduTarget.selectedList.map((targetNo: number) => {
      return eduTarget.dataByDataNo[targetNo].eduTargetNo;
    });

    try {
      const params = {
        eduNo: eduSummary.dataByEduNo[eduSummary.selectedEduNo].eduNo,
        targetNoArray: JSON.stringify(eduTargetNoList),
      };
      await dispatch(eduResend(params));
      setLoading(false);
      setModal('');
    } catch (error) {
      console.log('onResend', error);
    }
  };

  // 마스킹 사용 옵션 변경
  const changeMasking = (name: string, value: any) => {
    setMasking({ ...masking, [name]: value });
  };

  // 교육 보고서 다운로드 클릭 이벤트
  const onDownloadReport = () => {
    setLoading(true);
    try {
      if (eduSummary.selectedEduNo === 'all') {
        for (let i = 0; i < eduList.selectedList.length; i += 1) {
          const reportName = `${
            eduSummary.dataByEduNo[eduList.selectedList[i]]?.eduName
          }_${formatMessage({
            id: 'Edu_84',
            defaultMessage: '교육 보고서',
          })}.xlsx`;
          downloadExcel({ eduNo: eduList.selectedList[i] }, reportName);
        }
      } else {
        const reportName = `${
          eduSummary.dataByEduNo[eduSummary.selectedEduNo]?.eduName
        }_${formatMessage({
          id: 'Edu_84',
          defaultMessage: '교육 보고서',
        })}.xlsx`;
        downloadExcel({ eduNo: eduSummary.selectedEduNo }, reportName);
      }
      setLoading(false);
    } catch (error) {
      console.log('Edu onDownloadReport', error);
    }
  };

  // 태그 만들기
  const onAddTag = async (formData: any) => {
    try {
      // 태그명 중복체크
      const res: any = await dispatch(getTag({ filter: { tagNameExact: formData.tagName } }));
      if (res?.data?.list?.length > 0) {
        setError('tagName', {
          type: 'duplicate',
          message: formatMessage({ id: 'Tag_56', defaultMessage: '이미 등록된 태그명입니다.' }),
        });
      } else {
        // 태그 이니셜 추출 가능 여부 확인
        const initial = getInitialTag(formData.tagName);
        if (!initial) {
          setError('tagName', {
            type: 'notAllowed',
            message: formatMessage({
              id: 'Tag_53',
              defaultMessage: '입력 값(태그)이 올바르지 않습니다.',
            }),
          });

          return;
        }

        setLoading(true);

        const params = {
          serviceNo: userData?.serviceNo,
          eduNo: currentEdu?.eduNo,
          tagName: formData.tagName,
          tagInitial: getInitialTag(formData.tagName),
          filter: eduTarget.param.filter,
          targetNoArray: eduTarget.selectedList,
        };

        const response: any = await dispatch(addEduTag(params));
        if (response?.data?.list) {
          setModal('');
          openNotification(
            `'${response.data.list[0].tagName}' ${formatMessage({
              id: 'Tag_40',
              defaultMessage: '태그 생성',
            })}`,
          );
        } else if (response?.data?.error) {
          setError('tagName', {
            type: 'notAllowed',
            message: formatMessage({
              id: 'Tag_53',
              defaultMessage: '입력 값(태그)이 올바르지 않습니다.',
            }),
          });
        }
        setLoading(false);
      }
    } catch (error) {
      console.log('OnAddTag', error);
    }
  };

  // 전체 교육 선택 여부
  const isAllEdu = useMemo(() => eduSummary.selectedEduNo === 'all', [eduSummary.selectedEduNo]);

  // 선택한 교육 정보
  const currentEdu = useMemo(() => {
    const copyList = [...eduList.selectedList];
    return eduSummary.selectedEduNo !== 'all'
      ? eduSummary.dataByEduNo[eduSummary.selectedEduNo]
      : eduList.dataByEduNo[copyList.pop()];
  }, [eduSummary.dataByEduNo, eduSummary.selectedEduNo, eduList.dataByEduNo, eduList.selectedList]);

  const navButtonList = useMemo(
    () => [
      {
        isShow: true,
        moveLink: '/eduresult',
        buttonText: formatMessage({ id: 'Name_4', defaultMessage: '대상자' }),
        tooltipText: formatMessage({
          id: 'Exam_29',
          defaultMessage: "교육 결과를 '대상자'별로 보여줍니다.",
        }),
        clickEvent: () =>
          dispatch(
            changeTargetState({
              key: 'param',
              value: {
                filter: {},
                sort: [{ field: 'eduTargetNo', order: 'DESC' }],
                offset: 0,
                limit: 30,
              },
            }),
          ),
      },
      {
        isShow: true,
        moveLink: '/eduresult/tag',
        buttonText: formatMessage({ id: 'Tag_37', defaultMessage: '태 그' }),
        tooltipText: formatMessage({
          id: 'Exam_31',
          defaultMessage: "교육 결과를 '태그'별로 보여줍니다.",
        }),
        clickEvent: () =>
          dispatch(
            changeTargetState({
              key: 'param',
              value: {
                filter: {},
                sort: [{ field: 'tagNo', order: 'DESC' }],
                offset: 0,
                limit: 30,
              },
            }),
          ),
      },
    ],
    [currentEdu],
  );

  // 상단 제목
  const headerTitle = useMemo(
    () =>
      isAllEdu ? formatMessage({ id: 'Edu_19', defaultMessage: '전체 교육' }) : currentEdu?.eduName,
    [isAllEdu, currentEdu],
  );

  const noContentText: any = {
    ing: {
      title: formatMessage({ id: 'Edu_148', defaultMessage: '교육 대상자 집계 중' }),
      subTitle: (
        <div>
          {formatMessage({ id: 'Edu_149', defaultMessage: '미종료훈련을 포함하는 경우,' })}
          <br />
          {formatMessage({
            id: 'Edu_150',
            defaultMessage: '훈련 종료 시에 교육 대상자가 확정됩니다.',
          })}
          <Tooltip
            overlayClassName="black-tooltip"
            placement="bottom"
            title={formatMessage({
              id: 'Edu_151',
              defaultMessage: '훈련 결과로 이동',
            })}
          >
            <img
              src="/img/exam/startExam/btn_arrow.png"
              alt="move"
              onClick={(e: any) => {
                e.stopPropagation();
                history.push('/exam/pi');
              }}
              aria-hidden="true"
              className="status-icon"
            />
          </Tooltip>
        </div>
      ),
    },
    fail: {
      title: formatMessage({ id: 'Edu_152', defaultMessage: '교육실시 실패' }),
      subTitle: (
        <div>
          {formatMessage({ id: 'Edu_153', defaultMessage: '알 수 없는 이유로,' })}
          <br />
          {formatMessage({
            id: 'Edu_154',
            defaultMessage: '연결 훈련에 의한 교육실시에 실패하였습니다.',
          })}
        </div>
      ),
    },
    end: {
      title: formatMessage({ id: 'Edu_23', defaultMessage: '교육 대상자가 없습니다.' }),
      subTitle: formatMessage({ id: 'Edu_24', defaultMessage: '교육을 실시하세요.' }),
    },
  };

  const targetCount = `${currentEdu?.eduCount}${formatMessage({
    id: 'StartExam_35',
    defaultMessage: '명',
  })}`;

  let bindExamResult = '';
  if (currentEdu?.eduFix !== 1) {
    bindExamResult = currentEdu?.startEpoch > nowEpoch() ? 'ing' : 'fail';
  }
  const notExistTarget = bindExamResult ? noContentText[bindExamResult] : '';

  // 상단 부제목
  const headerSubTitle = useMemo(
    () =>
      isAllEdu
        ? formatMessage(
            { id: 'Edu_22', defaultMessage: '총 교육: {count}개' },
            { count: eduSummary.data.length > 0 ? eduSummary.data[0].rnum : 0 },
          )
        : ` ${formatMessage({
            id: 'Target_12',
            defaultMessage: '총 대상자',
          })}: ${notExistTarget ? notExistTarget.title : targetCount}`,
    [isAllEdu, eduSummary.data, eduSummary.data.length, eduTarget.data, currentEdu],
  );

  // 교육 삭제 버튼
  const deleteEduBtn = (
    <div
      className={`round-grey-button ${eduList.selectedList.length < 1 ? 'disabled' : ''}`}
      onClick={() => setModal('delete')}
      aria-hidden="true"
    >
      {formatMessage({ id: 'Edu_49', defaultMessage: '교육 삭제' })}
    </div>
  );

  // 태그 만들기 버튼
  const addTagBtn =
    pathname === '/eduresult/tag' ? (
      <></>
    ) : (
      <div
        className={`round-grey-button tag ${eduTarget.selectedList.length < 1 ? 'disabled' : ''}`}
        onClick={() => {
          setModal('addTag');
        }}
        aria-hidden="true"
      >
        <div className="plus-icon" />
        {formatMessage({ id: 'Tag_4', defaultMessage: '태그 만들기' })}
      </div>
    );

  const ongoingList = eduList.selectedList?.filter(
    (eduNo: number) => eduList.dataByEduNo[eduNo]?.eduFix !== 1,
  );
  const reportDisabled =
    ongoingList.length > 0 ||
    currentEdu?.eduFix !== 1 ||
    (eduSummary?.selectedEduNo === 'all' && eduList?.selectedList?.length < 1);

  const buttonComponent = (
    <div className="flex">
      {eduSummary?.selectedEduNo !== 'all' && pathname === '/eduresult' && (
        <button
          className={`round-grey-button ${eduTarget.selectedList.length < 1 ? 'disabled' : ''}`}
          type="button"
          onClick={() => setModal('resend')}
        >
          {formatMessage({ id: 'Resend_1', defaultMessage: '재전송' })}
        </button>
      )}
      <button
        className={`round-grey-button ${reportDisabled ? 'disabled' : ''}`}
        type="button"
        onClick={() => {
          setMasking({});
          setModal('report');
        }}
      >
        {formatMessage({ id: 'Exam_48', defaultMessage: '보고서' })}
        <div className="download-img">
          <img src="/img/exam/menu_down.png" alt="down" />
        </div>
      </button>
    </div>
  );

  const eduSort = useMemo(() => {
    return {
      send: formatMessage({ id: 'Send_1', defaultMessage: '발송' }),
      read: formatMessage({ id: 'Read_1', defaultMessage: '열람' }),
      beforeCount: formatMessage({ id: 'Status_15', defaultMessage: '진행전' }),
      playCount: formatMessage({ id: 'Status_8', defaultMessage: '진행중' }),
      afterCount: formatMessage({ id: 'Status_16', defaultMessage: '완료' }),
    };
  }, []);

  const eduFilter = useMemo(() => {
    return {
      status: {
        name: formatMessage({ id: 'Status_1', defaultMessage: '상태' }),
        child: [
          { label: formatMessage({ id: 'Send_1', defaultMessage: '발송' }), value: 'send' },
          { label: formatMessage({ id: 'Read_1', defaultMessage: '열람' }), value: 'read' },
          {
            label: formatMessage({ id: 'Status_15', defaultMessage: '진행전' }),
            value: 'beforeCount',
          },
          {
            label: formatMessage({ id: 'Status_8', defaultMessage: '진행중' }),
            value: 'playCount',
          },
          {
            label: formatMessage({ id: 'Status_16', defaultMessage: '완료' }),
            value: 'afterCount',
          },
        ],
      },
    };
  }, []);

  const eduTargetSort = useMemo(() => {
    return {
      ...(pathname === '/eduresult'
        ? { grade: formatMessage({ id: 'Edu_104', defaultMessage: '점수' }) }
        : null),
      read: formatMessage({ id: 'Read_1', defaultMessage: '열람' }),
      finish: formatMessage({ id: 'Status_16', defaultMessage: '완료' }),
    };
  }, [pathname]);

  const eduTargetFilter = useMemo(() => {
    return {
      status: {
        name: formatMessage({ id: 'Status_1', defaultMessage: '상태' }),
        child: [
          { label: formatMessage({ id: 'Edu_105', defaultMessage: '미열람' }), value: 'notRead' },
          { label: formatMessage({ id: 'Edu_106', defaultMessage: '미이수' }), value: 'notFinish' },
        ],
      },
    };
  }, []);

  const targetComponent = (
    <ContentList
      dataList={eduTarget.data}
      onLoadData={onGetEduTarget}
      loading={loading}
      page={eduTarget.page}
      totalPage={eduTarget.totalPage}
      noContent={notExistTarget || noContentText.end}
    >
      {notExistTarget ? (
        <></>
      ) : (
        eduTarget.data.map((rowData: any) => {
          return (
            <div
              className={`content-list-item ${
                !isAllEdu &&
                eduTarget.selectedList.includes(rowData.targetNo) &&
                eduTarget.selectType === 'select'
                  ? 'selected'
                  : ''
              }`}
              key={rowData.targetNo}
              onClick={() => onSelectItem('select', rowData.targetNo)}
              aria-hidden="true"
            >
              <EduItem
                data={rowData}
                selectedEduNo={eduSummary.selectedEduNo}
                // eduFileInfo={isAllEdu ? null : currentEdu?.fileInfo}
                quizInfo={isAllEdu ? null : currentEdu?.quizData}
                isChecked={eduTarget.selectedList.includes(rowData.targetNo)}
                selectedType={eduTarget.selectType}
                onSelectItem={(no: number) => onSelectItem('check', no)}
              />
            </div>
          );
        })
      )}
    </ContentList>
  );

  const tagComponent = (
    <ContentList
      dataList={eduTarget.data}
      onLoadData={onGetEduTarget}
      loading={loading}
      page={eduTarget.page}
      totalPage={eduTarget.totalPage}
      noContent={notExistTarget || noContentText.end}
    >
      {notExistTarget ? (
        <></>
      ) : (
        eduTarget.data.map((rowData: any) => {
          const stepDataAll = [
            {
              view: true,
              icon: {
                stepIcon: '/img/exam/list_target.png',
              },
              tooltipTitle: `${formatMessage({ id: 'Edu_110', defaultMessage: '교육 대상' })}: ${
                rowData.target
              }`,
              count: rowData.target,
            },
            {
              view: true,
              icon: {
                stepIcon: '/img/edu/list_send.png',
              },
              tooltipTitle: `${formatMessage({ id: 'Send_1', defaultMessage: '발송' })}: ${
                rowData.send
              }`,
              count: rowData.send,
            },
            {
              view: true,
              icon: {
                stepIcon: '/img/edu/list_read.png',
              },
              tooltipTitle: `${formatMessage({ id: 'Read_1', defaultMessage: '열람' })}: ${
                rowData.read
              }`,
              count: rowData.read,
            },
            {
              view: true,
              icon: {
                stepIcon: '/img/edu/list_proceed.png',
              },
              tooltipTitle: `${formatMessage({ id: 'Status_8', defaultMessage: '진행중' })}: ${
                rowData.playCount
              }`,
              count: rowData.playCount,
            },
            {
              view: true,
              icon: {
                stepIcon: '/img/edu/list_end.png',
              },
              tooltipTitle: `${formatMessage({ id: 'Status_16', defaultMessage: '완료' })}: ${
                rowData.afterCount
              }`,
              count: rowData.afterCount,
            },
          ];

          return (
            <div
              className={`content-list-item tag ${
                !isAllEdu &&
                eduTarget.selectedList.includes(rowData.tagNo) &&
                eduTarget.selectType === 'select'
                  ? 'selected'
                  : ''
              }`}
              key={rowData.tagNo}
              onClick={() => onSelectItem('select', rowData.tagNo)}
              aria-hidden="true"
            >
              <div className="tag-column">
                <Tooltip
                  overlayClassName="black-tooltip"
                  placement="bottom"
                  title={rowData.tagName}
                >
                  <Tag className={`color-tag tag-label-${rowData.color} small`}>
                    {rowData.tagName}
                  </Tag>
                </Tooltip>
              </div>
              <div className="status-column">
                <div className="status-box">
                  {stepDataAll.map(
                    (item: any) =>
                      item.view && (
                        <ListStep
                          tooltipTitle={item.tooltipTitle}
                          icon={item.icon}
                          count={item.count}
                          countColor={item.countColor}
                          key={item.tooltipTitle}
                        />
                      ),
                  )}
                </div>
                <div className="edu-percent-box">
                  <div>{formatMessage({ id: 'Edu_25', defaultMessage: '진행률' })}</div>
                  <div className="edu-percent">{`${rowData.progress}%`}</div>
                </div>
              </div>
            </div>
          );
        })
      )}
    </ContentList>
  );

  const EduDetailComponent = () => {
    const isEduList = eduSummary.selectedEduNo === 'all';
    const isSelected = eduTarget.selectedList?.length > 0 ? true : false;
    const isSelectedEach = !isEduList && isSelected;
    const subTab = pathname === '/eduresult/tag' ? 'tag' : 'target';
    const targetOrTagEdu =
      eduTarget.dataByDataNo[eduTarget.selectedList[eduTarget.selectedList.length - 1]];

    return (
      <EduDetail
        detailType={isSelectedEach ? subTab : 'edu'}
        detailData={isSelectedEach ? targetOrTagEdu : currentEdu}
        eduSummaryData={currentEdu}
      />
    );
  };

  let selectedType = null;
  if (pathname !== '/eduresult/tag') {
    if (isAllEdu) {
      selectedType = eduList.selectType;
    } else {
      selectedType = eduTarget.selectType;
    }
  }

  return (
    <div className="common-content-layout">
      <div className={`common-left-side ${Option.isJapan ? '' : 'grey-theme'}`}>
        <EduSidebar />
      </div>
      <div className="common-content">
        <div className="common-inner">
          <ContentHeader
            title={headerTitle}
            subTitle={headerSubTitle}
            buttons={buttonComponent}
            onRefresh={onEduRefresh}
            navButtonList={isAllEdu ? null : navButtonList}
          />

          <div className="edu-content-wrap pi">
            {/* 검색 영역 */}
            <SearchFilter
              sortMenu={isAllEdu ? eduSort : eduTargetSort}
              filterMenu={isAllEdu ? eduFilter : eduTargetFilter}
              filterType="array"
              param={isAllEdu ? eduList.param : eduTarget.param}
              paramAction={({ key, value }: any) => {
                return changeState({
                  key: isAllEdu ? 'eduList' : 'eduTarget',
                  value: { [key]: value },
                });
              }}
              dataList={Object.keys(isAllEdu ? eduList.dataByEduNo : eduTarget.dataByDataNo).map(
                (key: any) => {
                  return parseInt(key, 10);
                },
              )}
              selectedList={isAllEdu ? eduList.selectedList : eduTarget.selectedList}
              selectedType={selectedType}
              allCheckText={{
                allText: formatMessage({ id: 'Filter_14', defaultMessage: '전체선택' }),
                noneText: formatMessage({ id: 'Filter_15', defaultMessage: '전체해제' }),
              }}
              extraBtn={isAllEdu ? deleteEduBtn : addTagBtn}
            />
            {isAllEdu && (
              <ContentList
                dataList={eduList.data}
                onLoadData={onGetEduList}
                loading={loading}
                page={eduList.page}
                totalPage={eduList.totalPage}
                noContent={{
                  title: formatMessage({ id: 'Edu_23', defaultMessage: '교육 대상자가 없습니다.' }),
                  subTitle: formatMessage({ id: 'Edu_24', defaultMessage: '교육을 실시하세요.' }),
                }}
              >
                {eduList.data.map((rowData: any) => {
                  return (
                    <div
                      className={`content-list-item ${
                        eduList.selectedList.includes(rowData.eduNo) &&
                        eduList.selectType === 'select'
                          ? 'selected'
                          : ''
                      }`}
                      key={rowData.eduNo}
                      onClick={() => onSelectItem('select', rowData.eduNo)}
                      aria-hidden="true"
                    >
                      <EduItem
                        data={rowData}
                        selectedEduNo={eduSummary.selectedEduNo}
                        // eduFileInfo={rowData.fileInfo}
                        quizInfo={rowData.quizData}
                        isChecked={eduList.selectedList.includes(rowData.eduNo)}
                        selectedType={eduList.selectType}
                        onSelectItem={(no: any) => onSelectItem('check', no)}
                      />
                    </div>
                  );
                })}
              </ContentList>
            )}
            {!isAllEdu && pathname === '/eduresult' && targetComponent}
            {!isAllEdu && pathname === '/eduresult/tag' && tagComponent}
          </div>
        </div>

        {/* eduTarget */}
        <div className="common-inner-right">
          {!currentEdu ? (
            <Dimmer
              dimmerText={formatMessage({ id: 'Edu_18', defaultMessage: '교육을 선택하세요.' })}
            />
          ) : (
            <EduDetailComponent />
          )}
        </div>

        {/* 교육 삭제 모달 */}
        {modal === 'delete' && (
          <ModalTemplate
            className="modal-464"
            visible={modal === 'delete'}
            title={formatMessage({ id: 'Edu_49', defaultMessage: '교육 삭제' })}
            onOk={onDeleteEdu}
            onCancel={() => setModal('')}
            okText={formatMessage({ id: 'Button_15', defaultMessage: '삭 제' })}
            cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
            loading={loading}
            greyButton
          >
            <div className="modal-explain-text">
              <div>
                {formatMessage({
                  id: 'Edu_50',
                  defaultMessage: '삭제한 교육 정보는 복구할 수 없습니다.',
                })}
              </div>
              <div>
                {formatMessage({
                  id: 'Edu_51',
                  defaultMessage: '다음 정보를 삭제합니까?',
                })}
              </div>
            </div>
            <div className="modal-border-box">
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({ id: 'Edu_81', defaultMessage: '교육 번호' })}
                  </div>
                </li>
                <div>{eduList.selectedList.join(', ')}</div>
              </ul>
            </div>
          </ModalTemplate>
        )}

        {/* 교육 메일(촉구메일) 재전송 모달 */}
        {modal === 'resend' && (
          <ModalTemplate
            className="modal-464"
            visible={modal === 'resend'}
            title={formatMessage({ id: 'Resend_12', defaultMessage: '교육 메일 재전송' })}
            onOk={onResend}
            onCancel={() => setModal('')}
            okText={formatMessage({ id: 'Resend_1', defaultMessage: '재전송' })}
            cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
            loading={loading}
            greyButton
          >
            <div className="modal-explain-text">
              <div>
                {formatMessage({
                  id: 'Resend_13',
                  defaultMessage: '교육 메일을 다시 보내시겠습니까?',
                })}
              </div>
            </div>
            <div className="modal-border-box">
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({ id: 'Edu_28', defaultMessage: '교육명' })}
                  </div>
                </li>
                <div className="item-title">{currentEdu.eduName}</div>
              </ul>
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({ id: 'Period_4', defaultMessage: '교육 기간' })}
                  </div>
                </li>
                <div className="item-title">{`${timeFormatFromUTCEpoch(
                  currentEdu?.startEpoch,
                  3,
                )} ~ ${timeFormatFromUTCEpoch(currentEdu?.endEpoch, 3)}`}</div>
              </ul>
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({ id: 'Target_10', defaultMessage: '대상자 번호' })}
                  </div>
                </li>
                <div>{eduTarget.selectedList.join(', ')}</div>
              </ul>
            </div>
          </ModalTemplate>
        )}

        {modal === 'report' && (
          <ModalTemplate
            className="edu-report-download-modal modal-464"
            visible={modal === 'report' || modal === 'reportRandom'}
            title={formatMessage({ id: 'Download_10', defaultMessage: '보고서 다운로드' })}
            onOk={handleSubmit(onDownloadReport)}
            onCancel={() => {
              setModal('');
            }}
            okText={formatMessage({ id: 'Download_2', defaultMessage: '다운로드' })}
            cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
            loading={loading}
          >
            <form onSubmit={(e: any) => e.preventDefault()}>
              <div className="input-title">
                {formatMessage({ id: 'Exam_57', defaultMessage: '마스킹 사용' })}
              </div>
              <div className="white-border-box">
                <div className="box-item">
                  {formatMessage({ id: 'Name_1', defaultMessage: '이름' })}
                  <FormSwitchField
                    className="input-switch"
                    control={control}
                    name="maskingName"
                    handleOnChange={changeMasking}
                  />
                </div>
                <div className="box-item">
                  {formatMessage({ id: 'Email_1', defaultMessage: '이메일' })}
                  <FormSwitchField
                    className="input-switch"
                    control={control}
                    name="maskingEmail"
                    handleOnChange={changeMasking}
                  />
                </div>
                <div className="box-item">
                  {formatMessage({ id: 'Contact_1', defaultMessage: '연락처' })}
                  <FormSwitchField
                    className="input-switch"
                    control={control}
                    name="maskingPhone"
                    handleOnChange={changeMasking}
                  />
                </div>
              </div>
            </form>
          </ModalTemplate>
        )}

        {/* 태그 만들기 모달 */}
        {modal === 'addTag' && (
          <ModalTemplate
            className="exam-add-tag-modal modal-464"
            visible={modal === 'addTag'}
            title={formatMessage({ id: 'Tag_4', defaultMessage: '태그 만들기' })}
            onOk={handleSubmit(onAddTag)}
            onCancel={() => setModal('')}
            okText={formatMessage({ id: 'Button_16', defaultMessage: '생 성' })}
            cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
            loading={loading}
          >
            <div className="modal-explain-text">
              {formatMessage({
                id: 'Tag_62',
                defaultMessage: '현재 교육에서 선택한 대상자를 태그로 등록합니다.',
              })}
            </div>
            <div className="modal-border-box">
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({ id: 'Edu_28', defaultMessage: '교육명' })}
                  </div>
                </li>
                <div>{currentEdu.eduName}</div>
              </ul>
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({ id: 'Target_10', defaultMessage: '대상자 번호' })}
                  </div>
                </li>
                <div>
                  {Object.keys(eduTarget.dataByDataNo)?.length === eduTarget.selectedList.length
                    ? formatMessage({ id: 'Tag_42', defaultMessage: '모든 대상자' })
                    : eduTarget.selectedList
                        ?.map((targetNo: number) => {
                          return eduTarget.dataByDataNo[targetNo]?.targetNo;
                        })
                        .join(', ')}
                </div>
              </ul>
              <form autoComplete="off" onSubmit={(e: any) => e.preventDefault()}>
                <ul className="modal-item-list">
                  <li>
                    <div className="item-title">
                      {formatMessage({ id: 'Tag_2', defaultMessage: '태그명' })}
                    </div>
                  </li>
                  <FormTextField
                    name="tagName"
                    error={errors.tagName}
                    arrowPosition="top"
                    register={register}
                    validation={{
                      validate: {
                        required: (value: any) => valid.required(value),
                        name: (value: any) => valid.name(value),
                      },
                    }}
                  />
                </ul>
              </form>
            </div>
          </ModalTemplate>
        )}
      </div>
    </div>
  );
}

export default Eduresult;
