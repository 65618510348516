import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Tooltip } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { phishingUploadImage } from 'store/phishing';
import * as valid from 'utils/validation';
import FormButtonGroup from 'components/common/FormButtonGroup';
import FormTextField from 'components/common/FormTextField';
import './PhishingPageSidebar.scss';

// import qmarkImage from 'img/exam/startExam/btn_qmark.png';
// import closeImage from 'img/exam/search_cancel.png';

function PhishingPageSidebar({
  isAdmin,
  isAddMode,
  control,
  errors,
  register,
  formValues,
  changeFormValue,
  phishingLevel,
  changePhishingLevel,
  currentPage,
  changeCurrentPage,
  phishingNo,
  images,
  setImageList,
}: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [file, setFile]: any = useState(null);
  const [showImageUrl, setShowImageUrl] = useState(-1);

  // 파일 선택
  const selectFile = (e: any) => {
    const file = e.target.files[0];
    const fileTypeArray = ['image/bmp', 'image/jpg', 'image/jpeg', 'image/png', 'image/gif'];
    if (file.type && fileTypeArray.indexOf(file.type) > -1) {
      setFile(file);
    } else {
      setFile(null);
    }
  };

  // 파일 선택 취소
  const resetFile = () => {
    setFile(null);
  };

  // 파일 업로드
  const uploadFile = async () => {
    try {
      const formData = new FormData();
      formData.append('name', file.name);
      formData.append('file', file);
      formData.append('phishingNo', phishingNo);
      const response: any = await dispatch(phishingUploadImage(formData));
      if (!response?.data?.error) {
        const { imageName, imagePath, imageNo } = response.data.list;
        setImageList('upload', [...images, { imageName, imagePath, imageNo }]);
      }

      // 파일 선택되지 않은 상태로 초기화
      setFile(null);
    } catch (error) {
      console.log('PhishingPageSidebar uploadFile', error);
    }
  };

  // 업로드한 이미지 삭제
  const deleteFile = (imageNo: number) => {
    setImageList(
      'delete',
      images.filter((image: any) => image.imageNo !== imageNo),
    );
  };

  return (
    <div className="phishing-page-sidebar">
      {/* 공개 여부 */}
      {!!isAdmin && (
        <div className="sidebar-item">
          <div className="sidebar-item-title">
            {formatMessage({ id: 'Template_35', defaultMessage: '공개 여부' })}
          </div>
          <FormButtonGroup
            control={control}
            name="isPublic"
            error={errors.isPublic}
            validation={{
              validate: {
                required: (value: any) => valid.required(value === 0 ? '0' : value),
              },
            }}
            buttonList={[
              {
                visible: true,
                text: formatMessage({ id: 'Template_26', defaultMessage: '공개' }),
                value: 1,
              },
              {
                visible: true,
                text: formatMessage({ id: 'Template_39', defaultMessage: '비공개' }),
                value: 0,
              },
            ]}
            defaultValue={isAddMode ? null : formValues.isPublic}
            handleOnChange={(value: any) => changeFormValue('isPublic', value)}
          />
        </div>
      )}

      {/* 피싱 제목 */}
      <div className="sidebar-item">
        <div className="sidebar-item-title">
          {formatMessage({ id: 'Phishing_5', defaultMessage: '피싱 제목' })}
        </div>
        <FormTextField
          name="phishingName"
          error={errors.phishingName}
          // value={formValues.phishingName}
          register={register}
          placeholder={formatMessage({ id: 'Phishing_6', defaultMessage: '제목을 입력하세요.' })}
          validation={{
            validate: {
              required: (value: any) => valid.required(value),
              name: (value: any) => valid.name(value),
            },
          }}
          defaultValue={formValues.phishingName}
          // disabled={!serviceTemplateNo && !isAdmin}
          onChange={(e: any) => changeFormValue('phishingName', e.target.value)}
        />
      </div>

      {/* 결과 화면 사용 여부 */}
      <div className="sidebar-item">
        <div className="sidebar-item-title">
          {formatMessage({ id: 'Phishing_7', defaultMessage: '결과 화면 사용 여부' })}
        </div>
        <FormButtonGroup
          control={control}
          name="phishingScreenEnable"
          error={errors.phishingScreenEnable}
          buttonList={[
            {
              visible: true,
              text: formatMessage({ id: 'Phishing_8', defaultMessage: '사용' }),
              value: 1,
            },
            {
              visible: true,
              text: formatMessage({ id: 'Phishing_9', defaultMessage: '미사용' }),
              value: 0,
            },
          ]}
          defaultValue={isAddMode ? 1 : formValues.phishingScreenEnable}
          handleOnChange={(value: any) => changeFormValue('phishingScreenEnable', value)}
        />
      </div>

      {/* 피싱 단계 */}
      <div className="sidebar-item phishing-level">
        <div className="sidebar-item-title">
          {formatMessage({ id: 'Phishing_4', defaultMessage: '피싱 단계' })}

          <div className="step-setting">
            <div className="step-text">{phishingLevel}</div>
            <div className="step-buttons">
              <CaretUpOutlined onClick={() => changePhishingLevel('increase')} />
              <CaretDownOutlined onClick={() => changePhishingLevel('decrease')} />
            </div>
          </div>
        </div>
        {/* 피싱 단계 목록 */}
        <div
          className={`step-list-btn${currentPage === 0 ? ' active' : ''}`}
          onClick={() => changeCurrentPage(0)}
          aria-hidden="true"
        >
          {formatMessage({ id: 'Template_55', defaultMessage: '메일 본문' })}
        </div>
        {phishingLevel > 0 && (
          <div
            className={`step-list-btn${currentPage === 1 ? ' active' : ''}`}
            onClick={() => changeCurrentPage(1)}
            aria-hidden="true"
          >
            {`${formatMessage({ id: 'Phishing_3', defaultMessage: '단계' })} 1`}
          </div>
        )}
        {phishingLevel > 1 && (
          <div
            className={`step-list-btn${currentPage === 2 ? ' active' : ''}`}
            onClick={() => changeCurrentPage(2)}
            aria-hidden="true"
          >
            {`${formatMessage({ id: 'Phishing_3', defaultMessage: '단계' })} 2`}
          </div>
        )}
        {!!formValues.phishingScreenEnable && (
          <div
            className={`step-list-btn${currentPage === 3 ? ' active' : ''}`}
            onClick={() => changeCurrentPage(3)}
            aria-hidden="true"
          >
            {formatMessage({ id: 'Phishing_10', defaultMessage: '결과 화면' })}
          </div>
        )}
      </div>

      {/* 이미지 업로드 */}
      <div className="sidebar-item">
        <div className="sidebar-item-title image-upload">
          {formatMessage({ id: 'Phishing_11', defaultMessage: '이미지 업로드' })}
          <Tooltip
            overlayClassName="black-tooltip"
            placement="topLeft"
            title={formatMessage({
              id: 'Phishing_12',
              defaultMessage:
                "업로드된 이미지 클릭 시 나타나는 URL을 복사한 후 <img src='복사한 URL'> 형태로 사용",
            })}
          >
            <img src="/img/exam/startExam/btn_qmark.png" alt="icon" />
          </Tooltip>
        </div>

        <div className="image-file-upload-area">
          {/* 파일 선택 영역 */}
          <div className="file-input-area">
            {file ? (
              <label>{file.name}</label>
            ) : (
              <label className="upload-hint">
                {/* <img src={uploadImage} alt="upload" /> */}
                {formatMessage({
                  id: 'Phishing_13',
                  defaultMessage: '파일을 선택하세요.',
                })}
              </label>
            )}
            <input
              accept="image/bmp, image/jpg, image/jpeg, image/png, image/gif"
              type="file"
              onChange={(e: any) => selectFile(e)}
              onClick={(e: any) => (e.target.value = null)} // eslint-disable-line
            />
          </div>
        </div>
        {/* 취소, 업로드 버튼 */}
        {file && (
          <div className="upload-control-box">
            <button className="basic-btn" type="button" onClick={resetFile}>
              {formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
            </button>
            <button className="basic-btn" type="button" onClick={uploadFile}>
              {formatMessage({ id: 'Button_20', defaultMessage: '업로드' })}
            </button>
          </div>
        )}

        {/* 업로드한 이미지 목록 */}
        <div className="image-list">
          {images?.length > 0 &&
            images?.map((image: any, index: number) => {
              return (
                <div
                  key={image.imageNo}
                  className={`image-list-item${showImageUrl === index ? ' active' : ''}`}
                  onClick={() => setShowImageUrl(index)}
                  aria-hidden="true"
                >
                  <div className="item-btn">
                    {showImageUrl === index ? (
                      <input
                        type="text"
                        className="image-source"
                        value={image.imagePath}
                        onBlur={() => setShowImageUrl(-1)}
                        autoFocus // eslint-disable-line
                      />
                    ) : (
                      <div className="image-name">{image.imageName}</div>
                    )}
                  </div>
                  <button
                    type="button"
                    className="delete-btn"
                    onClick={() => deleteFile(image.imageNo)}
                  >
                    <img src="/img/exam/search_cancel.png" alt="delete" />
                  </button>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default PhishingPageSidebar;
