// IE 지원
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';
import { IntlProvider } from 'react-intl';
import { CookiesProvider } from 'react-cookie';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import rootReducer from 'store';

import 'antd/dist/antd.css';

// lang 폴더에 있는 json 파일들 취합하여 하나의 json으로 만듦
const langRequire = require.context('lang', false, /.json/);
const langJson: { [key: string]: any } = {};
langRequire.keys().forEach((key) => {
  const langKey = key.substring(2, key.length - 5);
  const langMessage = langRequire(key);
  langJson[langKey] = langMessage;
});

const localStorageLang = localStorage.getItem('language');
const browserLangCode =
  localStorageLang || (navigator.languages && navigator.languages[0]) || navigator.language;
const language = browserLangCode.toLowerCase().split(/[_-]+/)[0];
const messages = langJson[localStorageLang || language || browserLangCode || 'en'];

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(ReduxThunk)));

// localStorage에 언어 설정
// body에 언어 클래스 추가
if (localStorageLang) {
  document.body.classList.toggle(language, true);
} else if (language === 'ko' || language === 'ja') {
  // 한국 또는 일본일 때 각국의 언어로 기본 설정
  localStorage.setItem('language', language);
} else {
  // 기타 국가에서는 en으로 기본 설정
  localStorage.setItem('language', 'en');
  document.body.classList.toggle('en', true);
}

// 지원 브라우저 체크
const { userAgent } = navigator;
if (
  userAgent.includes('Firefox/') ||
  userAgent.includes('Edg/') ||
  userAgent.includes('Chrome/') ||
  userAgent.includes('Safari/') ||
  userAgent.includes('Trident/7.0')
) {
  // 지원하는 브라우저일 경우 정상 화면
} else {
  // 지원하지 않는 브라우저일 경우 브라우저 안내 화면
  window.location.href = `/browserVersion_${localStorage.getItem('language')}.html`;
}

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale={language} messages={messages}>
      <Provider store={store}>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </Provider>
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
