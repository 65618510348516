import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import parse from 'html-react-parser';
import * as valid from 'utils/validation';
import { setPassword } from 'store/edumain';
import FormTextField from 'components/common/FormTextField';

function SetPassword() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');
  const { register, handleSubmit, errors } = useForm({
    mode: 'all',
  });
  const edumainStore = useSelector((state: any) => {
    return {
      serviceNo: state.edumain.serviceNo,
      userData: state.edumain.userData,
      japanMode: state.edumain.japanMode,
    };
  }, shallowEqual);

  useEffect(() => {
    // form validation 표시
    if (Object.keys(errors)?.length > 0) {
      setErrorMessage(Object.values(errors)[0]?.message);
    }
  }, [Object.keys(errors)]);

  const onSetPassword = async (form: any) => {
    try {
      // 비밀번호 일치 여부 확인
      if (form.newPassword !== form.checkNewPassword) {
        setErrorMessage(
          formatMessage({ id: 'Password_15', defaultMessage: '비밀번호가 일치하지 않습니다.' }),
        );
      } else {
        const params = {
          eduTargetPw: form.newPassword,
        };
        const response: any = await dispatch(setPassword(params));
        if (response?.data?.list) {
          // 메인 화면으로 이동
          history.push(`/edumain/eduplay?serviceNo=${edumainStore.serviceNo}`);
        } else if (response?.data?.error && response.data.error[0]?.errInfo) {
          setErrorMessage(response.data.error[0].errInfo);
        }
      }
    } catch (error) {
      console.log('Setpassword onSetPassword', error);
    }
  };

  return (
    <div className="edu-layout">
      <div className="layout-left">
        <div className="layout-left-box find-password">
          {edumainStore.japanMode ? (
            <div className="edu-login-left">
              <div className="bg-effect">
                <div className="effect-1" />
                <div className="effect-2" />
                <div className="effect-3" />
              </div>
            </div>
          ) : (
            <img className="login-bg" src="/img/edu/title_bg.png" alt="bg" />
          )}
          <span className="login-text">
            {formatMessage({ id: 'Edu_76', defaultMessage: '비밀번호 설정하기' })}
          </span>
          <img className="arrow" src="/img/edu/login_arr.png" alt="arrow" />
        </div>
      </div>
      <div className="layout-right">
        <div className="r-logo">
          <img src="/img/logo/mf_logo.png" alt="logo" />
        </div>

        <div className="layout-right-box">
          <div className="edu-title">
            {parse(
              formatMessage({
                id: 'Edu_73',
                defaultMessage: '계정에 사용할 새로운 비밀번호를<br/>입력해 주시기 바랍니다.',
              }),
            )}
          </div>

          <div className="edu-form-box">
            <form
              autoComplete="off"
              onChange={() => setErrorMessage('')}
              onSubmit={handleSubmit(onSetPassword)}
            >
              <div className="text-field-title">
                {formatMessage({ id: 'Password_1', defaultMessage: '비밀번호' })}
              </div>
              <FormTextField
                name="newPassword"
                register={register}
                type="password"
                placeholder={formatMessage({
                  id: 'Edu_75',
                  defaultMessage: '영문, 숫자, 특수문자 조합 8-20자',
                })}
                validation={{
                  validate: {
                    required: (value: any) =>
                      !value
                        ? formatMessage({
                            id: 'Password_19',
                            defaultMessage: '비밀번호를 입력해 주세요.',
                          })
                        : undefined,
                    eduPasswd: (value: any) => valid.eduPasswd(value) !== undefined 
                    ? formatMessage(
                      { id: 'Vali_59', defaultMessage: '영문, 숫자, 특수문자({character})만 가능합니다.' },
                      { character: '!@#$%^*=+' },
                    )
                    : undefined,
                  },
                }}
              />

              <div className="text-field-title">
                {formatMessage({ id: 'Password_3', defaultMessage: '비밀번호 확인' })}
              </div>
              <FormTextField
                name="checkNewPassword"
                register={register}
                type="password"
                placeholder={formatMessage({
                  id: 'Edu_75',
                  defaultMessage: '영문, 숫자, 특수문자 조합 8-20자',
                })}
                validation={{
                  validate: {
                    required: (value: any) =>
                      !value
                        ? formatMessage({
                            id: 'Password_19',
                            defaultMessage: '비밀번호를 입력해 주세요.',
                          })
                        : undefined,
                  },
                }}
              />
              {!!errorMessage && (
                <div className="edu-error-message">
                  <img src="/img/edu/fail_login_i.jpg" alt="icon" />
                  {errorMessage}
                </div>
              )}

              <div className="edu-button-box setpassword">
                <button className="btn-point" type="submit">
                  {formatMessage({ id: 'Button_1', defaultMessage: '변경' })}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SetPassword;
