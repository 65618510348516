import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getServiceConfig, updateServiceConfig } from 'store/config';
import MainTitle from 'components/common/admin/MainTitle';
import ConfigTemplate from 'components/common/config/ConfigTemplate';
import '../mudmanage/Conf.scss';

export default function Notification(props: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const isAdmin = useSelector((state: any) => state.login.userData.isAdmin, shallowEqual);
  const notificationConfig = useSelector((state: any) => state.config.notificationConfig);

  useEffect(() => {
    dispatch(getServiceConfig({ category: 'notification' }));
  }, []);

  return (
    <div className="admin-basic-conf">
      <MainTitle
        className="small"
        title={formatMessage({ id: 'MyPage_9', defaultMessage: '알림설정' })}
      />
      <ConfigTemplate
        category="notificationConfig"
        updateConfig={updateServiceConfig}
        order={notificationConfig.classOrder || []}
        lists={notificationConfig.class || {}}
        isAdmin={isAdmin}
        styleConfig={[]}
      />
    </div>
  );
}
