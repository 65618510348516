import { useEffect } from 'react'
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import MainTitle from 'components/common/admin/MainTitle'
import { getServiceConfig, updateServiceConfig } from 'store/config'
import InitialConfig from 'components/common/config/InitialConfig'
import ConfigTemplate from 'components/common/config/ConfigTemplate'
import SmtpTest from 'components/common/config/SmtpTest'
import "../mudmanage/Conf.scss";

export default function Smtp(props: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const isAdmin = useSelector((state: any) => state.login.userData.isAdmin, shallowEqual)
  const smtpConfig = useSelector((state: any) => state.config.smtpConfig)

  // const smtpTestInfo = smtpConfig?.parameter?.mailSmtpTested

  useEffect(() => {
    dispatch(getServiceConfig({ category: "smtp" }))
  }, [])

  return (
    <div className="admin-basic-conf">
      <MainTitle className="small" title="SMTP">
        <InitialConfig category="smtp" />
      </MainTitle>
      <ConfigTemplate category="smtpConfig" updateConfig={updateServiceConfig} order={smtpConfig.classOrder || []} lists={smtpConfig.class || {}} isAdmin={isAdmin} />
      <SmtpTest isAdmin={isAdmin} />
    </div>
  );
}