import { useState } from 'react';
import Loading from 'components/common/Loading';
import ModalTemplate from 'components/common/ModalTemplate';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { deletePhishingPage } from 'store/phishing';
import { timeFormatFromUTCEpoch } from 'utils/commonFunctions';

function PhishingPageDelete({ visible, phishingInfo, toggleModal }: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { phishingNo, phishingName, isBasic, regEpoch } = phishingInfo;

  // 템플릿 삭제
  const onDeletePhishing = async () => {
    setLoading(true);
    try {
      const params: any = {
        phishingNo: phishingNo,
      };

      await dispatch(deletePhishingPage(params));
      setLoading(false);
      toggleModal(false);
    } catch (error) {
      console.log('PhishingPageDelete onDeletePhishing', error);
    }
  };

  return (
    <div>
      <ModalTemplate
        className="modal-464"
        visible={visible}
        title={
          <div className="modal-template-header">
            <div className="title">
              {formatMessage({ id: 'Phishing_26', defaultMessage: '피싱 페이지 삭제' })}
            </div>
          </div>
        }
        onOk={onDeletePhishing}
        onCancel={() => toggleModal(false)}
        okText={formatMessage({ id: 'Button_15', defaultMessage: '삭 제' })}
        cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
        loading={loading}
        greyButton
      >
        <Loading loading={loading} />

        <div className="modal-explain-text">
          <div>
            {formatMessage({
              id: 'Phishing_27',
              defaultMessage: '삭제한 피싱 페이지는 복구할 수 없습니다.',
            })}
          </div>
          <div>
            {formatMessage({
              id: 'Phishing_28',
              defaultMessage: '다음 피싱 페이지를 삭제합니까?',
            })}
          </div>
        </div>
        <div className="modal-border-box">
          <ul className="modal-item-list">
            <li>
              <div className="item-title">No</div>
            </li>
            <div>
              {isBasic
                ? `${phishingNo} (${formatMessage({
                    id: 'Template_71',
                    defaultMessage: '기본 제공',
                  })})`
                : phishingNo}
            </div>
          </ul>
          <ul className="modal-item-list">
            <li>
              <div className="item-title">
                {formatMessage({ id: 'Phishing_29', defaultMessage: '피싱 페이지명' })}
              </div>
            </li>
            <div>{phishingName}</div>
          </ul>
          <ul className="modal-item-list">
            <li>
              <div className="item-title">
                {formatMessage({ id: 'Date_3', defaultMessage: '등록일시' })}
              </div>
            </li>
            <div>{timeFormatFromUTCEpoch(regEpoch)}</div>
          </ul>
        </div>
      </ModalTemplate>
    </div>
  );
}

export default PhishingPageDelete;
