import { apiUrl, execUrl } from 'utils/commonValues';
import { actionFormat, getFollowOrderArray } from 'utils/commonFunctions';

// Action Types
const SET_INITIAL = 'quiz/SET_INITIAL';
const GET_QUIZ = 'quiz/GET_QUIZ';
const CHANGE_PARAM = 'quiz/CHANGE_PARAM';
const RELOAD_QUIZ = 'quiz/RELOAD_QUIZ';

// Actions
export const getQuiz = (params?: { [key: string]: any }) =>
  actionFormat(GET_QUIZ, params, 'get', `${apiUrl}Quiz`);
// 교육 퀴즈 등록
export const addEduQuiz = (params: { [key: string]: any }) =>
  actionFormat(RELOAD_QUIZ, params, 'post', `${apiUrl}Quiz`);
// 교육 퀴즈 수정
export const updateEduQuiz = (params: { [key: string]: any }) =>
  actionFormat(RELOAD_QUIZ, params, 'put', `${apiUrl}Quiz`);
// 교육 퀴즈 삭제
export const deleteEduQuiz = (params: { [key: string]: any }) =>
  actionFormat(RELOAD_QUIZ, params, 'delete', `${apiUrl}Quiz`);
export const changeParam = (params: { [key: string]: any }) =>
  actionFormat(CHANGE_PARAM, params, null, '');

// initial state
interface stateType {
  data: { [key: string]: any };
  param: { [key: string]: any };
  needReload: boolean;
  totalPages: number;
  totalCount: number;
}

const initialState: stateType = {
  data: {
    result: -1,
    success: [],
    fail: [],
  },
  param: {
    filter: {},
    sort: [{ field: 'regEpoch', order: 'DESC' }],
  },
  needReload: false,
  totalPages: 0,
  totalCount: 0,
};

// Reducers
export default function (state: { [key: string]: any } = initialState, action: any) {
  switch (action.type) {
    case SET_INITIAL:
      return initialState;
    case GET_QUIZ:
      if (action.payload) {
        const { data } = action.payload;
        const { offset, limit } = action.payload.config.params;

        // 전체 목록 수
        const totalCount = data.list.length > 0 ? data.list[0].rnum + offset : 0;
        // 전체 페이지 수
        const totalPages = Math.ceil(totalCount / limit);

        if (!data.error && data.list && Array.isArray(data.list)) {
          state.data = {
            result: 1,
            success: data.list,
            fail: [],
          };
        } else if (data.error && Array.isArray(data.error)) {
          state.data = {
            result: 0,
            success: [],
            fail: data.errror,
          };
        }

        state.needReload = false;
        state.totalPages = totalPages;
        state.totalCount = totalCount;
      }
      return state;
    case CHANGE_PARAM:
      if (action.payload) {
        const { name, value } = action.payload;
        state.param[name] = value;
      }
      return state;
    case RELOAD_QUIZ:
      if (action.payload) {
        state.needReload = true;
      }
      return state;
    default:
      return state;
  }
}
