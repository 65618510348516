import { apiUrl, execUrl } from 'utils/commonValues';
import { actionFormat, getFollowOrderArray } from 'utils/commonFunctions';

// Action Types
const SET_INITIAL = 'phishing/SET_INITIAL';
const GET_PHISHING = 'phishing/GET_PHISHING';
const SAVE_PHISHING = 'phishing/SAVE_PHISHING';
const CHANGE_PARAM = 'phishing/CHANGE_PARAM';
const GET_INFINITY_PHISHING = 'phishing/GET_INFINITY_PHISHING';

// Actions
export const getPhishing = (params?: { [key: string]: any }) =>
  actionFormat(GET_PHISHING, params, 'get', `${apiUrl}Template/Phishing`);
// 피싱 페이지 저장
export const savePhishingPage = (params: { [key: string]: any }) =>
  actionFormat(SAVE_PHISHING, params, 'put', `${apiUrl}Template/Phishing`);
// 피싱 페이지 삭제
export const deletePhishingPage = (params: { [key: string]: any }) =>
  actionFormat(SAVE_PHISHING, params, 'delete', `${apiUrl}Template/Phishing`);
// 피싱 페이지 복제
export const copyPhishingPage = (params: { [key: string]: any }) =>
  actionFormat(SAVE_PHISHING, params, 'post', `${apiUrl}Template/PhishingCopy`);
export const changeParam = (params: { [key: string]: any }) =>
  actionFormat(CHANGE_PARAM, params, null, '');
// 피싱페이지 번호 생성
export const makePhishingNo = () => actionFormat(null, null, 'post', `${apiUrl}Template/Phishing`);
// 피싱페이지 번호 삭제
export const deletePhishingNo = (params: any) =>
  actionFormat(null, params, 'delete', `${apiUrl}Template/PhishingInsertCancle`);
// 이미지 업로드
export const phishingUploadImage = (params: any) =>
  actionFormat(null, params, 'post', `${apiUrl}Template/PhishingUploadImage`, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
// 이미지 삭제
export const phishingDeleteImage = (params: any) =>
  actionFormat(null, params, 'delete', `${apiUrl}Template/PhishingUploadImage`);
// 피싱 데이터 조회(무한스크롤)
export const getInfinityPhishing = (params: { [key: string]: any }) =>
  actionFormat(GET_INFINITY_PHISHING, params, 'get', `${apiUrl}Template/Phishing`);

// initial state
interface stateType {
  data: { [key: string]: any };
  param: { [key: string]: any };
  needReload: boolean;
  totalPages: number;
  totalCount: number;
  infinity: any;
}

const initialState: stateType = {
  data: {
    result: -1,
    success: [],
    fail: [],
  },
  param: {
    filter: {},
    sort: [{ field: 'phishingNo', order: 'DESC' }],
  },
  needReload: false,
  totalPages: 0,
  totalCount: 0,

  infinity: {
    data: [],
    dataByPhishingNo: {},
    page: 0,
    totalPages: 0,
    totalCount: 0,
    param: {
      offset: 0,
      limit: 12,
    },
  },
};

// Reducers
export default function (state: { [key: string]: any } = initialState, action: any) {
  switch (action.type) {
    case SET_INITIAL:
      return initialState;
    case GET_PHISHING:
      if (action.payload) {
        const { data } = action.payload;
        const { offset, limit } = action.payload.config.params;

        // 전체 목록 수
        const totalCount = data.list.length > 0 ? data.list[0].rnum + offset : 0;
        // 전체 페이지 수
        const totalPages = Math.ceil(totalCount / limit);

        if (!data.error && data.list && Array.isArray(data.list)) {
          state.data = {
            result: 1,
            success: data.list,
            fail: [],
          };
        } else if (data.error && Array.isArray(data.error)) {
          state.data = {
            result: 0,
            success: [],
            fail: data.error,
          };
        }

        state.needReload = false;
        state.totalPages = totalPages;
        state.totalCount = totalCount;
      }
      return state;
    case GET_INFINITY_PHISHING:
      if (action.payload) {
        const { list } = action.payload.data;
        const { refresh, limit } = action.payload.config.params;

        let curPage = 1;
        let curDataObj: any = {};
        let curDataList = [];

        if (list?.length > 0) {
          if (refresh) {
            state.infinity.totalPages = Math.ceil(list[0].rnum / limit);
            state.infinity.totalCount = list[0].rnum;
            curDataList = list;
            list.forEach((item: any) => {
              curDataObj[item.phishingNo] = item;
            });
          } else {
            // 무한스크롤
            curPage = state.infinity.page + 1;
            curDataList = state.infinity.data.concat(list);
            list.forEach((item: any) => {
              curDataObj[item.phishingNo] = item;
            });
            curDataObj = { ...state.infinity.dataByPhishingNo, ...curDataObj };
          }
        }
        state.infinity.data = curDataList;
        state.infinity.dataByPhishingNo = curDataObj;
        state.infinity.page = curPage;
      }
      return state;
    case SAVE_PHISHING:
      if (action.payload) {
        state.needReload = true;
      }
      return state;
    case CHANGE_PARAM:
      if (action.payload) {
        const { name, value } = action.payload;
        state.param[name] = value;
      }
      return state;
    default:
      return state;
  }
}
