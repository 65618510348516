import { apiUrl, execUrl } from 'utils/commonValues';
import { actionFormat } from 'utils/commonFunctions';

// Action Types
const SET_INITIAL = 'declare/SET_INITIAL';
const GET_DECLARE = 'declare/GET_DECLARE';
const PUT_DECLARE = 'declare/PUT_DECLARE';
const DELETE_DECLARE = 'declare/DELETE_DECLARE';
const GET_DECLARE_MAIL = 'declare/GET_DECLARE_MAIL';
const GET_DECLARE_DETAIL = 'declare/GET_DECLARE_DETAIL';
const GET_EXAM = 'declare/GET_EXAM';
const CHANGE_STATE = 'declare/CHANGE_STATE';

// Actions
// 신고 리스트 조회
export const getDeclare = (params?: { [key: string]: any }) =>
  actionFormat(GET_DECLARE, params, 'get', `${apiUrl}Declare`);
export const putDeclare = (params: { [key: string]: any }) =>
  actionFormat(PUT_DECLARE, params, 'put', `${apiUrl}Declare`);
export const deleteDeclare = (params: { [key: string]: any }) =>
  actionFormat(DELETE_DECLARE, params, 'delete', `${apiUrl}Declare`);
export const getDeclareMail = (params?: { [key: string]: any }) =>
  actionFormat(GET_DECLARE_MAIL, params, 'get', `${apiUrl}Declare/External`);
export const getDeclareDetail = (params?: { [key: string]: any }) =>
  actionFormat(GET_DECLARE_DETAIL, params, 'get', `${apiUrl}Declare/Detail`);
export const getExam = (params?: { [key: string]: any }) =>
  actionFormat(GET_EXAM, params, 'get', `${apiUrl}Exam`);
export const changeState = (params: { [key: string]: any }) =>
  actionFormat(CHANGE_STATE, params, null, '');

// initial state
interface stateType {
  data: any;
  examData: any;
  dataMail: any;
  declareDetail: any;
  param: { [key: string]: any };
  needReload: boolean;
  tagReload: boolean;
  page: number;
  totalPages: number;
  totalCount: number;
  selectedNo: number;
}

const initialState: stateType = {
  data: [],
  dataMail: [],
  examData: [],
  declareDetail: [],
  param: {
    filter: {},
    sort: [{ field: 'declareNo', order: 'DESC' }],
    limit: 30,
  },
  needReload: false,
  tagReload: false,
  page: 1,
  totalPages: 0,
  totalCount: 0,
  selectedNo: 0,
};

// Reducers
export default function (state: { [key: string]: any } = initialState, action: any) {
  switch (action.type) {
    case SET_INITIAL:
      return initialState;
    case GET_DECLARE:
      if (action.payload) {
        const { data } = action.payload;
        const { offset, limit, refresh } = action.payload.config.params;

        // 전체 목록 수
        const totalCount = data.list.length > 0 ? data.list[0].rnum + offset : 0;
        // 전체 페이지 수
        const totalPages = Math.ceil(totalCount / limit);

        let curPage = 1;
        let curDataList: any = [];

        if (data.list?.length > 0) {
          if (refresh) {
            state.totalPages = totalPages;
            state.totalCount = totalCount;
            curDataList = data.list;
            state.tagReload = false;
          } else {
            // 무한스크롤
            curPage = state.page + 1;
            curDataList = state.data.concat(data.list);
          }
        } else {
          state.totalPages = 0;
          state.totalCount = 0;
        }

        state.data = curDataList;
        state.page = curPage;
        state.needReload = false;
        state.selectedNo = 0;
      }
      return state;
    case PUT_DECLARE:
      if (action.payload) {
        state.needReload = true;
      }
      return state;
    case DELETE_DECLARE:
      if (action.payload) {
        state.needReload = true;
      }
      return state;
    case GET_DECLARE_MAIL:
      if (action.payload) {
        const { data } = action.payload;
        const { offset, limit, refresh } = action.payload.config.params;

        // 전체 목록 수
        const totalCount = data.list.length > 0 ? data.list[0].rnum + offset : 0;
        // 전체 페이지 수
        const totalPages = Math.ceil(totalCount / limit);

        let curPage = 1;
        let curDataList: any = [];

        if (data.list?.length > 0) {
          if (refresh) {
            state.totalPages = totalPages;
            state.totalCount = totalCount;
            curDataList = data.list;
            state.tagReload = false;
          } else {
            // 무한스크롤
            curPage = state.page + 1;
            curDataList = state.data.concat(data.list);
          }
        } else {
          state.totalPages = 0;
          state.totalCount = 0;
        }

        state.dataMail = curDataList;
        state.page = curPage;
        state.needReload = false;
        state.selectedNo = 0;
      }
      return state;
    case GET_EXAM:
      if (action.payload) {
        const { list } = action.payload.data;
        state.examData = list;
      }
      return state;
    case GET_DECLARE_DETAIL:
      if (action.payload) {
        const { list } = action.payload.data;
        state.declareDetail = list;
      }
      return state;
    case CHANGE_STATE:
      if (action.payload) {
        const { key, value } = action.payload;
        state[key] = value;
      }
      return state;
    default:
      return state;
  }
}
