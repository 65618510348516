import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { Tooltip } from 'antd';
import { GoldFilled } from '@ant-design/icons';
import {
  getTag,
  changeTagState,
  putTag,
  addTag,
  deleteTag,
  deleteAllTag,
  checkTag,
  setInitial,
} from 'store/tag';
import { initForm } from 'store/form';
import * as valid from 'utils/validation';
import { Option } from 'utils/commonValues';
import { getInitialTag, openNotification, timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import ModalTemplate from 'components/common/ModalTemplate';
import FormTextField from 'components/common/FormTextField';
import TagItem from 'components/branch/tag/TagItem';
import TagColor from 'components/branch/tag/TagColor';
import Loading from 'components/common/Loading';
import './Tag.scss';

// import icoSearchImage from 'img/tag/ico_search.png';
// import tagOrImage from 'img/tag/ico_tag_or.png';
// import tagAndImage from 'img/tag/ico_tag_and.png';
// import addImage from 'img/tag/ico_make.png';
// import editImage from 'img/tag/ico_modify.png';
// import closeImage from 'img/tag/ico_close.png';
// import openImage from 'img/tag/ico_open.png';
// import searchImage from 'img/exam/search.png';
// import cancelImage from 'img/exam/search_cancel.png';

function TagSidebar({ type }: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  // 선택한 옵션 버튼
  const [selectBtn, setSelectBtn] = useState('');
  // 모달 대상
  const [openModal, setOpenModal] = useState('');
  // 검색어 필터
  const [searchText, setSearchText] = useState('');
  const [editData, setEditData]: any = useState({});
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState('red');
  const tagData = useSelector((state: any) => {
    return {
      data: state.tag.data,
      tagInitialData: state.tag.tagInitialData,
      isAllOpen: state.tag.isAllOpen,
      openList: state.tag.openList,
      dataByTagNo: state.tag.dataByTagNo,
      param: state.tag.param,
      selectedTag: state.tag.selectedTag,
      needReload: state.tag.needReload,
      multiType: state.tag.multiType,
      treeView: state.tag.treeView,
    };
  }, shallowEqual);
  const tagReload = useSelector((state: any) => state.target.tagReload);
  const { errors, register, getValues, handleSubmit, setError } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    // tagtree 옵션 있을 시 기본으로 treeView 및 and 조건 활성화
    dispatch(changeTagState({ key: 'treeView', value: true }));
    setSelectBtn(Option.isTagTree ? 'and' : '');
    dispatch(
      changeTagState({
        key: 'multiType',
        value: Option.isTagTree ? 'and' : tagData.multiType,
      }),
    );
    // 대상관리 페이지 벗어날 시 데이터 초기화
    return () => {
      dispatch(setInitial());
    };
  }, []);

  useEffect(() => {
    onGetTag();
  }, [tagData.param, tagData.needReload, tagReload]);

  // 태그 정보 조회
  const onGetTag = async () => {
    setLoading(true);
    try {
      const params: any = { filter: tagData.param.filter };
      await dispatch(getTag(params));
      setLoading(false);
    } catch (error) {
      console.log('TagSidebar onGetTag', error);
    }
  };

  // 태그 버튼 클릭 이벤트(검색, 조건 선택, 추가, 편집)
  const clickHeaderBtn = (type: string) => {
    setSelectBtn(selectBtn === type ? '' : type);

    if (type === 'or' || type === 'and') {
      dispatch(
        changeTagState({ key: 'multiType', value: tagData.multiType === type ? 'or' : type }),
      );

      const newTag = selectBtn === type ? tagData.selectedTag.pop() : { tagNo: 'all-tag', tagLevel: 0 };
      dispatch(changeTagState({ key: 'selectedTag', value: [newTag] }));

    } else if (type === 'edit') {
      setSelectBtn(selectBtn === type ? tagData.multiType : type);
    }
  };

  // 트리 구조로 보기
  const selectTreeView = async () => {
    setLoading(true);
    await dispatch(changeTagState({ key: 'treeView', value: !tagData.treeView }));
    await onGetTag();
    setLoading(false);
  };

  // 태그를 여러개 선택(and, or) 버튼 활성화 된 상태에서 태그 선택 시 이벤트
  const selectMultiTag = (tag: number | string) => {
    const { dataByTagNo, selectedTag, treeView } = tagData;
    const selectAllTag = selectedTag[0].tagNo === 'all-tag' || tag === 'all-tag';
    const selectNoTag = selectedTag[0].tagNo === 'no-tag' || tag === 'no-tag';
    let selectedList = selectAllTag || selectNoTag ? [] : [...selectedTag];
    const isExist = selectedList.some(item => item.tagNo === tag);

    // 여러 태그 선택 버튼 클릭한 상태에서 항목 클릭 시
    if (isExist) {
      selectedList = selectedList.filter((item: any) => item.tagNo !== tag);

      // 기타 태그를 제외한 나머지 태그 트리 비교하여 초기화
      if (treeView && dataByTagNo[tag]?.ldapLevel !== null) {
        selectedList = selectedList.filter((item: any) => item.tagLevel <= dataByTagNo[tag]?.ldapLevel);
      }

      // 선택한 태그가 하나도 없을 시 모든 대상자로 지정
      if (selectedList.length < 1) {
        selectedList = [{ tagNo: 'all-tag', tagLevel: 0 }];
      }
      return selectedList;
    }

    // 모든 대상자 또는 태그가 없는 대상자가 선택된 상태에서 태그 클릭 시 리셋된 데이터에 넣어줌
    selectedList.push({ tagNo: tag, tagLevel: dataByTagNo[tag]?.ldapLevel });
    return selectedList;
  };

  // 태그 선택
  const clickTag = async (tag: number | string) => {
    const { dataByTagNo } = tagData;
    // 단일 태그 선택 시
    let selectTagList = [{ tagNo: tag, tagLevel: dataByTagNo[tag]?.ldapLevel }];
    // 멀티 태그 선택 시
    if (selectBtn === 'or' || selectBtn === 'and') {
      selectTagList = selectMultiTag(tag);
    }

    await dispatch(changeTagState({ key: 'selectedTag', value: selectTagList }));
  };

  // 태그 목록 열기/닫기
  const toggleOpenList = (tagName: string) => {
    let openTagList = [...tagData.openList];

    if (tagName === 'all') {
      openTagList = tagData.isAllOpen ? [] : Object.keys(tagData.tagInitialData);
      dispatch(changeTagState({ key: 'isAllOpen', value: !tagData.isAllOpen }));
    }

    if (openTagList.includes(tagName)) {
      openTagList = openTagList.filter((tag: any) => tag !== tagName);
    } else {
      openTagList.push(tagName);
    }

    dispatch(changeTagState({ key: 'openList', value: openTagList }));
  };

  // 필터 검색
  const handleSearch = () => {
    if (searchText) {
      tagData.param.filter.tagName = searchText;
    } else {
      // 검색어 없을 시 tagName 파라미터 제거
      delete tagData.param.filter.tagName;
    }

    dispatch(changeTagState({ key: 'param', value: { filter: tagData.param.filter } }));
  };

  // 필터 검색 취소
  const handleCancelSearch = () => {
    setSearchText('');
    delete tagData.param.filter.tagName;
    dispatch(changeTagState({ key: 'param', value: { filter: tagData.param.filter } }));
  };

  // 태그명 수정
  const onEditTagName = async (tagName: any) => {
    // 변경 사항 없을 경우 리턴
    if (tagName === editData.tagName) return;

    try {
      const params = {
        tagNo: editData.tagNo,
        tagName: tagName,
        tagInitial: getInitialTag(tagName),
        beforeTagInitial: getInitialTag(editData.tagName),
      };
      const response: any = await dispatch(putTag(params));
      if (response?.data) {
        openNotification(
          `'${editData.tagName}'${formatMessage({
            id: 'Tag_48',
            defaultMessage: '에서',
          })} '${tagName}'${formatMessage({ id: 'Tag_49', defaultMessage: '로 수정' })}`,
        );
        setEditData({});
      }
    } catch (error) {
      console.log('TagSidebar onEditTagName', error);
    }
  };

  // 태그 색상 수정
  const onEditColor = async (color: string) => {
    try {
      const params = {
        tagNo: editData.tagNo,
        color: color,
      };
      const response: any = await dispatch(putTag(params));
      if (response?.data) {
        openNotification(
          `'${editData.tagName}' ${formatMessage({
            id: 'Tag_5',
            defaultMessage: '태그 색상 수정',
          })}`,
        );
        setEditData({});
      }
    } catch (error) {
      console.log('TagSidebar onEditColor', error);
    }
    setEditData({ ...editData, color: color });
  };

  // 모달 닫기(초기화)
  const onCloseModal = () => {
    setOpenModal('');
    dispatch(initForm());
  };

  // 태그 만들기
  const onAddTag = async () => {
    const { tagName } = getValues();

    try {
      // 태그명 중복체크
      const res: any = await dispatch(checkTag({ filter: { tagNameExact: tagName } }));
      if (res?.data?.list?.length > 0) {
        setError('tagName', {
          type: 'duplicate',
          message: formatMessage({ id: 'Tag_56', defaultMessage: '이미 등록된 태그명입니다.' }),
        });
      } else {
        // 태그 이니셜 추출 가능 여부 확인
        const initial = getInitialTag(tagName);
        if (!initial) {
          setError('tagName', {
            type: 'notAllowed',
            message: formatMessage({
              id: 'Tag_53',
              defaultMessage: '입력 값(태그)이 올바르지 않습니다.',
            }),
          });

          return;
        }

        const params = {
          tagName: tagName,
          tagInitial: initial,
          color: color,
        };
        const response: any = await dispatch(addTag(params));
        if (response?.data?.error?.length > 0) {
          setError('tagName', {
            type: 'notAllowed',
            message: formatMessage({
              id: 'Tag_53',
              defaultMessage: '입력 값(태그)이 올바르지 않습니다.',
            }),
          });
        } else {
          openNotification(
            `'${tagName}' ${formatMessage({ id: 'Tag_6', defaultMessage: '태그 등록' })}`,
          );
          onCloseModal();
          setColor('red');
        }
      }
    } catch (error) {
      console.log('TagSidebar onAddTag', error);
    }
  };

  // 태그 삭제
  const onDeleteTag = async () => {
    try {
      const params = {
        tagNo: editData.tagNo,
      };
      const response: any = await dispatch(deleteTag(params));
      if (response?.data) {
        dispatch(changeTagState({ key: 'selectedTag', value: [{ tagNo: 'all-tag', tagLevel: 0 }] }));
        openNotification(
          `'${editData.tagName}' ${formatMessage({ id: 'Tag_7', defaultMessage: '태그 삭제' })}`,
        );
        onCloseModal();
      }
    } catch (error) {
      console.log('TagSidebar onDeleteTag', error);
    }
  };

  // 모든 태그 삭제
  const onDeleteAllTag = async () => {
    try {
      const response: any = await dispatch(deleteAllTag());
      if (response?.data) {
        openNotification(formatMessage({ id: 'Tag_7', defaultMessage: '태그 삭제' }));
        onCloseModal();
      }
    } catch (error) {
      console.log('TagSidebar onDeleteAllTag', error);
    }
  };

  const tagComponent = useMemo(() => {
    return !!Option.isTagTree && tagData.treeView
      ? // 트리 구조로 출력
        Object.keys(tagData.tagInitialData)?.map((ldapLevel: string) => {
          const groupName: any = {
            '0': formatMessage({ id: 'Tag_57', defaultMessage: '최상위 태그' }),
            '1': formatMessage({ id: 'Tag_58', defaultMessage: '상위 태그' }),
            '2': formatMessage({ id: 'Tag_59', defaultMessage: '관련 태그' }),
            null: formatMessage({ id: 'Tag_60', defaultMessage: '기타 태그' }),
          };

          let groupData = [];
          if (ldapLevel === '0' || ldapLevel === 'null' || !selectBtn) {
            // 최상위 태그와 기타 태그는 항상 보이도록
            groupData = tagData.tagInitialData[ldapLevel];
          } else {
            // 상위 태그와 관련 태그는 트리 구조의 상단 태그 선택 시 보이도록
            const lowTags: any = [];
            tagData.data.forEach((item: any) => {
              if (item.ldapLevel === parseInt(ldapLevel, 10) - 1) {
                tagData.selectedTag.forEach((tagItem: any) => {
                  if (tagItem.tagNo === item.tagNo && item.lowTagList) {
                    lowTags.push(...item.lowTagList);
                  }
                });
              }
            });
            // 선택한 태그들의 하위 리스트 모두 보이도록
            groupData = tagData.tagInitialData[ldapLevel].filter((data: any) =>
              lowTags?.some((tagNo: any) => tagNo === data.tagNo),
            );
          }

          return (
            <TagItem
              key={ldapLevel}
              type={type}
              tagInitial={ldapLevel}
              tagInitialText={groupName[ldapLevel]}
              tagData={groupData} // {tagData.tagInitialData[ldapLevel]}
              isOpen={tagData.openList.includes(ldapLevel)}
              isEditMode={selectBtn === 'edit'}
              selectedTag={tagData.selectedTag}
              editData={editData}
              setEditData={setEditData}
              toggleOpenList={toggleOpenList}
              clickTag={clickTag}
              changeTagName={onEditTagName}
              changeTagColor={onEditColor}
              setModal={setOpenModal}
            />
          );
        })
      : // 기본 구조로 출력
        Object.keys(tagData.tagInitialData).map((tagInitial: any) => {
          return (
            <TagItem
              key={tagInitial}
              type={type}
              tagInitial={tagInitial}
              tagData={tagData.tagInitialData[tagInitial]}
              isOpen={tagData.openList.includes(tagInitial)}
              isEditMode={selectBtn === 'edit'}
              selectedTag={tagData.selectedTag}
              editData={editData}
              setEditData={setEditData}
              toggleOpenList={toggleOpenList}
              clickTag={clickTag}
              changeTagName={onEditTagName}
              changeTagColor={onEditColor}
              setModal={setOpenModal}
            />
          );
        });
  }, [JSON.stringify(tagData), JSON.stringify(editData), selectBtn]);

  const tagBtnShowAllTarget = (
    <div
      className={`tag-list-btn ${
        tagData.selectedTag?.some((item: any) => item.tagNo === 'all-tag') ? 'selected' : ''
      }`}
      onClick={() => clickTag('all-tag')}
      aria-hidden="true"
    >
      {formatMessage({ id: 'Tag_42', defaultMessage: '모든 대상자' })}
    </div>
  );

  const tagBtnShowNoTagTarget = (
    <div
      className={`tag-list-btn ${
        tagData.selectedTag?.some((item: any) => item.tagNo === 'no-tag') ? 'selected' : ''
      }`}
      onClick={() => clickTag('no-tag')}
      aria-hidden="true"
    >
      {formatMessage({ id: 'Tag_43', defaultMessage: '태그가 없는 대상자' })}
    </div>
  );
  const tagBtnDeleteAllTag = (
    <div
      className="tag-list-btn delete"
      onClick={() => setOpenModal('deleteAll')}
      aria-hidden="true"
    >
      {formatMessage({ id: 'Tag_44', defaultMessage: '모든 태그 삭제' })}
    </div>
  );

  const tagListView = (
    <div className={`tag-inner ${selectBtn === 'search' ? 'large' : ''}`}>
      {tagBtnShowAllTarget}
      {/* isJapan의 경우 '태그가 없는 대상자' 버튼은 아래로 아닌경우 상단으로 위치 하도록 함. */}
      {!Option.isJapan ? (
        <div>
          {tagBtnShowNoTagTarget}
          {tagComponent}
        </div>
      ) : (
        <div>
          {tagComponent}
          {tagBtnShowNoTagTarget}
        </div>
      )}
      {tagBtnDeleteAllTag}
    </div>
  );
  
  return (
    <div className="summary-list tag-sidebar">
      <Loading loading={loading} darkMode />
      <div className="tag-header">
        <div className="bold">{formatMessage({ id: 'Tag_1', defaultMessage: '태그' })}</div>
        <div className="tag-header-btns">
          <Tooltip
            overlayClassName="black-tooltip"
            placement="bottom"
            title={formatMessage({ id: 'Filter_8', defaultMessage: '검색' })}
          >
            <img
              src="/img/tag/ico_search.png"
              alt="search"
              className={`header-icon ${selectBtn === 'search' ? 'selected' : ''}`}
              onClick={() => clickHeaderBtn('search')}
              aria-hidden="true"
            />
          </Tooltip>
          <Tooltip
            overlayClassName="black-tooltip"
            placement="bottom"
            title={formatMessage({ id: 'Tag_33', defaultMessage: '태그를 여러개 선택(OR 조건)' })}
          >
            <img
              src="/img/tag/ico_tag_or.png"
              alt="or"
              className={`header-icon ${selectBtn === 'or' ? 'selected' : ''}`}
              onClick={() => clickHeaderBtn('or')}
              aria-hidden="true"
            />
          </Tooltip>

          {type !== 'declare' && (
            <Tooltip
              overlayClassName="black-tooltip"
              placement="bottom"
              title={formatMessage({
                id: 'Tag_34',
                defaultMessage: '태그를 여러개 선택(AND 조건)',
              })}
            >
              <img
                src="/img/tag/ico_tag_and.png"
                alt="and"
                className={`header-icon ${selectBtn === 'and' ? 'selected' : ''}`}
                onClick={() => clickHeaderBtn('and')}
                aria-hidden="true"
              />
            </Tooltip>
          )}

          <Tooltip
            overlayClassName="black-tooltip"
            placement="bottom"
            title={formatMessage({ id: 'Tag_4', defaultMessage: '태그 만들기' })}
          >
            <img
              src="/img/tag/ico_make.png"
              alt="add"
              className={`header-icon ${selectBtn === 'add' ? 'selected' : ''}`}
              onClick={() => setOpenModal('add')}
              aria-hidden="true"
            />
          </Tooltip>

          <Tooltip
            overlayClassName="black-tooltip"
            placement="bottom"
            title={formatMessage({ id: 'Tag_45', defaultMessage: '편집' })}
          >
            <img
              src="/img/tag/ico_modify.png"
              alt="edit"
              className={`header-icon ${selectBtn === 'edit' ? 'selected' : ''}`}
              onClick={() => clickHeaderBtn('edit')}
              aria-hidden="true"
            />
          </Tooltip>

          {!!Option.isTagTree && (
            <Tooltip
              overlayClassName="black-tooltip"
              placement="bottom"
              title={formatMessage({ id: 'Tag_61', defaultMessage: '트리 구조로 보기' })}
            >
              <GoldFilled
                className={`header-icon tree-view ${tagData.treeView ? 'selected' : ''}`}
                style={{ color: '#a0a0a0' }}
                onClick={selectTreeView}
              />
            </Tooltip>
          )}

          <Tooltip
            overlayClassName="black-tooltip"
            placement="bottom"
            title={
              tagData.isAllOpen
                ? formatMessage({ id: 'Exam_3', defaultMessage: '전체 닫기' })
                : formatMessage({ id: 'Exam_4', defaultMessage: '전체 열기' })
            }
          >
            <img
              src={tagData.isAllOpen ? '/img/tag/ico_close.png' : '/img/tag/ico_open.png'}
              alt="arrow"
              className="header-icon"
              onClick={() => toggleOpenList('all')}
              aria-hidden="true"
            />
          </Tooltip>
        </div>
      </div>
      {/* 검색 영역 */}
      {selectBtn === 'search' && (
        <div className="tag-search">
          <div className="summary-search-item active">
            <div className="summary-search-text">
              <input
                type="text"
                value={searchText}
                onChange={(e: any) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleSearch();
                }}
                placeholder={formatMessage({ id: 'Filter_8', defaultMessage: '검색' })}
              />
              <div className="summary-search-btns">
                <img
                  className="search-btn"
                  src="/img/exam/search.png"
                  alt="search"
                  onClick={handleSearch}
                  aria-hidden="true"
                  title={formatMessage({ id: 'Filter_8', defaultMessage: '검색' })}
                />
                <img
                  className="search-btn"
                  src="/img/exam/search_cancel.png"
                  alt="cancel"
                  onClick={handleCancelSearch}
                  aria-hidden="true"
                  title={formatMessage({ id: 'Button_2', defaultMessage: '취소' })}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {/* 태그 리스트 */}
      {tagListView}

      {/* 태그 만들기 모달 */}
      {openModal === 'add' && (
        <ModalTemplate
          visible={openModal === 'add'}
          className="modal-464"
          onOk={handleSubmit(onAddTag)}
          onCancel={onCloseModal}
          okText={formatMessage({ id: 'Button_16', defaultMessage: '생 성' })}
          cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
          loading={loading}
          title={formatMessage({ id: 'Tag_4', defaultMessage: '태그 만들기' })}
        >
          <form autoComplete="off" onSubmit={(e: any) => e.preventDefault()}>
            <div className="input-title">
              {formatMessage({ id: 'Tag_2', defaultMessage: '태그명' })}
            </div>
            <FormTextField
              name="tagName"
              error={errors.tagName}
              register={register}
              validation={{
                validate: {
                  required: (value: any) => valid.required(value),
                  name: (value: any) => valid.name(value),
                },
              }}
              arrowPosition="top"
            />

            <TagColor color={color} onSelectColor={setColor} className="tag-add-full" />
          </form>
        </ModalTemplate>
      )}

      {/* 태그 삭제 모달 */}
      {openModal === 'delete' && (
        <ModalTemplate
          className="modal-464"
          visible={openModal === 'delete'}
          title={formatMessage({ id: 'Tag_7', defaultMessage: '태그 삭제' })}
          onOk={onDeleteTag}
          onCancel={onCloseModal}
          okText={formatMessage({ id: 'Button_15', defaultMessage: '삭 제' })}
          cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
          loading={loading}
          greyButton
        >
          <div className="modal-explain-text">
            <div>
              {formatMessage({ id: 'Tag_35', defaultMessage: '삭제한 태그는 복구할 수 없습니다.' })}
            </div>
            <div>{formatMessage({ id: 'Tag_50', defaultMessage: '다음 태그를 삭제합니까?' })}</div>
          </div>
          <div className="modal-border-box">
            <ul className="modal-item-list">
              <li>
                <div className="item-title">No</div>
              </li>
              <div>{editData.tagNo}</div>
            </ul>
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'Tag_2', defaultMessage: '태그명' })}
                </div>
              </li>
              <div className="ellipsis">{editData.tagName}</div>
            </ul>
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'Date_3', defaultMessage: '등록일시' })}
                </div>
              </li>
              <div>{timeFormatFromUTCEpoch(editData.regEpoch)}</div>
            </ul>
          </div>
        </ModalTemplate>
      )}

      {/* 모든 태그 삭제 모달 */}
      {openModal === 'deleteAll' && (
        <ModalTemplate
          className="modal-464"
          visible={openModal === 'deleteAll'}
          title={formatMessage({ id: 'Tag_44', defaultMessage: '모든 태그 삭제' })}
          onOk={onDeleteAllTag}
          onCancel={onCloseModal}
          okText={formatMessage({ id: 'Button_15', defaultMessage: '삭 제' })}
          cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
          loading={loading}
          greyButton
        >
          <div className="modal-explain-text">
            <div>
              {formatMessage({ id: 'Tag_35', defaultMessage: '삭제한 태그는 복구할 수 없습니다.' })}
            </div>
            <div>{formatMessage({ id: 'Tag_23', defaultMessage: '모든 태그를 삭제합니까?' })}</div>
          </div>
          <div className="modal-border-box">
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'Tag_3', defaultMessage: '태그 수' })}
                </div>
              </li>
              <div>{`${tagData.data.length}${formatMessage({
                id: 'StartExam_21',
                defaultMessage: '개',
              })}`}</div>
            </ul>
          </div>
        </ModalTemplate>
      )}
    </div>
  );
}

export default TagSidebar;
