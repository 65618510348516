import { Controller } from 'react-hook-form';
import { DatePicker } from 'antd';
import './FormDatePicker.scss';

import moment from 'moment';
import 'moment/locale/ko';
import 'moment/locale/ja';

const localData: any = localStorage.getItem('language');
moment.locale(localData);

function FormDatePicker(props: any) {
  const {
    control,
    name,
    value,
    defaultValue,
    className,
    error,
    validation,
    handleOnChange,
    handleDisableDate,
    arrowPosition,
    helperText,
    disabled,
    extraRight,
    dropdownClassName,
    renderExtraFooter,
    open,
    onOpenChange,
    format,
  } = props;

  return (
    <div className="date-picker-box">
      <Controller
        control={control}
        name={name}
        rules={validation}
        // defaultValue={value}
        defaultValue={defaultValue || null}
        render={({ onChange, onBlur }) => (
          <DatePicker
            {...props} // eslint-disable-line
            className={`date-picker ${error && 'error'} ${className || ''} ${
              disabled ? 'disabled' : ''
            }`}
            value={value}
            onChange={(date: any) => {
              onChange(date);
              handleOnChange(name, date);
            }}
            disabledDate={(current: any) => handleDisableDate(name, current)}
            allowClear={false}
            inputReadOnly
            format={format || 'YYYY-MM-DD'}
          />
        )}
      />
      {!!error && <div className={`arrow-box ${arrowPosition}`}>{error?.message}</div>}

      <div className="extra-area">
        <div className="date-detail-text">{helperText}</div>
        {extraRight && <div className="right">{extraRight}</div>}
      </div>
    </div>
  );
}

export default FormDatePicker;
