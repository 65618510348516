import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { copyAttach } from 'store/attach';
import { timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import * as valid from 'utils/validation';
import FormTextField from 'components/common/FormTextField';
import Loading from 'components/common/Loading';
import ModalTemplate from 'components/common/ModalTemplate';

function AttachCopy({ visible, isAdmin, dataInfo, toggleModal }: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, setError, errors } = useForm({
    mode: 'onChange',
  });

  const { attachInfoNo, attachSubject, regEpoch } = dataInfo;

  // 첨부파일 복제
  const onCopyAttach = async (formValues: any) => {
    setLoading(true);
    try {
      const params: any = {
        attachInfoNo: attachInfoNo,
        attachSubject: formValues.attachSubject,
        serviceNo: parseInt(formValues.serviceNo, 10),
      };

      const response: any = await dispatch(copyAttach(params));
      if (response?.data?.error) {
        if (response?.data?.error && response.data?.error[0].errInfo) {
          if (response.data.error[0].errInfo === 'Invalid ServiceNo') {
            setError('serviceNo', {
              type: 'wrong',
              message: formatMessage({
                id: 'Template_75',
                defaultMessage: '잘못된 서비스 번호입니다.',
              }),
            });
          } else {
            setError('attachSubject', { type: 'wrong', message: response.data.error[0].errInfo });
          }
        }
        setLoading(false);
      } else {
        setLoading(false);
        toggleModal(false);
        // 새로고침 필요
      }
    } catch (error) {
      console.log('AttachCopy onCopyAttach', error);
    }
  };

  return (
    <div>
      <ModalTemplate
        className="modal-464"
        visible={visible}
        title={
          <div className="modal-template-header">
            <div className="title">
              {formatMessage({ id: 'Attach_38', defaultMessage: '첨부파일 복제' })}
            </div>
          </div>
        }
        onOk={handleSubmit(onCopyAttach)}
        onCancel={() => toggleModal(false)}
        okText={formatMessage({ id: 'Button_23', defaultMessage: '복 제' })}
        cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
        loading={loading}
        greyButton
      >
        <Loading loading={loading} />

        <div className="modal-explain-text">
          {formatMessage({
            id: 'Attach_43',
            defaultMessage: '첨부파일을 복제하여 새로 생성합니다.',
          })}
          <br />
          {formatMessage({
            id: 'Attach_44',
            defaultMessage: '다음 첨부파일을 복제합니까?',
          })}
        </div>
        <div className="modal-border-box">
          <ul className="modal-item-list">
            <li>
              <div className="item-title">No</div>
            </li>
            <div>{attachInfoNo}</div>
          </ul>
          <ul className="modal-item-list">
            <li>
              <div className="item-title">
                {formatMessage({ id: 'Email_23', defaultMessage: '제목' })}
              </div>
            </li>
            <div>{attachSubject}</div>
          </ul>
          <ul className="modal-item-list">
            <li>
              <div className="item-title">
                {formatMessage({ id: 'Date_3', defaultMessage: '등록일시' })}
              </div>
            </li>
            <div>{timeFormatFromUTCEpoch(regEpoch)}</div>
          </ul>

          <div className="service-no-area">
            <form autoComplete="off" onSubmit={(e: any) => e.preventDefault()}>
              {/* 첨부파일 제목 */}
              <div className="text-field-title">
                {formatMessage({ id: 'Attach_45', defaultMessage: '첨부파일 제목' })}
              </div>
              <FormTextField
                name="attachSubject"
                error={errors.attachSubject}
                register={register}
                arrowPosition="top"
                validation={{
                  validate: {
                    blank: (value: any) => valid.blank(value),
                    required: (value: any) => valid.required(value),
                  },
                }}
              />

              {/* 대상 서비스 번호 */}
              <div className="text-field-title">
                {formatMessage({ id: 'Service_5', defaultMessage: '대상 서비스 번호' })}
              </div>
              <FormTextField
                name="serviceNo"
                error={errors.serviceNo}
                register={register}
                arrowPosition="top"
                validation={{
                  validate: {
                    blank: (value: any) => valid.blank(value),
                    number: (value: any) => valid.number(value),
                    required: (value: any) => valid.required(value),
                  },
                }}
              />
            </form>
          </div>
        </div>
      </ModalTemplate>
    </div>
  );
}

export default AttachCopy;
