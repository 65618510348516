import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import Loading from 'components/common/Loading';
import ModalTemplate from 'components/common/ModalTemplate';

interface DeleteModalProps {
  visible: boolean;
  params: any;
  modalData: { title: string; contents: any; list: Array<{ key: string; value: any }> };
  toggleModal: (open: boolean) => void;
  dispatchFunc: (params: any) => void;
}

function DeleteModal({ visible, params, modalData, toggleModal, dispatchFunc }: DeleteModalProps) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // 삭제
  const onDelete = async () => {
    setLoading(true);
    try {
      await dispatch(dispatchFunc(params));
      setLoading(false);
      toggleModal(false);
    } catch (error) {
      console.log('DeleteModal', error);
    }
  };

  return (
    <ModalTemplate
      className="modal-464"
      visible={visible}
      title={
        <div className="modal-template-header">
          <div className="title">{modalData.title}</div>
        </div>
      }
      onOk={onDelete}
      onCancel={() => toggleModal(false)}
      okText={formatMessage({ id: 'Button_15', defaultMessage: '삭 제' })}
      cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
      loading={loading}
      greyButton
    >
      <Loading loading={loading} />

      <div className="modal-explain-text">{modalData.contents}</div>
      <div className="modal-border-box">
        {modalData.list.map((list: any) => {
          return (
            <ul className="modal-item-list" key={list.key}>
              <li>
                <div className="item-title">{list.key}</div>
              </li>
              <div>{list.value}</div>
            </ul>
          );
        })}
      </div>
    </ModalTemplate>
  );
}

export default DeleteModal;
