import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Legend, LineChart, ResponsiveContainer, XAxis, YAxis, Tooltip as ChartTooltip, Line, CartesianGrid } from 'recharts';
import { getDashboardStatistics } from 'store/dashboard';

function DashboardYears() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [yearsData, setYearsData]: any = useState({});

  useEffect(() => {
    onGetYear();
  }, []);

  const onGetYear = async () => {
    try {
      const response: any = await dispatch(getDashboardStatistics());
      if (response.data?.list) {
        setYearsData(response.data?.list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 커스텀 툴팁
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (!active || !payload || !payload.length) return null;

    const unit = [formatMessage({ id: 'Dashboard_3', defaultMessage: '건' }), formatMessage({ id: 'StartExam_35', defaultMessage: '명' })];

    const tooltipContent = Object.keys(yearsData).map((year: string) => {
      const data = payload[0].payload[`data_${year}`];

      return (
        <div className='tooltip-group' key={year}>
          <div className='tooltip-label'>{`${year}${formatMessage({ id: 'Dashboard_5', defaultMessage: '년' })}`}</div>
          {data ?
            <div className='tooltip-content'>
              <div>{`${formatMessage({ id: 'Infection_25', defaultMessage: 'PC 감염 / 정보 유출' })} : ${data.infection}${unit[0]}`}</div>
              <div>{`${formatMessage({ id: 'Download_5', defaultMessage: '파일 다운 / 피싱 접속' })} : ${data.download}${unit[0]}`}</div>
              <div>{`${formatMessage({ id: 'Read_1', defaultMessage: '열람' })} : ${data.read}${unit[1]} / ${data.target}${unit[1]}`}</div>
              <div>{`${formatMessage({ id: 'Send_1', defaultMessage: '발송' })} : ${data.send}${unit[1]} / ${data.target}${unit[1]}`}</div>
              <div>{`${formatMessage({ id: 'Dashboard_4', defaultMessage: '위험률' })} : ${data.progress}%`}</div>
            </div>
            : <div className="tooltip-content">{formatMessage({ id: 'StartExam_55', defaultMessage: '훈련 결과 없음' })}</div>
          }
        </div>
      );
    });

    return (
      <div className='dashboard-custom-tooltip'>
        {tooltipContent.filter(Boolean).length > 0 ? (
          tooltipContent
        ) : (
          <div className='tooltip-content'>
            {formatMessage({ id: 'StartExam_55', defaultMessage: '훈련 결과 없음' })}
          </div>
        )}
      </div>
    );
  };

  const CustomLegend = ({ payload }: any) => {
    return (
      <div className='dashboard-legend'>
        {payload?.map((entry: any) => (
          <div key={`item-${entry.value}`} className='legend-item'>
            <div className='color-circle' style={{ backgroundColor: entry.color }} />
            <div>
              {`${entry.value}${formatMessage({ id: 'Dashboard_5', defaultMessage: '년' })} ${formatMessage({ id: 'Dashboard_6', defaultMessage: '히스토리' })}`}
            </div>
          </div>
        ))}
      </div>
    );
  };

  // 연간 훈련 위험 통계 데이터
  const chartData = useMemo(() => {
    const allMonths = Array.from({ length: 12 }, (_, index) => index + 1);

    return allMonths.map((month) => {
      const monthData: any = { month: `${month}${formatMessage({ id: 'Exam_94', defaultMessage: '월' })}` };
      Object.keys(yearsData).forEach((year: string) => {
        const yearData = yearsData[year].find((item: any) => item.month === month);
        monthData[`progress_${year}`] = yearData ? yearData.progress : 0;
        monthData[`data_${year}`] = yearData;
      });
      return monthData;
    });
  }, [yearsData]);

  // 연도별 색상 배열
  const colors = ['#CE0808', '#1CA63E', '#4CD7F6'];

  return (
    <div className='dash-years'>
      <div className='result-group right'>
        <div className='group-title'>
          {formatMessage({ id: 'Dashboard_2', defaultMessage: '연간 훈련 위험 통계' })}
        </div>
        <div className='line-graph-box'>
          <ResponsiveContainer width={1120} height={270}>
            <LineChart
              data={chartData}
              margin={{
                top: 10,
                right: 5,
                left: -20,
                bottom: 5,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis dataKey="month" axisLine={false} tickLine={false} tickMargin={20} padding={{ left: 30, right: 30 }} height={40} />
              <YAxis tickCount={6} axisLine={false} tickLine={false} ticks={[0, 20, 40, 60, 80, 100]} unit="%" />
              <ChartTooltip content={<CustomTooltip />} />
              <Legend content={<CustomLegend />} verticalAlign="top" align="right" />
              {Object.keys(yearsData)?.reverse()?.map((year, index) => {
                return (
                  <Line
                    key={year}
                    type="linear"
                    dataKey={`progress_${year}`}
                    name={year}
                    strokeWidth={3}
                    dot={{ stroke: colors[index], strokeWidth: 7 }}
                    stroke={colors[index]}
                    fill={colors[index]}
                    animationDuration={4000} // 애니메이션 속도
                  />
                );
              })}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}

export default DashboardYears;