import TableList from 'components/common/TableList';
import TablePagination from 'components/common/TablePagenation';
import TableSearch from 'components/common/TableSearch';
import LogDetail from 'components/branch/log/LogDetail'
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual } from 'react-intl/src/utils';
import { useDispatch, useSelector } from 'react-redux';
import { changeParam, getLog } from 'store/log';
import { PlusCircleFilled, MinusCircleTwoTone } from '@ant-design/icons'


export default function LogList(props: any) {
  let tableRowCount = Math.trunc((window.innerHeight - 315) / 48);
  tableRowCount = tableRowCount < 5 ? 5 : tableRowCount;

  const { formatMessage } = useIntl();
  const [loading, setLoding] = useState(false)
  const [pageSize, setPageSize] = useState(tableRowCount)
  const [currentPage, setCurrentPage] = useState(0)
  const dispatch = useDispatch();
  const { sort, filter } = useSelector((state: any) => ({
    sort: state.log.param.sort,
    filter: state.log.param.filter
  }), shallowEqual)
  const needReload = useSelector((state: any) => state.log.needReload)
  const respData = useSelector((state: any) => state.log.data)
  const totalCount = useSelector((state: any) => state.log.totalCount)
  const totalPages = useSelector((state: any) => state.log.totalPages)
  const isAdmin = useSelector((state: any) => state.login.userData.isAdmin)

  useEffect(() => {
    getLogData();
  }, [sort, filter, pageSize, currentPage, needReload])

  // API 호출
  const getLogData = async () => {
    if (!loading) {
      setLoding(true);

      try {
        const params = {
          filter: JSON.stringify(filter),
          sort: JSON.stringify(sort),
          offset: pageSize * (currentPage < 0 ? 0 : currentPage), // page 는 0 번 부터 시작
          limit: pageSize < 1 ? 20 : pageSize
        }

        await dispatch(getLog(params))
      } catch (e) {
        console.log(e);
      }
    }

    setLoding(false);
  }

  // 검색 
  const searchOption = {
    searchAll: formatMessage({ id: 'Faq_1', defaultMessage: '전체' }),
    serviceNo: formatMessage({ id: 'Service_8', defaultMessage: '서비스No' }),
    serviceName: formatMessage({ id: 'Service_1', defaultMessage: '서비스명' }),
    logNo: "No",
    logCategory: "API",
    userName: formatMessage({ id: 'Name_1', defaultMessage: '이름' }),
    userEmail: formatMessage({ id: 'Email_1', defaultMessage: '이메일' }),
    regIp: formatMessage({ id: 'Log_4', defaultMessage: '접속 IP' }),
    logTitle: formatMessage({ id: 'Log_5', defaultMessage: '작업' }),
  }

  // 필터
  const filterOption: { [key: string]: any } = {
    logTypeArray: {
      name: formatMessage({ id: 'Template_22', defaultMessage: '구분' }),
      child: [
        {
          label: formatMessage({ id: 'Log_6', defaultMessage: '사용자 로그인' }),
          value: "1"
        },
        {
          label: formatMessage({ id: 'Log_7', defaultMessage: '관리자 로그인' }),
          value: "2"
        },
        {
          label: formatMessage({ id: 'Log_8', defaultMessage: '시스템' }),
          value: "4"
        },
        {
          label: formatMessage({ id: 'Log_9', defaultMessage: '일반' }),
          value: "8"
        }
      ]
    }
  }
  // 테이블 
  const baseColumns: Array<{ [key: string]: any }> = [
    {
      Header: formatMessage({ id: 'Service_8', defaultMessage: '서비스No' }),
      accessor: 'serviceNo',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center number-text'
    },
    {
      Header: formatMessage({ id: 'Service_1', defaultMessage: '서비스명' }),
      accessor: 'serviceName',
      resizable: false,
      sortable: true,
      width: 143,
      headerClassName: 'react-table-th',
      className: 'react-table-td sub-text'
    },
    {
      Header: "No",
      accessor: 'logNo',
      resizable: false,
      sortable: true,
      width: 72,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center number-text'
    },
    {
      Header: formatMessage({ id: 'Template_22', defaultMessage: '구분' }),
      accessor: 'logType',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        let printValue = '-';
        if (props.value === 1) {
          printValue = formatMessage({ id: 'Log_6', defaultMessage: '사용자 로그인' });
        } else if (props.value === 2) {
          printValue = formatMessage({ id: 'Log_7', defaultMessage: '관리자 로그인' });
        } else if (props.value === 4) {
          printValue = formatMessage({ id: 'Log_8', defaultMessage: '시스템' });
        } else if (props.value === 8) {
          printValue = formatMessage({ id: 'Log_9', defaultMessage: '일반' });
        } else {
          // 나머지
        }
        return printValue;
      }
    },
    {
      Header: "API",
      accessor: 'logCategory',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th',
      className: 'react-table-td'
    },
    {
      Header: formatMessage({ id: 'Name_1', defaultMessage: '이름' }),
      accessor: 'userName',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
    },
    {
      Header: formatMessage({ id: 'Email_1', defaultMessage: '이메일' }),
      accessor: 'userEmail',
      resizable: false,
      sortable: true,
      width: 199,
      headerClassName: 'react-table-th',
      className: 'react-table-td',
    },
    {
      Header: formatMessage({ id: 'Log_4', defaultMessage: '접속 IP' }),
      accessor: 'regIp',
      resizable: false,
      sortable: true,
      width: 125,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
    },
    {
      Header: formatMessage({ id: 'Date_1', defaultMessage: '일시' }),
      accessor: 'regEpoch',
      sortable: true,
      resizable: false,
      width: 177,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center sub-text',
      Cell: function regEpoch(props: { [key: string]: any }) {
        const { value } = props;
        const dateFormat = localStorage.getItem('language') === 'en' ? 'MM/DD/YYYY' : 'YYYY-MM-DD';
        return (
          <div>
            {moment.unix(value).format(`${dateFormat} HH:mm:ss`)}
          </div>
        );
      }
    },
    {
      Header: formatMessage({ id: 'Log_10', defaultMessage: '상세' }),
      resizable: false,
      sortable: false,
      width: 72,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
      Cell: function detail(props: { [key: string]: any }) {
        return (
          <span
            {...props.row.getToggleRowExpandedProps()} // eslint-disable-line
          >
            {props.row.isExpanded ? <MinusCircleTwoTone style={{fontSize: 20}} /> : <PlusCircleFilled style={{fontSize: 20}} />}
          </span>
        )
      }
    },
    {
      Header: formatMessage({ id: 'Log_5', defaultMessage: '작업' }),
      accessor: 'logTitle',
      resizable: false,
      sortable: true,
      width: 390,
      headerClassName: 'react-table-th',
      className: 'react-table-td'
    }
  ]


  const columns: Array<any> = useMemo(() => baseColumns, [respData])

  if (
    respData?.result === 1 &&
    respData.success &&
    Array.isArray(respData.success) &&
    respData.success.length > 0
  ) {
    if (respData.success[0].rnum) {
      respData.success.map((userData: any, index: number) => {

        // respData.success[index].subRows = <div> test </div>
        return true
      })
    }
  }

  return (
    <>
      <TableSearch
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalCount={totalCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        changeParam={changeParam}
        searchOption={searchOption}
        filter={filter}
        filterOption={filterOption}
      />
      <TableList
        columns={columns}
        data={respData.success}
        changeParam={changeParam}
        loading={loading}
        SubComponent={LogDetail}
      />
      <TablePagination
        totalPages={totalPages}
        currentPage={currentPage + 1}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}