import React, { useState } from 'react';
import { Tooltip } from 'antd';
import { CopyOutlined, FormOutlined, DeleteOutlined, FileOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { Option } from 'utils/commonValues';
import ExamTemplateCopy from 'components/branch/examTemplate/ExamTemplateCopy';
import ExamTemplateAddEdit from 'components/branch/examTemplate/ExamTemplateAddEdit';
import ExamTemplateDelete from 'components/branch/examTemplate/ExamTemplateDelete';

function ExamTemplateSettings({ isAdmin, templateInfo }: any) {
  const { formatMessage } = useIntl();
  const [openModal, setOpenModal] = useState('');

  return (
    <div className="table-settings">
      {/* 템플릿 복제 */}
      <Tooltip
        overlayClassName="black-tooltip"
        placement="bottom"
        title={formatMessage({ id: 'Template_65', defaultMessage: '템플릿 복제' })}
      >
        <CopyOutlined onClick={() => setOpenModal('copy')} />
      </Tooltip>

      {openModal === 'copy' && (
        <ExamTemplateCopy
          visible={openModal === 'copy'}
          isAdmin={isAdmin}
          templateInfo={templateInfo}
          toggleModal={(open: boolean) => setOpenModal(open ? 'copy' : '')}
        />
      )}

      {/* 템플릿 수정 / 상세 보기 */}
      <Tooltip
        overlayClassName="black-tooltip"
        placement="bottom"
        title={
          (isAdmin && Option.isSaas === 1) || templateInfo.serviceTemplateNo
            ? formatMessage({ id: 'Template_66', defaultMessage: '템플릿 수정' })
            : formatMessage({ id: 'Template_68', defaultMessage: '템플릿 상세 보기' })
        }
      >
        {(isAdmin && Option.isSaas === 1) || templateInfo.serviceTemplateNo ? (
          <FormOutlined onClick={() => setOpenModal('edit')} />
        ) : (
          <FileOutlined onClick={() => setOpenModal('edit')} />
        )}
      </Tooltip>

      {openModal === 'edit' && (
        <ExamTemplateAddEdit
          visible={openModal === 'edit'}
          isAdmin={isAdmin}
          isAddMode={false}
          templateInfo={templateInfo}
          toggleModal={(open: boolean) => setOpenModal(open ? 'edit' : '')}
        />
      )}

      {/* 템플릿 삭제 */}
      {((!Option.isGs && isAdmin) || templateInfo.serviceTemplateNo) && (
        <Tooltip
          overlayClassName="black-tooltip"
          placement="bottom"
          title={formatMessage({ id: 'Template_67', defaultMessage: '템플릿 삭제' })}
        >
          <DeleteOutlined onClick={() => setOpenModal('delete')} />
        </Tooltip>
      )}

      {openModal === 'delete' && (
        <ExamTemplateDelete
          visible={openModal === 'delete'}
          templateInfo={templateInfo}
          toggleModal={(open: boolean) => setOpenModal(open ? 'delete' : '')}
        />
      )}
    </div>
  );
}

export default ExamTemplateSettings;
