import { Option, apiUrl } from 'utils/commonValues';
import { actionFormat } from 'utils/commonFunctions';

// Action Types
const SET_INITIAL = 'tag/SET_INITIAL';
const GET_TAG = 'tag/GET_TAG';
const PUT_TAG = 'tag/PUT_TAG';
const ADD_TAG = 'tag/ADD_TAG';
const DELETE_TAG = 'tag/DELETE_TAG';
const DELETE_ALL_TAG = 'tag/DELETE_ALL_TAG';
const CHANGE_TAG_STATE = 'tag/CHANGE_TAG_STATE';
const CHECK_TAG = 'tag/CHECK_TAG';

// Actions
export const setInitial = () => actionFormat(SET_INITIAL);
export const getTag = (param: any) => actionFormat(GET_TAG, param, 'get', `${apiUrl}Tag`);
export const putTag = (param: any) => actionFormat(PUT_TAG, param, 'put', `${apiUrl}Tag`);
export const addTag = (param: any) => actionFormat(ADD_TAG, param, 'post', `${apiUrl}Tag`);
export const deleteTag = (param: any) => actionFormat(DELETE_TAG, param, 'delete', `${apiUrl}Tag`);
export const deleteAllTag = () =>
  actionFormat(DELETE_ALL_TAG, null, 'delete', `${apiUrl}Tag/DeleteAll`);
export const changeTagState = (param: any) => actionFormat(CHANGE_TAG_STATE, param);
export const checkTag = (param: any) => actionFormat(CHECK_TAG, param, 'get', `${apiUrl}Tag`);

// Initial state
const initialState: any = () => {
  return {
    data: [],
    needReload: false,
    param: {
      filter: {},
      sort: [{ field: 'tagNo', order: 'DESC' }],
      offset: 0,
      limit: 5,
    },
    isAllOpen: true,
    openList: [],
    dataByTagNo: {},
    tagInitialData: {},
    selectedTag: [{ tagNo: 'all-tag', tagLevel: 0 }],
    multiType: 'or',
    treeView: false,
  };
};

// Reducers
export default function (state = initialState(), action: any) {
  switch (action.type) {
    case SET_INITIAL:
      return initialState();
    case GET_TAG:
      if (action.payload) {
        const { list } = action.payload.data;
        /* ldapLevel: 0-최상위(회사명)/1-상위(부서명)/2-(관련)팀명/null-기타(대상자연동 아닌 태그) */
        state.needReload = false;
        state.data = list;

        const initial: any = {};
        const tagData: any = {};
        if (Option.isTagTree && state.treeView) {
          list.forEach((item: any) => {
            if (!initial[item.ldapLevel]) {
              initial[item.ldapLevel] = [];
            }
            initial[item.ldapLevel].push(item);
            tagData[item.tagNo] = item;
          });
        } else {
          list.forEach((item: any) => {
            if (!initial[item.tagInitial]) {
              initial[item.tagInitial] = [];
            }
            initial[item.tagInitial].push(item);
            tagData[item.tagNo] = item;
          });
        }

        state.dataByTagNo = tagData;
        state.tagInitialData = initial;
        state.openList = Object.keys(initial);
      }
      return state;
    case PUT_TAG:
      if (action.payload) {
        state.needReload = true;
      }
      return state;
    case ADD_TAG:
      if (!action.payload.data.error) {
        state.needReload = true;
      }
      return state;
    case DELETE_TAG:
      if (action.payload) {
        state.needReload = true;
      }
      return state;
    case DELETE_ALL_TAG:
      if (action.payload) {
        state.needReload = true;
      }
      return state;
    case CHANGE_TAG_STATE:
      if (action.payload) {
        const { key, value } = action.payload;
        state[key] = value;
      }
      return state;
    default:
      return state;
  }
}
