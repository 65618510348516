import { useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import parse from 'html-react-parser';
import { Option, updateVersion } from 'utils/commonValues';
import Footer from 'components/branch/Footer';
import './Intro.scss';

// import textImage from 'img/intro/main_text.png';
// import main1Image from 'img/intro/intro_main1.png';
// import main2Image from 'img/intro/intro_main2.png';
// import main3Image from 'img/intro/intro_main3.png';
// import main4Image from 'img/intro/intro_main4.png';

export const authLevel = 0;
function Intro(props: any) {
  const { formatMessage } = useIntl();

  const movePath = (path: string) => {
    props.history.push(path);
  };

  return (
    <div className="intro-page">
      <div className="body">
        <div
          className="intro-section section-1"
          style={{
            background: 'url(/img/intro/main_top_img.png) no-repeat',
            backgroundSize: '100%',
          }}
        >
          <div className="top-center">
            <img src="/img/intro/main_text.png" alt="text" />
            {Option.customer === 'hyundai' ? (
              <div className="title">{`"${formatMessage({
                id: 'Hyundai_1',
                defaultMessage: '현대차 그룹 피싱 모의훈련 플랫폼',
              })}"`}</div>
            ) : (
              <>
                <div className="title">
                  {`"${formatMessage({
                    id: 'Login_5',
                    defaultMessage: '보안의 시작과 끝은 사람',
                  })}"`}
                </div>
                <div className="sub-title">
                  {`${formatMessage({
                    id: 'Login_6',
                    defaultMessage: '악성 메일 모의 테스트로',
                  })} ${formatMessage({
                    id: 'Login_7',
                    defaultMessage: '보안 인식 수준을 점검하세요.',
                  })}`}
                </div>
              </>
            )}
            <div
              className="login-btn"
              onClick={() => movePath('/login')}
              aria-hidden="true"
              style={{ background: 'url(/img/login/login_button.png) no-repeat center' }}
            >
              {formatMessage({ id: 'Intro_1', defaultMessage: '시작하기' })}
            </div>
          </div>
        </div>

        <div className="intro-section section-2">
          <img src="/img/intro/intro_main1.png" alt="icon" />
          <div>
            <div className="section-title">
              {formatMessage({
                id: 'Intro_2',
                defaultMessage: "'메일의 첨부파일은 결코 안전하지 않습니다'",
              })}
            </div>
            <div className="main-text">
              {parse(
                formatMessage({
                  id: 'Intro_3',
                  defaultMessage:
                    '<div>보안인식 제고를 위한 <b>악성메일 대응 모의 훈련서비스, </b>저희와 함께하세요.</div>',
                }),
              )}
            </div>
            <ul>
              <li>
                {formatMessage({
                  id: 'Intro_4',
                  defaultMessage: '이메일을 통한 감염, 개인정보 유출, APT 공격 등',
                })}
                <br />
                {formatMessage({
                  id: 'Intro_5',
                  defaultMessage: '침해사고 발생 가능성 실태 파악',
                })}
              </li>
              <li>
                {formatMessage({
                  id: 'Intro_6',
                  defaultMessage: '악성메일 공격 회피에 대한 지속적인 반복 훈련을 통해',
                })}
                <br />
                {formatMessage({
                  id: 'Intro_7',
                  defaultMessage: '개인의 분별력 제고 및 보안의식 수준 측정/관리',
                })}
              </li>
            </ul>
          </div>
        </div>

        <div className="intro-section grey">
          <div>
            <div className="section-title">
              {formatMessage({ id: 'Intro_8', defaultMessage: '랜섬웨어 예방' })}
            </div>
            <div>
              {formatMessage({
                id: 'Intro_9',
                defaultMessage:
                  '당신의 소중한 데이터를 위협하는 랜섬웨어 멀웨어(워너크라이, 워너크립트 등)',
              })}
              <br />
              {formatMessage({
                id: 'Intro_10',
                defaultMessage:
                  '저희 서비스는 악성메일 모의 테스트를 통해 2차 피해를 미연에 방지합니다.',
              })}
            </div>
          </div>
        </div>

        <div className="intro-section section-4">
          <div className="section-item">
            <div className="image-area">
              <img src="/img/intro/intro_main2.png" alt="icon" />
            </div>
            <div className="section-title">
              {formatMessage({ id: 'Intro_11', defaultMessage: '보안인식 제고' })}
            </div>
            <div>
              {formatMessage({
                id: 'Intro_12',
                defaultMessage:
                  '반복적인 훈련을 통하여 보안 인식이 부족한 사람들의 보안 인식을 제고하게 합니다.',
              })}
            </div>
          </div>
          <div className="section-item">
            <div className="image-area">
              <img src="/img/intro/intro_main3.png" alt="icon" />
            </div>
            <div className="section-title">
              {formatMessage({ id: 'Intro_13', defaultMessage: '훈련대상 관리' })}
            </div>
            <div>
              {formatMessage({
                id: 'Intro_14',
                defaultMessage:
                  '훈련이 필요한 대상들을 연관 태그, 검색 기능을 이용하여 손쉽게 관리 및 선택할 수 있습니다.',
              })}
            </div>
          </div>
          <div className="section-item">
            <div className="image-area">
              <img src="/img/intro/intro_main4.png" alt="icon" />
            </div>
            <div className="section-title">
              {formatMessage({ id: 'Intro_15', defaultMessage: '쉽고 빠른 통계 확인' })}
            </div>
            <div>
              {formatMessage({
                id: 'Intro_16',
                defaultMessage:
                  '각 훈련별, 대상별로 쉽고 빠르게 시각적으로 통계 확인이 가능합니다.',
              })}
            </div>
          </div>
        </div>

        <div className="intro-section grey">
          <div className="left">
            <div className="section-title">
              {Option.customer === 'hyundai'
                ? formatMessage({
                    id: 'Hyundai_2',
                    defaultMessage: '피싱 모의훈련 플랫폼을 통한 완벽메일 예방',
                  })
                : formatMessage({
                    id: 'Intro_17',
                    defaultMessage: '제품을 통한 완벽 악성메일 예방',
                  })}
            </div>
            <div>
              {formatMessage({
                id: 'Intro_18',
                defaultMessage: '악성메일 공격을 통한 PC감염을 미연에 방지하세요.',
              })}
            </div>
          </div>
          <div>
            <div className="section-title">
              {formatMessage({
                id: 'Intro_19',
                defaultMessage: '모의 테스트를 통한 완벽한 사내 보안 관리',
              })}
            </div>
            <div>
              {formatMessage({
                id: 'Intro_20',
                defaultMessage: '모의 테스트를 통한 통계 수치로 완벽한 회사의 보안을 구성해보세요.',
              })}
            </div>
          </div>
        </div>

        <div className="intro-section section-6">
          <div className="section-item">
            <div className="section-title">
              {formatMessage({
                id: 'Intro_21',
                defaultMessage: '악성메일 모의 테스트를 통해 단단한 기업 보안을 준비하세요.',
              })}
            </div>
            <div>
              {formatMessage({ id: 'Login_4', defaultMessage: '이미 회원이신가요?' })}
              {/* <a href="/login" className="link">
              {formatMessage({ id: 'Login_1', defaultMessage: '로그인' })}
            </a> */}
              <span className="link" onClick={() => movePath('/login')} aria-hidden="true">
                {formatMessage({ id: 'Login_1', defaultMessage: '로그인' })}
              </span>
            </div>
          </div>
          {Option.isSaas === 1 && Option.customer !== 'hyundai' && (
            <div className="section-item">
              <div
                className="start-btn"
                onClick={() => movePath('/login/register')}
                aria-hidden="true"
              >
                {formatMessage({ id: 'Intro_22', defaultMessage: '서비스 가입 후 시작하기' })}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* footer */}
      <Footer />
    </div>
  );
}

export default withRouter(Intro);
