import React from "react";
import { Redirect, Route, useRouteMatch, Switch } from "react-router-dom";
import AutoRoute from "components/common/AutoRoute"
import "./Mudlogin.scss";

export const authLevel = 0;
export default function Mudlogin(props: any) {
  const { url } = useRouteMatch();

  return (
    <>
      {/* <ul>
        <li>
          <Link to='category'>Shoes</Link>
        </li>
      </ul> */}
      <div className="admin-login">
        <Switch>
          <Route path={`${url}/:name`} component={AutoRoute} />
          <Redirect path="*" to="/mudlogin/admin" />
        </Switch>
      </div>
    </>
  );
}