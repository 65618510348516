export default function Monit(props: any) {

  return (
    <iframe
        id="inlineFrameExample"
        title="Inline Frame Example"
        width="100%"
        height="100%"
        src="/monit"
        style= {{border: 0}}
      />
  )
}