import { apiUrl } from 'utils/commonValues';
import { actionFormat } from 'utils/commonFunctions';

// Action Types
const GET_SHARE_SERVICE = 'share/GET_SHARE_SERVICE';
const CHANGE_SHARE_SERVICE = 'share/CHANGE_SHARE_SERVICE';
const ADD_SHARE = 'share/ADD_SHARE';
const CANCEL_SHARE_USER = 'share/CANCEL_SHARE_USER';
const CANCEL_SHARE_SERVICE = 'share/CANCEL_SHARE_SERVICE';

// Actions
export const getShareService = () => actionFormat(GET_SHARE_SERVICE, null, 'get', `${apiUrl}Share`);
export const changeShareService = (params: any) =>
  actionFormat(CHANGE_SHARE_SERVICE, params, 'post', `${apiUrl}Share/ChangeService`);
export const addShare = (params: any) =>
  actionFormat(ADD_SHARE, params, 'post', `${apiUrl}Share/AddUser`);
export const cancelShareUser = (params: any) =>
  actionFormat(CANCEL_SHARE_USER, params, 'delete', `${apiUrl}Share/CancelUser`);
export const cancelShareService = (params: any) =>
  actionFormat(CANCEL_SHARE_SERVICE, params, 'delete', `${apiUrl}Share/CancelService`);

// Initial state
const initialState = {
  data: [],
  needReload: false,
};

// Reducers
export default function (state = initialState, action: any) {
  switch (action.type) {
    case GET_SHARE_SERVICE:
      if (action.payload) {
        const { list } = action.payload.data;
        state.needReload = false;
        state.data = list;
      }
      return state;
    case CHANGE_SHARE_SERVICE:
      state.needReload = false;
      return state;
    case ADD_SHARE:
      if (action.payload) {
        state.needReload = true;
      }
      return state;
    case CANCEL_SHARE_USER:
      if (action.payload) {
        state.needReload = true;
      }
      return state;
    case CANCEL_SHARE_SERVICE:
      if (action.payload) {
        state.needReload = true;
      }
      return state;
    default:
      return state;
  }
}
